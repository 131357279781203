* {
  &:before,
  &:after {
    outline: none;
  }

  &:focus,
  &.focus,
  &:active,
  &.active {
    outline: none;
  }
}

// ::selection {
//     background: $brand-yellow;
//     color: #000;
//     text-shadow: none;
// }

html {
  height: 100%;
  scroll-behavior: smooth;
  @include font-smoothing;
}

body {
  position: relative;
  min-height: 100%;
}

.bg-square {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  margin: 0;
  padding: 0;
  width: rem(20px);
  height: rem(240px);
  background-color: theme-color("primary");

  @include media-breakpoint-up(xl) {
    width: rem(100px);
    height: rem(500px);
  }
}
