.main-navigation__mob {
    margin-left: auto;
    height: 60px;
    display: flex;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.main-navigation__mob-trigger {
    $hamburger-width: 20px;

    display: flex;
    padding: 0;
    border: none;
    outline: inherit;
    background: none;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    &__label {
        @include line-height(28px, 20px);
        height: 60px;
        align-items: center;
        color: $white;
        transition: 300ms background ease-in-out;
    }

    &__icon {
        position: absolute;
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        margin-left: rem(12px);
        top: 22px;
        right: 10px;

        span {
            @include animate;
            position: absolute;
            left: 2px;
            width: $hamburger-width;
            height: 2px;
            background-color: $white;

            &:nth-child(1) {
                top: rem(6px);
            }

            &:nth-child(2),
            &:nth-child(3) {
                top: rem(12px);
            }

            span:nth-child(4) {
                top: rem(18px);
            }
        }
    }
    /**
     * Modifiers
     */
    &.is-active {
        .main-navigation__mob-trigger__label {
            @include link-underline(90%, 100%, theme-color("secondary"), .75);
            color: theme-color("secondary");
        }

        .main-navigation__mob-trigger__icon {
            top: 15px;

            span {
                background-color: theme-color("secondary");
                width: $hamburger-width;

                &:nth-child(1),
                &:nth-child(4) {
                    top: rem(12px);
                    opacity: 0;
                }

                &:nth-child(2) {
                    transform: rotate(45deg);
                }

                &:nth-child(3) {
                    transform: rotate(-45deg);
                }
            }
        }
    }
}

.main-navigation__mob-wrapper {
    @include animate(350ms);
    position: fixed;
    overflow: auto;
    top: 60px;
    right: -100%;
    width: calc(100% - 1.25rem);
    height: calc(100vh - 60px); /* Fallback for browsers that do not support Custom Properties */
    height: calc((var(--vh, 1vh) * 100) - 60px);
    background-color: $primary-200;
    box-shadow: 0 rem(20px) rem(30px) 0 rgba(0, 0, 0, .4);
    padding-bottom: rem(60px);
    /**
     * Modifiers
     */
    &.is-open {
        right: 0;
    }
}

.main-navigation__mob-lv-1 {
    &__item {
        a {
            text-decoration: none;
        }

        .level-2 {
            margin-left: rem(-20px);
            font-size: $font-size-xl;

            .icon {
                width: 15px;
                height: 15px;
                fill: theme-color("primary-800");
                position: relative;
                left: -5px;

                svg {
                    width: 15px;
                    height: 15px;
                }
            }
        }

        > button,
        > a {
            @include line-height(28px, 20px);
            width: 100%;
            position: relative;
            color: $primary-800;
            padding: 17px 0 14px;
            border: none;
            outline: inherit;
            background: none;
            cursor: pointer;
            font-size: rem(20px);
            text-align: left;
            display: block;
        }

        > button {
            font-size: $font-size-xl;

            .icon {
                @include animate(100ms);
                position: absolute;
                right: 20px;

                svg {
                    fill: theme-color("primary");
                }
            }

            + hr {
                display: none;
            }

            &.collapsed {
                .icon {
                    transform: rotate(-180deg);
                    transform-origin: 50% 60%;

                    svg {
                        fill: theme-color("secondary");
                    }
                }

                + hr {
                    display: block;
                }
            }
        }

        .link {
            @include line-height(24px, 18px);
            font-size: rem(18px);
            color: theme-color("primary-800");
            text-decoration: none;
        }
    }
    // stylelint-disable
    label {
        color: red;
    }
    // stylelint-enable

    ul li a {
        color: blue;
    }

    /**
     * Modifiers
     */
    &.is-primary {
        background-color: $white;

        > button,
        > a {
            font-weight: $font-weight-semibold;
        }
    }
}

.main-navigation__mob-languages-wrapper {
    @include animate(350ms);
    position: fixed;
    overflow: auto;
    top: calc(100% - 60px);
    top: calc((var(--vh, 1vh) * 100) - 60px);
    right: -100%;
    width: calc(100% - 1.25rem);
    height: calc(100% - 60px);
    height: calc((var(--vh, 1vh) * 100) - 60px);
    background-color: $white;

    &.is-shown {
        right: 0;
    }
    &.is-open {
        top: rem(60px);
    }
}

.main-navigation__mob-lang-trigger {
    @include line-height(24px, 18px);
    position: relative;
    display: block;
    width: 100%;
    height: rem(60px);
    border: none;
    outline: none;
    background: $primary-800;
    color: $white;
    cursor: pointer;
    font-size: rem(18px);
    text-align: left;
    font-weight: $font-weight-normal;

    .plus-btn {
        position: absolute;
        top: rem(22px);
        right: 1.5rem;
        width: 1rem;
        height: 1rem;

        &:before,
        &:after {
            content: "";
            position: absolute;
            background-color: white;
            transition: transform .25s ease-out;
        }
        /* Vertical line */
        &:before {
            top: 0;
            left: 50%;
            width: rem(2px);
            height: 100%;
            margin-left: rem(-1px);
        }
        /* horizontal line */
        &:after {
            top: 50%;
            left: 0;
            width: 100%;
            height: rem(2px);
            margin-top: rem(-1px);
        }
    }

    &:focus {
        outline: none;
    }

    &.is-active {
        .plus-btn {
            &:before {
                transform: rotate(90deg);
            }
            &:after {
                transform: rotate(180deg);
            }
        }
    }
}

.main-navigation__mob-languages {
    padding: rem(9px) 1.5rem 1.5rem;

    .mb-5 {
        margin-bottom: rem(29px) !important;
    }
}

.main-navigation__mob-ctas {
    background-color: $white;
}