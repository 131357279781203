&__search {
    margin: 0;

    > .list-inline-item:not(:last-child) {
        margin-right: rem(30px);
    }

    .form {
        &-control {
            padding-right: 70px;
            border: 1px solid theme-color("primary-600");
            background-color: theme-color("primary");
            color: $white;
            max-width: 190px;
        }

        .submit {
            position: absolute;
            top: 0;
            right: 0;
            border: 0;
            background: transparent;
            border-radius: 33px;
            width: 60px;
            height: 100%;

            .icon {
                fill: $white;
            }
        }
    }

    &--mobile {
        .form-control {
            max-width: none;
            background-color: $white;
            border: 1px solid theme-color("primary-400");
        }

        .form {
            .submit {
                .icon {
                    fill: theme-color("primary");
                }
            }
        }
    }
}
