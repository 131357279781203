.page-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: rem(60px);
  padding: 0 rem(20px);
  z-index: 999;

  @include media-breakpoint-up(lg) {
    padding: 0 $grid-gutter-width * .5;
    height: rem(140px);
  }

  &__placeholder {
    position: relative;
    margin: 0;
    padding: 0;
    width: 100%;
    height: rem(60px);
    background: transparent;

    @include media-breakpoint-up(lg) {
      height: rem(140px);
    }
  }

  &.headroom {
    transition: all 400ms cubic-bezier(.45, 0, .14, 1.03);

    &--pinned { top: 0; }

    &--unpinned { top: rem(-40px); }
  }
}

.pagemode-edit,
.pagemode-preview,
.pagemode-debug {
  .page-header {
    position: relative;
    padding: 0;

    &__placeholder {
      display: none;
    }
  }
}
