.hce-product-cards {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
  margin-bottom: -1rem;
  background-color: $primary-100;

  &__grid-introduction,
  &__grid-introduction-cta-sm {
    display: none; // Styling part of modifier `is-grid`
  }

  // Modifier; grid view
  &.is-grid {
    .hce-product-cards__grid-introduction {
      display: inline-block;
      margin-bottom: 1rem;

      @include media-breakpoint-up(md) {
        display: flex;
        flex-flow: column;
      }

      h3 {
        margin-top: rem(-7px);
        margin-bottom: rem(12px);

        @include media-breakpoint-up(md) {
          margin-top: 0;
          margin-bottom: rem(22px);
        }
      }

      .lead {
        margin-bottom: rem(9px);

        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }
      }

      .btn {
        display: none;

        @include media-breakpoint-up(md) {
          display: inline-block;
          margin-top: auto;
        }
      }
    }

    .hce-product-cards__grid-introduction-cta-sm {
      display: inline-block;
      margin-top: 1rem;
      margin-bottom: 1rem;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    // Apply Bootstrap classes to change layout
    .grid-row { @extend .row; }
    .grid-col {
      @extend .col-md-4;
      @extend .col-lg-3;
    }

    // Overwrite image wrapper col size
    // Overwrite content wrapper col size
    // Overwrite specs col size
    .hce-product-card__img-md,
    .hce-product-card__content-wrapper,
    .hce-product-card__specs .col-6.col-md-3,
    .hce-product-card__specs .col-6.col-md-2 {
      @extend .col-md-12;
    }

    .hce-product-card__title,
    .hce-product-card__spec-label,
    .hce-product-card__spec {
      @include media-breakpoint-up(md) {
        padding-right: 1rem;
        padding-left: 1rem;
      }
    }

    .hce-product-card__specs {
      margin-bottom: .5rem;

      .col-6.col-md-3,
      .col-6.col-md-2 {
        margin-bottom: .5rem;
      }
    }
  }

  // Modifier; dark background
  &.is-dark {
    background-color: $primary;

    .hce-product-cards__grid-introduction {
      h3,
      p.lead {
        color: $white;
      }
    }

    .hce-product-cards__grid-introduction,
    .hce-product-cards__grid-introduction-cta-sm {
      .btn {
        border-color: $white;
        color: $white;
      }
    }

    .hce-product-card {
      border: 0;

      > svg {
        path:nth-child(1) { display: none; }
        path:nth-child(2) { fill: $primary; }
      }

      // State; focus
      &:focus {
        outline-color: $primary-100;
      }
    }
  }
}

.hce-product-card {
  display: block;
  position: relative;
  border: rem(1px) solid $primary-200;
  margin-bottom: 1rem;
  background-color: $white;
  text-decoration: none;

  // State; focus
  &:focus {
    outline: rem(1px) dotted $primary;
  }

  // State; focus and hover
  &:focus,
  &:hover {
    text-decoration: none;

    .hce-product-card__title {
      .h6 {
        @include link-underline(70%, 85%, $secondary, 1);
      }
    }

    // Move cta, works for both absolute as relative positioned
    .hce-product-card__cta .icon {
      right: rem(0);
      margin-left: .5rem;
    }
  }

  // Bottom right corner (styling)
  > svg {
    position: absolute;
    z-index: 1;
    right: rem(-1px);
    bottom: rem(-1px);
    width: rem(17px);
    height: rem(27px);
  }

  // Wrapper for title (and img-sm on mobile)
  &__content-wrapper {
    @include media-breakpoint-up(md) {
      .hce-product-cards:not(.is-grid) & {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
    }

    .row:first-child {
      display: flex;
      align-items: center;
    }
  }

  &__img-md {
    img { height: rem(168px); }
  }

  // &__img-sm {} // No default styling required

  &__title {
    @include media-breakpoint-up(md) {
      margin-top: rem(15px);
    }

    .h6 {
      @include link-underline(56%, 72%, $secondary, .25);

      transition: 150ms all ease-in-out;
    }
  }

  &__specs {
    margin-top: rem(10px);
    margin-bottom: rem(10px);

    @include media-breakpoint-up(md) {
      margin-top: rem(2px);
      margin-bottom: 1rem;
    }

    // Overwriting column width is required to support layout nested columns
    .col-6 {
      margin-top: rem(2px);
      margin-bottom: rem(2px);

      @include media-breakpoint-up(md) {
        flex: 0 0 20%;
        max-width: 20%;
        margin-top: 0;
        margin-bottom: 0;

        &:nth-child(1) {
          flex: 0 0 30%;
          max-width: 30%;
        }
      }
    }

    // Apply required padding for mobile
    .col-6 {
      &:nth-child(1),
      &:nth-child(3) {
        .hce-product-card__spec-label,
        .hce-product-card__spec {
          padding-left: 1rem;
        }
      }

      &:nth-child(2),
      &:nth-child(4) {
        .hce-product-card__spec-label,
        .hce-product-card__spec {
          padding-right: 1rem;
        }
      }

      @include media-breakpoint-up(md) {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }

  &__spec-label {
    width: 100%;
    color: $primary-900;
    cursor: pointer;
    font-size: $font-size-xs;
    letter-spacing: rem(2px);
  }

  &__spec {
    display: block;
    color: $primary-900;
    font-size: $font-size-sm;
    font-weight: $font-weight-semibold;
    line-height: 1rem;

    @include media-breakpoint-up(md) {
      .hce-product-cards:not(.is-grid) & {
        font-size: $font-size-base;
      }
    }
  }

  &__cta {
    padding: 9px 0;
    margin: 0 1rem;
    border-top: rem(1px) solid $primary-200;

    .icon {
      transition: 150ms all ease-in-out;
    }

    svg {
      fill: theme-color("secondary");
    }
  }

  // Styling only required for list view (default state)
  .hce-product-cards:not(.is-grid) & {
    // Add divider right side
    @include media-breakpoint-up(md) {
      &:after {
        content: "";
        position: absolute;
        top: rem(10px);
        right: 6%;
        width: rem(1px);
        height: calc(100% - 20px);
        background-color: $primary-200;
      }
    }

    // Absolute reposition cta button
    &__cta {
      @include media-breakpoint-up(md) {
        position: absolute;
        right: rem(5px);
        bottom: rem(13px);
        width: 1.75rem;
        height: 1.75rem;
        padding: 0;
        border: none;
        margin: 0;
      }

      @include media-breakpoint-up(lg) {
        right: rem(14px);
      }

      svg {
        fill: theme-color("secondary");
      }
    }

    &__img-md {
        img {
          min-height: 100%;
          height: rem(120px);
        }
    }
  }
}
