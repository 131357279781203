@mixin clipped($w, $h, $side: "left") {
  @if $side == "right" {
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - #{$h}), calc(100% - #{$w}) 100%, 0 100%);
  } @else {
    clip-path: polygon(0 0, 100% 0, 100% 100%, #{$w} 100%, 0 calc(100% - #{$h}));
  }
}

$clip-small: ( w: '15px', h: '24px' );
$clip-medium: ( w: '30px', h: '48px' );
$clip-large: ( w: '45px', h: '72px' );

.clipped {
  &--small {
    &.clipped--left {
      @include clipped(map-get($clip-small, w), map-get($clip-small, h), "left");
    }
    &.clipped--right {
      @include clipped(map-get($clip-small, w), map-get($clip-small, h), "right");
    }
  }

  &--medium {
    &.clipped--left {
      @include clipped(map-get($clip-small, w), map-get($clip-small, h), "left");
    }
    &.clipped--right {
      @include clipped(map-get($clip-small, w), map-get($clip-small, h), "right");
    }

    @include media-breakpoint-up(md) {
      &.clipped--left {
        @include clipped(map-get($clip-small, w), map-get($clip-small, h), "left");
      }
      &.clipped--right {
        @include clipped(map-get($clip-small, w), map-get($clip-small, h), "right");
      }
    }

    @include media-breakpoint-up(lg) {
      &.clipped--left {
        @include clipped(map-get($clip-medium, w), map-get($clip-medium, h), "left");
      }
      &.clipped--right {
        @include clipped(map-get($clip-medium, w), map-get($clip-medium, h), "right");
      }
    }
  }

  &,
  &--large {
    &.clipped--left {
      @include clipped(map-get($clip-small, w), map-get($clip-small, h), "left");
    }
    &.clipped--right {
      @include clipped(map-get($clip-small, w), map-get($clip-small, h), "right");
    }

    @include media-breakpoint-up(md) {
      &.clipped--left {
        @include clipped(map-get($clip-medium, w), map-get($clip-medium, h), "left");
      }
      &.clipped--right {
        @include clipped(map-get($clip-medium, w), map-get($clip-medium, h), "right");
      }
    }

    @include media-breakpoint-up(lg) {
      &.clipped--left {
        @include clipped(map-get($clip-large, w), map-get($clip-large, h), "left");
      }
      &.clipped--right {
        @include clipped(map-get($clip-large, w), map-get($clip-large, h), "right");
      }
    }
  }
}
