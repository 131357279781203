.hce-image-slider {
  position: relative;
  &__carousel {
    padding: 50px 20px;
    @include media-breakpoint-up(sm) {
      padding: 50px 12px;
    }
  }

  &__carousel-inner {
    position: relative;
    /*&::after {
      @include media-breakpoint-up(md) {
        @include triangle(bottomright, 42px, 28px, #fff);
        .bg-medium & {
          @include triangle(bottomright, 42px, 28px, #f1f1f1);
        }
        .bg-dark & {
          @include triangle(bottomright, 42px, 28px, #00093b);
        }
      }

      position: absolute;
      bottom: -1px;
      right: -1px;
    }*/
  }

  > img {
    width: 100%;

    @include media-breakpoint-up(md) {
      max-width: 470px;
      width: auto;
    }
  }

  .glide {
    &__slides {
      align-items: flex-end;
    }

    &__slide {
      &-wrapper {
        padding-top: 100%;
        width: 100%;
        position: relative;
        overflow: hidden;
      }
      img {
        position: absolute;
        left: -1000%;
        right: -1000%;
        top: -1000%;
        bottom: -1000%;
        margin: auto;
        min-height: 100%;
        min-width: 100%;
      }
    }

    &__arrows {
      position: absolute;
      left: 0;
      bottom: 0;
    }

    &__arrow {
      .icon {
        svg {
          fill: $white;
        }
      }

      &--left,
      &--right {
        position: absolute;
        bottom: 1rem;

        @include media-breakpoint-up(md) {
          bottom: 1.6rem;
        }
      }

      &--left {
        left: 1rem;

        @include media-breakpoint-up(md) {
          left: 2rem;
        }
      }

      &--right {
        right: 1rem;

        @include media-breakpoint-up(md) {
          right: 2rem;
        }
      }
    }

    &__bullets {
      &--wrapper {
        position: absolute;
        right: 0;
        top: -20px;

        @include media-breakpoint-up(md) {
          top: auto;
          right: 35px;
          bottom: -30px;
        }
      }

      .glide__bullet {
        display: none;
        cursor: default;
        background-color: transparent;

        &--active {
          display: block;
        }
      }
    }
    /*&:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        to bottom,
        transparent 75%,
        rgba($primary, .6)
      );
      pointer-events: none;
      transition: opacity .2s;
    }

    &:hover:after {
      background-image: linear-gradient(
        to bottom,
        transparent 75%,
        rgba($primary, .8)
      );
    }*/
  }
}
