.modal { background-color: rgba($primary, .75); }

.modal-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  border: 0;
  margin: 1rem;
  outline: 0;
  background: none;
  cursor: pointer;

  span {
    @include animate;

    position: absolute;
    top: 50%;
    left: 50%;
    width: 1rem;
    border-bottom: rem(2px) solid $primary-600; // A11Y
    margin: rem(-1px) 0 0 rem(-8px);
    transition-property: border-color;

    &:first-child { transform: rotate(45deg); }
    &:last-child { transform: rotate(-45deg); }
  }

  &:hover,
  &:focus {
    span { border-color: $primary; }
  }
}

.modal-dialog { max-width: 60rem; }

.modal-content {
  padding: 23px 2rem 2rem 2rem;
  border-radius: 0;
}

.modal-title {
  width: 100%;
  text-align: center;

  h1,
  h2,
  h3,
  h4,
  h5 { margin: 0; }
}

/**
 * Content specific spacing changes
 */
.modal .language-selector { margin: rem(34px) 0 rem(-2px); }
