.teaser {
  position: relative;
  display: block;
  margin-bottom: 1.5rem;
  transition: filter 200ms;

  @include media-breakpoint-down(sm) {
    [class*="col-"]:last-child & {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }

  &__image {
    height: rem(280px);

    @include media-breakpoint-up(md) {
      height: rem(340px);
    }
  }

  &__gradient {
    opacity: .5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, transparent, $black);
    transition: 300ms opacity ease-in-out;
  }

  &__body {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 2rem 1rem 2rem;
  }

  &__hidden-content {
    max-height: 0;
    margin-top: .5rem;
    overflow: hidden;
    transition: 300ms max-height ease-in-out;
  }

  &__title {
    @include link-underline(62%, 72%, $white, .5);
    color: $white;
    transition: 300ms background ease-in-out;

    // reduce font-size for col-*-3
    @include media-breakpoint-up(md) {
      [class^="col-"][class$="-3"] & {
        font-size: $h5-font-size;
      }
    }
  }

  &__text {
    color: $white;
    font-weight: 300;
  }

  .icon {
    display: inline-block;
    margin-top: .75rem;
    fill: theme-color("secondary");
  }

  &:hover {
    @include media-breakpoint-up(md) {
      text-decoration: none !important;
      filter: drop-shadow(0 0 1.25rem rgba($black, .5));

      .teaser__gradient {
        opacity: 1;
        background: linear-gradient(180deg, rgba($black, .5), rgba($black, .75));
      }

      .teaser__title {
        @include link-underline(71%, 82%, $opacity: 1);
      }

      .teaser__hidden-content {
        max-height: rem(300px);
      }
    }
  }
}
