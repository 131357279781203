// stylelint-disable declaration-no-important, no-duplicate-selectors, selector-list-comma-newline-after

//
// Headings
//

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6,
.display-1, .display-2, .display-3, .display-4, .display-5, .display-6 {
  font-family: $headings-font-family;
  color: theme-color("primary");
}

h1, h2, h3, h4,
.h1, .h2, .h3, .h4 {
  color: theme-color("secondary");
}

h1, h2, h3,
.h1, .h2, .h3,
.display-1 {
  font-weight: $font-weight-bold;
}

h4, h5, h6,
.h4, .h5, .h6 {
  font-weight: $font-weight-semibold;
}

h1, .h1, .display-1  {
  font-size: $h1-font-size;
  letter-spacing: -1.17px;
  line-height: .857;
  text-transform: uppercase;
}

h2, .h2, .display-2 {
  font-size: $h2-font-size;
  letter-spacing: -1px;
  line-height: 1;
}

h3, .h3, .display-3 {
  margin-bottom: rem(24px);
  font-size: rem(32px);
  letter-spacing: -.89px;
  line-height: 1;

  @include media-breakpoint-up(md) {
    margin-bottom: rem(18px);
    font-size: $h3-font-size;
    letter-spacing: -1px;
    line-height: 1;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: rem(25px);
  }
}

h4, .h4, .display-4 {
  font-size: $h4-font-size;
  letter-spacing: -.5px;
  line-height: 1;
}

h5, .h5, .display-5 {
  font-size: $h5-font-size;
  line-height: 1.166;
  letter-spacing: -.6px;
}

h6, .h6, .display-6 {
  margin-bottom: rem(4px);
  font-size: $h6-font-size;
  letter-spacing: 0;
  @include line-height(20px, 18px);

  @include media-breakpoint-up(md) {
    margin-bottom: rem(8px);
    font-size: $h6-font-size;
    line-height: 1.166;
    letter-spacing: -.5px;
  }
}

.display-1 {
  color: $primary-dark;
  font-size: rem(36px);
  line-height: rem(36px);
  letter-spacing: rem(-1px);

  @include media-breakpoint-up(md) {
    font-size: rem(48px);
    line-height: 1;
    letter-spacing: rem(-1px);
  }

  @include media-breakpoint-up(lg) {
    font-size: $display1-size;
    line-height: .942;
    letter-spacing: -2px;
  }
}

.display-2, .display-3, .display-4, .display-5, .display-6 {
  font-weight: $font-weight-normal;
}

.lead {
  @include line-height(24px, 18px);
  font-size: rem(18px);
  font-weight: $font-weight-normal;

  @include media-breakpoint-up(md) {
    @include line-height(28px, 20px);
    font-size: rem(20px);
  }
}

p {
  @include line-height(20px, 16px);
  margin: 0;
  color: theme-color("primary-700");

  @include media-breakpoint-up(md) {
    @include line-height(24px, 18px);
    font-size: rem(18px);
  }
}

//
// Emphasis
//

small,
.small,
.tag,
label,
.label {
  color: theme-color("primary-800");
  font-size: $font-size-sm;
  font-weight: $font-weight-normal;
}

label,
.label {
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 3px;
  line-height: $font-size-base;
}

mark,
.mark {
  padding: $mark-padding;
  background-color: $mark-bg;
}

b,
strong {
  font-weight: $font-weight-bold;
  color: theme-color("primary-900");
}

//
// Horizontal rules
//

hr {
  margin-top: $hr-margin-y;
  margin-bottom: $hr-margin-y;
  border: 0;
  border-top: $hr-border-width solid $hr-border-color;
}

//
// Lists
//

.list-unstyled {
  @include list-unstyled;
}

// Inline turns list items into inline-block
.list-inline {
  @include list-unstyled;
}
.list-inline-item {
  display: inline-block;

  &:not(:last-child) {
    margin-right: $list-inline-padding;
  }
}


//
// Misc
//

// Builds on `abbr`
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

// Blockquote
.quote {
  padding-left: 2rem;
  border-left: 1rem solid $primary;
  margin: 3rem 0;
  color: $primary-800;
  font-size: 1.75rem;
  font-weight: $font-weight-light;
  letter-spacing: rem(-.5px);
  line-height: 1;

  @include media-breakpoint-up(md) {
    padding-left: 4rem;
    border-width: rem(10px);
    margin: 4rem 0;
    font-size: 2.25rem;
    letter-spacing: rem(-1px);
    line-height: 1.11;
  }
}

//
// Backgrounds
//

.bg-dark,
.bg-secondary {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  .display-1,
  .display-2,
  .display-3,
  .display-4,
  .display-5,
  .display-6,
  p,
  ul,
  li,
  strong,
  small,
  .small,
  label {
    color: $white !important;
  }

  .text-dark {
    color: $black !important;
  }
}

.text-light {
  color: $white !important;
}
