@mixin active-flyout {
    > .nav-link {
        @include link-underline(95%, 100%, theme-color("secondary"), .75);

        .text {
            color: theme-color("secondary");
        }
    }

    .main-navigation__flyout {
        opacity: 1;
        transform: translateY(0);
        box-shadow: 0 10px 50px 0 rgba($black, .5);
    }
}

@mixin active-level-2 {
    &:before {
        content: "";
        position: absolute;
        right: -8px;
        border-color: transparent transparent transparent $white;
        border-style: solid;
        border-width: 8px 0 8px 8px;
        top: auto;
        transform: translateY(22px);
    }

    > .nav-link {
        .title {
            .icon,
            .text {
                color: $primary;
                font-weight: $font-weight-bold;
            }
        }
    }

    > .level-3 {
        height: 100%;
        opacity: 1;
        overflow: visible;
        //padding: rem(32px) rem(24px) rem(65px) rem(32px);
        transform: translateX(100%);
        width: 100%;
    }
}

@mixin active-level-3 {
    &:before {
        content: "";
        position: absolute;
        right: -8px;
        border-color: transparent transparent transparent $white;
        border-style: solid;
        border-width: 8px 0 8px 8px;
        top: auto;
        transform: translateY(22px);
    }

    > .nav-link {
        .title {
            .icon,
            .text {
                color: $primary;
                font-weight: $font-weight-bold;
            }
        }

        .preview {
            display: block;
            opacity: 1;
            z-index: 2;
        }
    }
}

@mixin hover-bridge {
    &:after {
        content: "";
        left: 75%;
        position: absolute;
        right: 0;
        top: 0;
        width: 150px;
        height: 100%;
        background: transparent;
    }
}

&__items {
    position: relative;
    height: 100%;
    flex-grow: 1;

    .level-1 {
        align-items: center;
        display: flex;
        flex-flow: row wrap;
        gap: 0;
        height: 100%;
        margin-bottom: 0;

        > .nav-item {
            display: block;
            height: 100%;
            padding: 0 1rem;

            > .nav-link {
                height: 100%;
                padding: 0;

                .text {
                    @include line-height(28px, 20px);
                    @include align-vertical;
                    color: $white;
                    display: block;
                    font-size: rem(16px);
                    letter-spacing: 0;
                    user-select: none;
                }
            }

            &.has-flyout {
                &:hover,
                &:active,
                &:focus {
                    @include active-flyout;
                }

                &:focus-within {
                    @include active-flyout;
                }
            }
        }
    }

    .main-navigation__flyout {
        background-color: theme-color("primary-100");
        box-shadow: 0 10px 50px 0 rgba($black, 0);
        cursor: default;
        left: 0;
        min-height: rem(360px);
        opacity: 0;
        overflow: hidden;
        padding: 0;
        position: absolute;
        top: 100px;
        transform: translateY(-100%);
        transition:
            transform .5s cubic-bezier(.45, 0, .14, 1.03),
            100ms box-shadow ease-out;
        width: 100%;
        z-index: -1;
    }

    .level-2,
    .level-3 {
        list-style: none;
        margin: 0;
        padding: rem(32px) rem(24px) rem(65px) rem(32px);
        width: rem(350px);

        > .nav-item {
            border-bottom: 1px solid rgba(0, 0, 0, .1);

            > .nav-link {
                padding: 0;
                text-decoration: none;

                > .title {
                    align-items: center;
                    display: flex;
                    flex-direction: row-reverse;
                    gap: .5rem;
                    justify-content: space-between;
                    margin-bottom: 0;
                    max-height: rem(60px);
                    position: relative;
                }

                .icon {
                    $icon-size: 1rem;
                    display: block;
                    fill: theme-color("primary-600");
                    height: 100%;
                    width: $icon-size;

                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }

                .text {
                    color: theme-color("primary-600");
                }
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }

    .level-2 {
        background-color: $white;
        height: 100%;
        min-height: 360px;
        position: relative;
        z-index: auto;

        > .nav-item {
            &:hover,
            &:active,
            &:focus {
                @include active-level-2;
                @include hover-bridge;
            }

            &:focus-within {
                @include active-level-2;
            }
        }
    }

    .level-3 {
        background-color: theme-color("primary-100");
        height: 0;
        left: 0;
        opacity: 0;
        overflow: hidden;
        //padding: 0;
        position: absolute;
        top: 0;
        transform: translateX(0%);
        transition: transform .5s cubic-bezier(.45, 0, .14, 1.03);
        width: 0;
        z-index: -1;

        > .nav-item {
            &:hover,
            &:active,
            &:focus {
                @include active-level-3;
                @include hover-bridge;
            }

            &:focus-within {
                @include active-level-3;
            }

            > .nav-link {
                @include line-height(24px, 18px);
                color: $primary-800;
                font-size: rem(18px);
    
                .preview {
                    background-color: theme-color("primary-100");
                    bottom: 0;
                    display: none;
                    height: 400px;
                    left: 350px;
                    max-width: 360px;
                    opacity: 0;
                    overflow-x: auto;
                    overflow-y: hidden;
                    padding: rem(35px);
                    position: absolute;
                    top: 0;
                    transition: opacity .2s ease-in-out;
                    width: 100%;
    
                    .icon {
                        width: 100px;
                        height: auto;
                        fill: theme-color("primary");
                        display: block;
    
                        svg {
                            aspect-ratio: 1;
                            height: auto;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
