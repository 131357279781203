.hce-highlighted-articles {
  $component: &;
  padding: 2rem 0;

  @include media-breakpoint-up(md) {
    padding: rem(60px) 0;
  }

  @at-root .bg-dark & {
    #{$component}__title,
    p.lead,
    #{$component}__introduction-cta .btn,
    #{$component}__cta-sm .btn {
      color: $white;
    }
  }

  .row {
    padding-right: 1.25rem;

    @include media-breakpoint-up(md) {
      padding-right: 0;
    }
  }

  &__introduction-cta {
    margin-bottom: 1rem;

    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 0;
    }

    .btn {
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
        margin-top: 2rem;
      }
    }

    p.lead {
      width: 100%;
    }
  }

  &__cta-sm {
    width: 100%;
    margin-top: .5rem;
    text-align: center;

    @include media-breakpoint-up(md) {
      margin-top: 1rem;
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__title {
    width: 100%;
    margin-bottom: .5rem;

    @include media-breakpoint-up(lg) {
      margin-bottom: 1.25rem;
    }
  }

   &__introduction-cta,
   &__card {
     padding: .5rem .75rem;

    @include media-breakpoint-up(lg) {
      padding: 0 .75rem;
    }
   }
}
