.meta-navigation {
  position: relative;
  height: 40px;
  background-color: $white;

  .list {
    > .list-inline-item {

      &:not(:last-child) {
        margin-right: rem(24px);
      }

      a {
        padding: rem(8px) 0;
        color: theme-color("primary-700");
        display: block;
      }
    }
  }
}
