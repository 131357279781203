.link-list {
  position: relative;

  .label {
    margin-bottom: rem(17px);
  }

  .list {
    > li {
      padding: rem(4px) 0;
    }
  }

  .link {
    font-weight: $font-weight-medium;
    text-decoration: none;
    //text-decoration-color: $secondary-600;
    //@include link-underline(56%, 72%, theme-color("secondary"), .5);

    &:hover {
      color: $secondary;
      //@include link-underline(68%, 84%, theme-color("secondary"), 1);
      //text-decoration-color: $secondary;
      //text-decoration-thickness: 2px;
    }
  }
}
