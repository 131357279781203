.hce-arrow-controls {
  z-index: 0;
  position: relative;
  display: inline-flex;
  padding: rem(10px) 0;
  border: rem(1px) solid $primary-400;
  border-radius: rem(33px);

  // divider
  &::before {
    content: '';
    position: relative;
    left: 50%;
    display: block;
    width: rem(1px);
    height: rem(26px);
    background-color: $primary-400;
  }

  button {
    height: auto;
    padding: 0 1.25rem;

    &:focus {
      > span {
        background-color: $primary;

        svg {
          color: $white;
          fill: $white;
        }

        @at-root .bg-dark & {
          background-color: $white;

          svg {
            color: $primary;
            fill: $primary;
          }
        }
      }
    }

    &[disabled] {
      opacity: .2;
    }

    .icon {
      display: block;
      width: 1.5rem;
      height: 1.5rem;

      svg {
        width: 100%;
        height: 100%;
        color: $primary;
        fill: $primary;

        .bg-dark & {
          color: $white;
          fill: $white;
        }
      }
    }
  }
}
