$list-width: rem(420px);

@mixin top-background-underlay($background-color, $bottom-offset, $z-index: 0) {
  position: relative;

  &:before {
    z-index: $z-index;
    content: "";
    position: absolute;
    top: -9999px;
    right: -9999px;
    bottom: $bottom-offset;
    left: -9999px;
    background-color: $background-color;
  }
}

@mixin custom-scrollbar {
  &::-webkit-scrollbar {
    width: .5rem;
  }

  &::-webkit-scrollbar-track {
    border-radius: .25rem;
    background-color: $primary-100;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: .25rem;
    background-color: $primary-400;

    &:hover {
      background-color: $primary-600;
    }
  }
}

.hce-dealer-locator {
  &:not(.detail) {
    z-index: -1;
    display: flex;
    flex-direction: column;
    height: auto;
    overflow-x: hidden;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      height: 80vh;
    }
  }

  &.has-filters {
    background-color: $primary-200;
  }

  .col-12 {
    padding: 0;
  }

  .main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-right: 1.25rem;
    padding-bottom: 3rem;
    padding-left: 1.25rem;

    @include media-breakpoint-up(md) {
      max-width: 70%;
      margin-right: auto;
      margin-left: auto;
    }

    @include media-breakpoint-up(lg) {
      max-width: $list-width;
      padding: 0;
      margin-right: rem(37px);
    }

    @include media-breakpoint-up(xl) {
      margin-right: rem(74px);
    }
  }

  .introduction {
    z-index: 1;
    position: relative;
    padding-top: 2.5rem;
    padding-bottom: 3.5rem;
    padding-left: 1.25rem;

    @include media-breakpoint-up(md) {
      padding-bottom: 1rem;
      padding-left: 0;
    }

    &.detail .introduction {
      padding-left: 0;
    }

    &.is-startscreen {
      padding-bottom: 1.25rem;

      @include media-breakpoint-up(md) {
        padding-bottom: 1.5rem;
      }
    }

    h1 {
      margin-bottom: 1rem;
      font-size: rem(36px); // overwrite required by design
      line-height: 1;
      letter-spacing: rem(-1px);

      @include media-breakpoint-up(md) {
        font-size: 3rem;
      }
    }

    &__text {
      font-size: rem(18px);
      line-height: rem(24px);
    }
  }

  .start-filter {
    @include media-breakpoint-up(lg) {
      margin-right: rem(34px);
    }

    .start-search-button {
      margin-top: 1.75rem;
    }

    .country-background {
      @include top-background-underlay($primary-100, 2rem, -1);
    }
  }

  input[type="text"] {
    border: rem(1px) solid $primary-400;
    font-size: 1rem;

    &:focus {
      color: $primary;
      box-shadow: none;
    }
  }

  .filter-type-section {
    padding: 1.5rem 0 0 1.5rem;
  }

  .form-check:last-child .form-check-label {
    margin-bottom: 0;
  }

  .form-check-input {
    display: none;

    & + .form-check-label {
      padding-left: rem(10px);
      margin: rem(9px) 0;
      color: theme-color("primary-800");
      cursor: pointer;
      font-size: rem(16px); // overwrite
      letter-spacing: 0; // overwrite
      text-transform: inherit; // overwrite

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: rem(6px);
        left: 0;
        width: rem(20px);
        height: rem(20px);
      }

      &:before {
        background-image: url("/assets/images/svg/checkbox-unchecked.svg");
      }
    }

    &:checked + .form-check-label:after {
      background-image: url("/assets/images/svg/checkbox-checked.svg");
    }
  }

  .filter-toggle {
    @include top-background-underlay($primary-100, 1.5rem);

    @include media-breakpoint-up(lg) {
      margin-right: rem(34px);
    }

    .btn {
      width: 100%;
    }

    .form-group.has-icon {
      position: relative;
      margin-bottom: 0;

      .form-control {
        padding-right: 4rem;
        border-color: $primary-400;
      }

      svg {
        position: absolute;
        top: .5rem;
        right: rem(20px);
        width: 2rem;
        height: 2rem;
      }
    }
  }

  .filters {
    z-index: 2;
    position: relative;
    width: 100%;
    height: 0;
    @include custom-scrollbar;

    @include media-breakpoint-up(lg) {
      overflow-y: auto;
    }

    @include media-breakpoint-up(lg) {
      .hce-dealer-filters {
        max-width: calc(#{$list-width} - 1.5rem - .5rem); // list width - minus padding - scrollbar width
        max-height: 0;
      }
    }

    &.are-open {
      flex: 1;
      height: 100%;
      padding-bottom: 2rem;

      .hce-dealer-filters {
        max-height: rem(1000px);
        padding: .75rem 0;
      }
    }
  }

  .filter-slide-enter-active,
  .filter-slide-leave {
    height: rem(500px);
    transition: height .2s ease-out;
  }

  .filter-slide-enter,
  .filter-slide-leave-to {
    height: 0;
  }

  .dealers {
    flex: 1;
    padding-bottom: 3rem;
    margin-top: 2rem;

    @include media-breakpoint-up(lg) {
      height: 100%;
      padding-bottom: 2rem;
      overflow-y: auto;
      @include custom-scrollbar;
    }

    .hce-dealer-list {
      @include media-breakpoint-up(lg) {
        max-width: calc(#{$list-width} - 1.5rem - .5rem); // list width - minus padding - scrollbar width
      }
    }
  }

  .dealer-locator-detail {
    border: 3px solid #e5e6eb;
    padding: 30px 15px;
    .hce-dealer-list__dealer-heading {
      padding-bottom: 30px;
      border-bottom: .0625rem solid #e5e6eb;
      margin-bottom: 30px;
    }
    .hce-dealer-list__dealer-service-types {
      display: flex;
      gap: .5rem;
      margin: .25rem 0;

      .badge {
        font-size: .85rem;
        padding: .25rem .5rem;
        font-weight: 400;
        line-height: 1.5;

        &-primary {
          background-color: $green;
          color: $white;
        }
      }
    }
    p,
    .btn-link--alt {
      padding: 0;
      line-height: 1.33333;
      font-size: 1.125rem;
    }
    @include media-breakpoint-down(sm) {
      margin-bottom: 15px;
    }
  }

  .map-section {
    z-index: 4;
    padding: 0;
    @include media-breakpoint-down(md) {
      padding-right: 1.25rem;
      padding-bottom: 1.25rem;
      padding-left: 1.25rem;
      margin: 0 auto;
    }
  }

  .hce-dealer-map {
    @include media-breakpoint-down(md) {
      padding-top: 100%;
    }
  }

  .opening-hours {
    table {
      @include table-styles;
    }
  }
}
