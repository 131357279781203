$glide: glide;

.#{$glide} {
  &__slides {
    margin-bottom: 0;
  }

  &__arrows {
    width: 100%;
  }

  &__arrow {
    display: block;
    z-index: 2;
    background-color: transparent;
    border: 0;
    opacity: 1;
    cursor: pointer;
    transition: opacity 150ms ease, border 300ms ease-in-out;
    padding: 0;
    margin: 0;

    &:focus {
      outline: none;
    }

    &--disabled {
      opacity: .33;
    }
  }

  &__bullet {
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;

    &:focus {
      outline: none;
    }

    &--active {
      background-color: white;
    }
  }
}
