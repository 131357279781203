@use 'sass:math';

.hce-product-table {
  width: 100%;

  thead tr {
    border-bottom: rem(1px) solid $primary-800;
  }

  tr {
    &:nth-child(even) {
      background-color: $primary-100;
    }
  }

  th {
    padding: 1.25rem 1rem;
    color: $primary-600;
    font-weight: $font-weight-bold;
  }

  td {
    padding: .75rem 1rem;
    color: $primary-800;
    vertical-align: top;

    &:nth-child(1) {
      font-weight: $font-weight-semibold;
      width: 35%;
    }

    &:nth-child(2) {
      width: 65%;
    }

    @include media-breakpoint-up(md) {
      &:nth-child(1) {
        width: math.percentage(math.div(7, 12));
      }

      &:nth-child(2) {
        width: math.percentage(math.div(5, 12));
      }
    }
  }
}
