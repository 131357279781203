//
// Color system
//

// define
$white: #ffffff;
$black: #000000;

// override bootstrap grays
$gray-900: #333333;
$gray-600: #666666;
$gray-500: #a2a2a2;
$gray-400: #c7c7c7;
$gray-300: #e0e0e0;
$gray-100: #f1f1f1;

// override bootstrap colors
$blue: #002554;
$yellow: #faa519;
$orange: #fd7e14;
$red: #d0021b;
$green: #00ad1d;

$primary: $blue;
$primary-dark: #0a1f44 !default;
$primary-900: #003087 !default;
$primary-800: #40476c !default;
$primary-700: #63666a !default;
$primary-600: #7f849d !default;
$primary-400: #bfc1ce !default;
$primary-200: #e5e6eb !default;
$primary-100: #f2f2f5 !default;

$secondary: $green;
$secondary-800: #02ab54 !default;
$secondary-600: #67cd98 !default;
$secondary-400: lighten($green, 40%) !default;
$secondary-200: lighten($green, 50%) !default;

$warning: $orange;
$error: $red;
$light: $gray-400;
$dark: $gray-600;

// add color definitions to theme-colors
$theme-colors: (
  "white": $white,
  "black": $black,
  "primary-900": $primary-900,
  "primary-800": $primary-800,
  "primary-700": $primary-700,
  "primary-600": $primary-600,
  "primary-400": $primary-400,
  "primary-200": $primary-200,
  "primary-100": $primary-100,
  "secondary-800": $secondary-800,
  "secondary-600": $secondary-600,
  "secondary-400": $secondary-400,
  "secondary-200": $secondary-200,
);

// background colors map
$bg-colors: (
  light: $white,
  medium: $gray-100,
  dark: $primary,
  secondary: $secondary,
);

// $heading-colors: (
//     light: $white,
//     medium: $grey-100,
//     dark: $grey-800
// );

// $text-colors: (
//     light: $white,
//     medium: $grey-100,
//     dark: $grey-600
// );
