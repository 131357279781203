.switch {
  max-width: $switch-width;
  padding: rem(2px);
  border: rem(1px) solid $primary-400;
  border-radius: $border-radius;

  &--lg {
    max-width: $switch-width-lg;

    .switch__label {
      height: $switch-height-lg;

      > span {
        span {
          height: $switch-height-lg;
        }
      }
    }
  }

  &__label {
    height: $switch-height;
    position: relative;
    line-height: inherit;
    font-size: $font-size-xs;
    letter-spacing: 1px;

    > a {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9;
      width: 100%;
      height: 100%;
    }

    > span {
      position: absolute;
      left: -100px;
      padding-right: 100px;

      span {
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 5;
        margin-left: 100px;
        outline: none;
        height: $switch-height;
        cursor: pointer;

        &:last-child {
          left: 50%;
        }

        a {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    span {
      span,
      label {
        color: theme-color("primary-600");
      }
    }

    .btn {
      z-index: 4;
      position: absolute;
      top: 0;
      right: 50%;
      width: 50%;
      min-height: 100%;
      transition: all 100ms ease-out 0s;
    }

    input {
      position: absolute;
      opacity: 0;
      z-index: 5;

      &:checked ~ .btn {
        right: 0%;
      }

      ~ span span:nth-child(1) {
        color: $white;
      }

      &:checked ~ span span:nth-child(1) {
        color: theme-color("primary");
        // background-color: theme-color("primary");
        // border-radius: $border-radius;
      }

      &:checked ~ span span:nth-child(2) {
        color: $white;
        // background-color: theme-color("primary");
        // border-radius: $border-radius;
      }
    }
  }
}
