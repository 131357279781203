// grid
$grid-columns: 12;
$grid-gutter-width: 24px;

$container-max-widths: (
  sm: map-get($grid-breakpoints, sm) -
    (
      $grid-gutter-width * 2
    ),
  md: map-get($grid-breakpoints, md) -
    (
      $grid-gutter-width * 2
    ),
  lg: map-get($grid-breakpoints, lg) -
    (
      $grid-gutter-width * 2
    ),
  xl: 960px + $grid-gutter-width
);

// row-big
.row-big {
  display: flex;
  flex-wrap: wrap;
  margin-left: -25px;
  margin-right: -25px;
}
.row-big > .col,
.row-big > [class*="col-"] {
  padding-right: 25px;
  padding-left: 25px;
}
