&__bar {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    height: 60px;

    @include media-breakpoint-up(lg) {
        height: 100px;
    }

    @include media-breakpoint-up(xl) {
        margin-left: 100px;
    }
}
