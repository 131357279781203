.hce-dealer-map {
  position: relative;
  height: 100%;

  &.is-disabled:after {
    z-index: 5;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba($primary, .4);
  }
}

// infowindow
.gm-style-iw {
  .firstHeading {
    font-size: 1.125rem;
  }
}
