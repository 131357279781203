.form {
  position: relative;
}

@import "input";

/*SITECORE FORMS */
.form-hyun {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;

  .container-fluid & {
    // max width for forms directly in container fluid
    width: auto;
    margin-right: -12px;
    margin-left: -12px;
    @media (min-width: 576px) {
      max-width: calc(528px - 16px);
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    @media (min-width: 768px) {
      max-width: calc(720px - 16px);
    }
    @media (min-width: 992px) {
      max-width: 944px;
    }
    @media (min-width: 1200px) {
      max-width: 984px;
    }
  }

  // Default on 100%
  .form-group {
    width: 100%;
  }
  p {
    color: #7f849d;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 15px;
  }
  label {
    color: $primary-900;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    text-transform: initial;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 5px;
    &.label-radio,
    &.label-checkbox {
      font-weight: 400;
      line-height: normal;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="number"],
  textarea,
  select {
    color: $primary-900;
    display: block;
    width: 100%;
    //margin-bottom: 15px;
    padding: 0 15px;
    min-height: 27px;
    height: 40px;
    font-size: 16px;
    border: 2px solid $primary;

    &:focus {
      border-color: $primary-600;
    }
  }
  input[type="checkbox"] {
    margin-right: 10px;
  }
  textarea {
    height: auto;
  }
  [data-sc-field-name="List Box"] {
    height: auto;
    padding: 0;
    option {
      padding: 5px 15px;
      font-size: 14px;
    }
  }
  input[type="submit"] {
    margin: 15px 0;
    background: $white;
    border: 1px solid $secondary;
    color: $secondary;
    display: inline-block;
    font-weight: 700;
    text-align: center;
    border-radius: 0;
    font-size: 14px;
    padding: 12px 24px;
    &:hover {
      background: $secondary;
      border-color: $secondary;
      color: $white;
    }
  }

  .field-validation-error {
    color: #f2545b;
    display: block;
    font-size: 14px;
  }
  label + .field-validation-error {
    margin-top: -5px;
  }
  input + .field-validation-error,
  select + .field-validation-error,
  textarea + .field-validation-error {
    margin-top: 5px;
  }

  /* required fields */
  label {
    &.required {
      &:after {
        content: "*";
        color: #f2545b;
        margin-left: 2px;
      }
    }
  }
  input {
    &[type="checkbox"] {
      + label {
        &.required {
          &:after {
            content: none;
          }
        }
      }
    }
  }
}
