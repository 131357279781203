///
/// Alignes elements vertically and horizontally.
/// @param {string} $position [relative] Optional relative (default) or absolute
/// @author Rocco Janse, <rocco.janse@valtech.nl>
///
@mixin align-center($position: relative) {
  position: $position;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

///
/// Horizontally alignes elements.
/// @param {string} $position [relative] Optional relative (default) or absolute
/// @author Rocco Janse, <rocco.janse@valtech.nl>
///
@mixin align-horizontal($position: relative) {
  position: $position;
  left: 50%;
  transform: translateX(-50%);
}

///
/// Vertically alignes elements.
/// @param {string} $position [relative] Optional relative (default) or absolute
/// @author Rocco Janse, <rocco.janse@valtech.nl>
///
@mixin align-vertical($position: relative) {
  position: $position;
  top: 50%;
  transform: translateY(-50%);
}
