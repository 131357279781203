@charset "UTF-8";
/*
// light
@font-face {
  font-family: 'Hind';
  src: url('/assets/fonts/Hind/Hind-Light.woff2') format('woff2'),
  url('/assets/fonts/Hind/Hind-Light.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: fallback;
}

// regular
@font-face {
  font-family: 'Hind';
  src: url('/assets/fonts/Hind/Hind-Regular.woff2') format('woff2'),
  url('/assets/fonts/Hind/Hind-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

// medium
@font-face {
  font-family: 'Hind';
  src: url('/assets/fonts/Hind/Hind-Medium.woff2') format('woff2'),
  url('/assets/fonts/Hind/Hind-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

// semibold
@font-face {
  font-family: 'Hind';
  src: url('/assets/fonts/Hind/Hind-SemiBold.woff2') format('woff2'),
  url('/assets/fonts/Hind/Hind-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}

// bold
@font-face {
  font-family: 'Hind';
  src: url('/assets/fonts/Hind/Hind-Bold.woff2') format('woff2'),
  url('/assets/fonts/Hind/Hind-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}
*/
@import "../../../../node_modules/swiper/css/swiper.min.css";
@font-face {
  font-family: "HDHyundaiGothicEN";
  src: url("/assets/fonts/HDHyundaiGothicEN/woff2/HDHyundaiGothicEN-Light.woff2") format("woff2"), url("/assets/fonts/HDHyundaiGothicEN/woff/HDHyundaiGothicEN-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "HDHyundaiGothicEN";
  src: url("/assets/fonts/HDHyundaiGothicEN/woff2/HDHyundaiGothicEN-Regular.woff2") format("woff2"), url("/assets/fonts/HDHyundaiGothicEN/woff/HDHyundaiGothicEN-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "HDHyundaiGothicEN";
  src: url("/assets/fonts/HDHyundaiGothicEN/woff2/HDHyundaiGothicEN-Bold.woff2") format("woff2"), url("/assets/fonts/HDHyundaiGothicEN/woff/HDHyundaiGothicEN-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}
.custom-select {
  color: #002554;
  height: auto;
  padding-top: 12px !important;
  padding-right: 2.5rem !important;
  padding-bottom: 10px !important;
  padding-left: 1.5rem !important;
  border: 1px solid #002554;
  border-radius: 0px;
  appearance: none;
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjYgKDY3NDkxKSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5JY29ucy9kcm9wZG93bi8vd2hpdGUvZG93bjwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJJY29ucy9kcm9wZG93bi8vd2hpdGUvZG93biIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMy4wMDAwMDAsIDExLjUwMDAwMCkgc2NhbGUoMSwgLTEpIHRyYW5zbGF0ZSgtMTMuMDAwMDAwLCAtMTEuNTAwMDAwKSB0cmFuc2xhdGUoNi4wMDAwMDAsIDcuMDAwMDAwKSIgZmlsbC1ydWxlPSJub256ZXJvIiBzdHJva2U9IiMwMDA5M0IiIHN0cm9rZS13aWR0aD0iMiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDcuMDIwODE1MjggTDcsMCIgaWQ9IkxpbmUtMi1Db3B5LTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMuNTAwMDAwLCA0LjAwMDAwMCkgcm90YXRlKC0xODAuMDAwMDAwKSB0cmFuc2xhdGUoLTMuNTAwMDAwLCAtNC4wMDAwMDApICI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNNywxLjk1ODM2OTQ0IEwxNCw4Ljk3OTE4NDcyIiBpZD0iTGluZS0yLUNvcHktMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAuNTAwMDAwLCA0Ljk3OTE4NSkgcm90YXRlKC0xODAuMDAwMDAwKSB0cmFuc2xhdGUoLTEwLjUwMDAwMCwgLTQuOTc5MTg1KSAiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==") no-repeat right 20px center/18px 16px;
  cursor: pointer;
  font-size: 1rem;
}
.custom-select.big {
  min-height: 64px;
  font-size: 1.25rem;
  line-height: 1.3;
  font-weight: 600;
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjYgKDY3NDkxKSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5JY29ucy9kcm9wZG93bi8vd2hpdGUvZG93bjwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJJY29ucy9kcm9wZG93bi8vd2hpdGUvZG93biIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMy4wMDAwMDAsIDExLjUwMDAwMCkgc2NhbGUoMSwgLTEpIHRyYW5zbGF0ZSgtMTMuMDAwMDAwLCAtMTEuNTAwMDAwKSB0cmFuc2xhdGUoNi4wMDAwMDAsIDcuMDAwMDAwKSIgZmlsbC1ydWxlPSJub256ZXJvIiBzdHJva2U9IiMwMDA5M0IiIHN0cm9rZS13aWR0aD0iMiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDcuMDIwODE1MjggTDcsMCIgaWQ9IkxpbmUtMi1Db3B5LTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMuNTAwMDAwLCA0LjAwMDAwMCkgcm90YXRlKC0xODAuMDAwMDAwKSB0cmFuc2xhdGUoLTMuNTAwMDAwLCAtNC4wMDAwMDApICI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNNywxLjk1ODM2OTQ0IEwxNCw4Ljk3OTE4NDcyIiBpZD0iTGluZS0yLUNvcHktMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAuNTAwMDAwLCA0Ljk3OTE4NSkgcm90YXRlKC0xODAuMDAwMDAwKSB0cmFuc2xhdGUoLTEwLjUwMDAwMCwgLTQuOTc5MTg1KSAiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==") no-repeat right 32px center/18px 16px;
}
.custom-select.bold {
  font-weight: 600;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select option {
  background-color: #ffffff;
}
.custom-select:focus {
  border-color: #7f849d !important;
  box-shadow: none !important;
}
.custom-select:disabled {
  background: url("data:image/svg+xml,%3Csvg width='17' height='10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.707 9.142L7 8.437l.002-.002L0 1.412 1.416 0l6.998 7.019L15.412 0l1.416 1.412-7.002 7.023.002.002-.707.705-.705.707-.002-.002-.002.002-.705-.707z' fill='%23BFC1CE'/%3E%3C/svg%3E") no-repeat right 20px center/14px 8px;
  background-color: #e0e0e0 !important;
  border-color: #e0e0e0;
  color: #7f849d;
  cursor: no-drop;
}
.custom-select.is-selected {
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5JY29ucy9kcm9wZG93bi8vd2hpdGUvZG93bjwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZyBpZD0iSWNvbnMvZHJvcGRvd24vL3doaXRlL2Rvd24iIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiPiAgICAgICAgPGcgaWQ9Ikdyb3VwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMy4wMDAwMDAsIDExLjUwMDAwMCkgc2NhbGUoMSwgLTEpIHRyYW5zbGF0ZSgtMTMuMDAwMDAwLCAtMTEuNTAwMDAwKSB0cmFuc2xhdGUoNi4wMDAwMDAsIDcuMDAwMDAwKSIgZmlsbC1ydWxlPSJub256ZXJvIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMiI+ICAgICAgICAgICAgPHBhdGggZD0iTTAsNy4wMjA4MTUyOCBMNywwIiBpZD0iTGluZS0yLUNvcHktMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMy41MDAwMDAsIDQuMDAwMDAwKSByb3RhdGUoLTE4MC4wMDAwMDApIHRyYW5zbGF0ZSgtMy41MDAwMDAsIC00LjAwMDAwMCkgIj48L3BhdGg+ICAgICAgICAgICAgPHBhdGggZD0iTTcsMS45NTgzNjk0NCBMMTQsOC45NzkxODQ3MiIgaWQ9IkxpbmUtMi1Db3B5LTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEwLjUwMDAwMCwgNC45NzkxODUpIHJvdGF0ZSgtMTgwLjAwMDAwMCkgdHJhbnNsYXRlKC0xMC41MDAwMDAsIC00Ljk3OTE4NSkgIj48L3BhdGg+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=") no-repeat right 20px center/18px 16px;
  background-color: #002554 !important;
  border-color: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5JY29ucy9kcm9wZG93bi8vd2hpdGUvZG93bjwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZyBpZD0iSWNvbnMvZHJvcGRvd24vL3doaXRlL2Rvd24iIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiPiAgICAgICAgPGcgaWQ9Ikdyb3VwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMy4wMDAwMDAsIDExLjUwMDAwMCkgc2NhbGUoMSwgLTEpIHRyYW5zbGF0ZSgtMTMuMDAwMDAwLCAtMTEuNTAwMDAwKSB0cmFuc2xhdGUoNi4wMDAwMDAsIDcuMDAwMDAwKSIgZmlsbC1ydWxlPSJub256ZXJvIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMiI+ICAgICAgICAgICAgPHBhdGggZD0iTTAsNy4wMjA4MTUyOCBMNywwIiBpZD0iTGluZS0yLUNvcHktMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMy41MDAwMDAsIDQuMDAwMDAwKSByb3RhdGUoLTE4MC4wMDAwMDApIHRyYW5zbGF0ZSgtMy41MDAwMDAsIC00LjAwMDAwMCkgIj48L3BhdGg+ICAgICAgICAgICAgPHBhdGggZD0iTTcsMS45NTgzNjk0NCBMMTQsOC45NzkxODQ3MiIgaWQ9IkxpbmUtMi1Db3B5LTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEwLjUwMDAwMCwgNC45NzkxODUpIHJvdGF0ZSgtMTgwLjAwMDAwMCkgdHJhbnNsYXRlKC0xMC41MDAwMDAsIC00Ljk3OTE4NSkgIj48L3BhdGg+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=") no-repeat right 20px center/18px 16px;
  color: #ffffff;
}

.dropdown {
  background: none;
  border-radius: 0;
  border: none;
  position: relative;
  width: 100%;
  text-align: left;
  padding: 0;
}
.dropdown .dropdown-toggle {
  background: #fff url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjYgKDY3NDkxKSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5JY29ucy9kcm9wZG93bi8vd2hpdGUvZG93bjwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJJY29ucy9kcm9wZG93bi8vd2hpdGUvZG93biIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMy4wMDAwMDAsIDExLjUwMDAwMCkgc2NhbGUoMSwgLTEpIHRyYW5zbGF0ZSgtMTMuMDAwMDAwLCAtMTEuNTAwMDAwKSB0cmFuc2xhdGUoNi4wMDAwMDAsIDcuMDAwMDAwKSIgZmlsbC1ydWxlPSJub256ZXJvIiBzdHJva2U9IiMwMDA5M0IiIHN0cm9rZS13aWR0aD0iMiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDcuMDIwODE1MjggTDcsMCIgaWQ9IkxpbmUtMi1Db3B5LTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMuNTAwMDAwLCA0LjAwMDAwMCkgcm90YXRlKC0xODAuMDAwMDAwKSB0cmFuc2xhdGUoLTMuNTAwMDAwLCAtNC4wMDAwMDApICI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNNywxLjk1ODM2OTQ0IEwxNCw4Ljk3OTE4NDcyIiBpZD0iTGluZS0yLUNvcHktMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAuNTAwMDAwLCA0Ljk3OTE4NSkgcm90YXRlKC0xODAuMDAwMDAwKSB0cmFuc2xhdGUoLTEwLjUwMDAwMCwgLTQuOTc5MTg1KSAiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==") no-repeat right 20px center/18px 16px;
  border-radius: 0;
  border: 1px solid #002554;
  color: #002554;
  cursor: pointer;
  font-size: 1rem;
  height: 47px;
  line-height: 47px;
  padding-left: 1.5rem;
  user-select: none;
  width: 100%;
}
.dropdown .dropdown-toggle:after {
  content: none;
}
.dropdown .dropdown-toggle[aria-expanded=true] {
  border-radius: 0;
}
.dropdown .dropdown-menu {
  background-color: #fff;
  border: 1px solid #002554;
  border-radius: 0;
  color: #002554;
  max-height: 240px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px 0;
  width: 100%;
}
.dropdown .dropdown-item {
  cursor: pointer;
}
.dropdown .item-link {
  display: block;
  padding: 0.5rem 1.5rem;
}
.dropdown.big .dropdown-toggle {
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjYgKDY3NDkxKSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5JY29ucy9kcm9wZG93bi8vd2hpdGUvZG93bjwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJJY29ucy9kcm9wZG93bi8vd2hpdGUvZG93biIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMy4wMDAwMDAsIDExLjUwMDAwMCkgc2NhbGUoMSwgLTEpIHRyYW5zbGF0ZSgtMTMuMDAwMDAwLCAtMTEuNTAwMDAwKSB0cmFuc2xhdGUoNi4wMDAwMDAsIDcuMDAwMDAwKSIgZmlsbC1ydWxlPSJub256ZXJvIiBzdHJva2U9IiMwMDA5M0IiIHN0cm9rZS13aWR0aD0iMiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDcuMDIwODE1MjggTDcsMCIgaWQ9IkxpbmUtMi1Db3B5LTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMuNTAwMDAwLCA0LjAwMDAwMCkgcm90YXRlKC0xODAuMDAwMDAwKSB0cmFuc2xhdGUoLTMuNTAwMDAwLCAtNC4wMDAwMDApICI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNNywxLjk1ODM2OTQ0IEwxNCw4Ljk3OTE4NDcyIiBpZD0iTGluZS0yLUNvcHktMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAuNTAwMDAwLCA0Ljk3OTE4NSkgcm90YXRlKC0xODAuMDAwMDAwKSB0cmFuc2xhdGUoLTEwLjUwMDAwMCwgLTQuOTc5MTg1KSAiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==") no-repeat right 32px center/18px 16px;
  border-radius: 0px;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.3;
  min-height: 64px;
  padding: 12px 2.5rem 10px 1.5rem;
}
.dropdown.bold {
  font-weight: 700;
}

::selection {
  color: #ffffff;
  background: #00ad1d;
}

.row-big {
  display: flex;
  flex-wrap: wrap;
  margin-left: -25px;
  margin-right: -25px;
}

.row-big > .col,
.row-big > [class*=col-] {
  padding-right: 25px;
  padding-left: 25px;
}

:root {
  --blue: #002554;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #d0021b;
  --orange: #fd7e14;
  --yellow: #faa519;
  --green: #00ad1d;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #666666;
  --gray-dark: #343a40;
  --primary: #002554;
  --secondary: #00ad1d;
  --success: #00ad1d;
  --info: #17a2b8;
  --warning: #fd7e14;
  --danger: #d0021b;
  --light: #c7c7c7;
  --dark: #666666;
  --white: #ffffff;
  --black: #000000;
  --primary-900: #003087;
  --primary-800: #40476c;
  --primary-700: #63666a;
  --primary-600: #7f849d;
  --primary-400: #bfc1ce;
  --primary-200: #e5e6eb;
  --primary-100: #f2f2f5;
  --secondary-800: #02ab54;
  --secondary-600: #67cd98;
  --secondary-400: #7aff90;
  --secondary-200: #adffbb;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: HDHyundaiGothicEN, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: HDHyundaiGothicEN, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #333333;
  text-align: left;
  background-color: #ffffff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, .cookie-consent__introduction h3, h6, .cookie-consent__option .form-check-input + label.form-check-label strong {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #002554;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #000308;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #666666;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 528px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 944px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 984px;
  }
}
.row, .hce-product-cards.is-grid .grid-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .hce-product-cards.is-grid .grid-col, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .hce-product-cards.is-grid .hce-product-card__img-md,
.hce-product-cards.is-grid .hce-product-card__content-wrapper,
.hce-product-cards.is-grid .hce-product-card__specs .col-6.col-md-3,
.hce-product-cards.is-grid .hce-product-card__specs .col-6.col-md-2, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4, .hce-product-cards.is-grid .grid-col {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12, .hce-product-cards.is-grid .hce-product-card__img-md,
  .hce-product-cards.is-grid .hce-product-card__content-wrapper,
  .hce-product-cards.is-grid .hce-product-card__specs .col-6.col-md-3,
  .hce-product-cards.is-grid .hce-product-card__specs .col-6.col-md-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3, .hce-product-cards.is-grid .grid-col {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.clipped--small.clipped--left {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 15px 100%, 0 calc(100% - 24px));
}
.clipped--small.clipped--right {
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 24px), calc(100% - 15px) 100%, 0 100%);
}
.clipped--medium.clipped--left {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 15px 100%, 0 calc(100% - 24px));
}
.clipped--medium.clipped--right {
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 24px), calc(100% - 15px) 100%, 0 100%);
}
@media (min-width: 768px) {
  .clipped--medium.clipped--left {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 15px 100%, 0 calc(100% - 24px));
  }
  .clipped--medium.clipped--right {
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 24px), calc(100% - 15px) 100%, 0 100%);
  }
}
@media (min-width: 992px) {
  .clipped--medium.clipped--left {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 30px 100%, 0 calc(100% - 48px));
  }
  .clipped--medium.clipped--right {
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 48px), calc(100% - 30px) 100%, 0 100%);
  }
}
.clipped.clipped--left, .clipped--large.clipped--left {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 15px 100%, 0 calc(100% - 24px));
}
.clipped.clipped--right, .clipped--large.clipped--right {
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 24px), calc(100% - 15px) 100%, 0 100%);
}
@media (min-width: 768px) {
  .clipped.clipped--left, .clipped--large.clipped--left {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 30px 100%, 0 calc(100% - 48px));
  }
  .clipped.clipped--right, .clipped--large.clipped--right {
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 48px), calc(100% - 30px) 100%, 0 100%);
  }
}
@media (min-width: 992px) {
  .clipped.clipped--left, .clipped--large.clipped--left {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 45px 100%, 0 calc(100% - 72px));
  }
  .clipped.clipped--right, .clipped--large.clipped--right {
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 72px), calc(100% - 45px) 100%, 0 100%);
  }
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  padding-left: 20px;
  padding-right: 20px;
}
@media (min-width: 992px) {
  .container,
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.no-container-gutters {
  margin: 0 -20px 0 -20px !important;
}
@media (min-width: 992px) {
  .no-container-gutters {
    margin: 0 -12px 0 -12px !important;
  }
}

*:before, *:after {
  outline: none;
}
*:focus, *.focus, *:active, *.active {
  outline: none;
}

html {
  height: 100%;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  position: relative;
  min-height: 100%;
}

.bg-square {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  margin: 0;
  padding: 0;
  width: 1.25rem;
  height: 15rem;
  background-color: #002554;
}
@media (min-width: 1200px) {
  .bg-square {
    width: 6.25rem;
    height: 31.25rem;
  }
}

h1, h2, h3, h4, h5, .cookie-consent__introduction h3, h6, .cookie-consent__option .form-check-input + label.form-check-label strong,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 3.5rem;
}

h2, .h2 {
  font-size: 3rem;
}

h3, .h3 {
  font-size: 2.25rem;
}

h4, .h4 {
  font-size: 1.75rem;
}

h5, .cookie-consent__introduction h3, .h5 {
  font-size: 1.5rem;
}

h6, .cookie-consent__option .form-check-input + label.form-check-label strong, .h6 {
  font-size: 1.25rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 4.25rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 3rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 2.25rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 1.75rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #666666;
}
.blockquote-footer::before {
  content: "— ";
}

.whitespace {
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
  background-color: transparent;
}
.whitespace--small, .whitespace--sm {
  height: 1.5rem;
}
.whitespace--medium, .whitespace--md {
  height: 3rem;
}
.whitespace--large, .whitespace--lg {
  height: 4.5rem;
}
.whitespace--extralarge, .whitespace--xl {
  height: 6rem;
}

h1, h2, h3, h4, h5, .cookie-consent__introduction h3, h6, .cookie-consent__option .form-check-input + label.form-check-label strong,
.h1, .h2, .h3, .h4, .h5, .h6,
.display-1, .display-2, .display-3, .display-4, .display-5, .display-6 {
  color: #002554;
}

h1, h2, h3, h4,
.h1, .h2, .h3, .h4 {
  color: #00ad1d;
}

h1, h2, h3,
.h1, .h2, .h3,
.display-1 {
  font-weight: 700;
}

h4, h5, .cookie-consent__introduction h3, h6, .cookie-consent__option .form-check-input + label.form-check-label strong,
.h4, .h5, .h6 {
  font-weight: 700;
}

h1, .h1, .display-1 {
  font-size: 3.5rem;
  letter-spacing: -1.17px;
  line-height: 0.857;
  text-transform: uppercase;
}

h2, .h2, .display-2 {
  font-size: 3rem;
  letter-spacing: -1px;
  line-height: 1;
}

h3, .h3, .display-3 {
  margin-bottom: 1.5rem;
  font-size: 2rem;
  letter-spacing: -0.89px;
  line-height: 1;
}
@media (min-width: 768px) {
  h3, .h3, .display-3 {
    margin-bottom: 1.125rem;
    font-size: 2.25rem;
    letter-spacing: -1px;
    line-height: 1;
  }
}
@media (min-width: 992px) {
  h3, .h3, .display-3 {
    margin-bottom: 1.5625rem;
  }
}

h4, .h4, .display-4 {
  font-size: 1.75rem;
  letter-spacing: -0.5px;
  line-height: 1;
}

h5, .cookie-consent__introduction h3, .h5, .display-5 {
  font-size: 1.5rem;
  line-height: 1.166;
  letter-spacing: -0.6px;
}

h6, .cookie-consent__option .form-check-input + label.form-check-label strong, .h6, .display-6 {
  margin-bottom: 0.25rem;
  font-size: 1.25rem;
  letter-spacing: 0;
  line-height: 1.1111111111;
}
@media (min-width: 768px) {
  h6, .cookie-consent__option .form-check-input + label.form-check-label strong, .h6, .display-6 {
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
    line-height: 1.166;
    letter-spacing: -0.5px;
  }
}

.display-1 {
  color: #0a1f44;
  font-size: 2.25rem;
  line-height: 2.25rem;
  letter-spacing: -0.0625rem;
}
@media (min-width: 768px) {
  .display-1 {
    font-size: 3rem;
    line-height: 1;
    letter-spacing: -0.0625rem;
  }
}
@media (min-width: 992px) {
  .display-1 {
    font-size: 4.25rem;
    line-height: 0.942;
    letter-spacing: -2px;
  }
}

.display-2, .display-3, .display-4, .display-5, .display-6 {
  font-weight: 400;
}

.lead {
  line-height: 1.3333333333;
  font-size: 1.125rem;
  font-weight: 400;
}
@media (min-width: 768px) {
  .lead {
    line-height: 1.4;
    font-size: 1.25rem;
  }
}

p {
  line-height: 1.25;
  margin: 0;
  color: #63666a;
}
@media (min-width: 768px) {
  p {
    line-height: 1.3333333333;
    font-size: 1.125rem;
  }
}

small,
.small,
.tag,
label,
.label {
  color: #40476c;
  font-size: 0.875rem;
  font-weight: 400;
}

label,
.label {
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 3px;
  line-height: 1rem;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

b,
strong {
  font-weight: 700;
  color: #003087;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.quote {
  padding-left: 2rem;
  border-left: 1rem solid #002554;
  margin: 3rem 0;
  color: #40476c;
  font-size: 1.75rem;
  font-weight: 300;
  letter-spacing: -0.03125rem;
  line-height: 1;
}
@media (min-width: 768px) {
  .quote {
    padding-left: 4rem;
    border-width: 0.625rem;
    margin: 4rem 0;
    font-size: 2.25rem;
    letter-spacing: -0.0625rem;
    line-height: 1.11;
  }
}

.bg-dark h1,
.bg-dark h2,
.bg-dark h3,
.bg-dark h4,
.bg-dark h5,
.bg-dark h6,
.bg-dark .cookie-consent__option .form-check-input + label.form-check-label strong,
.cookie-consent__option .form-check-input + label.form-check-label .bg-dark strong,
.bg-dark .h1,
.bg-dark .h2,
.bg-dark .h3,
.bg-dark .h4,
.bg-dark .h5,
.bg-dark .h6,
.bg-dark .display-1,
.bg-dark .display-2,
.bg-dark .display-3,
.bg-dark .display-4,
.bg-dark .display-5,
.bg-dark .display-6,
.bg-dark p,
.bg-dark ul,
.bg-dark li,
.bg-dark strong,
.bg-dark small,
.bg-dark .small,
.bg-dark label,
.bg-secondary h1,
.hce-dealer-locator .opening-hours table thead h1,
.bg-secondary h2,
.hce-dealer-locator .opening-hours table thead h2,
.bg-secondary h3,
.hce-dealer-locator .opening-hours table thead h3,
.bg-secondary h4,
.hce-dealer-locator .opening-hours table thead h4,
.bg-secondary h5,
.hce-dealer-locator .opening-hours table thead h5,
.bg-secondary h6,
.hce-dealer-locator .opening-hours table thead h6,
.bg-secondary .cookie-consent__option .form-check-input + label.form-check-label strong,
.hce-dealer-locator .opening-hours table thead .cookie-consent__option .form-check-input + label.form-check-label strong,
.cookie-consent__option .form-check-input + label.form-check-label .bg-secondary strong,
.cookie-consent__option .form-check-input + label.form-check-label .hce-dealer-locator .opening-hours table thead strong,
.hce-dealer-locator .opening-hours table .cookie-consent__option .form-check-input + label.form-check-label thead strong,
.bg-secondary .h1,
.hce-dealer-locator .opening-hours table thead .h1,
.bg-secondary .h2,
.hce-dealer-locator .opening-hours table thead .h2,
.bg-secondary .h3,
.hce-dealer-locator .opening-hours table thead .h3,
.bg-secondary .h4,
.hce-dealer-locator .opening-hours table thead .h4,
.bg-secondary .h5,
.hce-dealer-locator .opening-hours table thead .h5,
.bg-secondary .h6,
.hce-dealer-locator .opening-hours table thead .h6,
.bg-secondary .display-1,
.hce-dealer-locator .opening-hours table thead .display-1,
.bg-secondary .display-2,
.hce-dealer-locator .opening-hours table thead .display-2,
.bg-secondary .display-3,
.hce-dealer-locator .opening-hours table thead .display-3,
.bg-secondary .display-4,
.hce-dealer-locator .opening-hours table thead .display-4,
.bg-secondary .display-5,
.hce-dealer-locator .opening-hours table thead .display-5,
.bg-secondary .display-6,
.hce-dealer-locator .opening-hours table thead .display-6,
.bg-secondary p,
.hce-dealer-locator .opening-hours table thead p,
.bg-secondary ul,
.hce-dealer-locator .opening-hours table thead ul,
.bg-secondary li,
.hce-dealer-locator .opening-hours table thead li,
.bg-secondary strong,
.hce-dealer-locator .opening-hours table thead strong,
.bg-secondary small,
.hce-dealer-locator .opening-hours table thead small,
.bg-secondary .small,
.hce-dealer-locator .opening-hours table thead .small,
.bg-secondary label,
.hce-dealer-locator .opening-hours table thead label {
  color: #ffffff !important;
}
.bg-dark .text-dark,
.bg-secondary .text-dark,
.hce-dealer-locator .opening-hours table thead .text-dark {
  color: #000000 !important;
}

.text-light {
  color: #ffffff !important;
}

.icon {
  width: 24px;
  height: 24px;
  pointer-events: none;
}
.icon svg {
  width: 24px;
  height: 24px;
}

.link-list {
  position: relative;
}
.link-list .label {
  margin-bottom: 1.0625rem;
}
.link-list .list > li {
  padding: 0.25rem 0;
}
.link-list .link {
  font-weight: 400;
  text-decoration: none;
}
.link-list .link:hover {
  color: #00ad1d;
}

.link-list--social {
  position: relative;
}
.link-list--social .list > .list-inline-item {
  margin-right: 1.6875rem;
}
@media (min-width: 768px) {
  .link-list--social .list > .list-inline-item {
    margin-right: 1.0625rem;
  }
}
.link-list--social .list > .list-inline-item:last-child {
  margin-right: 0;
}
.link-list--social .social-link .icon {
  width: 32px;
  height: 32px;
  fill: #ffffff;
  opacity: 0.9;
  transition: 100ms opacity ease-in-out;
}
.link-list--social .social-link .icon svg {
  width: 32px;
  height: 32px;
}
.link-list--social .social-link .icon--youtube {
  width: 38px;
  height: 38px;
}
.link-list--social .social-link .icon--youtube svg {
  width: 38px;
  height: 38px;
}
@media (min-width: 768px) {
  .link-list--social .social-link .icon {
    width: 20px;
    height: 20px;
  }
  .link-list--social .social-link .icon svg {
    width: 20px;
    height: 20px;
  }
  .link-list--social .social-link .icon--youtube {
    width: 26px;
    height: 26px;
  }
  .link-list--social .social-link .icon--youtube svg {
    width: 26px;
    height: 26px;
  }
}
.link-list--social .social-link:hover .icon {
  opacity: 1;
  fill: #00ad1d;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 0;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #666666;
}

.object-fit {
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.table, .hce-dealer-locator .opening-hours table {
  width: 100%;
  margin-bottom: 1rem;
  color: #333333;
}
.table th, .hce-dealer-locator .opening-hours table th,
.table td,
.hce-dealer-locator .opening-hours table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #e0e0e0;
}
.table thead th, .hce-dealer-locator .opening-hours table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e0e0e0;
}
.table tbody + tbody, .hce-dealer-locator .opening-hours table tbody + tbody {
  border-top: 2px solid #e0e0e0;
}

.table-sm th, .hce-dealer-locator .opening-hours table th,
.table-sm td,
.hce-dealer-locator .opening-hours table td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #e0e0e0;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #e0e0e0;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd), .hce-dealer-locator .opening-hours table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #333333;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8c2cf;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7a8ea6;
}

.table-hover .table-primary:hover {
  background-color: #a9b5c5;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #a9b5c5;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #b8e8c0;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #7ad489;
}

.table-hover .table-secondary:hover {
  background-color: #a5e2af;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #a5e2af;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #b8e8c0;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #7ad489;
}

.table-hover .table-success:hover {
  background-color: #a5e2af;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #a5e2af;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fedbbd;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #febc85;
}

.table-hover .table-warning:hover {
  background-color: #fecda4;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fecda4;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f2b8bf;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #e77b88;
}

.table-hover .table-danger:hover {
  background-color: #eea2ac;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #eea2ac;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #efefef;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #e2e2e2;
}

.table-hover .table-light:hover {
  background-color: #e2e2e2;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #e2e2e2;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #d4d4d4;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #afafaf;
}

.table-hover .table-dark:hover {
  background-color: #c7c7c7;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #c7c7c7;
}

.table-white,
.table-white > th,
.table-white > td {
  background-color: white;
}
.table-white th,
.table-white td,
.table-white thead th,
.table-white tbody + tbody {
  border-color: white;
}

.table-hover .table-white:hover {
  background-color: #f2f2f2;
}
.table-hover .table-white:hover > td,
.table-hover .table-white:hover > th {
  background-color: #f2f2f2;
}

.table-black,
.table-black > th,
.table-black > td {
  background-color: #b8b8b8;
}
.table-black th,
.table-black td,
.table-black thead th,
.table-black tbody + tbody {
  border-color: #7a7a7a;
}

.table-hover .table-black:hover {
  background-color: #ababab;
}
.table-hover .table-black:hover > td,
.table-hover .table-black:hover > th {
  background-color: #ababab;
}

.table-primary-900,
.table-primary-900 > th,
.table-primary-900 > td {
  background-color: #b8c5dd;
}
.table-primary-900 th,
.table-primary-900 td,
.table-primary-900 thead th,
.table-primary-900 tbody + tbody {
  border-color: #7a93c1;
}

.table-hover .table-primary-900:hover {
  background-color: #a7b7d5;
}
.table-hover .table-primary-900:hover > td,
.table-hover .table-primary-900:hover > th {
  background-color: #a7b7d5;
}

.table-primary-800,
.table-primary-800 > th,
.table-primary-800 > td {
  background-color: #cacbd6;
}
.table-primary-800 th,
.table-primary-800 td,
.table-primary-800 thead th,
.table-primary-800 tbody + tbody {
  border-color: #9c9fb3;
}

.table-hover .table-primary-800:hover {
  background-color: #bcbdcb;
}
.table-hover .table-primary-800:hover > td,
.table-hover .table-primary-800:hover > th {
  background-color: #bcbdcb;
}

.table-primary-700,
.table-primary-700 > th,
.table-primary-700 > td {
  background-color: #d3d4d5;
}
.table-primary-700 th,
.table-primary-700 td,
.table-primary-700 thead th,
.table-primary-700 tbody + tbody {
  border-color: #aeafb2;
}

.table-hover .table-primary-700:hover {
  background-color: #c6c7c9;
}
.table-hover .table-primary-700:hover > td,
.table-hover .table-primary-700:hover > th {
  background-color: #c6c7c9;
}

.table-primary-600,
.table-primary-600 > th,
.table-primary-600 > td {
  background-color: #dbdde4;
}
.table-primary-600 th,
.table-primary-600 td,
.table-primary-600 thead th,
.table-primary-600 tbody + tbody {
  border-color: #bcbfcc;
}

.table-hover .table-primary-600:hover {
  background-color: #cccfd9;
}
.table-hover .table-primary-600:hover > td,
.table-hover .table-primary-600:hover > th {
  background-color: #cccfd9;
}

.table-primary-400,
.table-primary-400 > th,
.table-primary-400 > td {
  background-color: #edeef1;
}
.table-primary-400 th,
.table-primary-400 td,
.table-primary-400 thead th,
.table-primary-400 tbody + tbody {
  border-color: #dedfe6;
}

.table-hover .table-primary-400:hover {
  background-color: #dfe0e6;
}
.table-hover .table-primary-400:hover > td,
.table-hover .table-primary-400:hover > th {
  background-color: #dfe0e6;
}

.table-primary-200,
.table-primary-200 > th,
.table-primary-200 > td {
  background-color: #f8f8f9;
}
.table-primary-200 th,
.table-primary-200 td,
.table-primary-200 thead th,
.table-primary-200 tbody + tbody {
  border-color: #f1f2f5;
}

.table-hover .table-primary-200:hover {
  background-color: #eaeaed;
}
.table-hover .table-primary-200:hover > td,
.table-hover .table-primary-200:hover > th {
  background-color: #eaeaed;
}

.table-primary-100,
.table-primary-100 > th,
.table-primary-100 > td {
  background-color: #fbfbfc;
}
.table-primary-100 th,
.table-primary-100 td,
.table-primary-100 thead th,
.table-primary-100 tbody + tbody {
  border-color: #f8f8fa;
}

.table-hover .table-primary-100:hover {
  background-color: #ececf1;
}
.table-hover .table-primary-100:hover > td,
.table-hover .table-primary-100:hover > th {
  background-color: #ececf1;
}

.table-secondary-800,
.table-secondary-800 > th,
.table-secondary-800 > td {
  background-color: #b8e7cf;
}
.table-secondary-800 th,
.table-secondary-800 td,
.table-secondary-800 thead th,
.table-secondary-800 tbody + tbody {
  border-color: #7bd3a6;
}

.table-hover .table-secondary-800:hover {
  background-color: #a5e1c2;
}
.table-hover .table-secondary-800:hover > td,
.table-hover .table-secondary-800:hover > th {
  background-color: #a5e1c2;
}

.table-secondary-600,
.table-secondary-600 > th,
.table-secondary-600 > td {
  background-color: #d4f1e2;
}
.table-secondary-600 th,
.table-secondary-600 td,
.table-secondary-600 thead th,
.table-secondary-600 tbody + tbody {
  border-color: #b0e5c9;
}

.table-hover .table-secondary-600:hover {
  background-color: #c1ebd5;
}
.table-hover .table-secondary-600:hover > td,
.table-hover .table-secondary-600:hover > th {
  background-color: #c1ebd5;
}

.table-secondary-400,
.table-secondary-400 > th,
.table-secondary-400 > td {
  background-color: #daffe0;
}
.table-secondary-400 th,
.table-secondary-400 td,
.table-secondary-400 thead th,
.table-secondary-400 tbody + tbody {
  border-color: #baffc5;
}

.table-hover .table-secondary-400:hover {
  background-color: #c1ffcb;
}
.table-hover .table-secondary-400:hover > td,
.table-hover .table-secondary-400:hover > th {
  background-color: #c1ffcb;
}

.table-secondary-200,
.table-secondary-200 > th,
.table-secondary-200 > td {
  background-color: #e8ffec;
}
.table-secondary-200 th,
.table-secondary-200 td,
.table-secondary-200 thead th,
.table-secondary-200 tbody + tbody {
  border-color: #d4ffdc;
}

.table-hover .table-secondary-200:hover {
  background-color: #cfffd7;
}
.table-hover .table-secondary-200:hover > td,
.table-hover .table-secondary-200:hover > th {
  background-color: #cfffd7;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th, .hce-dealer-locator .opening-hours table .thead-dark th {
  color: #ffffff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th, .hce-dealer-locator .opening-hours table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #e0e0e0;
}

.table-dark {
  color: #ffffff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd), .hce-dealer-locator .opening-hours table.table-dark tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #c7c7c7;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #ffffff;
  border-color: #005dd4;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 37, 84, 0.25);
}
.form-control::placeholder {
  color: #666666;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #ffffff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.125rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #333333;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #666666;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #00ad1d;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(0, 173, 29, 0.9);
  border-radius: 0;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #00ad1d;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2300ad1d' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #00ad1d;
  box-shadow: 0 0 0 0.2rem rgba(0, 173, 29, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #00ad1d;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjYgKDY3NDkxKSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5JY29ucy9kcm9wZG93bi8vd2hpdGUvZG93bjwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJJY29ucy9kcm9wZG93bi8vd2hpdGUvZG93biIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMy4wMDAwMDAsIDExLjUwMDAwMCkgc2NhbGUoMSwgLTEpIHRyYW5zbGF0ZSgtMTMuMDAwMDAwLCAtMTEuNTAwMDAwKSB0cmFuc2xhdGUoNi4wMDAwMDAsIDcuMDAwMDAwKSIgZmlsbC1ydWxlPSJub256ZXJvIiBzdHJva2U9IiMwMDA5M0IiIHN0cm9rZS13aWR0aD0iMiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDcuMDIwODE1MjggTDcsMCIgaWQ9IkxpbmUtMi1Db3B5LTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMuNTAwMDAwLCA0LjAwMDAwMCkgcm90YXRlKC0xODAuMDAwMDAwKSB0cmFuc2xhdGUoLTMuNTAwMDAwLCAtNC4wMDAwMDApICI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNNywxLjk1ODM2OTQ0IEwxNCw4Ljk3OTE4NDcyIiBpZD0iTGluZS0yLUNvcHktMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAuNTAwMDAwLCA0Ljk3OTE4NSkgcm90YXRlKC0xODAuMDAwMDAwKSB0cmFuc2xhdGUoLTEwLjUwMDAwMCwgLTQuOTc5MTg1KSAiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==") right 0.75rem center/8px 10px no-repeat, #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2300ad1d' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #00ad1d;
  box-shadow: 0 0 0 0.2rem rgba(0, 173, 29, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #00ad1d;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #00ad1d;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #00ad1d;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #00e026;
  background-color: #00e026;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 173, 29, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #00ad1d;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #00ad1d;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #00ad1d;
  box-shadow: 0 0 0 0.2rem rgba(0, 173, 29, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #d0021b;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(208, 2, 27, 0.9);
  border-radius: 0;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #d0021b;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d0021b' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d0021b' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #d0021b;
  box-shadow: 0 0 0 0.2rem rgba(208, 2, 27, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #d0021b;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjYgKDY3NDkxKSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5JY29ucy9kcm9wZG93bi8vd2hpdGUvZG93bjwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJJY29ucy9kcm9wZG93bi8vd2hpdGUvZG93biIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMy4wMDAwMDAsIDExLjUwMDAwMCkgc2NhbGUoMSwgLTEpIHRyYW5zbGF0ZSgtMTMuMDAwMDAwLCAtMTEuNTAwMDAwKSB0cmFuc2xhdGUoNi4wMDAwMDAsIDcuMDAwMDAwKSIgZmlsbC1ydWxlPSJub256ZXJvIiBzdHJva2U9IiMwMDA5M0IiIHN0cm9rZS13aWR0aD0iMiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDcuMDIwODE1MjggTDcsMCIgaWQ9IkxpbmUtMi1Db3B5LTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMuNTAwMDAwLCA0LjAwMDAwMCkgcm90YXRlKC0xODAuMDAwMDAwKSB0cmFuc2xhdGUoLTMuNTAwMDAwLCAtNC4wMDAwMDApICI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNNywxLjk1ODM2OTQ0IEwxNCw4Ljk3OTE4NDcyIiBpZD0iTGluZS0yLUNvcHktMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAuNTAwMDAwLCA0Ljk3OTE4NSkgcm90YXRlKC0xODAuMDAwMDAwKSB0cmFuc2xhdGUoLTEwLjUwMDAwMCwgLTQuOTc5MTg1KSAiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==") right 0.75rem center/8px 10px no-repeat, #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d0021b' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d0021b' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #d0021b;
  box-shadow: 0 0 0 0.2rem rgba(208, 2, 27, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #d0021b;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #d0021b;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #d0021b;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #fd0826;
  background-color: #fd0826;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(208, 2, 27, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #d0021b;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #d0021b;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #d0021b;
  box-shadow: 0 0 0 0.2rem rgba(208, 2, 27, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.form {
  position: relative;
}

.form-control {
  margin: 0;
  padding: 0.375rem 1.5rem;
  height: 48px;
  border-radius: 33px;
  font-size: 1rem;
  line-height: 1.5555555556;
  border: 0.0625rem solid #bfc1ce;
  color: #002554;
}
.form-control:focus {
  border-color: #7f849d;
  box-shadow: none;
  color: #002554;
}
.form-control::-webkit-input-placeholder {
  color: #7f849d;
}
.form-control::-moz-placeholder {
  color: #7f849d;
}
.form-control:-ms-input-placeholder {
  color: #7f849d;
}
.form-control:-moz-placeholder {
  color: #7f849d;
}
.form-control + .icon {
  position: absolute;
  top: 0.75rem;
  right: 1.1875rem;
}
.form-control.with-warning {
  padding-right: 3.5rem;
  border-color: #d0021b;
  color: #d0021b;
}
.form-control.with-warning + .icon--warning {
  visibility: visible;
  opacity: 1;
}
.form-control + .icon--warning {
  opacity: 0;
  visibility: hidden;
  color: #d0021b;
  fill: #d0021b;
  transition: visibility 0s, opacity 0.5s linear;
}
.form-control ~ .error-message {
  position: relative;
  color: #d0021b;
  left: 1.6875rem;
  margin-top: 0.625rem;
  font-size: 0.875rem;
}

/*SITECORE FORMS */
.form-hyun {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
  /* required fields */
}
.container-fluid .form-hyun, .container-sm .form-hyun, .container-md .form-hyun, .container-lg .form-hyun, .container-xl .form-hyun {
  width: auto;
  margin-right: -12px;
  margin-left: -12px;
}
@media (min-width: 576px) {
  .container-fluid .form-hyun, .container-sm .form-hyun, .container-md .form-hyun, .container-lg .form-hyun, .container-xl .form-hyun {
    max-width: 512px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 768px) {
  .container-fluid .form-hyun, .container-sm .form-hyun, .container-md .form-hyun, .container-lg .form-hyun, .container-xl .form-hyun {
    max-width: 704px;
  }
}
@media (min-width: 992px) {
  .container-fluid .form-hyun, .container-sm .form-hyun, .container-md .form-hyun, .container-lg .form-hyun, .container-xl .form-hyun {
    max-width: 944px;
  }
}
@media (min-width: 1200px) {
  .container-fluid .form-hyun, .container-sm .form-hyun, .container-md .form-hyun, .container-lg .form-hyun, .container-xl .form-hyun {
    max-width: 984px;
  }
}
.form-hyun .form-group {
  width: 100%;
}
.form-hyun p {
  color: #7f849d;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 15px;
}
.form-hyun label {
  color: #003087;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  text-transform: initial;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 5px;
}
.form-hyun label.label-radio, .form-hyun label.label-checkbox {
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.form-hyun input[type=text],
.form-hyun input[type=email],
.form-hyun input[type=tel],
.form-hyun input[type=number],
.form-hyun textarea,
.form-hyun select {
  color: #003087;
  display: block;
  width: 100%;
  padding: 0 15px;
  min-height: 27px;
  height: 40px;
  font-size: 16px;
  border: 2px solid #002554;
}
.form-hyun input[type=text]:focus,
.form-hyun input[type=email]:focus,
.form-hyun input[type=tel]:focus,
.form-hyun input[type=number]:focus,
.form-hyun textarea:focus,
.form-hyun select:focus {
  border-color: #7f849d;
}
.form-hyun input[type=checkbox] {
  margin-right: 10px;
}
.form-hyun textarea {
  height: auto;
}
.form-hyun [data-sc-field-name="List Box"] {
  height: auto;
  padding: 0;
}
.form-hyun [data-sc-field-name="List Box"] option {
  padding: 5px 15px;
  font-size: 14px;
}
.form-hyun input[type=submit] {
  margin: 15px 0;
  background: #ffffff;
  border: 1px solid #00ad1d;
  color: #00ad1d;
  display: inline-block;
  font-weight: 700;
  text-align: center;
  border-radius: 0;
  font-size: 14px;
  padding: 12px 24px;
}
.form-hyun input[type=submit]:hover {
  background: #00ad1d;
  border-color: #00ad1d;
  color: #ffffff;
}
.form-hyun .field-validation-error {
  color: #f2545b;
  display: block;
  font-size: 14px;
}
.form-hyun label + .field-validation-error {
  margin-top: -5px;
}
.form-hyun input + .field-validation-error,
.form-hyun select + .field-validation-error,
.form-hyun textarea + .field-validation-error {
  margin-top: 5px;
}
.form-hyun label.required:after {
  content: "*";
  color: #f2545b;
  margin-left: 2px;
}
.form-hyun input[type=checkbox] + label.required:after {
  content: none;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #333333;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #333333;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 37, 84, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #ffffff;
  background-color: #002554;
  border-color: #002554;
}
.btn-primary:hover {
  color: #ffffff;
  background-color: #00142e;
  border-color: #000f21;
}
.btn-primary:focus, .btn-primary.focus {
  color: #ffffff;
  background-color: #00142e;
  border-color: #000f21;
  box-shadow: 0 0 0 0.2rem rgba(38, 70, 110, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #ffffff;
  background-color: #002554;
  border-color: #002554;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #000f21;
  border-color: #000914;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 70, 110, 0.5);
}

.btn-secondary {
  color: #ffffff;
  background-color: #00ad1d;
  border-color: #00ad1d;
}
.btn-secondary:hover {
  color: #ffffff;
  background-color: #008717;
  border-color: #007a14;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #ffffff;
  background-color: #008717;
  border-color: #007a14;
  box-shadow: 0 0 0 0.2rem rgba(38, 185, 63, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #ffffff;
  background-color: #00ad1d;
  border-color: #00ad1d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #007a14;
  border-color: #006d12;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 185, 63, 0.5);
}

.btn-success {
  color: #ffffff;
  background-color: #00ad1d;
  border-color: #00ad1d;
}
.btn-success:hover {
  color: #ffffff;
  background-color: #008717;
  border-color: #007a14;
}
.btn-success:focus, .btn-success.focus {
  color: #ffffff;
  background-color: #008717;
  border-color: #007a14;
  box-shadow: 0 0 0 0.2rem rgba(38, 185, 63, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #ffffff;
  background-color: #00ad1d;
  border-color: #00ad1d;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #ffffff;
  background-color: #007a14;
  border-color: #006d12;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 185, 63, 0.5);
}

.btn-info {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #ffffff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #ffffff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #ffffff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #333333;
  background-color: #fd7e14;
  border-color: #fd7e14;
}
.btn-warning:hover {
  color: #ffffff;
  background-color: #e96b02;
  border-color: #dc6502;
}
.btn-warning:focus, .btn-warning.focus {
  color: #ffffff;
  background-color: #e96b02;
  border-color: #dc6502;
  box-shadow: 0 0 0 0.2rem rgba(223, 115, 25, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #333333;
  background-color: #fd7e14;
  border-color: #fd7e14;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #ffffff;
  background-color: #dc6502;
  border-color: #cf5f02;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 115, 25, 0.5);
}

.btn-danger {
  color: #ffffff;
  background-color: #d0021b;
  border-color: #d0021b;
}
.btn-danger:hover {
  color: #ffffff;
  background-color: #aa0216;
  border-color: #9d0214;
}
.btn-danger:focus, .btn-danger.focus {
  color: #ffffff;
  background-color: #aa0216;
  border-color: #9d0214;
  box-shadow: 0 0 0 0.2rem rgba(215, 40, 61, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #ffffff;
  background-color: #d0021b;
  border-color: #d0021b;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #9d0214;
  border-color: #910113;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(215, 40, 61, 0.5);
}

.btn-light {
  color: #333333;
  background-color: #c7c7c7;
  border-color: #c7c7c7;
}
.btn-light:hover {
  color: #333333;
  background-color: #b4b4b4;
  border-color: #aeaeae;
}
.btn-light:focus, .btn-light.focus {
  color: #333333;
  background-color: #b4b4b4;
  border-color: #aeaeae;
  box-shadow: 0 0 0 0.2rem rgba(177, 177, 177, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #333333;
  background-color: #c7c7c7;
  border-color: #c7c7c7;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #333333;
  background-color: #aeaeae;
  border-color: #a7a7a7;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(177, 177, 177, 0.5);
}

.btn-dark {
  color: #ffffff;
  background-color: #666666;
  border-color: #666666;
}
.btn-dark:hover {
  color: #ffffff;
  background-color: #535353;
  border-color: #4d4d4d;
}
.btn-dark:focus, .btn-dark.focus {
  color: #ffffff;
  background-color: #535353;
  border-color: #4d4d4d;
  box-shadow: 0 0 0 0.2rem rgba(125, 125, 125, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #ffffff;
  background-color: #666666;
  border-color: #666666;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #4d4d4d;
  border-color: #464646;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(125, 125, 125, 0.5);
}

.btn-white {
  color: #333333;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-white:hover {
  color: #333333;
  background-color: #ececec;
  border-color: #e6e6e6;
}
.btn-white:focus, .btn-white.focus {
  color: #333333;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(224, 224, 224, 0.5);
}
.btn-white.disabled, .btn-white:disabled {
  color: #333333;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active, .show > .btn-white.dropdown-toggle {
  color: #333333;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}
.btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus, .show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(224, 224, 224, 0.5);
}

.btn-black {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-black:hover {
  color: #ffffff;
  background-color: black;
  border-color: black;
}
.btn-black:focus, .btn-black.focus {
  color: #ffffff;
  background-color: black;
  border-color: black;
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}
.btn-black.disabled, .btn-black:disabled {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-black:not(:disabled):not(.disabled):active, .btn-black:not(:disabled):not(.disabled).active, .show > .btn-black.dropdown-toggle {
  color: #ffffff;
  background-color: black;
  border-color: black;
}
.btn-black:not(:disabled):not(.disabled):active:focus, .btn-black:not(:disabled):not(.disabled).active:focus, .show > .btn-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}

.btn-primary-900 {
  color: #ffffff;
  background-color: #003087;
  border-color: #003087;
}
.btn-primary-900:hover {
  color: #ffffff;
  background-color: #002261;
  border-color: #001e54;
}
.btn-primary-900:focus, .btn-primary-900.focus {
  color: #ffffff;
  background-color: #002261;
  border-color: #001e54;
  box-shadow: 0 0 0 0.2rem rgba(38, 79, 153, 0.5);
}
.btn-primary-900.disabled, .btn-primary-900:disabled {
  color: #ffffff;
  background-color: #003087;
  border-color: #003087;
}
.btn-primary-900:not(:disabled):not(.disabled):active, .btn-primary-900:not(:disabled):not(.disabled).active, .show > .btn-primary-900.dropdown-toggle {
  color: #ffffff;
  background-color: #001e54;
  border-color: #001947;
}
.btn-primary-900:not(:disabled):not(.disabled):active:focus, .btn-primary-900:not(:disabled):not(.disabled).active:focus, .show > .btn-primary-900.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 79, 153, 0.5);
}

.btn-primary-800 {
  color: #ffffff;
  background-color: #40476c;
  border-color: #40476c;
}
.btn-primary-800:hover {
  color: #ffffff;
  background-color: #323754;
  border-color: #2d324c;
}
.btn-primary-800:focus, .btn-primary-800.focus {
  color: #ffffff;
  background-color: #323754;
  border-color: #2d324c;
  box-shadow: 0 0 0 0.2rem rgba(93, 99, 130, 0.5);
}
.btn-primary-800.disabled, .btn-primary-800:disabled {
  color: #ffffff;
  background-color: #40476c;
  border-color: #40476c;
}
.btn-primary-800:not(:disabled):not(.disabled):active, .btn-primary-800:not(:disabled):not(.disabled).active, .show > .btn-primary-800.dropdown-toggle {
  color: #ffffff;
  background-color: #2d324c;
  border-color: #282d44;
}
.btn-primary-800:not(:disabled):not(.disabled):active:focus, .btn-primary-800:not(:disabled):not(.disabled).active:focus, .show > .btn-primary-800.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(93, 99, 130, 0.5);
}

.btn-primary-700 {
  color: #ffffff;
  background-color: #63666a;
  border-color: #63666a;
}
.btn-primary-700:hover {
  color: #ffffff;
  background-color: #515356;
  border-color: #4a4d50;
}
.btn-primary-700:focus, .btn-primary-700.focus {
  color: #ffffff;
  background-color: #515356;
  border-color: #4a4d50;
  box-shadow: 0 0 0 0.2rem rgba(122, 125, 128, 0.5);
}
.btn-primary-700.disabled, .btn-primary-700:disabled {
  color: #ffffff;
  background-color: #63666a;
  border-color: #63666a;
}
.btn-primary-700:not(:disabled):not(.disabled):active, .btn-primary-700:not(:disabled):not(.disabled).active, .show > .btn-primary-700.dropdown-toggle {
  color: #ffffff;
  background-color: #4a4d50;
  border-color: #444649;
}
.btn-primary-700:not(:disabled):not(.disabled):active:focus, .btn-primary-700:not(:disabled):not(.disabled).active:focus, .show > .btn-primary-700.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(122, 125, 128, 0.5);
}

.btn-primary-600 {
  color: #ffffff;
  background-color: #7f849d;
  border-color: #7f849d;
}
.btn-primary-600:hover {
  color: #ffffff;
  background-color: #6b708b;
  border-color: #656a84;
}
.btn-primary-600:focus, .btn-primary-600.focus {
  color: #ffffff;
  background-color: #6b708b;
  border-color: #656a84;
  box-shadow: 0 0 0 0.2rem rgba(146, 150, 172, 0.5);
}
.btn-primary-600.disabled, .btn-primary-600:disabled {
  color: #ffffff;
  background-color: #7f849d;
  border-color: #7f849d;
}
.btn-primary-600:not(:disabled):not(.disabled):active, .btn-primary-600:not(:disabled):not(.disabled).active, .show > .btn-primary-600.dropdown-toggle {
  color: #ffffff;
  background-color: #656a84;
  border-color: #60647d;
}
.btn-primary-600:not(:disabled):not(.disabled):active:focus, .btn-primary-600:not(:disabled):not(.disabled).active:focus, .show > .btn-primary-600.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(146, 150, 172, 0.5);
}

.btn-primary-400 {
  color: #333333;
  background-color: #bfc1ce;
  border-color: #bfc1ce;
}
.btn-primary-400:hover {
  color: #333333;
  background-color: #a9acbd;
  border-color: #a2a5b8;
}
.btn-primary-400:focus, .btn-primary-400.focus {
  color: #333333;
  background-color: #a9acbd;
  border-color: #a2a5b8;
  box-shadow: 0 0 0 0.2rem rgba(170, 172, 183, 0.5);
}
.btn-primary-400.disabled, .btn-primary-400:disabled {
  color: #333333;
  background-color: #bfc1ce;
  border-color: #bfc1ce;
}
.btn-primary-400:not(:disabled):not(.disabled):active, .btn-primary-400:not(:disabled):not(.disabled).active, .show > .btn-primary-400.dropdown-toggle {
  color: #333333;
  background-color: #a2a5b8;
  border-color: #9b9eb2;
}
.btn-primary-400:not(:disabled):not(.disabled):active:focus, .btn-primary-400:not(:disabled):not(.disabled).active:focus, .show > .btn-primary-400.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(170, 172, 183, 0.5);
}

.btn-primary-200 {
  color: #333333;
  background-color: #e5e6eb;
  border-color: #e5e6eb;
}
.btn-primary-200:hover {
  color: #333333;
  background-color: #cfd1da;
  border-color: #c8cad5;
}
.btn-primary-200:focus, .btn-primary-200.focus {
  color: #333333;
  background-color: #cfd1da;
  border-color: #c8cad5;
  box-shadow: 0 0 0 0.2rem rgba(202, 203, 207, 0.5);
}
.btn-primary-200.disabled, .btn-primary-200:disabled {
  color: #333333;
  background-color: #e5e6eb;
  border-color: #e5e6eb;
}
.btn-primary-200:not(:disabled):not(.disabled):active, .btn-primary-200:not(:disabled):not(.disabled).active, .show > .btn-primary-200.dropdown-toggle {
  color: #333333;
  background-color: #c8cad5;
  border-color: #c1c3cf;
}
.btn-primary-200:not(:disabled):not(.disabled):active:focus, .btn-primary-200:not(:disabled):not(.disabled).active:focus, .show > .btn-primary-200.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(202, 203, 207, 0.5);
}

.btn-primary-100 {
  color: #333333;
  background-color: #f2f2f5;
  border-color: #f2f2f5;
}
.btn-primary-100:hover {
  color: #333333;
  background-color: #dcdce4;
  border-color: #d5d5df;
}
.btn-primary-100:focus, .btn-primary-100.focus {
  color: #333333;
  background-color: #dcdce4;
  border-color: #d5d5df;
  box-shadow: 0 0 0 0.2rem rgba(213, 213, 216, 0.5);
}
.btn-primary-100.disabled, .btn-primary-100:disabled {
  color: #333333;
  background-color: #f2f2f5;
  border-color: #f2f2f5;
}
.btn-primary-100:not(:disabled):not(.disabled):active, .btn-primary-100:not(:disabled):not(.disabled).active, .show > .btn-primary-100.dropdown-toggle {
  color: #333333;
  background-color: #d5d5df;
  border-color: #ceced9;
}
.btn-primary-100:not(:disabled):not(.disabled):active:focus, .btn-primary-100:not(:disabled):not(.disabled).active:focus, .show > .btn-primary-100.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(213, 213, 216, 0.5);
}

.btn-secondary-800 {
  color: #ffffff;
  background-color: #02ab54;
  border-color: #02ab54;
}
.btn-secondary-800:hover {
  color: #ffffff;
  background-color: #028541;
  border-color: #01793b;
}
.btn-secondary-800:focus, .btn-secondary-800.focus {
  color: #ffffff;
  background-color: #028541;
  border-color: #01793b;
  box-shadow: 0 0 0 0.2rem rgba(40, 184, 110, 0.5);
}
.btn-secondary-800.disabled, .btn-secondary-800:disabled {
  color: #ffffff;
  background-color: #02ab54;
  border-color: #02ab54;
}
.btn-secondary-800:not(:disabled):not(.disabled):active, .btn-secondary-800:not(:disabled):not(.disabled).active, .show > .btn-secondary-800.dropdown-toggle {
  color: #ffffff;
  background-color: #01793b;
  border-color: #016c35;
}
.btn-secondary-800:not(:disabled):not(.disabled):active:focus, .btn-secondary-800:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary-800.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 184, 110, 0.5);
}

.btn-secondary-600 {
  color: #333333;
  background-color: #67cd98;
  border-color: #67cd98;
}
.btn-secondary-600:hover {
  color: #333333;
  background-color: #4ac484;
  border-color: #41c07e;
}
.btn-secondary-600:focus, .btn-secondary-600.focus {
  color: #333333;
  background-color: #4ac484;
  border-color: #41c07e;
  box-shadow: 0 0 0 0.2rem rgba(95, 182, 137, 0.5);
}
.btn-secondary-600.disabled, .btn-secondary-600:disabled {
  color: #333333;
  background-color: #67cd98;
  border-color: #67cd98;
}
.btn-secondary-600:not(:disabled):not(.disabled):active, .btn-secondary-600:not(:disabled):not(.disabled).active, .show > .btn-secondary-600.dropdown-toggle {
  color: #ffffff;
  background-color: #41c07e;
  border-color: #3cb878;
}
.btn-secondary-600:not(:disabled):not(.disabled):active:focus, .btn-secondary-600:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary-600.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(95, 182, 137, 0.5);
}

.btn-secondary-400 {
  color: #333333;
  background-color: #7aff90;
  border-color: #7aff90;
}
.btn-secondary-400:hover {
  color: #333333;
  background-color: #54ff70;
  border-color: #47ff66;
}
.btn-secondary-400:focus, .btn-secondary-400.focus {
  color: #333333;
  background-color: #54ff70;
  border-color: #47ff66;
  box-shadow: 0 0 0 0.2rem rgba(111, 224, 130, 0.5);
}
.btn-secondary-400.disabled, .btn-secondary-400:disabled {
  color: #333333;
  background-color: #7aff90;
  border-color: #7aff90;
}
.btn-secondary-400:not(:disabled):not(.disabled):active, .btn-secondary-400:not(:disabled):not(.disabled).active, .show > .btn-secondary-400.dropdown-toggle {
  color: #333333;
  background-color: #47ff66;
  border-color: #3aff5b;
}
.btn-secondary-400:not(:disabled):not(.disabled):active:focus, .btn-secondary-400:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary-400.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(111, 224, 130, 0.5);
}

.btn-secondary-200 {
  color: #333333;
  background-color: #adffbb;
  border-color: #adffbb;
}
.btn-secondary-200:hover {
  color: #333333;
  background-color: #87ff9b;
  border-color: #7aff90;
}
.btn-secondary-200:focus, .btn-secondary-200.focus {
  color: #333333;
  background-color: #87ff9b;
  border-color: #7aff90;
  box-shadow: 0 0 0 0.2rem rgba(155, 224, 167, 0.5);
}
.btn-secondary-200.disabled, .btn-secondary-200:disabled {
  color: #333333;
  background-color: #adffbb;
  border-color: #adffbb;
}
.btn-secondary-200:not(:disabled):not(.disabled):active, .btn-secondary-200:not(:disabled):not(.disabled).active, .show > .btn-secondary-200.dropdown-toggle {
  color: #333333;
  background-color: #7aff90;
  border-color: #6dff86;
}
.btn-secondary-200:not(:disabled):not(.disabled):active:focus, .btn-secondary-200:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary-200.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(155, 224, 167, 0.5);
}

.btn-outline-primary {
  color: #002554;
  border-color: #002554;
}
.btn-outline-primary:hover {
  color: #ffffff;
  background-color: #002554;
  border-color: #002554;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 37, 84, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #002554;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #002554;
  border-color: #002554;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 37, 84, 0.5);
}

.btn-outline-secondary {
  color: #00ad1d;
  border-color: #00ad1d;
}
.btn-outline-secondary:hover {
  color: #ffffff;
  background-color: #00ad1d;
  border-color: #00ad1d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 173, 29, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #00ad1d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #00ad1d;
  border-color: #00ad1d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 173, 29, 0.5);
}

.btn-outline-success {
  color: #00ad1d;
  border-color: #00ad1d;
}
.btn-outline-success:hover {
  color: #ffffff;
  background-color: #00ad1d;
  border-color: #00ad1d;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 173, 29, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #00ad1d;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #ffffff;
  background-color: #00ad1d;
  border-color: #00ad1d;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 173, 29, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #fd7e14;
  border-color: #fd7e14;
}
.btn-outline-warning:hover {
  color: #333333;
  background-color: #fd7e14;
  border-color: #fd7e14;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 126, 20, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #fd7e14;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #333333;
  background-color: #fd7e14;
  border-color: #fd7e14;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 126, 20, 0.5);
}

.btn-outline-danger {
  color: #d0021b;
  border-color: #d0021b;
}
.btn-outline-danger:hover {
  color: #ffffff;
  background-color: #d0021b;
  border-color: #d0021b;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(208, 2, 27, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #d0021b;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #d0021b;
  border-color: #d0021b;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(208, 2, 27, 0.5);
}

.btn-outline-light {
  color: #c7c7c7;
  border-color: #c7c7c7;
}
.btn-outline-light:hover {
  color: #333333;
  background-color: #c7c7c7;
  border-color: #c7c7c7;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(199, 199, 199, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #c7c7c7;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #333333;
  background-color: #c7c7c7;
  border-color: #c7c7c7;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(199, 199, 199, 0.5);
}

.btn-outline-dark {
  color: #666666;
  border-color: #666666;
}
.btn-outline-dark:hover {
  color: #ffffff;
  background-color: #666666;
  border-color: #666666;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(102, 102, 102, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #666666;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #666666;
  border-color: #666666;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(102, 102, 102, 0.5);
}

.btn-outline-white {
  color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-white:hover {
  color: #333333;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-white:focus, .btn-outline-white.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-outline-white.disabled, .btn-outline-white:disabled {
  color: #ffffff;
  background-color: transparent;
}
.btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active, .show > .btn-outline-white.dropdown-toggle {
  color: #333333;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-black {
  color: #000000;
  border-color: #000000;
}
.btn-outline-black:hover {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-outline-black:focus, .btn-outline-black.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}
.btn-outline-black.disabled, .btn-outline-black:disabled {
  color: #000000;
  background-color: transparent;
}
.btn-outline-black:not(:disabled):not(.disabled):active, .btn-outline-black:not(:disabled):not(.disabled).active, .show > .btn-outline-black.dropdown-toggle {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-outline-black:not(:disabled):not(.disabled):active:focus, .btn-outline-black:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-primary-900 {
  color: #003087;
  border-color: #003087;
}
.btn-outline-primary-900:hover {
  color: #ffffff;
  background-color: #003087;
  border-color: #003087;
}
.btn-outline-primary-900:focus, .btn-outline-primary-900.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 48, 135, 0.5);
}
.btn-outline-primary-900.disabled, .btn-outline-primary-900:disabled {
  color: #003087;
  background-color: transparent;
}
.btn-outline-primary-900:not(:disabled):not(.disabled):active, .btn-outline-primary-900:not(:disabled):not(.disabled).active, .show > .btn-outline-primary-900.dropdown-toggle {
  color: #ffffff;
  background-color: #003087;
  border-color: #003087;
}
.btn-outline-primary-900:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-900:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary-900.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 48, 135, 0.5);
}

.btn-outline-primary-800 {
  color: #40476c;
  border-color: #40476c;
}
.btn-outline-primary-800:hover {
  color: #ffffff;
  background-color: #40476c;
  border-color: #40476c;
}
.btn-outline-primary-800:focus, .btn-outline-primary-800.focus {
  box-shadow: 0 0 0 0.2rem rgba(64, 71, 108, 0.5);
}
.btn-outline-primary-800.disabled, .btn-outline-primary-800:disabled {
  color: #40476c;
  background-color: transparent;
}
.btn-outline-primary-800:not(:disabled):not(.disabled):active, .btn-outline-primary-800:not(:disabled):not(.disabled).active, .show > .btn-outline-primary-800.dropdown-toggle {
  color: #ffffff;
  background-color: #40476c;
  border-color: #40476c;
}
.btn-outline-primary-800:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-800:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary-800.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(64, 71, 108, 0.5);
}

.btn-outline-primary-700 {
  color: #63666a;
  border-color: #63666a;
}
.btn-outline-primary-700:hover {
  color: #ffffff;
  background-color: #63666a;
  border-color: #63666a;
}
.btn-outline-primary-700:focus, .btn-outline-primary-700.focus {
  box-shadow: 0 0 0 0.2rem rgba(99, 102, 106, 0.5);
}
.btn-outline-primary-700.disabled, .btn-outline-primary-700:disabled {
  color: #63666a;
  background-color: transparent;
}
.btn-outline-primary-700:not(:disabled):not(.disabled):active, .btn-outline-primary-700:not(:disabled):not(.disabled).active, .show > .btn-outline-primary-700.dropdown-toggle {
  color: #ffffff;
  background-color: #63666a;
  border-color: #63666a;
}
.btn-outline-primary-700:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-700:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary-700.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(99, 102, 106, 0.5);
}

.btn-outline-primary-600 {
  color: #7f849d;
  border-color: #7f849d;
}
.btn-outline-primary-600:hover {
  color: #ffffff;
  background-color: #7f849d;
  border-color: #7f849d;
}
.btn-outline-primary-600:focus, .btn-outline-primary-600.focus {
  box-shadow: 0 0 0 0.2rem rgba(127, 132, 157, 0.5);
}
.btn-outline-primary-600.disabled, .btn-outline-primary-600:disabled {
  color: #7f849d;
  background-color: transparent;
}
.btn-outline-primary-600:not(:disabled):not(.disabled):active, .btn-outline-primary-600:not(:disabled):not(.disabled).active, .show > .btn-outline-primary-600.dropdown-toggle {
  color: #ffffff;
  background-color: #7f849d;
  border-color: #7f849d;
}
.btn-outline-primary-600:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-600:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary-600.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(127, 132, 157, 0.5);
}

.btn-outline-primary-400 {
  color: #bfc1ce;
  border-color: #bfc1ce;
}
.btn-outline-primary-400:hover {
  color: #333333;
  background-color: #bfc1ce;
  border-color: #bfc1ce;
}
.btn-outline-primary-400:focus, .btn-outline-primary-400.focus {
  box-shadow: 0 0 0 0.2rem rgba(191, 193, 206, 0.5);
}
.btn-outline-primary-400.disabled, .btn-outline-primary-400:disabled {
  color: #bfc1ce;
  background-color: transparent;
}
.btn-outline-primary-400:not(:disabled):not(.disabled):active, .btn-outline-primary-400:not(:disabled):not(.disabled).active, .show > .btn-outline-primary-400.dropdown-toggle {
  color: #333333;
  background-color: #bfc1ce;
  border-color: #bfc1ce;
}
.btn-outline-primary-400:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-400:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary-400.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(191, 193, 206, 0.5);
}

.btn-outline-primary-200 {
  color: #e5e6eb;
  border-color: #e5e6eb;
}
.btn-outline-primary-200:hover {
  color: #333333;
  background-color: #e5e6eb;
  border-color: #e5e6eb;
}
.btn-outline-primary-200:focus, .btn-outline-primary-200.focus {
  box-shadow: 0 0 0 0.2rem rgba(229, 230, 235, 0.5);
}
.btn-outline-primary-200.disabled, .btn-outline-primary-200:disabled {
  color: #e5e6eb;
  background-color: transparent;
}
.btn-outline-primary-200:not(:disabled):not(.disabled):active, .btn-outline-primary-200:not(:disabled):not(.disabled).active, .show > .btn-outline-primary-200.dropdown-toggle {
  color: #333333;
  background-color: #e5e6eb;
  border-color: #e5e6eb;
}
.btn-outline-primary-200:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-200:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary-200.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(229, 230, 235, 0.5);
}

.btn-outline-primary-100 {
  color: #f2f2f5;
  border-color: #f2f2f5;
}
.btn-outline-primary-100:hover {
  color: #333333;
  background-color: #f2f2f5;
  border-color: #f2f2f5;
}
.btn-outline-primary-100:focus, .btn-outline-primary-100.focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 242, 245, 0.5);
}
.btn-outline-primary-100.disabled, .btn-outline-primary-100:disabled {
  color: #f2f2f5;
  background-color: transparent;
}
.btn-outline-primary-100:not(:disabled):not(.disabled):active, .btn-outline-primary-100:not(:disabled):not(.disabled).active, .show > .btn-outline-primary-100.dropdown-toggle {
  color: #333333;
  background-color: #f2f2f5;
  border-color: #f2f2f5;
}
.btn-outline-primary-100:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-100:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary-100.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 242, 245, 0.5);
}

.btn-outline-secondary-800 {
  color: #02ab54;
  border-color: #02ab54;
}
.btn-outline-secondary-800:hover {
  color: #ffffff;
  background-color: #02ab54;
  border-color: #02ab54;
}
.btn-outline-secondary-800:focus, .btn-outline-secondary-800.focus {
  box-shadow: 0 0 0 0.2rem rgba(2, 171, 84, 0.5);
}
.btn-outline-secondary-800.disabled, .btn-outline-secondary-800:disabled {
  color: #02ab54;
  background-color: transparent;
}
.btn-outline-secondary-800:not(:disabled):not(.disabled):active, .btn-outline-secondary-800:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary-800.dropdown-toggle {
  color: #ffffff;
  background-color: #02ab54;
  border-color: #02ab54;
}
.btn-outline-secondary-800:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary-800:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary-800.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(2, 171, 84, 0.5);
}

.btn-outline-secondary-600 {
  color: #67cd98;
  border-color: #67cd98;
}
.btn-outline-secondary-600:hover {
  color: #333333;
  background-color: #67cd98;
  border-color: #67cd98;
}
.btn-outline-secondary-600:focus, .btn-outline-secondary-600.focus {
  box-shadow: 0 0 0 0.2rem rgba(103, 205, 152, 0.5);
}
.btn-outline-secondary-600.disabled, .btn-outline-secondary-600:disabled {
  color: #67cd98;
  background-color: transparent;
}
.btn-outline-secondary-600:not(:disabled):not(.disabled):active, .btn-outline-secondary-600:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary-600.dropdown-toggle {
  color: #333333;
  background-color: #67cd98;
  border-color: #67cd98;
}
.btn-outline-secondary-600:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary-600:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary-600.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(103, 205, 152, 0.5);
}

.btn-outline-secondary-400 {
  color: #7aff90;
  border-color: #7aff90;
}
.btn-outline-secondary-400:hover {
  color: #333333;
  background-color: #7aff90;
  border-color: #7aff90;
}
.btn-outline-secondary-400:focus, .btn-outline-secondary-400.focus {
  box-shadow: 0 0 0 0.2rem rgba(122, 255, 144, 0.5);
}
.btn-outline-secondary-400.disabled, .btn-outline-secondary-400:disabled {
  color: #7aff90;
  background-color: transparent;
}
.btn-outline-secondary-400:not(:disabled):not(.disabled):active, .btn-outline-secondary-400:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary-400.dropdown-toggle {
  color: #333333;
  background-color: #7aff90;
  border-color: #7aff90;
}
.btn-outline-secondary-400:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary-400:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary-400.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(122, 255, 144, 0.5);
}

.btn-outline-secondary-200 {
  color: #adffbb;
  border-color: #adffbb;
}
.btn-outline-secondary-200:hover {
  color: #333333;
  background-color: #adffbb;
  border-color: #adffbb;
}
.btn-outline-secondary-200:focus, .btn-outline-secondary-200.focus {
  box-shadow: 0 0 0 0.2rem rgba(173, 255, 187, 0.5);
}
.btn-outline-secondary-200.disabled, .btn-outline-secondary-200:disabled {
  color: #adffbb;
  background-color: transparent;
}
.btn-outline-secondary-200:not(:disabled):not(.disabled):active, .btn-outline-secondary-200:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary-200.dropdown-toggle {
  color: #333333;
  background-color: #adffbb;
  border-color: #adffbb;
}
.btn-outline-secondary-200:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary-200:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary-200.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(173, 255, 187, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #002554;
  text-decoration: none;
}
.btn-link:hover {
  color: #000308;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #666666;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.btn {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
  min-height: 48px;
  border-radius: 0;
  border-width: 1px;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.3888888889;
}
.btn.is-disabled {
  background-color: #e0e0e0;
  border-color: #e0e0e0;
  color: #c7c7c7;
  pointer-events: none;
}
.btn__label {
  display: inline-block;
}
.btn .icon {
  width: 18px;
  height: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.btn .icon--left {
  margin-right: 1.5625rem;
}
.btn .icon--right {
  margin-left: 1.5625rem;
}
.btn .icon svg {
  margin-top: -2px;
  width: 18px;
  height: 18px;
}
.btn-lg, .btn-group-lg > .btn {
  padding: 18px 32px;
  min-height: 64px;
  font-size: 1.25rem;
  line-height: 1.3;
  font-weight: 700;
}
.btn-lg .icon, .btn-group-lg > .btn .icon {
  width: 20px;
  height: 20px;
}
.btn-lg .icon svg, .btn-group-lg > .btn .icon svg {
  width: 20px;
  height: 20px;
}
.btn-sm, .btn-group-sm > .btn {
  padding: 6px 24px;
  font-size: 1rem;
  min-height: 38px;
}
.btn-sm .icon, .btn-group-sm > .btn .icon {
  width: 16px;
  height: 16px;
}
.btn-sm .icon svg, .btn-group-sm > .btn .icon svg {
  width: 16px;
  height: 16px;
}
.btn-primary .icon {
  fill: #ffffff;
}
.btn-primary-toggle {
  position: relative;
  border-color: #bfc1ce;
  background-color: #ffffff;
  color: #40476c;
  font-size: 1rem;
  text-align: left;
}
.btn-primary-toggle .icon {
  position: absolute;
  top: 0.6875rem;
  right: 1.75rem;
}
.btn-primary-toggle .icon svg {
  fill: #002554;
  transition: transform 300ms ease-in-out;
}
.btn-primary-toggle.is-open {
  border-color: #002554;
  background-color: #002554;
  color: #ffffff;
}
.btn-primary-toggle.is-open .icon svg {
  fill: #ffffff;
  transform: rotate(180deg);
}
.btn-primary-toggle:hover .icon svg {
  fill: #002554;
}
.btn-secondary {
  background-color: #00ad1d;
  color: #ffffff;
  border-color: #00ad1d;
}
.btn-secondary .icon {
  fill: #ffffff;
}
.btn-secondary:hover, .btn-secondary:active {
  background-color: #ffffff;
  color: #00ad1d;
  border-color: #00ad1d;
}
.btn-secondary:hover .icon, .btn-secondary:active .icon {
  fill: #00ad1d;
}
.btn-outline-primary {
  color: #40476c;
  border-color: #bfc1ce;
}
.btn-outline-primary .icon {
  fill: #40476c;
}
.btn-primary:hover, .btn-primary:active, .btn-outline-primary:hover, .btn-outline-primary:active {
  color: #002554 !important;
  background-color: #00ad1d !important;
  border-color: #00ad1d !important;
}
.btn-primary:hover .icon, .btn-primary:active .icon, .btn-outline-primary:hover .icon, .btn-outline-primary:active .icon {
  fill: #002554 !important;
}
.btn-link {
  text-decoration: none !important;
}
.btn-link .btn__label {
  text-decoration: underline;
  text-decoration-color: #67cd98;
}
.btn-link:hover .btn__label {
  text-decoration-color: #00ad1d;
}
.btn-link--alt .icon {
  margin-right: 4px;
}
.btn-link--alt .btn__label {
  text-decoration-color: #7f849d;
}
.btn-link--alt:hover .btn__label {
  text-decoration-color: #002554;
}
.bg-dark .btn-primary {
  background-color: #ffffff;
  color: #002554;
}
.bg-dark .btn-primary:hover, .bg-dark .btn-primary:active {
  color: #002554 !important;
  background-color: #00ad1d !important;
  border-color: #00ad1d !important;
}
.bg-dark .btn-primary:hover .icon, .bg-dark .btn-primary:active .icon {
  fill: #002554 !important;
}
.bg-dark .btn-primary .icon {
  fill: #002554;
}
.bg-dark .btn-outline-primary {
  border-color: #ffffff;
  color: #ffffff;
}
.bg-dark .btn-outline-primary:hover, .bg-dark .btn-outline-primary:active {
  color: #002554 !important;
  background-color: #00ad1d !important;
  border-color: #00ad1d !important;
}
.bg-dark .btn-outline-primary:hover .icon, .bg-dark .btn-outline-primary:active .icon {
  fill: #002554 !important;
}
.bg-dark .btn-outline-primary .icon {
  fill: #ffffff;
}
.bg-dark .btn-link {
  color: #ffffff;
}
.btn:focus, .btn.focus, .btn:active:focus, .btn.active:focus, .btn:active.focus, .btn.active.focus {
  box-shadow: none !important;
  outline: none !important;
}

.bg-dark .btn-link--alt .btn__label {
  text-decoration-color: #7f849d;
}
.bg-dark .btn-link--alt:hover .btn__label {
  text-decoration-color: #e5e6eb;
}
.bg-dark .btn-link--alt .icon {
  fill: #ffffff;
}

.switch {
  max-width: 150px;
  padding: 0.125rem;
  border: 0.0625rem solid #bfc1ce;
  border-radius: 0;
}
.switch--lg {
  max-width: 180px;
}
.switch--lg .switch__label {
  height: 36px;
}
.switch--lg .switch__label > span span {
  height: 36px;
}
.switch__label {
  height: 26px;
  position: relative;
  line-height: inherit;
  font-size: 0.75rem;
  letter-spacing: 1px;
}
.switch__label > a {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
}
.switch__label > span {
  position: absolute;
  left: -100px;
  padding-right: 100px;
}
.switch__label > span span {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 5;
  margin-left: 100px;
  outline: none;
  height: 26px;
  cursor: pointer;
}
.switch__label > span span:last-child {
  left: 50%;
}
.switch__label > span span a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.switch__label span span,
.switch__label span label {
  color: #7f849d;
}
.switch__label .btn {
  z-index: 4;
  position: absolute;
  top: 0;
  right: 50%;
  width: 50%;
  min-height: 100%;
  transition: all 100ms ease-out 0s;
}
.switch__label input {
  position: absolute;
  opacity: 0;
  z-index: 5;
}
.switch__label input:checked ~ .btn {
  right: 0%;
}
.switch__label input ~ span span:nth-child(1) {
  color: #ffffff;
}
.switch__label input:checked ~ span span:nth-child(1) {
  color: #002554;
}
.switch__label input:checked ~ span span:nth-child(2) {
  color: #ffffff;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #333333;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #333333;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #262626;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #002554;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #a2a2a2;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #666666;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #333333;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #666666;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #e0e0e0;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #e0e0e0;
}
.nav-tabs .nav-link.disabled {
  color: #666666;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #ffffff;
  border-color: #e0e0e0 #e0e0e0 #ffffff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #002554;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 0 solid transparent;
  border-radius: 0;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 0 solid transparent;
}
.card-header:first-child {
  border-radius: 0 0 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: transparent;
  border-top: 0 solid transparent;
}
.card-footer:last-child {
  border-radius: 0 0 0 0;
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 0;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-deck .card {
  margin-bottom: 0.5rem;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 0.5rem;
    margin-bottom: 0;
    margin-left: 0.5rem;
  }
}

.card-group > .card {
  margin-bottom: 0.5rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #666666;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #666666;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #ffffff;
  background-color: #002554;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #ffffff;
  background-color: #000f21;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 37, 84, 0.5);
}

.badge-secondary {
  color: #ffffff;
  background-color: #00ad1d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #ffffff;
  background-color: #007a14;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 173, 29, 0.5);
}

.badge-success {
  color: #ffffff;
  background-color: #00ad1d;
}
a.badge-success:hover, a.badge-success:focus {
  color: #ffffff;
  background-color: #007a14;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 173, 29, 0.5);
}

.badge-info {
  color: #ffffff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #ffffff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #333333;
  background-color: #fd7e14;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #333333;
  background-color: #dc6502;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(253, 126, 20, 0.5);
}

.badge-danger {
  color: #ffffff;
  background-color: #d0021b;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #ffffff;
  background-color: #9d0214;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(208, 2, 27, 0.5);
}

.badge-light {
  color: #333333;
  background-color: #c7c7c7;
}
a.badge-light:hover, a.badge-light:focus {
  color: #333333;
  background-color: #aeaeae;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(199, 199, 199, 0.5);
}

.badge-dark {
  color: #ffffff;
  background-color: #666666;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #ffffff;
  background-color: #4d4d4d;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(102, 102, 102, 0.5);
}

.badge-white {
  color: #333333;
  background-color: #ffffff;
}
a.badge-white:hover, a.badge-white:focus {
  color: #333333;
  background-color: #e6e6e6;
}
a.badge-white:focus, a.badge-white.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.badge-black {
  color: #ffffff;
  background-color: #000000;
}
a.badge-black:hover, a.badge-black:focus {
  color: #ffffff;
  background-color: black;
}
a.badge-black:focus, a.badge-black.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.badge-primary-900 {
  color: #ffffff;
  background-color: #003087;
}
a.badge-primary-900:hover, a.badge-primary-900:focus {
  color: #ffffff;
  background-color: #001e54;
}
a.badge-primary-900:focus, a.badge-primary-900.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 48, 135, 0.5);
}

.badge-primary-800 {
  color: #ffffff;
  background-color: #40476c;
}
a.badge-primary-800:hover, a.badge-primary-800:focus {
  color: #ffffff;
  background-color: #2d324c;
}
a.badge-primary-800:focus, a.badge-primary-800.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(64, 71, 108, 0.5);
}

.badge-primary-700 {
  color: #ffffff;
  background-color: #63666a;
}
a.badge-primary-700:hover, a.badge-primary-700:focus {
  color: #ffffff;
  background-color: #4a4d50;
}
a.badge-primary-700:focus, a.badge-primary-700.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(99, 102, 106, 0.5);
}

.badge-primary-600 {
  color: #ffffff;
  background-color: #7f849d;
}
a.badge-primary-600:hover, a.badge-primary-600:focus {
  color: #ffffff;
  background-color: #656a84;
}
a.badge-primary-600:focus, a.badge-primary-600.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(127, 132, 157, 0.5);
}

.badge-primary-400 {
  color: #333333;
  background-color: #bfc1ce;
}
a.badge-primary-400:hover, a.badge-primary-400:focus {
  color: #333333;
  background-color: #a2a5b8;
}
a.badge-primary-400:focus, a.badge-primary-400.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(191, 193, 206, 0.5);
}

.badge-primary-200 {
  color: #333333;
  background-color: #e5e6eb;
}
a.badge-primary-200:hover, a.badge-primary-200:focus {
  color: #333333;
  background-color: #c8cad5;
}
a.badge-primary-200:focus, a.badge-primary-200.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(229, 230, 235, 0.5);
}

.badge-primary-100 {
  color: #333333;
  background-color: #f2f2f5;
}
a.badge-primary-100:hover, a.badge-primary-100:focus {
  color: #333333;
  background-color: #d5d5df;
}
a.badge-primary-100:focus, a.badge-primary-100.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(242, 242, 245, 0.5);
}

.badge-secondary-800 {
  color: #ffffff;
  background-color: #02ab54;
}
a.badge-secondary-800:hover, a.badge-secondary-800:focus {
  color: #ffffff;
  background-color: #01793b;
}
a.badge-secondary-800:focus, a.badge-secondary-800.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(2, 171, 84, 0.5);
}

.badge-secondary-600 {
  color: #333333;
  background-color: #67cd98;
}
a.badge-secondary-600:hover, a.badge-secondary-600:focus {
  color: #333333;
  background-color: #41c07e;
}
a.badge-secondary-600:focus, a.badge-secondary-600.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(103, 205, 152, 0.5);
}

.badge-secondary-400 {
  color: #333333;
  background-color: #7aff90;
}
a.badge-secondary-400:hover, a.badge-secondary-400:focus {
  color: #333333;
  background-color: #47ff66;
}
a.badge-secondary-400:focus, a.badge-secondary-400.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(122, 255, 144, 0.5);
}

.badge-secondary-200 {
  color: #333333;
  background-color: #adffbb;
}
a.badge-secondary-200:hover, a.badge-secondary-200:focus {
  color: #333333;
  background-color: #7aff90;
}
a.badge-secondary-200:focus, a.badge-secondary-200.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(173, 255, 187, 0.5);
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f1f1f1;
}
.list-group-item-action:active {
  color: #333333;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #666666;
  pointer-events: none;
  background-color: #ffffff;
}
.list-group-item.active {
  z-index: 2;
  color: #ffffff;
  background-color: #002554;
  border-color: #002554;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #00132c;
  background-color: #b8c2cf;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #00132c;
  background-color: #a9b5c5;
}
.list-group-item-primary.list-group-item-action.active {
  color: #ffffff;
  background-color: #00132c;
  border-color: #00132c;
}

.list-group-item-secondary {
  color: #005a0f;
  background-color: #b8e8c0;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #005a0f;
  background-color: #a5e2af;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #ffffff;
  background-color: #005a0f;
  border-color: #005a0f;
}

.list-group-item-success {
  color: #005a0f;
  background-color: #b8e8c0;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #005a0f;
  background-color: #a5e2af;
}
.list-group-item-success.list-group-item-action.active {
  color: #ffffff;
  background-color: #005a0f;
  border-color: #005a0f;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #ffffff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #84420a;
  background-color: #fedbbd;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #84420a;
  background-color: #fecda4;
}
.list-group-item-warning.list-group-item-action.active {
  color: #ffffff;
  background-color: #84420a;
  border-color: #84420a;
}

.list-group-item-danger {
  color: #6c010e;
  background-color: #f2b8bf;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #6c010e;
  background-color: #eea2ac;
}
.list-group-item-danger.list-group-item-action.active {
  color: #ffffff;
  background-color: #6c010e;
  border-color: #6c010e;
}

.list-group-item-light {
  color: #676767;
  background-color: #efefef;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #676767;
  background-color: #e2e2e2;
}
.list-group-item-light.list-group-item-action.active {
  color: #ffffff;
  background-color: #676767;
  border-color: #676767;
}

.list-group-item-dark {
  color: #353535;
  background-color: #d4d4d4;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #353535;
  background-color: #c7c7c7;
}
.list-group-item-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: #353535;
  border-color: #353535;
}

.list-group-item-white {
  color: #858585;
  background-color: white;
}
.list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
  color: #858585;
  background-color: #f2f2f2;
}
.list-group-item-white.list-group-item-action.active {
  color: #ffffff;
  background-color: #858585;
  border-color: #858585;
}

.list-group-item-black {
  color: black;
  background-color: #b8b8b8;
}
.list-group-item-black.list-group-item-action:hover, .list-group-item-black.list-group-item-action:focus {
  color: black;
  background-color: #ababab;
}
.list-group-item-black.list-group-item-action.active {
  color: #ffffff;
  background-color: black;
  border-color: black;
}

.list-group-item-primary-900 {
  color: #001946;
  background-color: #b8c5dd;
}
.list-group-item-primary-900.list-group-item-action:hover, .list-group-item-primary-900.list-group-item-action:focus {
  color: #001946;
  background-color: #a7b7d5;
}
.list-group-item-primary-900.list-group-item-action.active {
  color: #ffffff;
  background-color: #001946;
  border-color: #001946;
}

.list-group-item-primary-800 {
  color: #212538;
  background-color: #cacbd6;
}
.list-group-item-primary-800.list-group-item-action:hover, .list-group-item-primary-800.list-group-item-action:focus {
  color: #212538;
  background-color: #bcbdcb;
}
.list-group-item-primary-800.list-group-item-action.active {
  color: #ffffff;
  background-color: #212538;
  border-color: #212538;
}

.list-group-item-primary-700 {
  color: #333537;
  background-color: #d3d4d5;
}
.list-group-item-primary-700.list-group-item-action:hover, .list-group-item-primary-700.list-group-item-action:focus {
  color: #333537;
  background-color: #c6c7c9;
}
.list-group-item-primary-700.list-group-item-action.active {
  color: #ffffff;
  background-color: #333537;
  border-color: #333537;
}

.list-group-item-primary-600 {
  color: #424552;
  background-color: #dbdde4;
}
.list-group-item-primary-600.list-group-item-action:hover, .list-group-item-primary-600.list-group-item-action:focus {
  color: #424552;
  background-color: #cccfd9;
}
.list-group-item-primary-600.list-group-item-action.active {
  color: #ffffff;
  background-color: #424552;
  border-color: #424552;
}

.list-group-item-primary-400 {
  color: #63646b;
  background-color: #edeef1;
}
.list-group-item-primary-400.list-group-item-action:hover, .list-group-item-primary-400.list-group-item-action:focus {
  color: #63646b;
  background-color: #dfe0e6;
}
.list-group-item-primary-400.list-group-item-action.active {
  color: #ffffff;
  background-color: #63646b;
  border-color: #63646b;
}

.list-group-item-primary-200 {
  color: #77787a;
  background-color: #f8f8f9;
}
.list-group-item-primary-200.list-group-item-action:hover, .list-group-item-primary-200.list-group-item-action:focus {
  color: #77787a;
  background-color: #eaeaed;
}
.list-group-item-primary-200.list-group-item-action.active {
  color: #ffffff;
  background-color: #77787a;
  border-color: #77787a;
}

.list-group-item-primary-100 {
  color: #7e7e7f;
  background-color: #fbfbfc;
}
.list-group-item-primary-100.list-group-item-action:hover, .list-group-item-primary-100.list-group-item-action:focus {
  color: #7e7e7f;
  background-color: #ececf1;
}
.list-group-item-primary-100.list-group-item-action.active {
  color: #ffffff;
  background-color: #7e7e7f;
  border-color: #7e7e7f;
}

.list-group-item-secondary-800 {
  color: #01592c;
  background-color: #b8e7cf;
}
.list-group-item-secondary-800.list-group-item-action:hover, .list-group-item-secondary-800.list-group-item-action:focus {
  color: #01592c;
  background-color: #a5e1c2;
}
.list-group-item-secondary-800.list-group-item-action.active {
  color: #ffffff;
  background-color: #01592c;
  border-color: #01592c;
}

.list-group-item-secondary-600 {
  color: #366b4f;
  background-color: #d4f1e2;
}
.list-group-item-secondary-600.list-group-item-action:hover, .list-group-item-secondary-600.list-group-item-action:focus {
  color: #366b4f;
  background-color: #c1ebd5;
}
.list-group-item-secondary-600.list-group-item-action.active {
  color: #ffffff;
  background-color: #366b4f;
  border-color: #366b4f;
}

.list-group-item-secondary-400 {
  color: #3f854b;
  background-color: #daffe0;
}
.list-group-item-secondary-400.list-group-item-action:hover, .list-group-item-secondary-400.list-group-item-action:focus {
  color: #3f854b;
  background-color: #c1ffcb;
}
.list-group-item-secondary-400.list-group-item-action.active {
  color: #ffffff;
  background-color: #3f854b;
  border-color: #3f854b;
}

.list-group-item-secondary-200 {
  color: #5a8561;
  background-color: #e8ffec;
}
.list-group-item-secondary-200.list-group-item-action:hover, .list-group-item-secondary-200.list-group-item-action:focus {
  color: #5a8561;
  background-color: #cfffd7;
}
.list-group-item-secondary-200.list-group-item-action.active {
  color: #ffffff;
  background-color: #5a8561;
  border-color: #5a8561;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e0e0e0;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #e0e0e0;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}

.flag-icon-background, .flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.flag-icon {
  position: relative;
  display: inline-block;
  width: 1.3333333333em;
  line-height: 1em;
}
.flag-icon:before {
  content: " ";
}
.flag-icon.flag-icon-squared {
  width: 1em;
}

.flag-icon-ad {
  background-image: url(/assets/icons/flags/4x3/ad.svg);
}
.flag-icon-ad.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ad.svg);
}

.flag-icon-ae {
  background-image: url(/assets/icons/flags/4x3/ae.svg);
}
.flag-icon-ae.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ae.svg);
}

.flag-icon-af {
  background-image: url(/assets/icons/flags/4x3/af.svg);
}
.flag-icon-af.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/af.svg);
}

.flag-icon-ag {
  background-image: url(/assets/icons/flags/4x3/ag.svg);
}
.flag-icon-ag.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ag.svg);
}

.flag-icon-ai {
  background-image: url(/assets/icons/flags/4x3/ai.svg);
}
.flag-icon-ai.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ai.svg);
}

.flag-icon-al {
  background-image: url(/assets/icons/flags/4x3/al.svg);
}
.flag-icon-al.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/al.svg);
}

.flag-icon-am {
  background-image: url(/assets/icons/flags/4x3/am.svg);
}
.flag-icon-am.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/am.svg);
}

.flag-icon-ao {
  background-image: url(/assets/icons/flags/4x3/ao.svg);
}
.flag-icon-ao.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ao.svg);
}

.flag-icon-aq {
  background-image: url(/assets/icons/flags/4x3/aq.svg);
}
.flag-icon-aq.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/aq.svg);
}

.flag-icon-ar {
  background-image: url(/assets/icons/flags/4x3/ar.svg);
}
.flag-icon-ar.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ar.svg);
}

.flag-icon-as {
  background-image: url(/assets/icons/flags/4x3/as.svg);
}
.flag-icon-as.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/as.svg);
}

.flag-icon-at {
  background-image: url(/assets/icons/flags/4x3/at.svg);
}
.flag-icon-at.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/at.svg);
}

.flag-icon-au {
  background-image: url(/assets/icons/flags/4x3/au.svg);
}
.flag-icon-au.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/au.svg);
}

.flag-icon-aw {
  background-image: url(/assets/icons/flags/4x3/aw.svg);
}
.flag-icon-aw.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/aw.svg);
}

.flag-icon-ax {
  background-image: url(/assets/icons/flags/4x3/ax.svg);
}
.flag-icon-ax.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ax.svg);
}

.flag-icon-az {
  background-image: url(/assets/icons/flags/4x3/az.svg);
}
.flag-icon-az.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/az.svg);
}

.flag-icon-ba {
  background-image: url(/assets/icons/flags/4x3/ba.svg);
}
.flag-icon-ba.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ba.svg);
}

.flag-icon-bb {
  background-image: url(/assets/icons/flags/4x3/bb.svg);
}
.flag-icon-bb.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/bb.svg);
}

.flag-icon-bd {
  background-image: url(/assets/icons/flags/4x3/bd.svg);
}
.flag-icon-bd.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/bd.svg);
}

.flag-icon-be {
  background-image: url(/assets/icons/flags/4x3/be.svg);
}
.flag-icon-be.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/be.svg);
}

.flag-icon-bf {
  background-image: url(/assets/icons/flags/4x3/bf.svg);
}
.flag-icon-bf.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/bf.svg);
}

.flag-icon-bg {
  background-image: url(/assets/icons/flags/4x3/bg.svg);
}
.flag-icon-bg.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/bg.svg);
}

.flag-icon-bh {
  background-image: url(/assets/icons/flags/4x3/bh.svg);
}
.flag-icon-bh.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/bh.svg);
}

.flag-icon-bi {
  background-image: url(/assets/icons/flags/4x3/bi.svg);
}
.flag-icon-bi.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/bi.svg);
}

.flag-icon-bj {
  background-image: url(/assets/icons/flags/4x3/bj.svg);
}
.flag-icon-bj.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/bj.svg);
}

.flag-icon-bl {
  background-image: url(/assets/icons/flags/4x3/bl.svg);
}
.flag-icon-bl.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/bl.svg);
}

.flag-icon-bm {
  background-image: url(/assets/icons/flags/4x3/bm.svg);
}
.flag-icon-bm.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/bm.svg);
}

.flag-icon-bn {
  background-image: url(/assets/icons/flags/4x3/bn.svg);
}
.flag-icon-bn.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/bn.svg);
}

.flag-icon-bo {
  background-image: url(/assets/icons/flags/4x3/bo.svg);
}
.flag-icon-bo.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/bo.svg);
}

.flag-icon-bq {
  background-image: url(/assets/icons/flags/4x3/bq.svg);
}
.flag-icon-bq.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/bq.svg);
}

.flag-icon-br {
  background-image: url(/assets/icons/flags/4x3/br.svg);
}
.flag-icon-br.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/br.svg);
}

.flag-icon-bs {
  background-image: url(/assets/icons/flags/4x3/bs.svg);
}
.flag-icon-bs.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/bs.svg);
}

.flag-icon-bt {
  background-image: url(/assets/icons/flags/4x3/bt.svg);
}
.flag-icon-bt.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/bt.svg);
}

.flag-icon-bv {
  background-image: url(/assets/icons/flags/4x3/bv.svg);
}
.flag-icon-bv.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/bv.svg);
}

.flag-icon-bw {
  background-image: url(/assets/icons/flags/4x3/bw.svg);
}
.flag-icon-bw.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/bw.svg);
}

.flag-icon-by {
  background-image: url(/assets/icons/flags/4x3/by.svg);
}
.flag-icon-by.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/by.svg);
}

.flag-icon-bz {
  background-image: url(/assets/icons/flags/4x3/bz.svg);
}
.flag-icon-bz.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/bz.svg);
}

.flag-icon-ca {
  background-image: url(/assets/icons/flags/4x3/ca.svg);
}
.flag-icon-ca.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ca.svg);
}

.flag-icon-cc {
  background-image: url(/assets/icons/flags/4x3/cc.svg);
}
.flag-icon-cc.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/cc.svg);
}

.flag-icon-cd {
  background-image: url(/assets/icons/flags/4x3/cd.svg);
}
.flag-icon-cd.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/cd.svg);
}

.flag-icon-cf {
  background-image: url(/assets/icons/flags/4x3/cf.svg);
}
.flag-icon-cf.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/cf.svg);
}

.flag-icon-cg {
  background-image: url(/assets/icons/flags/4x3/cg.svg);
}
.flag-icon-cg.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/cg.svg);
}

.flag-icon-ch {
  background-image: url(/assets/icons/flags/4x3/ch.svg);
}
.flag-icon-ch.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ch.svg);
}

.flag-icon-ci {
  background-image: url(/assets/icons/flags/4x3/ci.svg);
}
.flag-icon-ci.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ci.svg);
}

.flag-icon-ck {
  background-image: url(/assets/icons/flags/4x3/ck.svg);
}
.flag-icon-ck.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ck.svg);
}

.flag-icon-cl {
  background-image: url(/assets/icons/flags/4x3/cl.svg);
}
.flag-icon-cl.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/cl.svg);
}

.flag-icon-cm {
  background-image: url(/assets/icons/flags/4x3/cm.svg);
}
.flag-icon-cm.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/cm.svg);
}

.flag-icon-cn {
  background-image: url(/assets/icons/flags/4x3/cn.svg);
}
.flag-icon-cn.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/cn.svg);
}

.flag-icon-co {
  background-image: url(/assets/icons/flags/4x3/co.svg);
}
.flag-icon-co.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/co.svg);
}

.flag-icon-cr {
  background-image: url(/assets/icons/flags/4x3/cr.svg);
}
.flag-icon-cr.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/cr.svg);
}

.flag-icon-cu {
  background-image: url(/assets/icons/flags/4x3/cu.svg);
}
.flag-icon-cu.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/cu.svg);
}

.flag-icon-cv {
  background-image: url(/assets/icons/flags/4x3/cv.svg);
}
.flag-icon-cv.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/cv.svg);
}

.flag-icon-cw {
  background-image: url(/assets/icons/flags/4x3/cw.svg);
}
.flag-icon-cw.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/cw.svg);
}

.flag-icon-cx {
  background-image: url(/assets/icons/flags/4x3/cx.svg);
}
.flag-icon-cx.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/cx.svg);
}

.flag-icon-cy {
  background-image: url(/assets/icons/flags/4x3/cy.svg);
}
.flag-icon-cy.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/cy.svg);
}

.flag-icon-cz {
  background-image: url(/assets/icons/flags/4x3/cz.svg);
}
.flag-icon-cz.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/cz.svg);
}

.flag-icon-de {
  background-image: url(/assets/icons/flags/4x3/de.svg);
}
.flag-icon-de.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/de.svg);
}

.flag-icon-dj {
  background-image: url(/assets/icons/flags/4x3/dj.svg);
}
.flag-icon-dj.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/dj.svg);
}

.flag-icon-dk {
  background-image: url(/assets/icons/flags/4x3/dk.svg);
}
.flag-icon-dk.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/dk.svg);
}

.flag-icon-dm {
  background-image: url(/assets/icons/flags/4x3/dm.svg);
}
.flag-icon-dm.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/dm.svg);
}

.flag-icon-do {
  background-image: url(/assets/icons/flags/4x3/do.svg);
}
.flag-icon-do.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/do.svg);
}

.flag-icon-dz {
  background-image: url(/assets/icons/flags/4x3/dz.svg);
}
.flag-icon-dz.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/dz.svg);
}

.flag-icon-ec {
  background-image: url(/assets/icons/flags/4x3/ec.svg);
}
.flag-icon-ec.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ec.svg);
}

.flag-icon-ee {
  background-image: url(/assets/icons/flags/4x3/ee.svg);
}
.flag-icon-ee.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ee.svg);
}

.flag-icon-eg {
  background-image: url(/assets/icons/flags/4x3/eg.svg);
}
.flag-icon-eg.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/eg.svg);
}

.flag-icon-eh {
  background-image: url(/assets/icons/flags/4x3/eh.svg);
}
.flag-icon-eh.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/eh.svg);
}

.flag-icon-er {
  background-image: url(/assets/icons/flags/4x3/er.svg);
}
.flag-icon-er.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/er.svg);
}

.flag-icon-es {
  background-image: url(/assets/icons/flags/4x3/es.svg);
}
.flag-icon-es.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/es.svg);
}

.flag-icon-et {
  background-image: url(/assets/icons/flags/4x3/et.svg);
}
.flag-icon-et.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/et.svg);
}

.flag-icon-fi {
  background-image: url(/assets/icons/flags/4x3/fi.svg);
}
.flag-icon-fi.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/fi.svg);
}

.flag-icon-fj {
  background-image: url(/assets/icons/flags/4x3/fj.svg);
}
.flag-icon-fj.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/fj.svg);
}

.flag-icon-fk {
  background-image: url(/assets/icons/flags/4x3/fk.svg);
}
.flag-icon-fk.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/fk.svg);
}

.flag-icon-fm {
  background-image: url(/assets/icons/flags/4x3/fm.svg);
}
.flag-icon-fm.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/fm.svg);
}

.flag-icon-fo {
  background-image: url(/assets/icons/flags/4x3/fo.svg);
}
.flag-icon-fo.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/fo.svg);
}

.flag-icon-fr {
  background-image: url(/assets/icons/flags/4x3/fr.svg);
}
.flag-icon-fr.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/fr.svg);
}

.flag-icon-ga {
  background-image: url(/assets/icons/flags/4x3/ga.svg);
}
.flag-icon-ga.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ga.svg);
}

.flag-icon-gb {
  background-image: url(/assets/icons/flags/4x3/gb.svg);
}
.flag-icon-gb.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/gb.svg);
}

.flag-icon-gd {
  background-image: url(/assets/icons/flags/4x3/gd.svg);
}
.flag-icon-gd.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/gd.svg);
}

.flag-icon-ge {
  background-image: url(/assets/icons/flags/4x3/ge.svg);
}
.flag-icon-ge.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ge.svg);
}

.flag-icon-gf {
  background-image: url(/assets/icons/flags/4x3/gf.svg);
}
.flag-icon-gf.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/gf.svg);
}

.flag-icon-gg {
  background-image: url(/assets/icons/flags/4x3/gg.svg);
}
.flag-icon-gg.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/gg.svg);
}

.flag-icon-gh {
  background-image: url(/assets/icons/flags/4x3/gh.svg);
}
.flag-icon-gh.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/gh.svg);
}

.flag-icon-gi {
  background-image: url(/assets/icons/flags/4x3/gi.svg);
}
.flag-icon-gi.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/gi.svg);
}

.flag-icon-gl {
  background-image: url(/assets/icons/flags/4x3/gl.svg);
}
.flag-icon-gl.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/gl.svg);
}

.flag-icon-gm {
  background-image: url(/assets/icons/flags/4x3/gm.svg);
}
.flag-icon-gm.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/gm.svg);
}

.flag-icon-gn {
  background-image: url(/assets/icons/flags/4x3/gn.svg);
}
.flag-icon-gn.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/gn.svg);
}

.flag-icon-gp {
  background-image: url(/assets/icons/flags/4x3/gp.svg);
}
.flag-icon-gp.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/gp.svg);
}

.flag-icon-gq {
  background-image: url(/assets/icons/flags/4x3/gq.svg);
}
.flag-icon-gq.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/gq.svg);
}

.flag-icon-gr {
  background-image: url(/assets/icons/flags/4x3/gr.svg);
}
.flag-icon-gr.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/gr.svg);
}

.flag-icon-gs {
  background-image: url(/assets/icons/flags/4x3/gs.svg);
}
.flag-icon-gs.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/gs.svg);
}

.flag-icon-gt {
  background-image: url(/assets/icons/flags/4x3/gt.svg);
}
.flag-icon-gt.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/gt.svg);
}

.flag-icon-gu {
  background-image: url(/assets/icons/flags/4x3/gu.svg);
}
.flag-icon-gu.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/gu.svg);
}

.flag-icon-gw {
  background-image: url(/assets/icons/flags/4x3/gw.svg);
}
.flag-icon-gw.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/gw.svg);
}

.flag-icon-gy {
  background-image: url(/assets/icons/flags/4x3/gy.svg);
}
.flag-icon-gy.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/gy.svg);
}

.flag-icon-hk {
  background-image: url(/assets/icons/flags/4x3/hk.svg);
}
.flag-icon-hk.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/hk.svg);
}

.flag-icon-hm {
  background-image: url(/assets/icons/flags/4x3/hm.svg);
}
.flag-icon-hm.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/hm.svg);
}

.flag-icon-hn {
  background-image: url(/assets/icons/flags/4x3/hn.svg);
}
.flag-icon-hn.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/hn.svg);
}

.flag-icon-hr {
  background-image: url(/assets/icons/flags/4x3/hr.svg);
}
.flag-icon-hr.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/hr.svg);
}

.flag-icon-ht {
  background-image: url(/assets/icons/flags/4x3/ht.svg);
}
.flag-icon-ht.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ht.svg);
}

.flag-icon-hu {
  background-image: url(/assets/icons/flags/4x3/hu.svg);
}
.flag-icon-hu.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/hu.svg);
}

.flag-icon-id {
  background-image: url(/assets/icons/flags/4x3/id.svg);
}
.flag-icon-id.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/id.svg);
}

.flag-icon-ie {
  background-image: url(/assets/icons/flags/4x3/ie.svg);
}
.flag-icon-ie.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ie.svg);
}

.flag-icon-il {
  background-image: url(/assets/icons/flags/4x3/il.svg);
}
.flag-icon-il.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/il.svg);
}

.flag-icon-im {
  background-image: url(/assets/icons/flags/4x3/im.svg);
}
.flag-icon-im.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/im.svg);
}

.flag-icon-in {
  background-image: url(/assets/icons/flags/4x3/in.svg);
}
.flag-icon-in.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/in.svg);
}

.flag-icon-io {
  background-image: url(/assets/icons/flags/4x3/io.svg);
}
.flag-icon-io.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/io.svg);
}

.flag-icon-iq {
  background-image: url(/assets/icons/flags/4x3/iq.svg);
}
.flag-icon-iq.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/iq.svg);
}

.flag-icon-ir {
  background-image: url(/assets/icons/flags/4x3/ir.svg);
}
.flag-icon-ir.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ir.svg);
}

.flag-icon-is {
  background-image: url(/assets/icons/flags/4x3/is.svg);
}
.flag-icon-is.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/is.svg);
}

.flag-icon-it {
  background-image: url(/assets/icons/flags/4x3/it.svg);
}
.flag-icon-it.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/it.svg);
}

.flag-icon-je {
  background-image: url(/assets/icons/flags/4x3/je.svg);
}
.flag-icon-je.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/je.svg);
}

.flag-icon-jm {
  background-image: url(/assets/icons/flags/4x3/jm.svg);
}
.flag-icon-jm.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/jm.svg);
}

.flag-icon-jo {
  background-image: url(/assets/icons/flags/4x3/jo.svg);
}
.flag-icon-jo.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/jo.svg);
}

.flag-icon-jp {
  background-image: url(/assets/icons/flags/4x3/jp.svg);
}
.flag-icon-jp.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/jp.svg);
}

.flag-icon-ke {
  background-image: url(/assets/icons/flags/4x3/ke.svg);
}
.flag-icon-ke.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ke.svg);
}

.flag-icon-kg {
  background-image: url(/assets/icons/flags/4x3/kg.svg);
}
.flag-icon-kg.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/kg.svg);
}

.flag-icon-kh {
  background-image: url(/assets/icons/flags/4x3/kh.svg);
}
.flag-icon-kh.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/kh.svg);
}

.flag-icon-ki {
  background-image: url(/assets/icons/flags/4x3/ki.svg);
}
.flag-icon-ki.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ki.svg);
}

.flag-icon-km {
  background-image: url(/assets/icons/flags/4x3/km.svg);
}
.flag-icon-km.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/km.svg);
}

.flag-icon-kn {
  background-image: url(/assets/icons/flags/4x3/kn.svg);
}
.flag-icon-kn.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/kn.svg);
}

.flag-icon-kp {
  background-image: url(/assets/icons/flags/4x3/kp.svg);
}
.flag-icon-kp.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/kp.svg);
}

.flag-icon-kr {
  background-image: url(/assets/icons/flags/4x3/kr.svg);
}
.flag-icon-kr.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/kr.svg);
}

.flag-icon-kw {
  background-image: url(/assets/icons/flags/4x3/kw.svg);
}
.flag-icon-kw.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/kw.svg);
}

.flag-icon-ky {
  background-image: url(/assets/icons/flags/4x3/ky.svg);
}
.flag-icon-ky.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ky.svg);
}

.flag-icon-kz {
  background-image: url(/assets/icons/flags/4x3/kz.svg);
}
.flag-icon-kz.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/kz.svg);
}

.flag-icon-la {
  background-image: url(/assets/icons/flags/4x3/la.svg);
}
.flag-icon-la.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/la.svg);
}

.flag-icon-lb {
  background-image: url(/assets/icons/flags/4x3/lb.svg);
}
.flag-icon-lb.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/lb.svg);
}

.flag-icon-lc {
  background-image: url(/assets/icons/flags/4x3/lc.svg);
}
.flag-icon-lc.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/lc.svg);
}

.flag-icon-li {
  background-image: url(/assets/icons/flags/4x3/li.svg);
}
.flag-icon-li.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/li.svg);
}

.flag-icon-lk {
  background-image: url(/assets/icons/flags/4x3/lk.svg);
}
.flag-icon-lk.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/lk.svg);
}

.flag-icon-lr {
  background-image: url(/assets/icons/flags/4x3/lr.svg);
}
.flag-icon-lr.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/lr.svg);
}

.flag-icon-ls {
  background-image: url(/assets/icons/flags/4x3/ls.svg);
}
.flag-icon-ls.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ls.svg);
}

.flag-icon-lt {
  background-image: url(/assets/icons/flags/4x3/lt.svg);
}
.flag-icon-lt.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/lt.svg);
}

.flag-icon-lu {
  background-image: url(/assets/icons/flags/4x3/lu.svg);
}
.flag-icon-lu.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/lu.svg);
}

.flag-icon-lv {
  background-image: url(/assets/icons/flags/4x3/lv.svg);
}
.flag-icon-lv.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/lv.svg);
}

.flag-icon-ly {
  background-image: url(/assets/icons/flags/4x3/ly.svg);
}
.flag-icon-ly.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ly.svg);
}

.flag-icon-ma {
  background-image: url(/assets/icons/flags/4x3/ma.svg);
}
.flag-icon-ma.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ma.svg);
}

.flag-icon-mc {
  background-image: url(/assets/icons/flags/4x3/mc.svg);
}
.flag-icon-mc.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/mc.svg);
}

.flag-icon-md {
  background-image: url(/assets/icons/flags/4x3/md.svg);
}
.flag-icon-md.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/md.svg);
}

.flag-icon-me {
  background-image: url(/assets/icons/flags/4x3/me.svg);
}
.flag-icon-me.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/me.svg);
}

.flag-icon-mf {
  background-image: url(/assets/icons/flags/4x3/mf.svg);
}
.flag-icon-mf.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/mf.svg);
}

.flag-icon-mg {
  background-image: url(/assets/icons/flags/4x3/mg.svg);
}
.flag-icon-mg.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/mg.svg);
}

.flag-icon-mh {
  background-image: url(/assets/icons/flags/4x3/mh.svg);
}
.flag-icon-mh.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/mh.svg);
}

.flag-icon-mk {
  background-image: url(/assets/icons/flags/4x3/mk.svg);
}
.flag-icon-mk.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/mk.svg);
}

.flag-icon-ml {
  background-image: url(/assets/icons/flags/4x3/ml.svg);
}
.flag-icon-ml.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ml.svg);
}

.flag-icon-mm {
  background-image: url(/assets/icons/flags/4x3/mm.svg);
}
.flag-icon-mm.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/mm.svg);
}

.flag-icon-mn {
  background-image: url(/assets/icons/flags/4x3/mn.svg);
}
.flag-icon-mn.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/mn.svg);
}

.flag-icon-mo {
  background-image: url(/assets/icons/flags/4x3/mo.svg);
}
.flag-icon-mo.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/mo.svg);
}

.flag-icon-mp {
  background-image: url(/assets/icons/flags/4x3/mp.svg);
}
.flag-icon-mp.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/mp.svg);
}

.flag-icon-mq {
  background-image: url(/assets/icons/flags/4x3/mq.svg);
}
.flag-icon-mq.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/mq.svg);
}

.flag-icon-mr {
  background-image: url(/assets/icons/flags/4x3/mr.svg);
}
.flag-icon-mr.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/mr.svg);
}

.flag-icon-ms {
  background-image: url(/assets/icons/flags/4x3/ms.svg);
}
.flag-icon-ms.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ms.svg);
}

.flag-icon-mt {
  background-image: url(/assets/icons/flags/4x3/mt.svg);
}
.flag-icon-mt.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/mt.svg);
}

.flag-icon-mu {
  background-image: url(/assets/icons/flags/4x3/mu.svg);
}
.flag-icon-mu.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/mu.svg);
}

.flag-icon-mv {
  background-image: url(/assets/icons/flags/4x3/mv.svg);
}
.flag-icon-mv.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/mv.svg);
}

.flag-icon-mw {
  background-image: url(/assets/icons/flags/4x3/mw.svg);
}
.flag-icon-mw.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/mw.svg);
}

.flag-icon-mx {
  background-image: url(/assets/icons/flags/4x3/mx.svg);
}
.flag-icon-mx.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/mx.svg);
}

.flag-icon-my {
  background-image: url(/assets/icons/flags/4x3/my.svg);
}
.flag-icon-my.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/my.svg);
}

.flag-icon-mz {
  background-image: url(/assets/icons/flags/4x3/mz.svg);
}
.flag-icon-mz.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/mz.svg);
}

.flag-icon-na {
  background-image: url(/assets/icons/flags/4x3/na.svg);
}
.flag-icon-na.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/na.svg);
}

.flag-icon-nc {
  background-image: url(/assets/icons/flags/4x3/nc.svg);
}
.flag-icon-nc.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/nc.svg);
}

.flag-icon-ne {
  background-image: url(/assets/icons/flags/4x3/ne.svg);
}
.flag-icon-ne.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ne.svg);
}

.flag-icon-nf {
  background-image: url(/assets/icons/flags/4x3/nf.svg);
}
.flag-icon-nf.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/nf.svg);
}

.flag-icon-ng {
  background-image: url(/assets/icons/flags/4x3/ng.svg);
}
.flag-icon-ng.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ng.svg);
}

.flag-icon-ni {
  background-image: url(/assets/icons/flags/4x3/ni.svg);
}
.flag-icon-ni.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ni.svg);
}

.flag-icon-nl {
  background-image: url(/assets/icons/flags/4x3/nl.svg);
}
.flag-icon-nl.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/nl.svg);
}

.flag-icon-no {
  background-image: url(/assets/icons/flags/4x3/no.svg);
}
.flag-icon-no.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/no.svg);
}

.flag-icon-np {
  background-image: url(/assets/icons/flags/4x3/np.svg);
}
.flag-icon-np.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/np.svg);
}

.flag-icon-nr {
  background-image: url(/assets/icons/flags/4x3/nr.svg);
}
.flag-icon-nr.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/nr.svg);
}

.flag-icon-nu {
  background-image: url(/assets/icons/flags/4x3/nu.svg);
}
.flag-icon-nu.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/nu.svg);
}

.flag-icon-nz {
  background-image: url(/assets/icons/flags/4x3/nz.svg);
}
.flag-icon-nz.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/nz.svg);
}

.flag-icon-om {
  background-image: url(/assets/icons/flags/4x3/om.svg);
}
.flag-icon-om.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/om.svg);
}

.flag-icon-pa {
  background-image: url(/assets/icons/flags/4x3/pa.svg);
}
.flag-icon-pa.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/pa.svg);
}

.flag-icon-pe {
  background-image: url(/assets/icons/flags/4x3/pe.svg);
}
.flag-icon-pe.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/pe.svg);
}

.flag-icon-pf {
  background-image: url(/assets/icons/flags/4x3/pf.svg);
}
.flag-icon-pf.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/pf.svg);
}

.flag-icon-pg {
  background-image: url(/assets/icons/flags/4x3/pg.svg);
}
.flag-icon-pg.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/pg.svg);
}

.flag-icon-ph {
  background-image: url(/assets/icons/flags/4x3/ph.svg);
}
.flag-icon-ph.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ph.svg);
}

.flag-icon-pk {
  background-image: url(/assets/icons/flags/4x3/pk.svg);
}
.flag-icon-pk.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/pk.svg);
}

.flag-icon-pl {
  background-image: url(/assets/icons/flags/4x3/pl.svg);
}
.flag-icon-pl.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/pl.svg);
}

.flag-icon-pm {
  background-image: url(/assets/icons/flags/4x3/pm.svg);
}
.flag-icon-pm.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/pm.svg);
}

.flag-icon-pn {
  background-image: url(/assets/icons/flags/4x3/pn.svg);
}
.flag-icon-pn.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/pn.svg);
}

.flag-icon-pr {
  background-image: url(/assets/icons/flags/4x3/pr.svg);
}
.flag-icon-pr.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/pr.svg);
}

.flag-icon-ps {
  background-image: url(/assets/icons/flags/4x3/ps.svg);
}
.flag-icon-ps.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ps.svg);
}

.flag-icon-pt {
  background-image: url(/assets/icons/flags/4x3/pt.svg);
}
.flag-icon-pt.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/pt.svg);
}

.flag-icon-pw {
  background-image: url(/assets/icons/flags/4x3/pw.svg);
}
.flag-icon-pw.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/pw.svg);
}

.flag-icon-py {
  background-image: url(/assets/icons/flags/4x3/py.svg);
}
.flag-icon-py.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/py.svg);
}

.flag-icon-qa {
  background-image: url(/assets/icons/flags/4x3/qa.svg);
}
.flag-icon-qa.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/qa.svg);
}

.flag-icon-re {
  background-image: url(/assets/icons/flags/4x3/re.svg);
}
.flag-icon-re.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/re.svg);
}

.flag-icon-ro {
  background-image: url(/assets/icons/flags/4x3/ro.svg);
}
.flag-icon-ro.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ro.svg);
}

.flag-icon-rs {
  background-image: url(/assets/icons/flags/4x3/rs.svg);
}
.flag-icon-rs.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/rs.svg);
}

.flag-icon-ru {
  background-image: url(/assets/icons/flags/4x3/ru.svg);
}
.flag-icon-ru.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ru.svg);
}

.flag-icon-rw {
  background-image: url(/assets/icons/flags/4x3/rw.svg);
}
.flag-icon-rw.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/rw.svg);
}

.flag-icon-sa {
  background-image: url(/assets/icons/flags/4x3/sa.svg);
}
.flag-icon-sa.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/sa.svg);
}

.flag-icon-sb {
  background-image: url(/assets/icons/flags/4x3/sb.svg);
}
.flag-icon-sb.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/sb.svg);
}

.flag-icon-sc {
  background-image: url(/assets/icons/flags/4x3/sc.svg);
}
.flag-icon-sc.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/sc.svg);
}

.flag-icon-sd {
  background-image: url(/assets/icons/flags/4x3/sd.svg);
}
.flag-icon-sd.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/sd.svg);
}

.flag-icon-se {
  background-image: url(/assets/icons/flags/4x3/se.svg);
}
.flag-icon-se.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/se.svg);
}

.flag-icon-sg {
  background-image: url(/assets/icons/flags/4x3/sg.svg);
}
.flag-icon-sg.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/sg.svg);
}

.flag-icon-sh {
  background-image: url(/assets/icons/flags/4x3/sh.svg);
}
.flag-icon-sh.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/sh.svg);
}

.flag-icon-si {
  background-image: url(/assets/icons/flags/4x3/si.svg);
}
.flag-icon-si.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/si.svg);
}

.flag-icon-sj {
  background-image: url(/assets/icons/flags/4x3/sj.svg);
}
.flag-icon-sj.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/sj.svg);
}

.flag-icon-sk {
  background-image: url(/assets/icons/flags/4x3/sk.svg);
}
.flag-icon-sk.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/sk.svg);
}

.flag-icon-sl {
  background-image: url(/assets/icons/flags/4x3/sl.svg);
}
.flag-icon-sl.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/sl.svg);
}

.flag-icon-sm {
  background-image: url(/assets/icons/flags/4x3/sm.svg);
}
.flag-icon-sm.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/sm.svg);
}

.flag-icon-sn {
  background-image: url(/assets/icons/flags/4x3/sn.svg);
}
.flag-icon-sn.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/sn.svg);
}

.flag-icon-so {
  background-image: url(/assets/icons/flags/4x3/so.svg);
}
.flag-icon-so.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/so.svg);
}

.flag-icon-sr {
  background-image: url(/assets/icons/flags/4x3/sr.svg);
}
.flag-icon-sr.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/sr.svg);
}

.flag-icon-ss {
  background-image: url(/assets/icons/flags/4x3/ss.svg);
}
.flag-icon-ss.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ss.svg);
}

.flag-icon-st {
  background-image: url(/assets/icons/flags/4x3/st.svg);
}
.flag-icon-st.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/st.svg);
}

.flag-icon-sv {
  background-image: url(/assets/icons/flags/4x3/sv.svg);
}
.flag-icon-sv.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/sv.svg);
}

.flag-icon-sx {
  background-image: url(/assets/icons/flags/4x3/sx.svg);
}
.flag-icon-sx.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/sx.svg);
}

.flag-icon-sy {
  background-image: url(/assets/icons/flags/4x3/sy.svg);
}
.flag-icon-sy.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/sy.svg);
}

.flag-icon-sz {
  background-image: url(/assets/icons/flags/4x3/sz.svg);
}
.flag-icon-sz.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/sz.svg);
}

.flag-icon-tc {
  background-image: url(/assets/icons/flags/4x3/tc.svg);
}
.flag-icon-tc.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/tc.svg);
}

.flag-icon-td {
  background-image: url(/assets/icons/flags/4x3/td.svg);
}
.flag-icon-td.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/td.svg);
}

.flag-icon-tf {
  background-image: url(/assets/icons/flags/4x3/tf.svg);
}
.flag-icon-tf.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/tf.svg);
}

.flag-icon-tg {
  background-image: url(/assets/icons/flags/4x3/tg.svg);
}
.flag-icon-tg.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/tg.svg);
}

.flag-icon-th {
  background-image: url(/assets/icons/flags/4x3/th.svg);
}
.flag-icon-th.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/th.svg);
}

.flag-icon-tj {
  background-image: url(/assets/icons/flags/4x3/tj.svg);
}
.flag-icon-tj.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/tj.svg);
}

.flag-icon-tk {
  background-image: url(/assets/icons/flags/4x3/tk.svg);
}
.flag-icon-tk.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/tk.svg);
}

.flag-icon-tl {
  background-image: url(/assets/icons/flags/4x3/tl.svg);
}
.flag-icon-tl.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/tl.svg);
}

.flag-icon-tm {
  background-image: url(/assets/icons/flags/4x3/tm.svg);
}
.flag-icon-tm.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/tm.svg);
}

.flag-icon-tn {
  background-image: url(/assets/icons/flags/4x3/tn.svg);
}
.flag-icon-tn.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/tn.svg);
}

.flag-icon-to {
  background-image: url(/assets/icons/flags/4x3/to.svg);
}
.flag-icon-to.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/to.svg);
}

.flag-icon-tr {
  background-image: url(/assets/icons/flags/4x3/tr.svg);
}
.flag-icon-tr.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/tr.svg);
}

.flag-icon-tt {
  background-image: url(/assets/icons/flags/4x3/tt.svg);
}
.flag-icon-tt.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/tt.svg);
}

.flag-icon-tv {
  background-image: url(/assets/icons/flags/4x3/tv.svg);
}
.flag-icon-tv.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/tv.svg);
}

.flag-icon-tw {
  background-image: url(/assets/icons/flags/4x3/tw.svg);
}
.flag-icon-tw.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/tw.svg);
}

.flag-icon-tz {
  background-image: url(/assets/icons/flags/4x3/tz.svg);
}
.flag-icon-tz.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/tz.svg);
}

.flag-icon-ua {
  background-image: url(/assets/icons/flags/4x3/ua.svg);
}
.flag-icon-ua.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ua.svg);
}

.flag-icon-ug {
  background-image: url(/assets/icons/flags/4x3/ug.svg);
}
.flag-icon-ug.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ug.svg);
}

.flag-icon-um {
  background-image: url(/assets/icons/flags/4x3/um.svg);
}
.flag-icon-um.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/um.svg);
}

.flag-icon-us {
  background-image: url(/assets/icons/flags/4x3/us.svg);
}
.flag-icon-us.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/us.svg);
}

.flag-icon-uy {
  background-image: url(/assets/icons/flags/4x3/uy.svg);
}
.flag-icon-uy.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/uy.svg);
}

.flag-icon-uz {
  background-image: url(/assets/icons/flags/4x3/uz.svg);
}
.flag-icon-uz.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/uz.svg);
}

.flag-icon-va {
  background-image: url(/assets/icons/flags/4x3/va.svg);
}
.flag-icon-va.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/va.svg);
}

.flag-icon-vc {
  background-image: url(/assets/icons/flags/4x3/vc.svg);
}
.flag-icon-vc.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/vc.svg);
}

.flag-icon-ve {
  background-image: url(/assets/icons/flags/4x3/ve.svg);
}
.flag-icon-ve.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ve.svg);
}

.flag-icon-vg {
  background-image: url(/assets/icons/flags/4x3/vg.svg);
}
.flag-icon-vg.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/vg.svg);
}

.flag-icon-vi {
  background-image: url(/assets/icons/flags/4x3/vi.svg);
}
.flag-icon-vi.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/vi.svg);
}

.flag-icon-vn {
  background-image: url(/assets/icons/flags/4x3/vn.svg);
}
.flag-icon-vn.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/vn.svg);
}

.flag-icon-vu {
  background-image: url(/assets/icons/flags/4x3/vu.svg);
}
.flag-icon-vu.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/vu.svg);
}

.flag-icon-wf {
  background-image: url(/assets/icons/flags/4x3/wf.svg);
}
.flag-icon-wf.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/wf.svg);
}

.flag-icon-ws {
  background-image: url(/assets/icons/flags/4x3/ws.svg);
}
.flag-icon-ws.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ws.svg);
}

.flag-icon-ye {
  background-image: url(/assets/icons/flags/4x3/ye.svg);
}
.flag-icon-ye.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/ye.svg);
}

.flag-icon-yt {
  background-image: url(/assets/icons/flags/4x3/yt.svg);
}
.flag-icon-yt.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/yt.svg);
}

.flag-icon-za {
  background-image: url(/assets/icons/flags/4x3/za.svg);
}
.flag-icon-za.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/za.svg);
}

.flag-icon-zm {
  background-image: url(/assets/icons/flags/4x3/zm.svg);
}
.flag-icon-zm.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/zm.svg);
}

.flag-icon-zw {
  background-image: url(/assets/icons/flags/4x3/zw.svg);
}
.flag-icon-zw.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/zw.svg);
}

.flag-icon-es-ct {
  background-image: url(/assets/icons/flags/4x3/es-ct.svg);
}
.flag-icon-es-ct.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/es-ct.svg);
}

.flag-icon-eu {
  background-image: url(/assets/icons/flags/4x3/eu.svg);
}
.flag-icon-eu.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/eu.svg);
}

.flag-icon-gb-eng {
  background-image: url(/assets/icons/flags/4x3/gb-eng.svg);
}
.flag-icon-gb-eng.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/gb-eng.svg);
}

.flag-icon-gb-nir {
  background-image: url(/assets/icons/flags/4x3/gb-nir.svg);
}
.flag-icon-gb-nir.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/gb-nir.svg);
}

.flag-icon-gb-sct {
  background-image: url(/assets/icons/flags/4x3/gb-sct.svg);
}
.flag-icon-gb-sct.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/gb-sct.svg);
}

.flag-icon-gb-wls {
  background-image: url(/assets/icons/flags/4x3/gb-wls.svg);
}
.flag-icon-gb-wls.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/gb-wls.svg);
}

.flag-icon-un {
  background-image: url(/assets/icons/flags/4x3/un.svg);
}
.flag-icon-un.flag-icon-squared {
  background-image: url(/assets/icons/flags/1x1/un.svg);
}

.cookie-consent--sticky {
  opacity: 0;
  position: fixed;
  z-index: 9999;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  max-height: calc(var(--vh, 1vh) * 100);
  transform: translate(0, 100%);
  transition: 300ms transform ease-in-out;
  overflow-y: auto;
}
.cookie-consent--sticky.is-shown {
  opacity: 1;
  transform: translate(0, 0);
}
.cookie-consent__introduction {
  padding-top: 1.125rem;
  padding-bottom: 1rem;
}
@media (min-width: 768px) {
  .cookie-consent__introduction {
    padding-top: 1.5625rem;
    padding-bottom: 1.5rem;
  }
}
.cookie-consent__introduction h3,
.cookie-consent__introduction p {
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
}
@media (min-width: 768px) {
  .cookie-consent__introduction h3,
  .cookie-consent__introduction p {
    font-size: 18px;
    line-height: 24px;
  }
}
.cookie-consent__introduction h3 {
  font-size: 20px;
  line-height: 24px;
}
@media (min-width: 768px) {
  .cookie-consent__introduction h3 {
    font-size: 24px;
    line-height: 28px;
  }
}
.cookie-consent__introduction a {
  text-decoration: none;
  color: #ffffff;
  display: inline;
  background: linear-gradient(180deg, transparent 0, transparent 80%, rgba(255, 255, 255, 0.75) 80%, rgba(255, 255, 255, 0.75) 85%, transparent 85%, transparent 0);
}
.cookie-consent__introduction a:hover {
  display: inline;
  background: linear-gradient(180deg, transparent 0, transparent 80%, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 0) 85%, transparent 85%, transparent 0);
}
.cookie-consent__options {
  position: relative;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: 500ms all ease-in-out;
}
.cookie-consent__options:before, .cookie-consent__options:after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #40476c;
}
.cookie-consent__options:before {
  top: 0;
}
.cookie-consent__options:after {
  bottom: 1rem;
}
@media (min-width: 768px) {
  .cookie-consent__options:after {
    bottom: 2rem;
  }
}
.cookie-consent__options.is-open {
  max-height: 1000px;
  opacity: 1;
}
.cookie-consent__options .container {
  padding-top: 1.3125rem;
  padding-bottom: 2rem;
}
@media (min-width: 768px) {
  .cookie-consent__options .container {
    padding-top: 2rem;
    padding-bottom: 4rem;
  }
}
.cookie-consent__option {
  position: relative;
  margin-bottom: 1.25rem;
}
.cookie-consent__option:last-child {
  margin-bottom: 0;
}
.cookie-consent__option .form-check-input {
  display: none;
}
.cookie-consent__option .form-check-input + label.form-check-label {
  padding-left: 1.25rem;
  cursor: pointer;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  text-transform: inherit;
}
@media (min-width: 768px) {
  .cookie-consent__option .form-check-input + label.form-check-label {
    font-size: 18px;
    line-height: 24px;
  }
}
.cookie-consent__option .form-check-input + label.form-check-label strong {
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
  display: block;
}
@media (min-width: 768px) {
  .cookie-consent__option .form-check-input + label.form-check-label strong {
    font-size: 20px;
  }
}
.cookie-consent__option .form-check-input + label.form-check-label:before, .cookie-consent__option .form-check-input + label.form-check-label:after {
  content: "";
  position: absolute;
  top: 0.0625rem;
  left: 0;
  width: 1.25rem;
  height: 1.25rem;
}
.cookie-consent__option .form-check-input + label.form-check-label:before {
  background-image: url("/assets/images/svg/checkbox-unchecked-2.svg");
}
.cookie-consent__option .form-check-input:checked + .form-check-label:after {
  background-image: url("/assets/images/svg/checkbox-checked-2.svg");
}
.cookie-consent__actions {
  padding-bottom: 1rem;
}
@media (min-width: 768px) {
  .cookie-consent__actions {
    padding-bottom: 2rem;
  }
}
.cookie-consent__actions .btn.btn-secondary {
  color: #000000;
}
.cookie-consent__actions .btn.btn-secondary:hover, .cookie-consent__actions .btn.btn-secondary:focus {
  color: #ffffff;
}
.cookie-consent__actions .col-12 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  margin-left: 1.25rem;
  background-color: #002554;
}
.footer .brand-logo .img-fluid {
  max-width: 200px;
}
@media (min-width: 768px) {
  .footer {
    margin-left: 6.25rem;
  }
}
.footer__breadcrumb-container {
  padding: 0.8125rem 1rem;
  padding-left: 1.875rem;
  border-bottom: 0.0625rem solid #40476c;
  margin-bottom: 1rem;
  overflow: hidden;
  overflow-x: auto;
}
@media (min-width: 768px) {
  .footer__breadcrumb-container {
    padding-left: 1.25rem;
    margin-bottom: 2rem;
  }
}
.footer__breadcrumb-container .breadcrumbs {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}
.footer__breadcrumb-container .breadcrumbs .breadcrumb-item {
  font-weight: 300;
  white-space: nowrap;
}
.footer__breadcrumb-container .breadcrumbs .breadcrumb-item a {
  color: #ffffff;
}
.footer__breadcrumb-container .breadcrumbs .breadcrumb-item + .breadcrumb-item {
  position: relative;
  padding-left: 2.125rem;
}
.footer__breadcrumb-container .breadcrumbs .breadcrumb-item + .breadcrumb-item:before {
  content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBzdHlsZT0iZmlsbDojZmZmIiBkPSJNMTIgNGwtMS40MSAxLjQxTDE2LjE3IDExSDR2MmgxMi4xN2wtNS41OCA1LjU5TDEyIDIwbDgtOHoiLz48L3N2Zz4=");
  position: absolute;
  top: 0.125rem;
  left: 0.5625rem;
  width: 1.25rem;
  height: 0.9375rem;
}
.footer__breadcrumb-container .breadcrumbs .breadcrumb-item:last-child {
  padding-right: 1rem;
}
.footer__breadcrumb-container .breadcrumbs .breadcrumb-item.active {
  color: #bfc1ce;
}
.footer__links-container {
  margin-bottom: 2.5rem;
}
.footer__links-container .link-list {
  margin: 0 0 3rem 2rem;
}
@media (min-width: 768px) {
  .footer__links-container .link-list {
    margin: 0;
  }
}
.footer__links-container .link-list .label {
  color: #bfc1ce;
}
.footer__links-container .link-list .link {
  color: #e5e6eb;
}
.footer__links-container .link-list .link:hover, .footer__links-container .link-list .link:active, .footer__links-container .link-list .link:focus {
  color: #00ad1d;
}
.footer__links-container .link-list--social {
  margin-bottom: 0;
}
.footer__links-container .link-list--social .list {
  padding: 0.25rem;
  margin-bottom: 0;
}
.footer__links-container .link-list--social .list-inline-item {
  padding: 0;
}
.footer__links-container .link-list--social .list-inline-item a {
  display: block;
}
.footer__legal-container {
  background-color: #ffffff;
}
.footer__legal-container .legal-links {
  padding: 0 0 0.625rem 0;
}
@media (min-width: 992px) {
  .footer__legal-container .legal-links {
    padding: 0;
  }
}
.footer__legal-container .legal-links .copyright {
  padding: 1rem 0 0.9375rem;
  color: #7f849d;
}
.footer__legal-container .legal-links .list {
  margin: 0;
}
.footer__legal-container .legal-links .list .list-inline-item {
  padding: 0.875rem 0 0.3125rem 0;
}
.footer__legal-container .legal-links .list .list-inline-item a,
.footer__legal-container .legal-links .list .list-inline-item button {
  color: #7f849d;
}
.footer__legal-container .legal-links .list .list-inline-item button {
  padding: 0;
  border: none;
  margin: 0;
  background: none;
  outline: none;
}
.footer__legal-container .legal-links .list .list-inline-item button:hover, .footer__legal-container .legal-links .list .list-inline-item button:active {
  text-decoration: underline;
}
@media (min-width: 992px) {
  .footer__legal-container .legal-links .list .list-inline-item {
    padding: 0.875rem 0;
  }
}

.hero-header {
  position: relative;
}
.hero-header__image-container {
  padding-left: 1.25rem;
  /*.hero-header--transparent &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: rem(20px);
    background: linear-gradient(180deg, rgba($primary, .1) 0, rgba($primary, .6) 100%);
    clip-path: inherit;

    @include media-breakpoint-up(xl) {
      left: rem(100px);
    }
  }*/
}
@media (min-width: 1200px) {
  .hero-header__image-container {
    padding-left: 6.25rem;
  }
}
.hero-header__image {
  height: 16rem;
  clip-path: inherit;
}
@media (min-width: 768px) {
  .hero-header__image {
    height: 20rem;
  }
}
@media (min-width: 992px) {
  .hero-header__image {
    height: 40.5rem;
  }
}
.hero-header--transparent .hero-header__image {
  height: 33.5rem;
}
@media (min-width: 992px) {
  .hero-header--transparent .hero-header__image {
    height: 40.5rem;
  }
}
.hero-header--transparent.hero-header--medium .hero-header__image {
  height: 27rem;
}
.hero-header--none.hero-header--medium .hero-header__image {
  height: 27rem;
}
@media (max-width: 767.98px) {
  .hero-header--none.hero-header--medium .hero-header__image {
    height: 15.625rem;
  }
}
@media (max-width: 767.98px) {
  .hero-header .container {
    padding-right: 0;
  }
}
.hero-header__title {
  margin-bottom: 1rem;
}
.hero-header__subtitle {
  font-weight: 400;
}
.hero-header__title, .hero-header__subtitle {
  color: #ffffff;
}
.hero-header--transparent .hero-header__title, .hero-header--transparent .hero-header__subtitle {
  text-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.3);
}
.hero-header__color--primary .hero-header__title, .hero-header__color--primary .hero-header__subtitle {
  color: #002554;
}
.hero-header__color--secondary .hero-header__title, .hero-header__color--secondary .hero-header__subtitle {
  color: #00ad1d;
}
@media (max-width: 767.98px) {
  .hero-header--none .hero-header__title, .hero-header--none .hero-header__subtitle {
    color: #002554;
  }
}
.hero-header__back-link {
  position: absolute;
  top: 10px;
  left: 1.5625rem;
}
.hero-header__back-link a {
  color: #ffffff;
}
.hero-header__back-link a svg {
  fill: #ffffff;
}
@media (min-width: 1200px) {
  .hero-header__back-link {
    left: 6.875rem;
  }
}
.hero-header__color--primary .hero-header__back-link a {
  color: #002554;
}
.hero-header__color--primary .hero-header__back-link a svg {
  fill: #002554;
}
.hero-header__color--secondary .hero-header__back-link a {
  color: #00ad1d;
}
.hero-header__color--secondary .hero-header__back-link a svg {
  fill: #00ad1d;
}
.hero-header__panel {
  position: relative;
  padding: 1.5rem 2rem 1rem 2rem;
  margin-top: -3rem;
  margin-left: 1.25rem;
}
@media (min-width: 576px) {
  .hero-header__panel {
    margin-left: -2rem;
  }
}
@media (min-width: 768px) {
  .hero-header__panel {
    margin-left: 0;
  }
}
.hero-header--primary .hero-header__panel {
  background-color: #002554;
}
.hero-header--secondary .hero-header__panel {
  background-color: #00ad1d;
}
.hero-header--transparent .hero-header__panel {
  position: absolute;
  bottom: 8rem;
  background-color: transparent;
}
.hero-header--none .hero-header__panel {
  position: absolute;
  bottom: 0;
  background-color: transparent;
  padding-left: 0;
}
@media (max-width: 767.98px) {
  .hero-header--none .hero-header__panel {
    position: relative;
    margin-top: 0px;
  }
}
.hero-header--transparent.hero-header--medium .hero-header__panel {
  bottom: 4rem;
}
@media (min-width: 768px) {
  .hero-header__panel {
    width: 35.3125rem;
    padding: 2rem 2rem 1rem 2.5rem;
    margin: auto;
    margin-top: -4rem;
  }
}
@media (min-width: 992px) {
  .hero-header__panel {
    width: 44.625rem;
    padding: 3rem 3rem 2rem 5rem;
    margin: 0;
    margin-top: -10rem;
  }
}

.image-text__wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;
}
.row-big .image-text__wrapper {
  margin-left: -25px;
  margin-right: -25px;
}
@media (min-width: 768px) {
  .image-text__wrapper {
    flex-wrap: nowrap;
  }
}
.image-text__text {
  order: 1;
  padding-left: 12px;
  padding-right: 12px;
}
.row-big .image-text__text {
  padding-left: 25px;
  padding-right: 25px;
}
@media (min-width: 768px) {
  .image-text--left .image-text__text {
    order: 2;
  }
}
.image-text__text p:not(:last-child) {
  margin-bottom: 1rem;
}
.image-text__text .btn {
  margin-top: 1rem;
}
.image-text__text .btn-link {
  height: auto;
  padding: 0;
}
.image-text__image {
  order: 2;
  margin-top: 1rem;
  padding-left: 12px;
  padding-right: 12px;
}
.row-big .image-text__image {
  padding-left: 25px;
  padding-right: 25px;
}
@media (min-width: 768px) {
  .image-text__image {
    margin-top: 0;
  }
  .image-text--left .image-text__image {
    order: 1;
  }
}
.image-text__image img {
  width: 100%;
}

.language-selector {
  position: relative;
  padding: 0;
  margin: 0;
  list-style: none;
}
.language-selector-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.language-selector-wrapper.is-open {
  position: fixed;
  left: 20px;
  width: calc(100% - 20px);
}
@media (min-width: 576px) {
  .language-selector {
    column-count: 2;
    column-gap: 4rem;
  }
  .language-selector::before, .language-selector::after {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 1px;
    background-color: #e5e6eb;
  }
  .language-selector::before {
    left: 50%;
  }
  .language-selector::after {
    left: 50%;
  }
}
@media (min-width: 768px) {
  .language-selector {
    column-count: 3;
  }
  .language-selector::before {
    left: 32%;
  }
  .language-selector::after {
    left: 68%;
  }
}
.language-selector li {
  width: 100%;
}
.language-selector li a {
  height: auto;
  padding: 0.3125rem 0;
}
.language-selector li .flag-icon {
  width: 1.3125rem;
  height: 1rem;
  border: 0.0625rem solid #e5e6eb;
  margin-right: 1.25rem;
}

.image-caption {
  position: relative;
  margin-right: -1.25rem;
}
@media (min-width: 768px) {
  .image-caption {
    margin-right: 0;
  }
}
.image-caption .image-wrapper {
  position: relative;
}
@media (min-width: 768px) {
  .image-caption .image-wrapper .gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, transparent 75%, rgba(0, 37, 84, 0.6));
  }
}
.image-caption .caption {
  margin-top: 0.75rem;
  margin-right: 1.25rem;
  color: #7f849d;
  text-align: right;
}
@media (min-width: 768px) {
  .image-caption .caption {
    position: absolute;
    right: 1.25rem;
    bottom: 0.75rem;
    max-width: 50%;
    margin: 0;
    color: #ffffff;
  }
}

.modal {
  background-color: rgba(0, 37, 84, 0.75);
}

.modal-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  border: 0;
  margin: 1rem;
  outline: 0;
  background: none;
  cursor: pointer;
}
.modal-close span {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 100ms;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1rem;
  border-bottom: 0.125rem solid #7f849d;
  margin: -0.0625rem 0 0 -0.5rem;
  transition-property: border-color;
}
.modal-close span:first-child {
  transform: rotate(45deg);
}
.modal-close span:last-child {
  transform: rotate(-45deg);
}
.modal-close:hover span, .modal-close:focus span {
  border-color: #002554;
}

.modal-dialog {
  max-width: 60rem;
}

.modal-content {
  padding: 23px 2rem 2rem 2rem;
  border-radius: 0;
}

.modal-title {
  width: 100%;
  text-align: center;
}
.modal-title h1,
.modal-title h2,
.modal-title h3,
.modal-title h4,
.modal-title h5 {
  margin: 0;
}

/**
 * Content specific spacing changes
 */
.modal .language-selector {
  margin: 2.125rem 0 -0.125rem;
}

.main-navigation {
  position: relative;
  background-color: #002554;
}
.main-navigation__bar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 60px;
}
@media (min-width: 992px) {
  .main-navigation__bar {
    height: 100px;
  }
}
@media (min-width: 1200px) {
  .main-navigation__bar {
    margin-left: 100px;
  }
}
.main-navigation .brand-logo {
  display: block;
  width: 100%;
  max-width: 160px;
}
@media (min-width: 1200px) {
  .main-navigation .brand-logo {
    max-width: 270px;
  }
}
.main-navigation__search {
  margin: 0;
}
.main-navigation__search > .list-inline-item:not(:last-child) {
  margin-right: 1.875rem;
}
.main-navigation__search .form-control {
  padding-right: 70px;
  border: 1px solid #7f849d;
  background-color: #002554;
  color: #ffffff;
  max-width: 190px;
}
.main-navigation__search .form .submit {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  background: transparent;
  border-radius: 33px;
  width: 60px;
  height: 100%;
}
.main-navigation__search .form .submit .icon {
  fill: #ffffff;
}
.main-navigation__search--mobile .form-control {
  max-width: none;
  background-color: #ffffff;
  border: 1px solid #bfc1ce;
}
.main-navigation__search--mobile .form .submit .icon {
  fill: #002554;
}
.main-navigation__items {
  position: relative;
  height: 100%;
  flex-grow: 1;
}
.main-navigation__items .level-1 {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  gap: 0;
  height: 100%;
  margin-bottom: 0;
}
.main-navigation__items .level-1 > .nav-item {
  display: block;
  height: 100%;
  padding: 0 1rem;
}
.main-navigation__items .level-1 > .nav-item > .nav-link {
  height: 100%;
  padding: 0;
}
.main-navigation__items .level-1 > .nav-item > .nav-link .text {
  line-height: 1.4;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  color: #ffffff;
  display: block;
  font-size: 1rem;
  letter-spacing: 0;
  user-select: none;
}
.main-navigation__items .level-1 > .nav-item.has-flyout:hover > .nav-link, .main-navigation__items .level-1 > .nav-item.has-flyout:active > .nav-link, .main-navigation__items .level-1 > .nav-item.has-flyout:focus > .nav-link {
  display: inline;
  background: linear-gradient(180deg, transparent 0, transparent 95%, rgba(0, 173, 29, 0.75) 95%, rgba(0, 173, 29, 0.75) 100%, transparent 100%, transparent 0);
}
.main-navigation__items .level-1 > .nav-item.has-flyout:hover > .nav-link .text, .main-navigation__items .level-1 > .nav-item.has-flyout:active > .nav-link .text, .main-navigation__items .level-1 > .nav-item.has-flyout:focus > .nav-link .text {
  color: #00ad1d;
}
.main-navigation__items .level-1 > .nav-item.has-flyout:hover .main-navigation__flyout, .main-navigation__items .level-1 > .nav-item.has-flyout:active .main-navigation__flyout, .main-navigation__items .level-1 > .nav-item.has-flyout:focus .main-navigation__flyout {
  opacity: 1;
  transform: translateY(0);
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.5);
}
.main-navigation__items .level-1 > .nav-item.has-flyout:focus-within > .nav-link {
  display: inline;
  background: linear-gradient(180deg, transparent 0, transparent 95%, rgba(0, 173, 29, 0.75) 95%, rgba(0, 173, 29, 0.75) 100%, transparent 100%, transparent 0);
}
.main-navigation__items .level-1 > .nav-item.has-flyout:focus-within > .nav-link .text {
  color: #00ad1d;
}
.main-navigation__items .level-1 > .nav-item.has-flyout:focus-within .main-navigation__flyout {
  opacity: 1;
  transform: translateY(0);
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.5);
}
.main-navigation__items .main-navigation__flyout {
  background-color: #f2f2f5;
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0);
  cursor: default;
  left: 0;
  min-height: 22.5rem;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 100px;
  transform: translateY(-100%);
  transition: transform 0.5s cubic-bezier(0.45, 0, 0.14, 1.03), 100ms box-shadow ease-out;
  width: 100%;
  z-index: -1;
}
.main-navigation__items .level-2,
.main-navigation__items .level-3 {
  list-style: none;
  margin: 0;
  padding: 2rem 1.5rem 4.0625rem 2rem;
  width: 21.875rem;
}
.main-navigation__items .level-2 > .nav-item,
.main-navigation__items .level-3 > .nav-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.main-navigation__items .level-2 > .nav-item > .nav-link,
.main-navigation__items .level-3 > .nav-item > .nav-link {
  padding: 0;
  text-decoration: none;
}
.main-navigation__items .level-2 > .nav-item > .nav-link > .title,
.main-navigation__items .level-3 > .nav-item > .nav-link > .title {
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  gap: 0.5rem;
  justify-content: space-between;
  margin-bottom: 0;
  max-height: 3.75rem;
  position: relative;
}
.main-navigation__items .level-2 > .nav-item > .nav-link .icon,
.main-navigation__items .level-3 > .nav-item > .nav-link .icon {
  display: block;
  fill: #7f849d;
  height: 100%;
  width: 1rem;
}
.main-navigation__items .level-2 > .nav-item > .nav-link .icon svg,
.main-navigation__items .level-3 > .nav-item > .nav-link .icon svg {
  width: 100%;
  height: 100%;
}
.main-navigation__items .level-2 > .nav-item > .nav-link .text,
.main-navigation__items .level-3 > .nav-item > .nav-link .text {
  color: #7f849d;
}
.main-navigation__items .level-2 > .nav-item:last-child,
.main-navigation__items .level-3 > .nav-item:last-child {
  border-bottom: none;
}
.main-navigation__items .level-2 {
  background-color: #ffffff;
  height: 100%;
  min-height: 360px;
  position: relative;
  z-index: auto;
}
.main-navigation__items .level-2 > .nav-item:hover:before, .main-navigation__items .level-2 > .nav-item:active:before, .main-navigation__items .level-2 > .nav-item:focus:before {
  content: "";
  position: absolute;
  right: -8px;
  border-color: transparent transparent transparent #ffffff;
  border-style: solid;
  border-width: 8px 0 8px 8px;
  top: auto;
  transform: translateY(22px);
}
.main-navigation__items .level-2 > .nav-item:hover > .nav-link .title .icon,
.main-navigation__items .level-2 > .nav-item:hover > .nav-link .title .text, .main-navigation__items .level-2 > .nav-item:active > .nav-link .title .icon,
.main-navigation__items .level-2 > .nav-item:active > .nav-link .title .text, .main-navigation__items .level-2 > .nav-item:focus > .nav-link .title .icon,
.main-navigation__items .level-2 > .nav-item:focus > .nav-link .title .text {
  color: #002554;
  font-weight: 700;
}
.main-navigation__items .level-2 > .nav-item:hover > .level-3, .main-navigation__items .level-2 > .nav-item:active > .level-3, .main-navigation__items .level-2 > .nav-item:focus > .level-3 {
  height: 100%;
  opacity: 1;
  overflow: visible;
  transform: translateX(100%);
  width: 100%;
}
.main-navigation__items .level-2 > .nav-item:hover:after, .main-navigation__items .level-2 > .nav-item:active:after, .main-navigation__items .level-2 > .nav-item:focus:after {
  content: "";
  left: 75%;
  position: absolute;
  right: 0;
  top: 0;
  width: 150px;
  height: 100%;
  background: transparent;
}
.main-navigation__items .level-2 > .nav-item:focus-within:before {
  content: "";
  position: absolute;
  right: -8px;
  border-color: transparent transparent transparent #ffffff;
  border-style: solid;
  border-width: 8px 0 8px 8px;
  top: auto;
  transform: translateY(22px);
}
.main-navigation__items .level-2 > .nav-item:focus-within > .nav-link .title .icon,
.main-navigation__items .level-2 > .nav-item:focus-within > .nav-link .title .text {
  color: #002554;
  font-weight: 700;
}
.main-navigation__items .level-2 > .nav-item:focus-within > .level-3 {
  height: 100%;
  opacity: 1;
  overflow: visible;
  transform: translateX(100%);
  width: 100%;
}
.main-navigation__items .level-3 {
  background-color: #f2f2f5;
  height: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transform: translateX(0%);
  transition: transform 0.5s cubic-bezier(0.45, 0, 0.14, 1.03);
  width: 0;
  z-index: -1;
}
.main-navigation__items .level-3 > .nav-item:hover:before, .main-navigation__items .level-3 > .nav-item:active:before, .main-navigation__items .level-3 > .nav-item:focus:before {
  content: "";
  position: absolute;
  right: -8px;
  border-color: transparent transparent transparent #ffffff;
  border-style: solid;
  border-width: 8px 0 8px 8px;
  top: auto;
  transform: translateY(22px);
}
.main-navigation__items .level-3 > .nav-item:hover > .nav-link .title .icon,
.main-navigation__items .level-3 > .nav-item:hover > .nav-link .title .text, .main-navigation__items .level-3 > .nav-item:active > .nav-link .title .icon,
.main-navigation__items .level-3 > .nav-item:active > .nav-link .title .text, .main-navigation__items .level-3 > .nav-item:focus > .nav-link .title .icon,
.main-navigation__items .level-3 > .nav-item:focus > .nav-link .title .text {
  color: #002554;
  font-weight: 700;
}
.main-navigation__items .level-3 > .nav-item:hover > .nav-link .preview, .main-navigation__items .level-3 > .nav-item:active > .nav-link .preview, .main-navigation__items .level-3 > .nav-item:focus > .nav-link .preview {
  display: block;
  opacity: 1;
  z-index: 2;
}
.main-navigation__items .level-3 > .nav-item:hover:after, .main-navigation__items .level-3 > .nav-item:active:after, .main-navigation__items .level-3 > .nav-item:focus:after {
  content: "";
  left: 75%;
  position: absolute;
  right: 0;
  top: 0;
  width: 150px;
  height: 100%;
  background: transparent;
}
.main-navigation__items .level-3 > .nav-item:focus-within:before {
  content: "";
  position: absolute;
  right: -8px;
  border-color: transparent transparent transparent #ffffff;
  border-style: solid;
  border-width: 8px 0 8px 8px;
  top: auto;
  transform: translateY(22px);
}
.main-navigation__items .level-3 > .nav-item:focus-within > .nav-link .title .icon,
.main-navigation__items .level-3 > .nav-item:focus-within > .nav-link .title .text {
  color: #002554;
  font-weight: 700;
}
.main-navigation__items .level-3 > .nav-item:focus-within > .nav-link .preview {
  display: block;
  opacity: 1;
  z-index: 2;
}
.main-navigation__items .level-3 > .nav-item > .nav-link {
  line-height: 1.3333333333;
  color: #40476c;
  font-size: 1.125rem;
}
.main-navigation__items .level-3 > .nav-item > .nav-link .preview {
  background-color: #f2f2f5;
  bottom: 0;
  display: none;
  height: 400px;
  left: 350px;
  max-width: 360px;
  opacity: 0;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 2.1875rem;
  position: absolute;
  top: 0;
  transition: opacity 0.2s ease-in-out;
  width: 100%;
}
.main-navigation__items .level-3 > .nav-item > .nav-link .preview .icon {
  width: 100px;
  height: auto;
  fill: #002554;
  display: block;
}
.main-navigation__items .level-3 > .nav-item > .nav-link .preview .icon svg {
  aspect-ratio: 1;
  height: auto;
  width: 100%;
}
.main-navigation .main-navigation__mob {
  margin-left: auto;
  height: 60px;
  display: flex;
}
.main-navigation .main-navigation__mob ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (min-width: 992px) {
  .main-navigation .main-navigation__mob {
    display: none;
  }
}
.main-navigation .main-navigation__mob-trigger {
  display: flex;
  padding: 0;
  border: none;
  outline: inherit;
  background: none;
  cursor: pointer;
  /**
   * Modifiers
   */
}
.main-navigation .main-navigation__mob-trigger:focus {
  outline: none;
}
.main-navigation .main-navigation__mob-trigger__label {
  line-height: 1.4;
  height: 60px;
  align-items: center;
  color: #ffffff;
  transition: 300ms background ease-in-out;
}
.main-navigation .main-navigation__mob-trigger__icon {
  position: absolute;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.75rem;
  top: 22px;
  right: 10px;
}
.main-navigation .main-navigation__mob-trigger__icon span {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 100ms;
  position: absolute;
  left: 2px;
  width: 20px;
  height: 2px;
  background-color: #ffffff;
}
.main-navigation .main-navigation__mob-trigger__icon span:nth-child(1) {
  top: 0.375rem;
}
.main-navigation .main-navigation__mob-trigger__icon span:nth-child(2), .main-navigation .main-navigation__mob-trigger__icon span:nth-child(3) {
  top: 0.75rem;
}
.main-navigation .main-navigation__mob-trigger__icon span span:nth-child(4) {
  top: 1.125rem;
}
.main-navigation .main-navigation__mob-trigger.is-active .main-navigation__mob-trigger__label {
  display: inline;
  background: linear-gradient(180deg, transparent 0, transparent 90%, rgba(0, 173, 29, 0.75) 90%, rgba(0, 173, 29, 0.75) 100%, transparent 100%, transparent 0);
  color: #00ad1d;
}
.main-navigation .main-navigation__mob-trigger.is-active .main-navigation__mob-trigger__icon {
  top: 15px;
}
.main-navigation .main-navigation__mob-trigger.is-active .main-navigation__mob-trigger__icon span {
  background-color: #00ad1d;
  width: 20px;
}
.main-navigation .main-navigation__mob-trigger.is-active .main-navigation__mob-trigger__icon span:nth-child(1), .main-navigation .main-navigation__mob-trigger.is-active .main-navigation__mob-trigger__icon span:nth-child(4) {
  top: 0.75rem;
  opacity: 0;
}
.main-navigation .main-navigation__mob-trigger.is-active .main-navigation__mob-trigger__icon span:nth-child(2) {
  transform: rotate(45deg);
}
.main-navigation .main-navigation__mob-trigger.is-active .main-navigation__mob-trigger__icon span:nth-child(3) {
  transform: rotate(-45deg);
}
.main-navigation .main-navigation__mob-wrapper {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 350ms;
  position: fixed;
  overflow: auto;
  top: 60px;
  right: -100%;
  width: calc(100% - 1.25rem);
  height: calc(100vh - 60px); /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100 - 60px);
  background-color: #e5e6eb;
  box-shadow: 0 1.25rem 1.875rem 0 rgba(0, 0, 0, 0.4);
  padding-bottom: 3.75rem;
  /**
   * Modifiers
   */
}
.main-navigation .main-navigation__mob-wrapper.is-open {
  right: 0;
}
.main-navigation .main-navigation__mob-lv-1 {
  /**
   * Modifiers
   */
}
.main-navigation .main-navigation__mob-lv-1__item a {
  text-decoration: none;
}
.main-navigation .main-navigation__mob-lv-1__item .level-2 {
  margin-left: -1.25rem;
  font-size: 1.325rem;
}
.main-navigation .main-navigation__mob-lv-1__item .level-2 .icon {
  width: 15px;
  height: 15px;
  fill: #40476c;
  position: relative;
  left: -5px;
}
.main-navigation .main-navigation__mob-lv-1__item .level-2 .icon svg {
  width: 15px;
  height: 15px;
}
.main-navigation .main-navigation__mob-lv-1__item > button,
.main-navigation .main-navigation__mob-lv-1__item > a {
  line-height: 1.4;
  width: 100%;
  position: relative;
  color: #40476c;
  padding: 17px 0 14px;
  border: none;
  outline: inherit;
  background: none;
  cursor: pointer;
  font-size: 1.25rem;
  text-align: left;
  display: block;
}
.main-navigation .main-navigation__mob-lv-1__item > button {
  font-size: 1.325rem;
}
.main-navigation .main-navigation__mob-lv-1__item > button .icon {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 100ms;
  position: absolute;
  right: 20px;
}
.main-navigation .main-navigation__mob-lv-1__item > button .icon svg {
  fill: #002554;
}
.main-navigation .main-navigation__mob-lv-1__item > button + hr {
  display: none;
}
.main-navigation .main-navigation__mob-lv-1__item > button.collapsed .icon {
  transform: rotate(-180deg);
  transform-origin: 50% 60%;
}
.main-navigation .main-navigation__mob-lv-1__item > button.collapsed .icon svg {
  fill: #00ad1d;
}
.main-navigation .main-navigation__mob-lv-1__item > button.collapsed + hr {
  display: block;
}
.main-navigation .main-navigation__mob-lv-1__item .link {
  line-height: 1.3333333333;
  font-size: 1.125rem;
  color: #40476c;
  text-decoration: none;
}
.main-navigation .main-navigation__mob-lv-1 label {
  color: red;
}
.main-navigation .main-navigation__mob-lv-1 ul li a {
  color: blue;
}
.main-navigation .main-navigation__mob-lv-1.is-primary {
  background-color: #ffffff;
}
.main-navigation .main-navigation__mob-lv-1.is-primary > button,
.main-navigation .main-navigation__mob-lv-1.is-primary > a {
  font-weight: 700;
}
.main-navigation .main-navigation__mob-languages-wrapper {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 350ms;
  position: fixed;
  overflow: auto;
  top: calc(100% - 60px);
  top: calc(var(--vh, 1vh) * 100 - 60px);
  right: -100%;
  width: calc(100% - 1.25rem);
  height: calc(100% - 60px);
  height: calc(var(--vh, 1vh) * 100 - 60px);
  background-color: #ffffff;
}
.main-navigation .main-navigation__mob-languages-wrapper.is-shown {
  right: 0;
}
.main-navigation .main-navigation__mob-languages-wrapper.is-open {
  top: 3.75rem;
}
.main-navigation .main-navigation__mob-lang-trigger {
  line-height: 1.3333333333;
  position: relative;
  display: block;
  width: 100%;
  height: 3.75rem;
  border: none;
  outline: none;
  background: #40476c;
  color: #ffffff;
  cursor: pointer;
  font-size: 1.125rem;
  text-align: left;
  font-weight: 400;
}
.main-navigation .main-navigation__mob-lang-trigger .plus-btn {
  position: absolute;
  top: 1.375rem;
  right: 1.5rem;
  width: 1rem;
  height: 1rem;
  /* Vertical line */
  /* horizontal line */
}
.main-navigation .main-navigation__mob-lang-trigger .plus-btn:before, .main-navigation .main-navigation__mob-lang-trigger .plus-btn:after {
  content: "";
  position: absolute;
  background-color: white;
  transition: transform 0.25s ease-out;
}
.main-navigation .main-navigation__mob-lang-trigger .plus-btn:before {
  top: 0;
  left: 50%;
  width: 0.125rem;
  height: 100%;
  margin-left: -0.0625rem;
}
.main-navigation .main-navigation__mob-lang-trigger .plus-btn:after {
  top: 50%;
  left: 0;
  width: 100%;
  height: 0.125rem;
  margin-top: -0.0625rem;
}
.main-navigation .main-navigation__mob-lang-trigger:focus {
  outline: none;
}
.main-navigation .main-navigation__mob-lang-trigger.is-active .plus-btn:before {
  transform: rotate(90deg);
}
.main-navigation .main-navigation__mob-lang-trigger.is-active .plus-btn:after {
  transform: rotate(180deg);
}
.main-navigation .main-navigation__mob-languages {
  padding: 0.5625rem 1.5rem 1.5rem;
}
.main-navigation .main-navigation__mob-languages .mb-5 {
  margin-bottom: 1.8125rem !important;
}
.main-navigation .main-navigation__mob-ctas {
  background-color: #ffffff;
}
.main-navigation__item:not(.cta-item) {
  line-height: 1.4;
  display: block !important;
  font-size: 1rem;
  color: #ffffff;
  text-transform: uppercase;
  transition: 300ms background ease-in-out;
}
.main-navigation__item:not(.cta-item):hover {
  display: block !important;
  color: #ffffff;
  text-decoration: none;
}
.main-navigation__item.cta-item label {
  font-size: 1rem;
}

.meta-navigation {
  position: relative;
  height: 40px;
  background-color: #ffffff;
}
.meta-navigation .list > .list-inline-item:not(:last-child) {
  margin-right: 1.5rem;
}
.meta-navigation .list > .list-inline-item a {
  padding: 0.5rem 0;
  color: #63666a;
  display: block;
}

.page-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3.75rem;
  padding: 0 1.25rem;
  z-index: 999;
}
@media (min-width: 992px) {
  .page-header {
    padding: 0 12px;
    height: 8.75rem;
  }
}
.page-header__placeholder {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 3.75rem;
  background: transparent;
}
@media (min-width: 992px) {
  .page-header__placeholder {
    height: 8.75rem;
  }
}
.page-header.headroom {
  transition: all 400ms cubic-bezier(0.45, 0, 0.14, 1.03);
}
.page-header.headroom--pinned {
  top: 0;
}
.page-header.headroom--unpinned {
  top: -2.5rem;
}

.pagemode-edit .page-header,
.pagemode-preview .page-header,
.pagemode-debug .page-header {
  position: relative;
  padding: 0;
}
.pagemode-edit .page-header__placeholder,
.pagemode-preview .page-header__placeholder,
.pagemode-debug .page-header__placeholder {
  display: none;
}

.product-image-carousel {
  /*&::after {
    @include media-breakpoint-up(md) {
      @include triangle(bottomright, 42px, 28px, #fff);
    }

    position: absolute;
    bottom: -1px;
    right: -1px;
  }*/
}
@media (min-width: 768px) {
  .product-image-carousel {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 340px;
  }
}
@media (min-width: 992px) {
  .product-image-carousel {
    width: 460px;
  }
}
@media (min-width: 1200px) {
  .product-image-carousel {
    width: 480px;
  }
}
.product-image-carousel > img {
  width: 100%;
}
@media (min-width: 768px) {
  .product-image-carousel > img {
    max-width: 470px;
    width: auto;
  }
}
.product-image-carousel .glide__slides {
  align-items: flex-end;
}
.product-image-carousel .glide__slide img {
  width: 100%;
}
.product-image-carousel .glide__arrows {
  position: absolute;
  left: 0;
  bottom: 0;
}
.product-image-carousel .glide__arrow .icon svg {
  fill: #00ad1d;
}
.product-image-carousel .glide__arrow--left, .product-image-carousel .glide__arrow--right {
  position: absolute;
  bottom: 1rem;
}
@media (min-width: 768px) {
  .product-image-carousel .glide__arrow--left, .product-image-carousel .glide__arrow--right {
    bottom: 1.6rem;
  }
}
.product-image-carousel .glide__arrow--left {
  left: 1rem;
}
@media (min-width: 768px) {
  .product-image-carousel .glide__arrow--left {
    left: 2rem;
  }
}
.product-image-carousel .glide__arrow--right {
  right: 1rem;
}
@media (min-width: 768px) {
  .product-image-carousel .glide__arrow--right {
    right: 2rem;
  }
}
.product-image-carousel .glide__bullets--wrapper {
  position: absolute;
  right: 0;
  top: -20px;
}
@media (min-width: 768px) {
  .product-image-carousel .glide__bullets--wrapper {
    top: auto;
    right: 35px;
    bottom: -30px;
  }
}
.product-image-carousel .glide__bullets .glide__bullet {
  display: none;
}
.product-image-carousel .glide__bullets .glide__bullet--active {
  display: block;
}

.hce-product-list--introduction {
  padding-bottom: 4.375rem;
}
@media (min-width: 768px) {
  .hce-product-list--introduction {
    padding-top: 0.875rem;
    padding-bottom: 1.875rem;
  }
}
.hce-product-list--introduction .switch {
  position: absolute;
  bottom: -3.1875rem;
  right: 12px;
  width: 15.625rem;
  height: 2rem;
}
@media (min-width: 768px) {
  .hce-product-list--introduction .switch {
    top: 0.5625rem;
    bottom: auto;
    right: 0;
  }
}
.hce-product-list--introduction .rte {
  padding-top: 1.25rem;
}
@media (min-width: 768px) {
  .hce-product-list--introduction .rte {
    width: 66.6666666667%;
  }
}
.hce-product-list--introduction .rte h1 {
  margin-bottom: 0.8125rem;
  font-size: 2.25rem;
}
@media (min-width: 768px) {
  .hce-product-list--introduction .rte h1 {
    font-size: 3.5rem;
  }
}
.hce-product-list--introduction-wrapper {
  padding-left: 20px;
}
@media (min-width: 576px) {
  .hce-product-list--introduction-wrapper {
    padding-left: 0;
  }
}
.hce-product-list--filters {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 350ms;
  position: relative;
  background-color: #f2f2f5;
}
.hce-product-list--filters:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1.5rem;
  background-color: #ffffff;
}
@media (min-width: 992px) {
  .hce-product-list--filters:before {
    height: 5.5rem;
  }
}
.hce-product-list--filters .filterscontainer > form > .row, .hce-product-list--filters .hce-product-cards.is-grid .filterscontainer > form > .grid-row, .hce-product-cards.is-grid .hce-product-list--filters .filterscontainer > form > .grid-row {
  margin-top: 1.5rem;
  margin-bottom: 1.25rem;
}
@media (min-width: 992px) {
  .hce-product-list--filters .filterscontainer > form > .row, .hce-product-list--filters .hce-product-cards.is-grid .filterscontainer > form > .grid-row, .hce-product-cards.is-grid .hce-product-list--filters .filterscontainer > form > .grid-row {
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .hce-product-list--filters .filterscontainer.collapse:not(.show) {
    display: block;
  }
}
.hce-product-list--filters .btn--list, .hce-product-list--filters .btn--grid {
  width: 48px;
  height: 48px;
  border: solid 1px #bfc1ce;
  border-radius: 50%;
  background-color: #f2f2f5;
  padding: 0;
  min-height: auto;
  justify-content: center;
}
@media (min-width: 768px) {
  .hce-product-list--filters .btn--list, .hce-product-list--filters .btn--grid {
    width: 36px;
    height: 36px;
  }
}
.hce-product-list--filters .btn--list .icon, .hce-product-list--filters .btn--grid .icon {
  width: 24px;
  height: 24px;
  position: relative;
  top: 2px;
}
@media (min-width: 768px) {
  .hce-product-list--filters .btn--list .icon, .hce-product-list--filters .btn--grid .icon {
    left: 0px;
  }
}
.hce-product-list--filters .btn--list .icon svg, .hce-product-list--filters .btn--grid .icon svg {
  width: 24px;
  height: 24px;
  fill: #002554;
}
.hce-product-list--filters .btn--list.is-active, .hce-product-list--filters .btn--grid.is-active {
  background-color: #002554;
  border: solid 1px #002554;
}
.hce-product-list--filters .btn--list.is-active .icon svg, .hce-product-list--filters .btn--grid.is-active .icon svg {
  fill: #ffffff;
}
.hce-product-list--filters .btn--filter-mobile {
  position: relative;
}
.hce-product-list--filters .btn--filter-mobile .icon {
  position: absolute;
  right: 24px;
  fill: #002554;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 100ms;
}
.hce-product-list--filters .btn--filter-mobile.is-active {
  background-color: #002554 !important;
  border: solid 1px #002554 !important;
  color: #ffffff !important;
}
.hce-product-list--filters .btn--filter-mobile.is-active .icon {
  fill: #ffffff;
  transform: rotate(180deg);
  transform-origin: 50% 65%;
}
.hce-product-list--filters .btn + .btn {
  margin-left: 0.375rem;
}
.hce-product-list--filters .custom-select {
  margin-bottom: 0.5rem;
}
@media (min-width: 768px) {
  .hce-product-list--filters .custom-select {
    margin-bottom: 0;
  }
}

.productfilters-open .hce-product-list--filters {
  background-color: #bfc1ce;
}

.hce-product-link-box .card {
  background-color: #00ad1d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem;
}
.hce-product-link-box .card .img-fluid {
  height: auto;
  width: 100%;
}
.hce-product-link-box .card-header, .hce-product-link-box .card-body {
  color: #ffffff;
}
.hce-product-link-box .card-header ul, .hce-product-link-box .card-body ul {
  font-size: 1.25rem;
  font-weight: 400;
}
.hce-product-link-box .card-header ul span, .hce-product-link-box .card-body ul span {
  font-weight: 700;
}
.hce-product-link-box .card-body ul {
  margin-bottom: 1.5rem;
  padding-left: 22px;
}
.hce-product-link-box .card-header {
  position: relative;
}
.hce-product-link-box .card-header ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  margin-bottom: 0;
  margin-top: 10px;
  padding: 0;
}
.hce-product-link-box .card-header ul span {
  color: #ffffff;
}
.hce-product-link-box .card-title h3 {
  color: #ffffff;
  margin-bottom: 1rem;
}
.hce-product-link-box .card-title h4 {
  color: #ffffff;
  font-size: 1.5rem;
}
.hce-product-link-box .btn {
  display: table;
  font-size: 1.125rem;
  font-weight: 700;
  margin: 0 auto;
}
.hce-product-link-box .btn-primary:focus, .hce-product-link-box .btn-primary:hover, .hce-product-link-box .btn-primary:active {
  border: 1px solid #002554 !important;
}
.hce-product-link-box[data-mode=vertical] .card-title {
  margin-top: 1rem;
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .hce-product-link-box .card-header ul {
    flex-direction: column;
    position: absolute;
    right: 0;
  }
  .hce-product-link-box .card-title {
    margin-top: 1rem;
  }
}
@media (min-width: 768px) {
  .hce-product-link-box[data-mode=horizontal] .card {
    flex-direction: row-reverse;
  }
  .hce-product-link-box[data-mode=horizontal] .card-header, .hce-product-link-box[data-mode=horizontal] .card-body {
    flex: 1 0 0;
  }
  .hce-product-link-box[data-mode=horizontal] .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .hce-product-link-box[data-mode=horizontal] .btn {
    display: inline-block;
    margin: 0 auto 0 0;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .hce-product-link-box[data-mode=horizontal] .card-header {
    padding-left: 1.5rem;
  }
  .hce-product-link-box[data-mode=horizontal] .card-body {
    padding-right: 1.5rem;
  }
}
@media (min-width: 992px) {
  .hce-product-link-box .card-header, .hce-product-link-box .card-body {
    padding: 0 1.5rem;
  }
  .hce-product-link-box[data-mode=horizontal] .card-header, .hce-product-link-box[data-mode=horizontal] .card-body {
    padding: 0 3rem;
  }
  .hce-product-link-box[data-mode=horizontal][data-adaptive=sm] .card-header {
    padding: 0 0 0 1.5rem;
  }
  .hce-product-link-box[data-mode=horizontal][data-adaptive=sm] .card-body {
    padding: 0 1.5rem 0 0;
  }
  .hce-product-link-box[data-mode=vertical] .card-header ul {
    flex-direction: column;
    margin-bottom: 1rem;
    position: absolute;
    right: 0;
  }
  .hce-product-link-box[data-mode=vertical][data-adaptive=xs] .card-header, .hce-product-link-box[data-mode=vertical][data-adaptive=xs] .card-body {
    padding: 0;
  }
  .hce-product-link-box[data-mode=vertical][data-adaptive=xs] .card-header ul {
    position: relative;
  }
  .hce-product-link-box[data-mode=vertical][data-adaptive=xs] .card-title {
    margin-top: 0;
  }
}

.product-introduction {
  padding-left: 1.25rem;
  padding-bottom: 1.5rem;
}
@media (min-width: 768px) {
  .product-introduction {
    padding-left: 0;
  }
}
.product-introduction__title {
  margin-bottom: 1rem;
  font-size: 2.25rem;
  line-height: 2.25rem;
}
@media (min-width: 768px) {
  .product-introduction__title {
    font-size: 3.5rem;
    line-height: 3rem;
  }
}
.product-introduction__subtitle {
  margin-bottom: 1rem;
  color: #7f849d;
}
@media (min-width: 768px) {
  .product-introduction__subtitle {
    margin-bottom: 1.5rem;
  }
}
.product-introduction__summary {
  margin-bottom: 1rem;
}

.product-specs-details__label {
  font-size: 0.75rem;
  color: #003087;
  vertical-align: top;
}
@media (min-width: 768px) {
  .product-specs-details__label {
    height: auto;
    font-size: 0.875rem;
  }
}
.product-specs-details__value {
  color: #003087;
  font-size: 1rem;
  font-weight: bold;
}

.product-specs-highlights__row {
  display: flex;
  flex-wrap: wrap;
}
.product-specs-highlights__row:not(:first-child) .product-specs-highlights__column {
  border-top: 0;
}
.product-specs-highlights__column {
  padding: 0.875rem 1.25rem;
  border: 0.0625rem solid #e0e0e0;
  /*
  &--button {
    display: flex;
    padding: .5rem 1.25rem;

    @include media-breakpoint-up(md) {
      align-items: center;
      justify-content: center;
    }

    .btn {
      height: auto;
      padding: 0;
    }

    .icon svg {
      fill: $primary-600;
    }
  }
  */
}
.product-specs-highlights__column:not(:first-child):not(.col-12) {
  border-left: 0;
}
@media (min-width: 768px) {
  .product-specs-highlights__column:not(:first-child) {
    border-left: 0;
  }
}
.product-specs-highlights__column--image {
  order: -1;
  position: relative;
}
@media (min-width: 768px) {
  .product-specs-highlights__column--image {
    order: 3;
  }
}
.product-specs-highlights__label {
  font-size: 0.75rem;
  color: #003087;
  vertical-align: top;
}
@media (min-width: 768px) {
  .product-specs-highlights__label {
    height: auto;
    font-size: 0.875rem;
  }
}
.product-specs-highlights__value {
  color: #003087;
  font-size: 1rem;
  font-weight: bold;
}
.product-specs-highlights .glide {
  /*&:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      to bottom,
      transparent 75%,
      rgba($primary, .6)
    );
    pointer-events: none;
    transition: opacity .2s;
  }

  &:hover:after {
    background-image: linear-gradient(
      to bottom,
      transparent 75%,
      rgba($primary, .8)
    );
  }*/
}
.product-specs-highlights .glide__bullet {
  background-color: transparent;
}
.product-specs-highlights .glide__arrow:hover svg {
  fill: #002554;
}
.product-specs-highlights .glide img {
  height: 15.5rem;
  object-fit: cover;
}
@media (min-width: 768px) {
  .product-specs-highlights .glide img {
    height: 22.0625rem;
  }
}
.product-specs-highlights.short .product-specs-highlights__column {
  border-left: 0.0625rem solid #e0e0e0;
}

.quick-links {
  margin-top: -3.4375rem;
}
.quick-links__nav {
  gap: 1px;
}
.quick-links__item {
  background-color: #00ad1d;
  position: relative;
  height: 8.125rem;
  background-clip: padding-box;
  box-shadow: 0 0.125rem 2.5rem 0 rgba(0, 0, 0, 0.25);
  flex: 0 0 calc(50% - 1px);
}
@media (min-width: 992px) {
  .quick-links__item:hover {
    box-shadow: 0 0.9375rem 2.5rem 0 rgba(0, 0, 0, 0.25);
  }
}
.quick-links__item a {
  display: flex;
  height: 100%;
  margin-right: -0.0625rem;
  text-decoration: none;
  transition: all 80ms ease-in;
}
@media (min-width: 992px) {
  .quick-links__item a:hover, .quick-links__item a.is-active {
    background-color: #002554;
    transform: scaleY(1.3) translateY(0.25rem);
  }
}
@media (min-width: 768px) {
  .quick-links__item {
    flex-basis: calc(33.33% - 1px);
  }
}
@media (min-width: 992px) {
  .quick-links__item {
    flex-basis: calc(20% - 1px);
  }
}
.quick-links__content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.875rem;
  left: 0;
  top: 0;
  pointer-events: none;
}
.quick-links__content .label {
  width: 100%;
  min-height: 2.625rem;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.875rem;
  letter-spacing: 0.1875rem;
}
.quick-links__content .icon {
  width: 4.5rem;
  height: 4.5rem;
}
.quick-links__content .icon svg {
  width: 100%;
  height: 100%;
  fill: #ffffff;
}

.rte {
  letter-spacing: 0.5px;
  position: relative;
}
.rte a {
  display: inline-block;
  position: relative;
  text-decoration: none;
  z-index: 1;
  color: inherit;
}
.rte a:before {
  content: "";
  position: absolute;
  bottom: 0.4375rem;
  left: 0;
  width: 100%;
  height: 0.25rem;
  background-color: #67cd98;
  z-index: -1;
  transition: 100ms all ease-in-out;
}
.rte a:hover:before, .rte a:focus:before {
  bottom: 0.1875rem;
}
.rte a:focus {
  outline: 1px dotted black;
}

.card {
  position: relative;
  display: block;
}
.card .img-fluid {
  height: 100px;
}
@media (min-width: 768px) {
  .card .img-fluid {
    height: 180px;
  }
}
.card__body {
  padding: 0 2.5rem 1rem 0;
}
@media (min-width: 768px) {
  .card__body {
    padding: 1.0625rem 0.625rem 0 1.5625rem;
  }
}
.card__text {
  margin-bottom: 0.5625rem;
  color: #40476c;
}
.card__link {
  display: inline;
  background: linear-gradient(180deg, transparent 0, transparent 56%, rgba(0, 173, 29, 0.5) 56%, rgba(0, 173, 29, 0.5) 72%, transparent 72%, transparent 0);
  color: #002554;
}
.card:hover {
  text-decoration: none !important;
}
.card:hover .card__link {
  display: inline;
  background: linear-gradient(180deg, transparent 0, transparent 68%, #00ad1d 68%, #00ad1d 84%, transparent 84%, transparent 0);
}

.teaser-fullwidth {
  position: relative;
  padding-left: 1.25rem;
}
@media (min-width: 768px) {
  .teaser-fullwidth {
    padding-left: 5.125rem;
  }
}
@media (min-width: 992px) {
  .teaser-fullwidth {
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .teaser-fullwidth {
    padding-left: 6.25rem;
  }
}
.teaser-fullwidth__image-container {
  position: relative;
}
.teaser-fullwidth__gradient {
  display: none;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  clip-path: inherit;
}
@media (min-width: 992px) {
  .teaser-fullwidth__gradient {
    position: absolute;
    display: block;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));
  }
  .teaser-fullwidth.is-centered .teaser-fullwidth__gradient {
    background: none;
    background-color: rgba(0, 0, 0, 0.55);
  }
  .teaser-fullwidth.is-right .teaser-fullwidth__gradient {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));
  }
}
.teaser-fullwidth__image {
  height: 11.875rem;
  clip-path: inherit;
}
@media (min-width: 768px) {
  .teaser-fullwidth__image {
    height: 17.125rem;
  }
}
@media (min-width: 992px) {
  .teaser-fullwidth__image {
    height: 37.5rem;
  }
}
@media (min-width: 992px) {
  .teaser-fullwidth__container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.teaser-fullwidth__body {
  padding: 1.75rem 0;
}
@media (min-width: 768px) {
  .teaser-fullwidth__body {
    width: 30rem;
    padding-top: 2.5rem;
    padding-left: 2.75rem;
    margin-right: auto;
  }
}
@media (min-width: 992px) {
  .teaser-fullwidth__body {
    width: 19.125rem;
    padding: 0;
  }
  .teaser-fullwidth.is-centered .teaser-fullwidth__body {
    width: 39.5625rem;
    margin: 0 auto;
    text-align: center;
  }
}
@media (min-width: 992px) and (min-width: 768px) {
  .teaser-fullwidth.is-right .teaser-fullwidth__body {
    margin-right: 0;
    margin-left: auto;
  }
}
@media (min-width: 992px) {
  .teaser-fullwidth__body h3, .teaser-fullwidth__body .h3, .teaser-fullwidth__body p {
    color: #ffffff !important;
  }
}
.teaser-fullwidth__cta {
  margin-top: 1.25rem;
}
@media (min-width: 992px) {
  .teaser-fullwidth__cta {
    color: #ffffff;
  }
}

.teaser {
  position: relative;
  display: block;
  margin-bottom: 1.5rem;
  transition: filter 200ms;
}
@media (max-width: 767.98px) {
  [class*=col-]:last-child .teaser {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .teaser {
    margin-bottom: 0;
  }
}
.teaser__image {
  height: 17.5rem;
}
@media (min-width: 768px) {
  .teaser__image {
    height: 21.25rem;
  }
}
.teaser__gradient {
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, transparent, #000000);
  transition: 300ms opacity ease-in-out;
}
.teaser__body {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 2rem 1rem 2rem;
}
.teaser__hidden-content {
  max-height: 0;
  margin-top: 0.5rem;
  overflow: hidden;
  transition: 300ms max-height ease-in-out;
}
.teaser__title {
  display: inline;
  background: linear-gradient(180deg, transparent 0, transparent 62%, rgba(255, 255, 255, 0.5) 62%, rgba(255, 255, 255, 0.5) 72%, transparent 72%, transparent 0);
  color: #ffffff;
  transition: 300ms background ease-in-out;
}
@media (min-width: 768px) {
  [class^=col-][class$="-3"] .teaser__title {
    font-size: 1.5rem;
  }
}
.teaser__text {
  color: #ffffff;
  font-weight: 300;
}
.teaser .icon {
  display: inline-block;
  margin-top: 0.75rem;
  fill: #00ad1d;
}
@media (min-width: 768px) {
  .teaser:hover {
    text-decoration: none !important;
    filter: drop-shadow(0 0 1.25rem rgba(0, 0, 0, 0.5));
  }
  .teaser:hover .teaser__gradient {
    opacity: 1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.75));
  }
  .teaser:hover .teaser__title {
    display: inline;
    background: linear-gradient(180deg, transparent 0, transparent 71%, #00ad1d 71%, #00ad1d 82%, transparent 82%, transparent 0);
  }
  .teaser:hover .teaser__hidden-content {
    max-height: 18.75rem;
  }
}

.usp {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .usp--row {
    flex-direction: row;
    width: 100%;
    margin: 0;
  }
}
.usp--column {
  flex-direction: column;
}
.usp__divider {
  display: block;
  width: 3rem;
  height: 0.0625rem;
  margin: 0.75rem auto 0 auto;
}
@media (min-width: 768px) {
  .usp--row .usp__divider {
    display: none;
  }
}
.usp__item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.75rem;
  padding-bottom: 0;
  margin-bottom: 0.75rem;
  text-align: center;
  overflow: hidden;
}
@media (min-width: 768px) {
  .usp--row .usp__item {
    flex: 1;
  }
}
.usp__item--left {
  align-items: flex-start;
  padding-left: 0;
  text-align: left;
}
.usp__item--left .usp__value {
  padding-left: 0;
}
.usp__item--left .usp__divider {
  margin-left: 0;
}
.usp__value {
  position: relative;
  width: 15rem;
  padding: 0 1.25rem;
}
@media (min-width: 768px) {
  .usp--row .usp__value {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .usp--row .usp__item:not(:last-child) .usp__value:after {
    content: "";
    position: absolute;
    border-right: 0.0625rem solid #e5e6eb;
    top: 0;
    right: 0;
    height: 10rem;
  }
}
.usp .icon {
  width: 2rem;
  height: 2rem;
  margin-bottom: 0.5rem;
}
.usp .icon svg {
  width: inherit;
  height: inherit;
  fill: #00ad1d;
}

.hce-article-detail {
  padding-bottom: 3rem;
}
.hce-article-detail .article-header {
  padding-top: 1.5rem;
  background-color: #f2f2f5;
}
.hce-article-detail .article-header .back-button {
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .hce-article-detail .article-header .back-button {
    margin-bottom: 2rem;
  }
}
.hce-article-detail .article-header .back-button a {
  padding-right: 0;
  padding-left: 0;
}
.hce-article-detail .article-header h1 {
  margin-bottom: 1.25rem;
}
@media (min-width: 768px) {
  .hce-article-detail .article-header h1 {
    margin-bottom: 1.5rem;
  }
}
.hce-article-detail .article-wrapper {
  width: 100%;
  padding-left: 1.25rem;
}
.hce-article-detail time {
  color: #7f849d;
}
.hce-article-detail .article-intro {
  margin-top: 1.75rem;
  color: #40476c;
  line-height: 1.56;
  font-weight: 700;
  letter-spacing: 0;
}
@media (min-width: 768px) {
  .hce-article-detail .article-intro {
    margin-top: 2rem;
    color: #0a1f44;
    line-height: 1.8;
    letter-spacing: -0.03125rem;
  }
}
.hce-article-detail .article-hero {
  padding: 3rem 0;
}
.hce-article-detail .article-hero:last-child {
  padding: 0;
}
.hce-article-detail .image-caption img {
  width: 100%;
  height: 13.25rem;
  object-fit: cover;
}
@media (min-width: 768px) {
  .hce-article-detail .image-caption img {
    height: 27rem;
  }
}
.hce-article-detail .main-content > p:not(:last-child),
.hce-article-detail .main-content ul {
  margin-bottom: 2rem;
}
.hce-article-detail .main-content ul {
  padding-left: 1.25rem;
}
.hce-article-detail .main-content ul li {
  color: #40476c;
}
.hce-article-detail h4 {
  font-size: 1.125rem;
}

.article-card {
  transition: box-shadow 0.2s;
}
.article-card .card-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  text-decoration: none;
}
.article-card .card-wrapper:hover, .article-card .card-wrapper:active, .article-card .card-wrapper:focus {
  box-shadow: 0 0 1.75rem rgba(0, 0, 0, 0.5);
}
.bg-dark .article-card .card-wrapper:hover, .bg-dark .article-card .card-wrapper:active, .bg-dark .article-card .card-wrapper:focus {
  box-shadow: 0 0 1.75rem rgba(255, 255, 255, 0.5);
}

.article-card .card-wrapper:hover:after, .article-card .card-wrapper:active:after, .article-card .card-wrapper:focus:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom: 0.25rem solid #00ad1d;
}
.article-card .card-image {
  height: 8.625rem;
  background-color: #e5e6eb;
  object-fit: cover;
}
.article-card .card-body {
  padding: 1.25rem 1rem;
  border: 0.0625rem solid #e5e6eb;
  border-top-width: 0;
  background-color: #f2f2f5;
}
.bg-medium .article-card .card-body, .bg-dark .article-card .card-body {
  background-color: #ffffff;
}

.article-card .card-title {
  margin-bottom: 0.75rem;
  color: #002554;
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: -0.03125rem;
  line-height: 1;
}

.article-card-highlighted {
  position: relative;
  height: 20.25rem;
  transition: 0.2s filter;
}
.article-card-highlighted .card-wrapper {
  display: flex;
  flex-flow: column wrap;
  height: 100%;
  padding: 1.5rem 1rem;
  border: 0.0625rem solid #e5e6eb;
  text-decoration: none;
}
.article-card-highlighted .card-wrapper:hover, .article-card-highlighted .card-wrapper:active, .article-card-highlighted .card-wrapper:focus {
  filter: drop-shadow(0 0 1.75rem rgba(0, 0, 0, 0.5));
}
.article-card-highlighted .card-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.article-card-highlighted .card-body {
  position: relative;
  margin-top: auto;
  padding: 0;
}
.article-card-highlighted .card-title {
  margin-bottom: 0.75rem;
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.17;
}
@media (min-width: 768px) {
  .article-card-highlighted .card-title {
    margin-bottom: 0.5rem;
  }
}

.hce-article-overview {
  padding-bottom: 3rem;
}
.hce-article-overview .overview-header {
  padding-top: 2.25rem;
  padding-bottom: 5.5rem;
  padding-left: 1.25rem;
  background-color: #f2f2f5;
}
@media (min-width: 768px) {
  .hce-article-overview .overview-header {
    padding-top: 6.25rem;
    padding-bottom: 3.5rem;
  }
}
@media (min-width: 992px) {
  .hce-article-overview .overview-title {
    width: 58.3333333333%;
    font-size: 3.5rem;
    letter-spacing: -0.073125rem;
    line-height: 0.86;
  }
}
.hce-article-overview .highlighted-cards {
  display: flex;
  flex-wrap: wrap;
  padding-left: 1.25rem;
  margin: -0.625rem;
  margin-bottom: 0.625rem;
}
@media (min-width: 768px) {
  .hce-article-overview .highlighted-cards {
    padding-left: 0;
  }
}
.hce-article-overview .highlighted-cards .col-md-6 {
  padding: 0.625rem;
}
.hce-article-overview .regular-cards-masonry {
  margin: -0.625rem;
  margin-left: 0.625rem;
}
@media (min-width: 768px) {
  .hce-article-overview .regular-cards-masonry {
    margin-left: -0.625rem;
  }
}
.hce-article-overview .regular-cards-masonry .masonry-item {
  width: 100%;
  padding: 0.625rem;
}
@media (min-width: 768px) {
  .hce-article-overview .regular-cards-masonry .masonry-item {
    width: 33.3333333333%;
  }
}
@media (min-width: 992px) {
  .hce-article-overview .regular-cards-masonry .masonry-item {
    width: 25%;
  }
}
.hce-article-overview .cards-enter-active,
.hce-article-overview .cards-leave-active {
  transition: all 0.8s;
}
.hce-article-overview .cards-enter,
.hce-article-overview .cards-leave-to {
  opacity: 0;
  transform: translateY(1.875rem);
}
.hce-article-overview .load-more-cards {
  margin-top: 2rem;
}
.hce-article-overview .load-more-cards .btn {
  display: block;
  margin: 0 auto;
}

.article-tags {
  display: flex;
  margin-top: 0.5rem;
}
@media (min-width: 768px) {
  .article-tags {
    display: inline-flex;
    margin-top: 0;
    margin-left: 1rem;
  }
}

.article-tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.125rem 0.625rem 0 0.625rem;
  border: 0.0625rem solid #e0e0e0;
  border-radius: 1rem;
  background-color: #ffffff;
  color: #40476c;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
}
.article-tag, .article-tag.is-small {
  height: 1.25rem;
  font-size: 0.75rem;
  letter-spacing: 0.0625rem;
}
@media (min-width: 768px) {
  .article-tag {
    height: 1.75rem;
    font-size: 0.875rem;
    letter-spacing: 0.1875rem;
  }
}
.article-tag + .article-tag {
  margin-left: 0.5rem;
}

.hce-dealer-locator:not(.detail) {
  z-index: -1;
  display: flex;
  flex-direction: column;
  height: auto;
  overflow-x: hidden;
}
@media (min-width: 992px) {
  .hce-dealer-locator:not(.detail) {
    flex-direction: row;
    height: 80vh;
  }
}
.hce-dealer-locator.has-filters {
  background-color: #e5e6eb;
}
.hce-dealer-locator .col-12 {
  padding: 0;
}
.hce-dealer-locator .main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 1.25rem;
  padding-bottom: 3rem;
  padding-left: 1.25rem;
}
@media (min-width: 768px) {
  .hce-dealer-locator .main {
    max-width: 70%;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 992px) {
  .hce-dealer-locator .main {
    max-width: 26.25rem;
    padding: 0;
    margin-right: 2.3125rem;
  }
}
@media (min-width: 1200px) {
  .hce-dealer-locator .main {
    margin-right: 4.625rem;
  }
}
.hce-dealer-locator .introduction {
  z-index: 1;
  position: relative;
  padding-top: 2.5rem;
  padding-bottom: 3.5rem;
  padding-left: 1.25rem;
}
@media (min-width: 768px) {
  .hce-dealer-locator .introduction {
    padding-bottom: 1rem;
    padding-left: 0;
  }
}
.hce-dealer-locator .introduction.detail .introduction {
  padding-left: 0;
}
.hce-dealer-locator .introduction.is-startscreen {
  padding-bottom: 1.25rem;
}
@media (min-width: 768px) {
  .hce-dealer-locator .introduction.is-startscreen {
    padding-bottom: 1.5rem;
  }
}
.hce-dealer-locator .introduction h1 {
  margin-bottom: 1rem;
  font-size: 2.25rem;
  line-height: 1;
  letter-spacing: -0.0625rem;
}
@media (min-width: 768px) {
  .hce-dealer-locator .introduction h1 {
    font-size: 3rem;
  }
}
.hce-dealer-locator .introduction__text {
  font-size: 1.125rem;
  line-height: 1.5rem;
}
@media (min-width: 992px) {
  .hce-dealer-locator .start-filter {
    margin-right: 2.125rem;
  }
}
.hce-dealer-locator .start-filter .start-search-button {
  margin-top: 1.75rem;
}
.hce-dealer-locator .start-filter .country-background {
  position: relative;
}
.hce-dealer-locator .start-filter .country-background:before {
  z-index: -1;
  content: "";
  position: absolute;
  top: -9999px;
  right: -9999px;
  bottom: 2rem;
  left: -9999px;
  background-color: #f2f2f5;
}
.hce-dealer-locator input[type=text] {
  border: 0.0625rem solid #bfc1ce;
  font-size: 1rem;
}
.hce-dealer-locator input[type=text]:focus {
  color: #002554;
  box-shadow: none;
}
.hce-dealer-locator .filter-type-section {
  padding: 1.5rem 0 0 1.5rem;
}
.hce-dealer-locator .form-check:last-child .form-check-label {
  margin-bottom: 0;
}
.hce-dealer-locator .form-check-input {
  display: none;
}
.hce-dealer-locator .form-check-input + .form-check-label {
  padding-left: 0.625rem;
  margin: 0.5625rem 0;
  color: #40476c;
  cursor: pointer;
  font-size: 1rem;
  letter-spacing: 0;
  text-transform: inherit;
}
.hce-dealer-locator .form-check-input + .form-check-label:before, .hce-dealer-locator .form-check-input + .form-check-label:after {
  content: "";
  position: absolute;
  top: 0.375rem;
  left: 0;
  width: 1.25rem;
  height: 1.25rem;
}
.hce-dealer-locator .form-check-input + .form-check-label:before {
  background-image: url("/assets/images/svg/checkbox-unchecked.svg");
}
.hce-dealer-locator .form-check-input:checked + .form-check-label:after {
  background-image: url("/assets/images/svg/checkbox-checked.svg");
}
.hce-dealer-locator .filter-toggle {
  position: relative;
}
.hce-dealer-locator .filter-toggle:before {
  z-index: 0;
  content: "";
  position: absolute;
  top: -9999px;
  right: -9999px;
  bottom: 1.5rem;
  left: -9999px;
  background-color: #f2f2f5;
}
@media (min-width: 992px) {
  .hce-dealer-locator .filter-toggle {
    margin-right: 2.125rem;
  }
}
.hce-dealer-locator .filter-toggle .btn {
  width: 100%;
}
.hce-dealer-locator .filter-toggle .form-group.has-icon {
  position: relative;
  margin-bottom: 0;
}
.hce-dealer-locator .filter-toggle .form-group.has-icon .form-control {
  padding-right: 4rem;
  border-color: #bfc1ce;
}
.hce-dealer-locator .filter-toggle .form-group.has-icon svg {
  position: absolute;
  top: 0.5rem;
  right: 1.25rem;
  width: 2rem;
  height: 2rem;
}
.hce-dealer-locator .filters {
  z-index: 2;
  position: relative;
  width: 100%;
  height: 0;
}
.hce-dealer-locator .filters::-webkit-scrollbar {
  width: 0.5rem;
}
.hce-dealer-locator .filters::-webkit-scrollbar-track {
  border-radius: 0.25rem;
  background-color: #f2f2f5;
}
.hce-dealer-locator .filters::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
  background-color: #bfc1ce;
}
.hce-dealer-locator .filters::-webkit-scrollbar-thumb:hover {
  background-color: #7f849d;
}
@media (min-width: 992px) {
  .hce-dealer-locator .filters {
    overflow-y: auto;
  }
}
@media (min-width: 992px) {
  .hce-dealer-locator .filters .hce-dealer-filters {
    max-width: calc(26.25rem - 1.5rem - 0.5rem);
    max-height: 0;
  }
}
.hce-dealer-locator .filters.are-open {
  flex: 1;
  height: 100%;
  padding-bottom: 2rem;
}
.hce-dealer-locator .filters.are-open .hce-dealer-filters {
  max-height: 62.5rem;
  padding: 0.75rem 0;
}
.hce-dealer-locator .filter-slide-enter-active,
.hce-dealer-locator .filter-slide-leave {
  height: 31.25rem;
  transition: height 0.2s ease-out;
}
.hce-dealer-locator .filter-slide-enter,
.hce-dealer-locator .filter-slide-leave-to {
  height: 0;
}
.hce-dealer-locator .dealers {
  flex: 1;
  padding-bottom: 3rem;
  margin-top: 2rem;
}
@media (min-width: 992px) {
  .hce-dealer-locator .dealers {
    height: 100%;
    padding-bottom: 2rem;
    overflow-y: auto;
  }
  .hce-dealer-locator .dealers::-webkit-scrollbar {
    width: 0.5rem;
  }
  .hce-dealer-locator .dealers::-webkit-scrollbar-track {
    border-radius: 0.25rem;
    background-color: #f2f2f5;
  }
  .hce-dealer-locator .dealers::-webkit-scrollbar-thumb {
    border-radius: 0.25rem;
    background-color: #bfc1ce;
  }
  .hce-dealer-locator .dealers::-webkit-scrollbar-thumb:hover {
    background-color: #7f849d;
  }
}
@media (min-width: 992px) {
  .hce-dealer-locator .dealers .hce-dealer-list {
    max-width: calc(26.25rem - 1.5rem - 0.5rem);
  }
}
.hce-dealer-locator .dealer-locator-detail {
  border: 3px solid #e5e6eb;
  padding: 30px 15px;
}
.hce-dealer-locator .dealer-locator-detail .hce-dealer-list__dealer-heading {
  padding-bottom: 30px;
  border-bottom: 0.0625rem solid #e5e6eb;
  margin-bottom: 30px;
}
.hce-dealer-locator .dealer-locator-detail .hce-dealer-list__dealer-service-types {
  display: flex;
  gap: 0.5rem;
  margin: 0.25rem 0;
}
.hce-dealer-locator .dealer-locator-detail .hce-dealer-list__dealer-service-types .badge {
  font-size: 0.85rem;
  padding: 0.25rem 0.5rem;
  font-weight: 400;
  line-height: 1.5;
}
.hce-dealer-locator .dealer-locator-detail .hce-dealer-list__dealer-service-types .badge-primary {
  background-color: #00ad1d;
  color: #ffffff;
}
.hce-dealer-locator .dealer-locator-detail p,
.hce-dealer-locator .dealer-locator-detail .btn-link--alt {
  padding: 0;
  line-height: 1.33333;
  font-size: 1.125rem;
}
@media (max-width: 767.98px) {
  .hce-dealer-locator .dealer-locator-detail {
    margin-bottom: 15px;
  }
}
.hce-dealer-locator .map-section {
  z-index: 4;
  padding: 0;
}
@media (max-width: 991.98px) {
  .hce-dealer-locator .map-section {
    padding-right: 1.25rem;
    padding-bottom: 1.25rem;
    padding-left: 1.25rem;
    margin: 0 auto;
  }
}
@media (max-width: 991.98px) {
  .hce-dealer-locator .hce-dealer-map {
    padding-top: 100%;
  }
}
.hce-dealer-list ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.hce-dealer-list__dealer-count {
  margin-bottom: 0.5rem;
}
.hce-dealer-list__dealer-count .label {
  color: #7f849d;
}
.hce-dealer-list__dealer {
  position: relative;
  border: 0.0625rem solid #e5e6eb;
  background-color: #ffffff;
}
.hce-dealer-list__dealer:not(:last-child) {
  margin-bottom: 1rem;
}
.hce-dealer-list__dealer:after {
  content: none;
}
.hce-dealer-list__dealer.is-collapsed:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-left: 0.25rem solid #faa519;
}
.hce-dealer-list__dealer .cut-corner {
  z-index: 1;
  position: absolute;
  right: -0.0625rem;
  bottom: -1px;
  width: 1.0625rem;
  height: 1.6875rem;
}
.hce-dealer-list__dealer-service-types {
  display: flex;
  gap: 0.5rem;
  margin: 0.25rem 0;
}
.hce-dealer-list__dealer .badge {
  font-size: 0.85rem;
  padding: 0.25rem 0.5rem;
}
.hce-dealer-list__dealer .badge-primary {
  background-color: #00ad1d;
  color: #ffffff;
}
.hce-dealer-list__dealer-heading {
  font-size: 1.125rem;
}
.hce-dealer-list__dealer p,
.hce-dealer-list__dealer p > a {
  color: #40476c;
  line-height: 1.5;
}
.hce-dealer-list__dealer-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  padding: 1rem;
  cursor: pointer;
}
.hce-dealer-list__dealer .collapse-button {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 0;
  border: 0;
  background: none;
  outline: none;
}
.hce-dealer-list__dealer .collapse-button:focus {
  outline: 0.0625rem dotted black;
}
.hce-dealer-list__dealer-main {
  flex: 1;
  margin-right: 1.5rem;
}
.hce-dealer-list__dealer-toggle {
  position: relative;
  flex: 0 0 3.25rem;
  margin: -1rem;
}
@media (min-width: 768px) {
  .hce-dealer-list__dealer-toggle {
    flex-basis: 3.75rem;
  }
}
.hce-dealer-list__dealer-toggle:after {
  content: "";
  position: absolute;
  top: 1rem;
  bottom: 1rem;
  left: 0;
  border-left: 0.0625rem solid #e5e6eb;
}
.hce-dealer-list__dealer-toggle .collapse-icon {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  width: 1.125rem;
  height: 1.125rem;
  background: none;
  background-image: url(/assets/images/svg/checkmark-primary.svg);
  background-repeat: no-repeat;
  background-size: 1.125rem;
  transform: rotate(90deg);
  transition: transform 150ms ease-in-out;
}
.hce-dealer-list__dealer.is-collapsed .hce-dealer-list__dealer-toggle .collapse-icon {
  transform: rotate(0deg);
}

.hce-dealer-list__dealer-divider {
  display: none;
  height: 0.0625rem;
  margin: 0 1rem;
  background-color: #e5e6eb;
}
.hce-dealer-list__dealer.is-collapsed .hce-dealer-list__dealer-divider {
  display: block;
}

.hce-dealer-list__dealer-contact {
  max-height: 0;
  overflow: hidden;
}
.hce-dealer-list__dealer.is-collapsed .hce-dealer-list__dealer-contact {
  max-height: 15.625rem;
  padding: 1rem;
  transition: max-height 500ms ease;
}

.hce-dealer-list__dealer-contact p {
  margin-bottom: 1rem;
}
.hce-dealer-list__dealer-contact .btn-link {
  display: block;
  height: auto;
  padding: 0;
  text-align: left;
}
.hce-dealer-list__dealer-contact .btn-primary {
  border-color: #40476c;
  background-color: #40476c;
}
.hce-dealer-list__full-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.dealers-page {
  max-width: 30rem;
  padding: 2rem 1.25rem 2rem 2.5rem;
  margin: 0 auto;
}

.hce-dealer-map {
  position: relative;
  height: 100%;
}
.hce-dealer-map.is-disabled:after {
  z-index: 5;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 37, 84, 0.4);
}

.gm-style-iw .firstHeading {
  font-size: 1.125rem;
}

.hce-dealer-filters {
  overflow: hidden;
}
.hce-dealer-filters .apply {
  margin-top: 2rem;
}
.hce-dealer-filters .apply .btn {
  width: 100%;
}
@media (min-width: 768px) {
  .hce-dealer-filters .apply .btn {
    width: auto;
  }
}

.hce-highlighted-articles {
  padding: 2rem 0;
}
@media (min-width: 768px) {
  .hce-highlighted-articles {
    padding: 3.75rem 0;
  }
}
.bg-dark .hce-highlighted-articles .hce-highlighted-articles__title,
.bg-dark .hce-highlighted-articles p.lead,
.bg-dark .hce-highlighted-articles .hce-highlighted-articles__introduction-cta .btn,
.bg-dark .hce-highlighted-articles .hce-highlighted-articles__cta-sm .btn {
  color: #ffffff;
}

.hce-highlighted-articles .row, .hce-highlighted-articles .hce-product-cards.is-grid .grid-row, .hce-product-cards.is-grid .hce-highlighted-articles .grid-row {
  padding-right: 1.25rem;
}
@media (min-width: 768px) {
  .hce-highlighted-articles .row, .hce-highlighted-articles .hce-product-cards.is-grid .grid-row, .hce-product-cards.is-grid .hce-highlighted-articles .grid-row {
    padding-right: 0;
  }
}
.hce-highlighted-articles__introduction-cta {
  margin-bottom: 1rem;
}
@media (min-width: 992px) {
  .hce-highlighted-articles__introduction-cta {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
  }
}
.hce-highlighted-articles__introduction-cta .btn {
  display: none;
}
@media (min-width: 992px) {
  .hce-highlighted-articles__introduction-cta .btn {
    display: block;
    margin-top: 2rem;
  }
}
.hce-highlighted-articles__introduction-cta p.lead {
  width: 100%;
}
.hce-highlighted-articles__cta-sm {
  width: 100%;
  margin-top: 0.5rem;
  text-align: center;
}
@media (min-width: 768px) {
  .hce-highlighted-articles__cta-sm {
    margin-top: 1rem;
  }
}
@media (min-width: 992px) {
  .hce-highlighted-articles__cta-sm {
    display: none;
  }
}
.hce-highlighted-articles__title {
  width: 100%;
  margin-bottom: 0.5rem;
}
@media (min-width: 992px) {
  .hce-highlighted-articles__title {
    margin-bottom: 1.25rem;
  }
}
.hce-highlighted-articles__introduction-cta, .hce-highlighted-articles__card {
  padding: 0.5rem 0.75rem;
}
@media (min-width: 992px) {
  .hce-highlighted-articles__introduction-cta, .hce-highlighted-articles__card {
    padding: 0 0.75rem;
  }
}

@media (min-width: 768px) {
  .hce-image-gallery__carousel {
    transform: translateY(3.125rem);
  }
}
.hce-image-gallery__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.hce-image-gallery__controls-sm {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.25rem;
}
.hce-image-gallery__controls-md {
  display: none;
}
@media (min-width: 768px) {
  .hce-image-gallery__controls-sm {
    display: none;
  }
  .hce-image-gallery__controls-md {
    display: flex;
  }
}
.hce-image-gallery__slides {
  margin-right: -1.25rem;
}
@media (min-width: 768px) {
  .hce-image-gallery__slides {
    margin-right: 0;
  }
}
.hce-image-gallery__slide {
  position: relative;
  width: auto;
  border-bottom: 0.25rem solid transparent;
  transition: border 150ms;
  /*&:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, transparent, rgba($primary, .75));
    transition: opacity 200ms;

    @include media-breakpoint-up(md) {
      opacity: 0;
    }
  }*/
}
.hce-image-gallery__slide:not(:last-child) img {
  border-right: 0;
}
.hce-image-gallery__slide:hover {
  z-index: 1;
  border-bottom-color: #00ad1d;
  box-shadow: 0 0 1.875rem 0 rgba(0, 0, 0, 0.5);
}
.hce-image-gallery__slide:hover:after {
  opacity: 1;
}
.hce-image-gallery__slide:hover .hce-image-gallery__zoom-in {
  display: block;
  opacity: 1;
}
.hce-image-gallery__slide:hover img {
  outline: none;
}
.hce-image-gallery__slide img {
  max-height: 18.25rem;
  border: 0.0625rem solid #7f849d;
}
.hce-image-gallery__slide button {
  background-color: transparent;
  border: 0;
  padding: 0;
}
.hce-image-gallery__zoom-in {
  z-index: 1;
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  opacity: 0.75;
  width: 3.5rem;
  height: 3.5rem;
}
@media (min-width: 768px) {
  .hce-image-gallery__zoom-in {
    display: none;
  }
}
.hce-image-gallery__scroll-shadow {
  z-index: 1;
  opacity: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0.25rem;
  width: 5rem;
  background-image: linear-gradient(to right, transparent, rgba(0, 37, 84, 0.6));
  pointer-events: none;
  transition: opacity 0.5s ease-out;
}
.hce-image-gallery__scroll-shadow.is-hidden {
  opacity: 0;
}
.hce-image-gallery .image-gallery-overlay {
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 37, 84, 0.9);
}
.hce-image-gallery .image-gallery-overlay .swiper-container {
  width: 100%;
  height: 100%;
}
.hce-image-gallery .image-gallery-overlay__slide img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 60%;
}
.hce-image-gallery .image-gallery-overlay button {
  border: 0;
  background-color: transparent;
}
.hce-image-gallery .image-gallery-overlay__close {
  z-index: 10;
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 3.5rem;
  padding: 0;
  cursor: pointer;
}
.hce-image-gallery .icon {
  width: inherit;
  height: inherit;
  color: #ffffff;
  fill: #ffffff;
}
.hce-image-gallery .swiper-button-next,
.hce-image-gallery .swiper-button-prev {
  height: auto;
  margin: 0;
  transform: translateY(-50%);
  color: #ffffff;
}
.hce-image-gallery .swiper-button-next {
  right: 2rem;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .hce-image-gallery .swiper-button-next:after {
    content: "→";
    font-size: 2rem;
  }
}
.hce-image-gallery .swiper-button-prev {
  left: 2rem;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .hce-image-gallery .swiper-button-prev:after {
    content: "←";
    font-size: 2rem;
  }
}
.hce-image-gallery .swiper-pagination {
  bottom: 2.5rem;
}
.hce-image-gallery .swiper-pagination-bullet {
  background-color: #f2f2f5;
}

.no-scroll {
  overflow: hidden;
}

.hce-arrow-controls {
  z-index: 0;
  position: relative;
  display: inline-flex;
  padding: 0.625rem 0;
  border: 0.0625rem solid #bfc1ce;
  border-radius: 2.0625rem;
}
.hce-arrow-controls::before {
  content: "";
  position: relative;
  left: 50%;
  display: block;
  width: 0.0625rem;
  height: 1.625rem;
  background-color: #bfc1ce;
}
.hce-arrow-controls button {
  height: auto;
  padding: 0 1.25rem;
}
.hce-arrow-controls button:focus > span {
  background-color: #002554;
}
.hce-arrow-controls button:focus > span svg {
  color: #ffffff;
  fill: #ffffff;
}
.bg-dark .hce-arrow-controls button:focus > span {
  background-color: #ffffff;
}
.bg-dark .hce-arrow-controls button:focus > span svg {
  color: #002554;
  fill: #002554;
}

.hce-arrow-controls button[disabled] {
  opacity: 0.2;
}
.hce-arrow-controls button .icon {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
}
.hce-arrow-controls button .icon svg {
  width: 100%;
  height: 100%;
  color: #002554;
  fill: #002554;
}
.bg-dark .hce-arrow-controls button .icon svg {
  color: #ffffff;
  fill: #ffffff;
}

.hce-image-slider {
  position: relative;
}
.hce-image-slider__carousel {
  padding: 50px 20px;
}
@media (min-width: 576px) {
  .hce-image-slider__carousel {
    padding: 50px 12px;
  }
}
.hce-image-slider__carousel-inner {
  position: relative;
  /*&::after {
    @include media-breakpoint-up(md) {
      @include triangle(bottomright, 42px, 28px, #fff);
      .bg-medium & {
        @include triangle(bottomright, 42px, 28px, #f1f1f1);
      }
      .bg-dark & {
        @include triangle(bottomright, 42px, 28px, #00093b);
      }
    }

    position: absolute;
    bottom: -1px;
    right: -1px;
  }*/
}
.hce-image-slider > img {
  width: 100%;
}
@media (min-width: 768px) {
  .hce-image-slider > img {
    max-width: 470px;
    width: auto;
  }
}
.hce-image-slider .glide {
  /*&:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      to bottom,
      transparent 75%,
      rgba($primary, .6)
    );
    pointer-events: none;
    transition: opacity .2s;
  }

  &:hover:after {
    background-image: linear-gradient(
      to bottom,
      transparent 75%,
      rgba($primary, .8)
    );
  }*/
}
.hce-image-slider .glide__slides {
  align-items: flex-end;
}
.hce-image-slider .glide__slide-wrapper {
  padding-top: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.hce-image-slider .glide__slide img {
  position: absolute;
  left: -1000%;
  right: -1000%;
  top: -1000%;
  bottom: -1000%;
  margin: auto;
  min-height: 100%;
  min-width: 100%;
}
.hce-image-slider .glide__arrows {
  position: absolute;
  left: 0;
  bottom: 0;
}
.hce-image-slider .glide__arrow .icon svg {
  fill: #ffffff;
}
.hce-image-slider .glide__arrow--left, .hce-image-slider .glide__arrow--right {
  position: absolute;
  bottom: 1rem;
}
@media (min-width: 768px) {
  .hce-image-slider .glide__arrow--left, .hce-image-slider .glide__arrow--right {
    bottom: 1.6rem;
  }
}
.hce-image-slider .glide__arrow--left {
  left: 1rem;
}
@media (min-width: 768px) {
  .hce-image-slider .glide__arrow--left {
    left: 2rem;
  }
}
.hce-image-slider .glide__arrow--right {
  right: 1rem;
}
@media (min-width: 768px) {
  .hce-image-slider .glide__arrow--right {
    right: 2rem;
  }
}
.hce-image-slider .glide__bullets--wrapper {
  position: absolute;
  right: 0;
  top: -20px;
}
@media (min-width: 768px) {
  .hce-image-slider .glide__bullets--wrapper {
    top: auto;
    right: 35px;
    bottom: -30px;
  }
}
.hce-image-slider .glide__bullets .glide__bullet {
  display: none;
  cursor: default;
  background-color: transparent;
}
.hce-image-slider .glide__bullets .glide__bullet--active {
  display: block;
}

.hce-product-cards {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
  margin-bottom: -1rem;
  background-color: #f2f2f5;
}
.hce-product-cards__grid-introduction, .hce-product-cards__grid-introduction-cta-sm {
  display: none;
}
.hce-product-cards.is-grid .hce-product-cards__grid-introduction {
  display: inline-block;
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .hce-product-cards.is-grid .hce-product-cards__grid-introduction {
    display: flex;
    flex-flow: column;
  }
}
.hce-product-cards.is-grid .hce-product-cards__grid-introduction h3 {
  margin-top: -0.4375rem;
  margin-bottom: 0.75rem;
}
@media (min-width: 768px) {
  .hce-product-cards.is-grid .hce-product-cards__grid-introduction h3 {
    margin-top: 0;
    margin-bottom: 1.375rem;
  }
}
.hce-product-cards.is-grid .hce-product-cards__grid-introduction .lead {
  margin-bottom: 0.5625rem;
}
@media (min-width: 768px) {
  .hce-product-cards.is-grid .hce-product-cards__grid-introduction .lead {
    margin-bottom: 0;
  }
}
.hce-product-cards.is-grid .hce-product-cards__grid-introduction .btn {
  display: none;
}
@media (min-width: 768px) {
  .hce-product-cards.is-grid .hce-product-cards__grid-introduction .btn {
    display: inline-block;
    margin-top: auto;
  }
}
.hce-product-cards.is-grid .hce-product-cards__grid-introduction-cta-sm {
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .hce-product-cards.is-grid .hce-product-cards__grid-introduction-cta-sm {
    display: none;
  }
}
@media (min-width: 768px) {
  .hce-product-cards.is-grid .hce-product-card__title,
  .hce-product-cards.is-grid .hce-product-card__spec-label,
  .hce-product-cards.is-grid .hce-product-card__spec {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
.hce-product-cards.is-grid .hce-product-card__specs {
  margin-bottom: 0.5rem;
}
.hce-product-cards.is-grid .hce-product-card__specs .col-6.col-md-3,
.hce-product-cards.is-grid .hce-product-card__specs .col-6.col-md-2 {
  margin-bottom: 0.5rem;
}
.hce-product-cards.is-dark {
  background-color: #002554;
}
.hce-product-cards.is-dark .hce-product-cards__grid-introduction h3,
.hce-product-cards.is-dark .hce-product-cards__grid-introduction p.lead {
  color: #ffffff;
}
.hce-product-cards.is-dark .hce-product-cards__grid-introduction .btn,
.hce-product-cards.is-dark .hce-product-cards__grid-introduction-cta-sm .btn {
  border-color: #ffffff;
  color: #ffffff;
}
.hce-product-cards.is-dark .hce-product-card {
  border: 0;
}
.hce-product-cards.is-dark .hce-product-card > svg path:nth-child(1) {
  display: none;
}
.hce-product-cards.is-dark .hce-product-card > svg path:nth-child(2) {
  fill: #002554;
}
.hce-product-cards.is-dark .hce-product-card:focus {
  outline-color: #f2f2f5;
}

.hce-product-card {
  display: block;
  position: relative;
  border: 0.0625rem solid #e5e6eb;
  margin-bottom: 1rem;
  background-color: #ffffff;
  text-decoration: none;
}
.hce-product-card:focus {
  outline: 0.0625rem dotted #002554;
}
.hce-product-card:focus, .hce-product-card:hover {
  text-decoration: none;
}
.hce-product-card:focus .hce-product-card__title .h6, .hce-product-card:hover .hce-product-card__title .h6 {
  display: inline;
  background: linear-gradient(180deg, transparent 0, transparent 70%, #00ad1d 70%, #00ad1d 85%, transparent 85%, transparent 0);
}
.hce-product-card:focus .hce-product-card__cta .icon, .hce-product-card:hover .hce-product-card__cta .icon {
  right: 0rem;
  margin-left: 0.5rem;
}
.hce-product-card > svg {
  position: absolute;
  z-index: 1;
  right: -0.0625rem;
  bottom: -0.0625rem;
  width: 1.0625rem;
  height: 1.6875rem;
}
@media (min-width: 768px) {
  .hce-product-cards:not(.is-grid) .hce-product-card__content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}
.hce-product-card__content-wrapper .row:first-child, .hce-product-card__content-wrapper .hce-product-cards.is-grid .grid-row:first-child, .hce-product-cards.is-grid .hce-product-card__content-wrapper .grid-row:first-child {
  display: flex;
  align-items: center;
}
.hce-product-card__img-md img {
  height: 10.5rem;
}
@media (min-width: 768px) {
  .hce-product-card__title {
    margin-top: 0.9375rem;
  }
}
.hce-product-card__title .h6 {
  display: inline;
  background: linear-gradient(180deg, transparent 0, transparent 56%, rgba(0, 173, 29, 0.25) 56%, rgba(0, 173, 29, 0.25) 72%, transparent 72%, transparent 0);
  transition: 150ms all ease-in-out;
}
.hce-product-card__specs {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
@media (min-width: 768px) {
  .hce-product-card__specs {
    margin-top: 0.125rem;
    margin-bottom: 1rem;
  }
}
.hce-product-card__specs .col-6 {
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}
@media (min-width: 768px) {
  .hce-product-card__specs .col-6 {
    flex: 0 0 20%;
    max-width: 20%;
    margin-top: 0;
    margin-bottom: 0;
  }
  .hce-product-card__specs .col-6:nth-child(1) {
    flex: 0 0 30%;
    max-width: 30%;
  }
}
.hce-product-card__specs .col-6:nth-child(1) .hce-product-card__spec-label,
.hce-product-card__specs .col-6:nth-child(1) .hce-product-card__spec, .hce-product-card__specs .col-6:nth-child(3) .hce-product-card__spec-label,
.hce-product-card__specs .col-6:nth-child(3) .hce-product-card__spec {
  padding-left: 1rem;
}
.hce-product-card__specs .col-6:nth-child(2) .hce-product-card__spec-label,
.hce-product-card__specs .col-6:nth-child(2) .hce-product-card__spec, .hce-product-card__specs .col-6:nth-child(4) .hce-product-card__spec-label,
.hce-product-card__specs .col-6:nth-child(4) .hce-product-card__spec {
  padding-right: 1rem;
}
@media (min-width: 768px) {
  .hce-product-card__specs .col-6 {
    padding-right: 0;
    padding-left: 0;
  }
}
.hce-product-card__spec-label {
  width: 100%;
  color: #003087;
  cursor: pointer;
  font-size: 0.75rem;
  letter-spacing: 0.125rem;
}
.hce-product-card__spec {
  display: block;
  color: #003087;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1rem;
}
@media (min-width: 768px) {
  .hce-product-cards:not(.is-grid) .hce-product-card__spec {
    font-size: 1rem;
  }
}
.hce-product-card__cta {
  padding: 9px 0;
  margin: 0 1rem;
  border-top: 0.0625rem solid #e5e6eb;
}
.hce-product-card__cta .icon {
  transition: 150ms all ease-in-out;
}
.hce-product-card__cta svg {
  fill: #00ad1d;
}
@media (min-width: 768px) {
  .hce-product-cards:not(.is-grid) .hce-product-card:after {
    content: "";
    position: absolute;
    top: 0.625rem;
    right: 6%;
    width: 0.0625rem;
    height: calc(100% - 20px);
    background-color: #e5e6eb;
  }
}
@media (min-width: 768px) {
  .hce-product-cards:not(.is-grid) .hce-product-card__cta {
    position: absolute;
    right: 0.3125rem;
    bottom: 0.8125rem;
    width: 1.75rem;
    height: 1.75rem;
    padding: 0;
    border: none;
    margin: 0;
  }
}
@media (min-width: 992px) {
  .hce-product-cards:not(.is-grid) .hce-product-card__cta {
    right: 0.875rem;
  }
}
.hce-product-cards:not(.is-grid) .hce-product-card__cta svg {
  fill: #00ad1d;
}
.hce-product-cards:not(.is-grid) .hce-product-card__img-md img {
  min-height: 100%;
  height: 7.5rem;
}

.hce-product-specifications {
  padding-bottom: 2rem;
  background-color: #f2f2f5;
}
.hce-product-specifications__container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 61.5rem;
  padding-left: 1.25rem;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .hce-product-specifications__container {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
}
.hce-product-specifications__wrapper {
  flex: 1 1 auto;
  position: relative;
  top: -2.5rem;
  max-width: 100%;
  padding: 3.25rem 1.25rem 2.8125rem;
  border: 0.0625rem solid #e5e6eb;
  margin-bottom: -2.5rem;
  background-color: #ffffff;
  overflow: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
@media (min-width: 576px) {
  .hce-product-specifications__wrapper {
    padding-top: 0;
  }
}
@media (min-width: 768px) {
  .hce-product-specifications__wrapper {
    overflow: visible;
  }
}
.hce-product-specifications__wrapper .cut-corner {
  display: none;
  position: absolute;
  right: -0.0625rem;
  bottom: -0.0625rem;
  width: 0.9375rem;
  height: 1.5rem;
}
.hce-product-specifications__wrapper .cut-corner polygon {
  fill: #f2f2f5;
}
.hce-product-specifications__wrapper .cut-corner line {
  stroke: #e5e6eb;
}
@media (min-width: 768px) {
  .hce-product-specifications__wrapper .cut-corner {
    display: block;
  }
}
.hce-product-specifications__label {
  color: #7f849d;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.hce-product-specifications__label .icon,
.hce-product-specifications__label .icon svg {
  width: 1rem;
  height: 1rem;
  fill: #7f849d;
}
.hce-product-specifications__scroll-hint {
  opacity: 1;
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  transition: opacity 0.5s ease-out;
}
@media (min-width: 576px) {
  .hce-product-specifications__scroll-hint {
    display: none;
  }
}
.hce-product-specifications__scroll-hint.is-hidden {
  opacity: 0;
}
.hce-product-specifications__margin-wrapper {
  margin-top: 1.25rem;
  margin-bottom: 2rem;
}
.hce-product-specifications__footnote {
  margin-top: 1.25rem;
  white-space: nowrap;
}
.hce-product-specifications .hce-product-table {
  min-width: 30.5rem;
}

.product-specifications-anchor:before {
  content: "";
  display: block;
  position: relative;
  top: -13.25rem;
  height: 0.0625rem;
  margin-top: -0.0625rem;
}

.hce-product-table {
  width: 100%;
}
.hce-product-table thead tr {
  border-bottom: 0.0625rem solid #40476c;
}
.hce-product-table tr:nth-child(even) {
  background-color: #f2f2f5;
}
.hce-product-table th {
  padding: 1.25rem 1rem;
  color: #7f849d;
  font-weight: 700;
}
.hce-product-table td {
  padding: 0.75rem 1rem;
  color: #40476c;
  vertical-align: top;
}
.hce-product-table td:nth-child(1) {
  font-weight: 700;
  width: 35%;
}
.hce-product-table td:nth-child(2) {
  width: 65%;
}
@media (min-width: 768px) {
  .hce-product-table td:nth-child(1) {
    width: 58.3333333333%;
  }
  .hce-product-table td:nth-child(2) {
    width: 41.6666666667%;
  }
}

.hce-loader {
  width: 4.5rem;
  height: 4.5rem;
  position: relative;
}
@media (min-width: 768px) {
  .hce-loader {
    width: 5rem;
    height: 5rem;
  }
}
.hce-loader--small {
  width: 3.625rem;
  height: 3.625rem;
}
.hce-loader__circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.hce-loader__circle:before {
  content: "";
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100%;
  margin: 0 auto;
  background-color: #00ad1d;
  animation: fadeDelay 800ms infinite ease-in-out both, sizeDelay 800ms infinite ease-in-out both;
}
.hce-loader--small .hce-loader__circle:before {
  animation: fadeDelay 800ms infinite ease-in-out both, sizeDelaySmall 800ms infinite ease-in-out both;
}
.hce-loader__circle:nth-child(2) {
  transform: rotate(45deg);
}
.hce-loader__circle:nth-child(3) {
  transform: rotate(90deg);
}
.hce-loader__circle:nth-child(4) {
  transform: rotate(135deg);
}
.hce-loader__circle:nth-child(5) {
  transform: rotate(180deg);
}
.hce-loader__circle:nth-child(6) {
  transform: rotate(225deg);
}
.hce-loader__circle:nth-child(7) {
  transform: rotate(270deg);
}
.hce-loader__circle:nth-child(8) {
  transform: rotate(315deg);
}
.hce-loader__circle:nth-child(2):before {
  animation-delay: -700ms;
}
.hce-loader__circle:nth-child(3):before {
  animation-delay: -600ms;
}
.hce-loader__circle:nth-child(4):before {
  animation-delay: -500ms;
}
.hce-loader__circle:nth-child(5):before {
  animation-delay: -400ms;
}
.hce-loader__circle:nth-child(6):before {
  animation-delay: -300ms;
}
.hce-loader__circle:nth-child(7):before {
  animation-delay: -200ms;
}
.hce-loader__circle:nth-child(8):before {
  animation-delay: -100ms;
}

@keyframes fadeDelay {
  0%, 39%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
@keyframes sizeDelay {
  0%, 39%, 100% {
    width: 0.75rem;
    height: 0.75rem;
  }
  40% {
    width: 1.25rem;
    height: 1.25rem;
  }
}
@keyframes sizeDelaySmall {
  0%, 39%, 100% {
    width: 0.25rem;
    height: 0.25rem;
  }
  40% {
    width: 0.75rem;
    height: 0.75rem;
  }
}
.hce-toggle {
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .hce-toggle--large .hce-toggle__wrapper {
    width: 6rem;
    height: 3rem;
    padding: 0.3125rem;
  }
  .hce-toggle--large .hce-toggle__handle {
    width: 2.5rem;
    height: 2.5rem;
  }
  .hce-toggle--large .hce-toggle__checkbox:checked ~ .hce-toggle__wrapper .hce-toggle__handle {
    transform: translateX(2.875rem);
  }
  .hce-toggle--large .hce-toggle__label {
    font-size: 1.125rem;
  }
}
.hce-toggle__checkbox {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.hce-toggle__checkbox:checked ~ .hce-toggle__wrapper {
  background-image: linear-gradient(63deg, #67cd98, #02ab54);
}
.hce-toggle__checkbox:checked ~ .hce-toggle__wrapper .hce-toggle__handle {
  transform: translateX(1.875rem);
  box-shadow: -0.1875rem 0 0 0 rgba(0, 37, 84, 0.1);
}
.hce-toggle__wrapper {
  display: flex;
  align-items: center;
  width: 3.875rem;
  height: 2rem;
  padding: 0.25rem;
  border-radius: 2rem;
  background-image: linear-gradient(63deg, #faa519, #fccc7d);
}
.hce-toggle__handle {
  display: inline-flex;
  height: 1.625rem;
  width: 1.625rem;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0.1875rem 0 0 0 rgba(0, 37, 84, 0.1);
  transition: transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
}
.hce-toggle__label {
  color: #bfc1ce;
  font-size: 1rem;
}
.hce-toggle__label.is-highlighted {
  color: #ffffff;
  font-weight: 700;
}
.hce-toggle__label:first-child {
  margin-right: 0.75rem;
}
.hce-toggle__label:last-child {
  margin-left: 0.75rem;
}

.hce-video {
  position: relative;
  width: 100%;
  height: 29.6875rem;
}
.hce-video--small {
  height: auto;
  padding-top: 56.25%;
}
@media (min-width: 768px) {
  .hce-video {
    padding-top: 56.25%;
  }
}
.hce-video__player, .hce-video__placeholder, .hce-video__gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.hce-video__gradient {
  background-image: linear-gradient(to bottom, rgba(0, 37, 84, 0.2), rgba(0, 37, 84, 0.75));
}
.hce-video--small .hce-video__gradient {
  background-image: linear-gradient(to bottom, rgba(0, 37, 84, 0.2), rgba(0, 37, 84, 0.6));
}
.hce-video--disabled .hce-video__gradient {
  opacity: 0.75;
  background-color: #002554;
}
.hce-video__details {
  z-index: 2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  padding: 1.25rem;
}
@media (min-width: 768px) {
  .hce-video:not(.hce-video--small) .hce-video__details {
    width: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.hce-video__placeholder-image {
  height: 100%;
}
.hce-video .hce-loader {
  margin-bottom: 2rem;
}
.hce-video--small .hce-loader {
  margin-bottom: 0.5rem;
}

@media (min-width: 768px) {
  .hce-video--small .hce-loader {
    margin-bottom: 1rem;
  }
}
.hce-video__play-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.5rem;
  height: 4.5rem;
  border: 0.125rem solid #ffffff;
  border-radius: 50%;
  margin-bottom: 2rem;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.hce-video__play-button[disabled] {
  opacity: 0.5;
  border-width: 0.125rem;
  background-color: transparent;
  cursor: default;
}
.hce-video--small .hce-video__play-button {
  width: 4.5rem;
  height: 4.5rem;
  margin-bottom: 0.5rem;
}
.hce-video--small .hce-video__play-button[disabled] {
  width: 2.5rem;
  height: 2.5rem;
}
@media (min-width: 768px) {
  .hce-video__play-button {
    width: 5rem;
    height: 5rem;
  }
  .hce-video--small .hce-video__play-button {
    margin-bottom: 1rem;
    border-width: 0.125rem;
  }
  .hce-video--small .hce-video__play-button[disabled] {
    width: 3.625rem;
    height: 3.625rem;
  }
}
.hce-video__play-button-icon {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 85%;
  fill: #00ad1d;
  transition: fill 200ms;
}
.hce-video--small .hce-video__play-button[disabled] .hce-video__play-button-icon {
  height: 100%;
}
.hce-video__play-button:not([disabled]):hover .hce-video__play-button-icon {
  fill: #ffffff;
}
.hce-video__title, .hce-video__subtitle {
  width: 100%;
  color: #ffffff;
}
@media (max-width: 991.98px) {
  .hce-video__title {
    font-size: 1.5rem;
    font-weight: 700;
  }
}
@media (max-width: 1199.98px) {
  .hce-video--small .hce-video__title {
    margin-bottom: 0;
    font-size: 1.125rem;
    line-height: 1.166;
  }
}
@media (min-width: 992px) {
  .hce-video--small .hce-video__title {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.166;
  }
}
.hce-video__subtitle {
  margin-top: 0.5rem;
  font-weight: 300;
}
.hce-video--small .hce-video__subtitle {
  display: none;
}
@media (max-width: 991.98px) {
  .hce-video__subtitle {
    font-size: 1.125rem;
    font-weight: 400;
  }
}
@media (min-width: 768px) {
  .hce-video__subtitle {
    padding-right: 5rem;
    padding-left: 5rem;
  }
}
.hce-video .hce-toggle {
  margin-top: 1rem;
}
.hce-video--small .hce-toggle {
  margin-top: 0.5rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #002554 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #000f21 !important;
}

.bg-secondary, .hce-dealer-locator .opening-hours table thead {
  background-color: #00ad1d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #007a14 !important;
}

.bg-success {
  background-color: #00ad1d !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #007a14 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #fd7e14 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #dc6502 !important;
}

.bg-danger {
  background-color: #d0021b !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #9d0214 !important;
}

.bg-light {
  background-color: #c7c7c7 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #aeaeae !important;
}

.bg-dark {
  background-color: #666666 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #4d4d4d !important;
}

.bg-white {
  background-color: #ffffff !important;
}

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important;
}

.bg-black {
  background-color: #000000 !important;
}

a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: black !important;
}

.bg-primary-900 {
  background-color: #003087 !important;
}

a.bg-primary-900:hover, a.bg-primary-900:focus,
button.bg-primary-900:hover,
button.bg-primary-900:focus {
  background-color: #001e54 !important;
}

.bg-primary-800 {
  background-color: #40476c !important;
}

a.bg-primary-800:hover, a.bg-primary-800:focus,
button.bg-primary-800:hover,
button.bg-primary-800:focus {
  background-color: #2d324c !important;
}

.bg-primary-700 {
  background-color: #63666a !important;
}

a.bg-primary-700:hover, a.bg-primary-700:focus,
button.bg-primary-700:hover,
button.bg-primary-700:focus {
  background-color: #4a4d50 !important;
}

.bg-primary-600 {
  background-color: #7f849d !important;
}

a.bg-primary-600:hover, a.bg-primary-600:focus,
button.bg-primary-600:hover,
button.bg-primary-600:focus {
  background-color: #656a84 !important;
}

.bg-primary-400 {
  background-color: #bfc1ce !important;
}

a.bg-primary-400:hover, a.bg-primary-400:focus,
button.bg-primary-400:hover,
button.bg-primary-400:focus {
  background-color: #a2a5b8 !important;
}

.bg-primary-200 {
  background-color: #e5e6eb !important;
}

a.bg-primary-200:hover, a.bg-primary-200:focus,
button.bg-primary-200:hover,
button.bg-primary-200:focus {
  background-color: #c8cad5 !important;
}

.bg-primary-100 {
  background-color: #f2f2f5 !important;
}

a.bg-primary-100:hover, a.bg-primary-100:focus,
button.bg-primary-100:hover,
button.bg-primary-100:focus {
  background-color: #d5d5df !important;
}

.bg-secondary-800 {
  background-color: #02ab54 !important;
}

a.bg-secondary-800:hover, a.bg-secondary-800:focus,
button.bg-secondary-800:hover,
button.bg-secondary-800:focus {
  background-color: #01793b !important;
}

.bg-secondary-600 {
  background-color: #67cd98 !important;
}

a.bg-secondary-600:hover, a.bg-secondary-600:focus,
button.bg-secondary-600:hover,
button.bg-secondary-600:focus {
  background-color: #41c07e !important;
}

.bg-secondary-400 {
  background-color: #7aff90 !important;
}

a.bg-secondary-400:hover, a.bg-secondary-400:focus,
button.bg-secondary-400:hover,
button.bg-secondary-400:focus {
  background-color: #47ff66 !important;
}

.bg-secondary-200 {
  background-color: #adffbb !important;
}

a.bg-secondary-200:hover, a.bg-secondary-200:focus,
button.bg-secondary-200:hover,
button.bg-secondary-200:focus {
  background-color: #7aff90 !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #e0e0e0 !important;
}

.border-top {
  border-top: 1px solid #e0e0e0 !important;
}

.border-right {
  border-right: 1px solid #e0e0e0 !important;
}

.border-bottom {
  border-bottom: 1px solid #e0e0e0 !important;
}

.border-left {
  border-left: 1px solid #e0e0e0 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #002554 !important;
}

.border-secondary {
  border-color: #00ad1d !important;
}

.border-success {
  border-color: #00ad1d !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #fd7e14 !important;
}

.border-danger {
  border-color: #d0021b !important;
}

.border-light {
  border-color: #c7c7c7 !important;
}

.border-dark {
  border-color: #666666 !important;
}

.border-white {
  border-color: #ffffff !important;
}

.border-black {
  border-color: #000000 !important;
}

.border-primary-900 {
  border-color: #003087 !important;
}

.border-primary-800 {
  border-color: #40476c !important;
}

.border-primary-700 {
  border-color: #63666a !important;
}

.border-primary-600 {
  border-color: #7f849d !important;
}

.border-primary-400 {
  border-color: #bfc1ce !important;
}

.border-primary-200 {
  border-color: #e5e6eb !important;
}

.border-primary-100 {
  border-color: #f2f2f5 !important;
}

.border-secondary-800 {
  border-color: #02ab54 !important;
}

.border-secondary-600 {
  border-color: #67cd98 !important;
}

.border-secondary-400 {
  border-color: #7aff90 !important;
}

.border-secondary-200 {
  border-color: #adffbb !important;
}

.border-white {
  border-color: #ffffff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0 !important;
}

.rounded-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 0.75rem !important;
}

.mt-3,
.my-3 {
  margin-top: 0.75rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 0.75rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 0.75rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 0.75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1rem !important;
}

.m-5 {
  margin: 1.25rem !important;
}

.mt-5,
.my-5 {
  margin-top: 1.25rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 1.25rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 1.25rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 1.25rem !important;
}

.m-6 {
  margin: 1.5rem !important;
}

.mt-6,
.my-6 {
  margin-top: 1.5rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 1.5rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 1.5rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 1.5rem !important;
}

.m-7 {
  margin: 1.75rem !important;
}

.mt-7,
.my-7 {
  margin-top: 1.75rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 1.75rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 1.75rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 1.75rem !important;
}

.m-8 {
  margin: 2rem !important;
}

.mt-8,
.my-8 {
  margin-top: 2rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 2rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 2rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 2rem !important;
}

.m-9 {
  margin: 2.25rem !important;
}

.mt-9,
.my-9 {
  margin-top: 2.25rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 2.25rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 2.25rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 2.25rem !important;
}

.m-10 {
  margin: 2.5rem !important;
}

.mt-10,
.my-10 {
  margin-top: 2.5rem !important;
}

.mr-10,
.mx-10 {
  margin-right: 2.5rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 2.5rem !important;
}

.ml-10,
.mx-10 {
  margin-left: 2.5rem !important;
}

.m-11 {
  margin: 2.75rem !important;
}

.mt-11,
.my-11 {
  margin-top: 2.75rem !important;
}

.mr-11,
.mx-11 {
  margin-right: 2.75rem !important;
}

.mb-11,
.my-11 {
  margin-bottom: 2.75rem !important;
}

.ml-11,
.mx-11 {
  margin-left: 2.75rem !important;
}

.m-12 {
  margin: 3rem !important;
}

.mt-12,
.my-12 {
  margin-top: 3rem !important;
}

.mr-12,
.mx-12 {
  margin-right: 3rem !important;
}

.mb-12,
.my-12 {
  margin-bottom: 3rem !important;
}

.ml-12,
.mx-12 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.pt-3,
.py-3 {
  padding-top: 0.75rem !important;
}

.pr-3,
.px-3 {
  padding-right: 0.75rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 0.75rem !important;
}

.pl-3,
.px-3 {
  padding-left: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.pt-5,
.py-5 {
  padding-top: 1.25rem !important;
}

.pr-5,
.px-5 {
  padding-right: 1.25rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 1.25rem !important;
}

.pl-5,
.px-5 {
  padding-left: 1.25rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.pt-6,
.py-6 {
  padding-top: 1.5rem !important;
}

.pr-6,
.px-6 {
  padding-right: 1.5rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 1.5rem !important;
}

.pl-6,
.px-6 {
  padding-left: 1.5rem !important;
}

.p-7 {
  padding: 1.75rem !important;
}

.pt-7,
.py-7 {
  padding-top: 1.75rem !important;
}

.pr-7,
.px-7 {
  padding-right: 1.75rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 1.75rem !important;
}

.pl-7,
.px-7 {
  padding-left: 1.75rem !important;
}

.p-8 {
  padding: 2rem !important;
}

.pt-8,
.py-8 {
  padding-top: 2rem !important;
}

.pr-8,
.px-8 {
  padding-right: 2rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 2rem !important;
}

.pl-8,
.px-8 {
  padding-left: 2rem !important;
}

.p-9 {
  padding: 2.25rem !important;
}

.pt-9,
.py-9 {
  padding-top: 2.25rem !important;
}

.pr-9,
.px-9 {
  padding-right: 2.25rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 2.25rem !important;
}

.pl-9,
.px-9 {
  padding-left: 2.25rem !important;
}

.p-10 {
  padding: 2.5rem !important;
}

.pt-10,
.py-10 {
  padding-top: 2.5rem !important;
}

.pr-10,
.px-10 {
  padding-right: 2.5rem !important;
}

.pb-10,
.py-10 {
  padding-bottom: 2.5rem !important;
}

.pl-10,
.px-10 {
  padding-left: 2.5rem !important;
}

.p-11 {
  padding: 2.75rem !important;
}

.pt-11,
.py-11 {
  padding-top: 2.75rem !important;
}

.pr-11,
.px-11 {
  padding-right: 2.75rem !important;
}

.pb-11,
.py-11 {
  padding-bottom: 2.75rem !important;
}

.pl-11,
.px-11 {
  padding-left: 2.75rem !important;
}

.p-12 {
  padding: 3rem !important;
}

.pt-12,
.py-12 {
  padding-top: 3rem !important;
}

.pr-12,
.px-12 {
  padding-right: 3rem !important;
}

.pb-12,
.py-12 {
  padding-bottom: 3rem !important;
}

.pl-12,
.px-12 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -0.75rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -0.75rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -0.75rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -0.75rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -0.75rem !important;
}

.m-n4 {
  margin: -1rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1rem !important;
}

.m-n5 {
  margin: -1.25rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -1.25rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -1.25rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -1.25rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -1.25rem !important;
}

.m-n6 {
  margin: -1.5rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -1.5rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -1.5rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -1.5rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -1.5rem !important;
}

.m-n7 {
  margin: -1.75rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -1.75rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -1.75rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -1.75rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -1.75rem !important;
}

.m-n8 {
  margin: -2rem !important;
}

.mt-n8,
.my-n8 {
  margin-top: -2rem !important;
}

.mr-n8,
.mx-n8 {
  margin-right: -2rem !important;
}

.mb-n8,
.my-n8 {
  margin-bottom: -2rem !important;
}

.ml-n8,
.mx-n8 {
  margin-left: -2rem !important;
}

.m-n9 {
  margin: -2.25rem !important;
}

.mt-n9,
.my-n9 {
  margin-top: -2.25rem !important;
}

.mr-n9,
.mx-n9 {
  margin-right: -2.25rem !important;
}

.mb-n9,
.my-n9 {
  margin-bottom: -2.25rem !important;
}

.ml-n9,
.mx-n9 {
  margin-left: -2.25rem !important;
}

.m-n10 {
  margin: -2.5rem !important;
}

.mt-n10,
.my-n10 {
  margin-top: -2.5rem !important;
}

.mr-n10,
.mx-n10 {
  margin-right: -2.5rem !important;
}

.mb-n10,
.my-n10 {
  margin-bottom: -2.5rem !important;
}

.ml-n10,
.mx-n10 {
  margin-left: -2.5rem !important;
}

.m-n11 {
  margin: -2.75rem !important;
}

.mt-n11,
.my-n11 {
  margin-top: -2.75rem !important;
}

.mr-n11,
.mx-n11 {
  margin-right: -2.75rem !important;
}

.mb-n11,
.my-n11 {
  margin-bottom: -2.75rem !important;
}

.ml-n11,
.mx-n11 {
  margin-left: -2.75rem !important;
}

.m-n12 {
  margin: -3rem !important;
}

.mt-n12,
.my-n12 {
  margin-top: -3rem !important;
}

.mr-n12,
.mx-n12 {
  margin-right: -3rem !important;
}

.mb-n12,
.my-n12 {
  margin-bottom: -3rem !important;
}

.ml-n12,
.mx-n12 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 0.75rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 0.75rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 0.75rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 0.75rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 0.75rem !important;
  }
  .m-sm-4 {
    margin: 1rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1rem !important;
  }
  .m-sm-5 {
    margin: 1.25rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 1.25rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 1.25rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 1.25rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 1.25rem !important;
  }
  .m-sm-6 {
    margin: 1.5rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 1.5rem !important;
  }
  .m-sm-7 {
    margin: 1.75rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 1.75rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 1.75rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 1.75rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 1.75rem !important;
  }
  .m-sm-8 {
    margin: 2rem !important;
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 2rem !important;
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 2rem !important;
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 2rem !important;
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 2rem !important;
  }
  .m-sm-9 {
    margin: 2.25rem !important;
  }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 2.25rem !important;
  }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 2.25rem !important;
  }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 2.25rem !important;
  }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 2.25rem !important;
  }
  .m-sm-10 {
    margin: 2.5rem !important;
  }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 2.5rem !important;
  }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 2.5rem !important;
  }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 2.5rem !important;
  }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 2.5rem !important;
  }
  .m-sm-11 {
    margin: 2.75rem !important;
  }
  .mt-sm-11,
  .my-sm-11 {
    margin-top: 2.75rem !important;
  }
  .mr-sm-11,
  .mx-sm-11 {
    margin-right: 2.75rem !important;
  }
  .mb-sm-11,
  .my-sm-11 {
    margin-bottom: 2.75rem !important;
  }
  .ml-sm-11,
  .mx-sm-11 {
    margin-left: 2.75rem !important;
  }
  .m-sm-12 {
    margin: 3rem !important;
  }
  .mt-sm-12,
  .my-sm-12 {
    margin-top: 3rem !important;
  }
  .mr-sm-12,
  .mx-sm-12 {
    margin-right: 3rem !important;
  }
  .mb-sm-12,
  .my-sm-12 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-12,
  .mx-sm-12 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 0.75rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 0.75rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 0.75rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 0.75rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 0.75rem !important;
  }
  .p-sm-4 {
    padding: 1rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1rem !important;
  }
  .p-sm-5 {
    padding: 1.25rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 1.25rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 1.25rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 1.25rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 1.25rem !important;
  }
  .p-sm-6 {
    padding: 1.5rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 1.5rem !important;
  }
  .p-sm-7 {
    padding: 1.75rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 1.75rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 1.75rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 1.75rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 1.75rem !important;
  }
  .p-sm-8 {
    padding: 2rem !important;
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 2rem !important;
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 2rem !important;
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 2rem !important;
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 2rem !important;
  }
  .p-sm-9 {
    padding: 2.25rem !important;
  }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 2.25rem !important;
  }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 2.25rem !important;
  }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 2.25rem !important;
  }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 2.25rem !important;
  }
  .p-sm-10 {
    padding: 2.5rem !important;
  }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 2.5rem !important;
  }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 2.5rem !important;
  }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 2.5rem !important;
  }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 2.5rem !important;
  }
  .p-sm-11 {
    padding: 2.75rem !important;
  }
  .pt-sm-11,
  .py-sm-11 {
    padding-top: 2.75rem !important;
  }
  .pr-sm-11,
  .px-sm-11 {
    padding-right: 2.75rem !important;
  }
  .pb-sm-11,
  .py-sm-11 {
    padding-bottom: 2.75rem !important;
  }
  .pl-sm-11,
  .px-sm-11 {
    padding-left: 2.75rem !important;
  }
  .p-sm-12 {
    padding: 3rem !important;
  }
  .pt-sm-12,
  .py-sm-12 {
    padding-top: 3rem !important;
  }
  .pr-sm-12,
  .px-sm-12 {
    padding-right: 3rem !important;
  }
  .pb-sm-12,
  .py-sm-12 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-12,
  .px-sm-12 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -0.75rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -0.75rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -0.75rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -0.75rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -0.75rem !important;
  }
  .m-sm-n4 {
    margin: -1rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1rem !important;
  }
  .m-sm-n5 {
    margin: -1.25rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -1.25rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -1.25rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -1.25rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -1.25rem !important;
  }
  .m-sm-n6 {
    margin: -1.5rem !important;
  }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n7 {
    margin: -1.75rem !important;
  }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -1.75rem !important;
  }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -1.75rem !important;
  }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -1.75rem !important;
  }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -1.75rem !important;
  }
  .m-sm-n8 {
    margin: -2rem !important;
  }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -2rem !important;
  }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -2rem !important;
  }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -2rem !important;
  }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -2rem !important;
  }
  .m-sm-n9 {
    margin: -2.25rem !important;
  }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -2.25rem !important;
  }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -2.25rem !important;
  }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -2.25rem !important;
  }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -2.25rem !important;
  }
  .m-sm-n10 {
    margin: -2.5rem !important;
  }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -2.5rem !important;
  }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -2.5rem !important;
  }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -2.5rem !important;
  }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -2.5rem !important;
  }
  .m-sm-n11 {
    margin: -2.75rem !important;
  }
  .mt-sm-n11,
  .my-sm-n11 {
    margin-top: -2.75rem !important;
  }
  .mr-sm-n11,
  .mx-sm-n11 {
    margin-right: -2.75rem !important;
  }
  .mb-sm-n11,
  .my-sm-n11 {
    margin-bottom: -2.75rem !important;
  }
  .ml-sm-n11,
  .mx-sm-n11 {
    margin-left: -2.75rem !important;
  }
  .m-sm-n12 {
    margin: -3rem !important;
  }
  .mt-sm-n12,
  .my-sm-n12 {
    margin-top: -3rem !important;
  }
  .mr-sm-n12,
  .mx-sm-n12 {
    margin-right: -3rem !important;
  }
  .mb-sm-n12,
  .my-sm-n12 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n12,
  .mx-sm-n12 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 0.75rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 0.75rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 0.75rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 0.75rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 0.75rem !important;
  }
  .m-md-4 {
    margin: 1rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1rem !important;
  }
  .m-md-5 {
    margin: 1.25rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 1.25rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 1.25rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 1.25rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 1.25rem !important;
  }
  .m-md-6 {
    margin: 1.5rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 1.5rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 1.5rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 1.5rem !important;
  }
  .m-md-7 {
    margin: 1.75rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 1.75rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 1.75rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 1.75rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 1.75rem !important;
  }
  .m-md-8 {
    margin: 2rem !important;
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 2rem !important;
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 2rem !important;
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 2rem !important;
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 2rem !important;
  }
  .m-md-9 {
    margin: 2.25rem !important;
  }
  .mt-md-9,
  .my-md-9 {
    margin-top: 2.25rem !important;
  }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 2.25rem !important;
  }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 2.25rem !important;
  }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 2.25rem !important;
  }
  .m-md-10 {
    margin: 2.5rem !important;
  }
  .mt-md-10,
  .my-md-10 {
    margin-top: 2.5rem !important;
  }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 2.5rem !important;
  }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 2.5rem !important;
  }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 2.5rem !important;
  }
  .m-md-11 {
    margin: 2.75rem !important;
  }
  .mt-md-11,
  .my-md-11 {
    margin-top: 2.75rem !important;
  }
  .mr-md-11,
  .mx-md-11 {
    margin-right: 2.75rem !important;
  }
  .mb-md-11,
  .my-md-11 {
    margin-bottom: 2.75rem !important;
  }
  .ml-md-11,
  .mx-md-11 {
    margin-left: 2.75rem !important;
  }
  .m-md-12 {
    margin: 3rem !important;
  }
  .mt-md-12,
  .my-md-12 {
    margin-top: 3rem !important;
  }
  .mr-md-12,
  .mx-md-12 {
    margin-right: 3rem !important;
  }
  .mb-md-12,
  .my-md-12 {
    margin-bottom: 3rem !important;
  }
  .ml-md-12,
  .mx-md-12 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 0.75rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 0.75rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 0.75rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 0.75rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 0.75rem !important;
  }
  .p-md-4 {
    padding: 1rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1rem !important;
  }
  .p-md-5 {
    padding: 1.25rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 1.25rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 1.25rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 1.25rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 1.25rem !important;
  }
  .p-md-6 {
    padding: 1.5rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 1.5rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 1.5rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 1.5rem !important;
  }
  .p-md-7 {
    padding: 1.75rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 1.75rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 1.75rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 1.75rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 1.75rem !important;
  }
  .p-md-8 {
    padding: 2rem !important;
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 2rem !important;
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 2rem !important;
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 2rem !important;
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 2rem !important;
  }
  .p-md-9 {
    padding: 2.25rem !important;
  }
  .pt-md-9,
  .py-md-9 {
    padding-top: 2.25rem !important;
  }
  .pr-md-9,
  .px-md-9 {
    padding-right: 2.25rem !important;
  }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 2.25rem !important;
  }
  .pl-md-9,
  .px-md-9 {
    padding-left: 2.25rem !important;
  }
  .p-md-10 {
    padding: 2.5rem !important;
  }
  .pt-md-10,
  .py-md-10 {
    padding-top: 2.5rem !important;
  }
  .pr-md-10,
  .px-md-10 {
    padding-right: 2.5rem !important;
  }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 2.5rem !important;
  }
  .pl-md-10,
  .px-md-10 {
    padding-left: 2.5rem !important;
  }
  .p-md-11 {
    padding: 2.75rem !important;
  }
  .pt-md-11,
  .py-md-11 {
    padding-top: 2.75rem !important;
  }
  .pr-md-11,
  .px-md-11 {
    padding-right: 2.75rem !important;
  }
  .pb-md-11,
  .py-md-11 {
    padding-bottom: 2.75rem !important;
  }
  .pl-md-11,
  .px-md-11 {
    padding-left: 2.75rem !important;
  }
  .p-md-12 {
    padding: 3rem !important;
  }
  .pt-md-12,
  .py-md-12 {
    padding-top: 3rem !important;
  }
  .pr-md-12,
  .px-md-12 {
    padding-right: 3rem !important;
  }
  .pb-md-12,
  .py-md-12 {
    padding-bottom: 3rem !important;
  }
  .pl-md-12,
  .px-md-12 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -0.75rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -0.75rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -0.75rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -0.75rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -0.75rem !important;
  }
  .m-md-n4 {
    margin: -1rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1rem !important;
  }
  .m-md-n5 {
    margin: -1.25rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -1.25rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -1.25rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -1.25rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -1.25rem !important;
  }
  .m-md-n6 {
    margin: -1.5rem !important;
  }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -1.5rem !important;
  }
  .m-md-n7 {
    margin: -1.75rem !important;
  }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -1.75rem !important;
  }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -1.75rem !important;
  }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -1.75rem !important;
  }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -1.75rem !important;
  }
  .m-md-n8 {
    margin: -2rem !important;
  }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -2rem !important;
  }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -2rem !important;
  }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -2rem !important;
  }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -2rem !important;
  }
  .m-md-n9 {
    margin: -2.25rem !important;
  }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -2.25rem !important;
  }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -2.25rem !important;
  }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -2.25rem !important;
  }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -2.25rem !important;
  }
  .m-md-n10 {
    margin: -2.5rem !important;
  }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -2.5rem !important;
  }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -2.5rem !important;
  }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -2.5rem !important;
  }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -2.5rem !important;
  }
  .m-md-n11 {
    margin: -2.75rem !important;
  }
  .mt-md-n11,
  .my-md-n11 {
    margin-top: -2.75rem !important;
  }
  .mr-md-n11,
  .mx-md-n11 {
    margin-right: -2.75rem !important;
  }
  .mb-md-n11,
  .my-md-n11 {
    margin-bottom: -2.75rem !important;
  }
  .ml-md-n11,
  .mx-md-n11 {
    margin-left: -2.75rem !important;
  }
  .m-md-n12 {
    margin: -3rem !important;
  }
  .mt-md-n12,
  .my-md-n12 {
    margin-top: -3rem !important;
  }
  .mr-md-n12,
  .mx-md-n12 {
    margin-right: -3rem !important;
  }
  .mb-md-n12,
  .my-md-n12 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n12,
  .mx-md-n12 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 0.75rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 0.75rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 0.75rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 0.75rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 0.75rem !important;
  }
  .m-lg-4 {
    margin: 1rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1rem !important;
  }
  .m-lg-5 {
    margin: 1.25rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 1.25rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 1.25rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 1.25rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 1.25rem !important;
  }
  .m-lg-6 {
    margin: 1.5rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 1.5rem !important;
  }
  .m-lg-7 {
    margin: 1.75rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 1.75rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 1.75rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 1.75rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 1.75rem !important;
  }
  .m-lg-8 {
    margin: 2rem !important;
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 2rem !important;
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 2rem !important;
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 2rem !important;
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 2rem !important;
  }
  .m-lg-9 {
    margin: 2.25rem !important;
  }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 2.25rem !important;
  }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 2.25rem !important;
  }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 2.25rem !important;
  }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 2.25rem !important;
  }
  .m-lg-10 {
    margin: 2.5rem !important;
  }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 2.5rem !important;
  }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 2.5rem !important;
  }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 2.5rem !important;
  }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 2.5rem !important;
  }
  .m-lg-11 {
    margin: 2.75rem !important;
  }
  .mt-lg-11,
  .my-lg-11 {
    margin-top: 2.75rem !important;
  }
  .mr-lg-11,
  .mx-lg-11 {
    margin-right: 2.75rem !important;
  }
  .mb-lg-11,
  .my-lg-11 {
    margin-bottom: 2.75rem !important;
  }
  .ml-lg-11,
  .mx-lg-11 {
    margin-left: 2.75rem !important;
  }
  .m-lg-12 {
    margin: 3rem !important;
  }
  .mt-lg-12,
  .my-lg-12 {
    margin-top: 3rem !important;
  }
  .mr-lg-12,
  .mx-lg-12 {
    margin-right: 3rem !important;
  }
  .mb-lg-12,
  .my-lg-12 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-12,
  .mx-lg-12 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 0.75rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 0.75rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 0.75rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 0.75rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 0.75rem !important;
  }
  .p-lg-4 {
    padding: 1rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1rem !important;
  }
  .p-lg-5 {
    padding: 1.25rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 1.25rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 1.25rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 1.25rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 1.25rem !important;
  }
  .p-lg-6 {
    padding: 1.5rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 1.5rem !important;
  }
  .p-lg-7 {
    padding: 1.75rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 1.75rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 1.75rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 1.75rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 1.75rem !important;
  }
  .p-lg-8 {
    padding: 2rem !important;
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 2rem !important;
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 2rem !important;
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 2rem !important;
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 2rem !important;
  }
  .p-lg-9 {
    padding: 2.25rem !important;
  }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 2.25rem !important;
  }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 2.25rem !important;
  }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 2.25rem !important;
  }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 2.25rem !important;
  }
  .p-lg-10 {
    padding: 2.5rem !important;
  }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 2.5rem !important;
  }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 2.5rem !important;
  }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 2.5rem !important;
  }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 2.5rem !important;
  }
  .p-lg-11 {
    padding: 2.75rem !important;
  }
  .pt-lg-11,
  .py-lg-11 {
    padding-top: 2.75rem !important;
  }
  .pr-lg-11,
  .px-lg-11 {
    padding-right: 2.75rem !important;
  }
  .pb-lg-11,
  .py-lg-11 {
    padding-bottom: 2.75rem !important;
  }
  .pl-lg-11,
  .px-lg-11 {
    padding-left: 2.75rem !important;
  }
  .p-lg-12 {
    padding: 3rem !important;
  }
  .pt-lg-12,
  .py-lg-12 {
    padding-top: 3rem !important;
  }
  .pr-lg-12,
  .px-lg-12 {
    padding-right: 3rem !important;
  }
  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-12,
  .px-lg-12 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -0.75rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -0.75rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -0.75rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -0.75rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -0.75rem !important;
  }
  .m-lg-n4 {
    margin: -1rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1rem !important;
  }
  .m-lg-n5 {
    margin: -1.25rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -1.25rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -1.25rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -1.25rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -1.25rem !important;
  }
  .m-lg-n6 {
    margin: -1.5rem !important;
  }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n7 {
    margin: -1.75rem !important;
  }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -1.75rem !important;
  }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -1.75rem !important;
  }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -1.75rem !important;
  }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -1.75rem !important;
  }
  .m-lg-n8 {
    margin: -2rem !important;
  }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -2rem !important;
  }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -2rem !important;
  }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -2rem !important;
  }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -2rem !important;
  }
  .m-lg-n9 {
    margin: -2.25rem !important;
  }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -2.25rem !important;
  }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -2.25rem !important;
  }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -2.25rem !important;
  }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -2.25rem !important;
  }
  .m-lg-n10 {
    margin: -2.5rem !important;
  }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -2.5rem !important;
  }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -2.5rem !important;
  }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -2.5rem !important;
  }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -2.5rem !important;
  }
  .m-lg-n11 {
    margin: -2.75rem !important;
  }
  .mt-lg-n11,
  .my-lg-n11 {
    margin-top: -2.75rem !important;
  }
  .mr-lg-n11,
  .mx-lg-n11 {
    margin-right: -2.75rem !important;
  }
  .mb-lg-n11,
  .my-lg-n11 {
    margin-bottom: -2.75rem !important;
  }
  .ml-lg-n11,
  .mx-lg-n11 {
    margin-left: -2.75rem !important;
  }
  .m-lg-n12 {
    margin: -3rem !important;
  }
  .mt-lg-n12,
  .my-lg-n12 {
    margin-top: -3rem !important;
  }
  .mr-lg-n12,
  .mx-lg-n12 {
    margin-right: -3rem !important;
  }
  .mb-lg-n12,
  .my-lg-n12 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n12,
  .mx-lg-n12 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 0.75rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 0.75rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 0.75rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 0.75rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 0.75rem !important;
  }
  .m-xl-4 {
    margin: 1rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1rem !important;
  }
  .m-xl-5 {
    margin: 1.25rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 1.25rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 1.25rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 1.25rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 1.25rem !important;
  }
  .m-xl-6 {
    margin: 1.5rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 1.5rem !important;
  }
  .m-xl-7 {
    margin: 1.75rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 1.75rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 1.75rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 1.75rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 1.75rem !important;
  }
  .m-xl-8 {
    margin: 2rem !important;
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 2rem !important;
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 2rem !important;
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 2rem !important;
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 2rem !important;
  }
  .m-xl-9 {
    margin: 2.25rem !important;
  }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 2.25rem !important;
  }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 2.25rem !important;
  }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 2.25rem !important;
  }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 2.25rem !important;
  }
  .m-xl-10 {
    margin: 2.5rem !important;
  }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 2.5rem !important;
  }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 2.5rem !important;
  }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 2.5rem !important;
  }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 2.5rem !important;
  }
  .m-xl-11 {
    margin: 2.75rem !important;
  }
  .mt-xl-11,
  .my-xl-11 {
    margin-top: 2.75rem !important;
  }
  .mr-xl-11,
  .mx-xl-11 {
    margin-right: 2.75rem !important;
  }
  .mb-xl-11,
  .my-xl-11 {
    margin-bottom: 2.75rem !important;
  }
  .ml-xl-11,
  .mx-xl-11 {
    margin-left: 2.75rem !important;
  }
  .m-xl-12 {
    margin: 3rem !important;
  }
  .mt-xl-12,
  .my-xl-12 {
    margin-top: 3rem !important;
  }
  .mr-xl-12,
  .mx-xl-12 {
    margin-right: 3rem !important;
  }
  .mb-xl-12,
  .my-xl-12 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-12,
  .mx-xl-12 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 0.75rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 0.75rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 0.75rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 0.75rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 0.75rem !important;
  }
  .p-xl-4 {
    padding: 1rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1rem !important;
  }
  .p-xl-5 {
    padding: 1.25rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 1.25rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 1.25rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 1.25rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 1.25rem !important;
  }
  .p-xl-6 {
    padding: 1.5rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 1.5rem !important;
  }
  .p-xl-7 {
    padding: 1.75rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 1.75rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 1.75rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 1.75rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 1.75rem !important;
  }
  .p-xl-8 {
    padding: 2rem !important;
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 2rem !important;
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 2rem !important;
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 2rem !important;
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 2rem !important;
  }
  .p-xl-9 {
    padding: 2.25rem !important;
  }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 2.25rem !important;
  }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 2.25rem !important;
  }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 2.25rem !important;
  }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 2.25rem !important;
  }
  .p-xl-10 {
    padding: 2.5rem !important;
  }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 2.5rem !important;
  }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 2.5rem !important;
  }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 2.5rem !important;
  }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 2.5rem !important;
  }
  .p-xl-11 {
    padding: 2.75rem !important;
  }
  .pt-xl-11,
  .py-xl-11 {
    padding-top: 2.75rem !important;
  }
  .pr-xl-11,
  .px-xl-11 {
    padding-right: 2.75rem !important;
  }
  .pb-xl-11,
  .py-xl-11 {
    padding-bottom: 2.75rem !important;
  }
  .pl-xl-11,
  .px-xl-11 {
    padding-left: 2.75rem !important;
  }
  .p-xl-12 {
    padding: 3rem !important;
  }
  .pt-xl-12,
  .py-xl-12 {
    padding-top: 3rem !important;
  }
  .pr-xl-12,
  .px-xl-12 {
    padding-right: 3rem !important;
  }
  .pb-xl-12,
  .py-xl-12 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-12,
  .px-xl-12 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -0.75rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -0.75rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -0.75rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -0.75rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -0.75rem !important;
  }
  .m-xl-n4 {
    margin: -1rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1rem !important;
  }
  .m-xl-n5 {
    margin: -1.25rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -1.25rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -1.25rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -1.25rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -1.25rem !important;
  }
  .m-xl-n6 {
    margin: -1.5rem !important;
  }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n7 {
    margin: -1.75rem !important;
  }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -1.75rem !important;
  }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -1.75rem !important;
  }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -1.75rem !important;
  }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -1.75rem !important;
  }
  .m-xl-n8 {
    margin: -2rem !important;
  }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -2rem !important;
  }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -2rem !important;
  }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -2rem !important;
  }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -2rem !important;
  }
  .m-xl-n9 {
    margin: -2.25rem !important;
  }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -2.25rem !important;
  }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -2.25rem !important;
  }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -2.25rem !important;
  }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -2.25rem !important;
  }
  .m-xl-n10 {
    margin: -2.5rem !important;
  }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -2.5rem !important;
  }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -2.5rem !important;
  }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -2.5rem !important;
  }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -2.5rem !important;
  }
  .m-xl-n11 {
    margin: -2.75rem !important;
  }
  .mt-xl-n11,
  .my-xl-n11 {
    margin-top: -2.75rem !important;
  }
  .mr-xl-n11,
  .mx-xl-n11 {
    margin-right: -2.75rem !important;
  }
  .mb-xl-n11,
  .my-xl-n11 {
    margin-bottom: -2.75rem !important;
  }
  .ml-xl-n11,
  .mx-xl-n11 {
    margin-left: -2.75rem !important;
  }
  .m-xl-n12 {
    margin: -3rem !important;
  }
  .mt-xl-n12,
  .my-xl-n12 {
    margin-top: -3rem !important;
  }
  .mr-xl-n12,
  .mx-xl-n12 {
    margin-right: -3rem !important;
  }
  .mb-xl-n12,
  .my-xl-n12 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n12,
  .mx-xl-n12 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white, .hce-dealer-locator .opening-hours table thead {
  color: #ffffff !important;
}

.text-primary {
  color: #002554 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #000308 !important;
}

.text-secondary {
  color: #00ad1d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #006110 !important;
}

.text-success {
  color: #00ad1d !important;
}

a.text-success:hover, a.text-success:focus {
  color: #006110 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #fd7e14 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #c35a02 !important;
}

.text-danger {
  color: #d0021b !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #840111 !important;
}

.text-light {
  color: #c7c7c7 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #a1a1a1 !important;
}

.text-dark {
  color: #666666 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #404040 !important;
}

.text-white, .hce-dealer-locator .opening-hours table thead {
  color: #ffffff !important;
}

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important;
}

.text-black {
  color: #000000 !important;
}

a.text-black:hover, a.text-black:focus {
  color: black !important;
}

.text-primary-900 {
  color: #003087 !important;
}

a.text-primary-900:hover, a.text-primary-900:focus {
  color: #00153b !important;
}

.text-primary-800 {
  color: #40476c !important;
}

a.text-primary-800:hover, a.text-primary-800:focus {
  color: #24273c !important;
}

.text-primary-700 {
  color: #63666a !important;
}

a.text-primary-700:hover, a.text-primary-700:focus {
  color: #3e4042 !important;
}

.text-primary-600 {
  color: #7f849d !important;
}

a.text-primary-600:hover, a.text-primary-600:focus {
  color: #5a5f76 !important;
}

.text-primary-400 {
  color: #bfc1ce !important;
}

a.text-primary-400:hover, a.text-primary-400:focus {
  color: #9497ad !important;
}

.text-primary-200 {
  color: #e5e6eb !important;
}

a.text-primary-200:hover, a.text-primary-200:focus {
  color: #babcca !important;
}

.text-primary-100 {
  color: #f2f2f5 !important;
}

a.text-primary-100:hover, a.text-primary-100:focus {
  color: #c7c7d4 !important;
}

.text-secondary-800 {
  color: #02ab54 !important;
}

a.text-secondary-800:hover, a.text-secondary-800:focus {
  color: #015f2f !important;
}

.text-secondary-600 {
  color: #67cd98 !important;
}

a.text-secondary-600:hover, a.text-secondary-600:focus {
  color: #39ae71 !important;
}

.text-secondary-400 {
  color: #7aff90 !important;
}

a.text-secondary-400:hover, a.text-secondary-400:focus {
  color: #2eff51 !important;
}

.text-secondary-200 {
  color: #adffbb !important;
}

a.text-secondary-200:hover, a.text-secondary-200:focus {
  color: #61ff7b !important;
}

.text-body {
  color: #333333 !important;
}

.text-muted {
  color: #666666 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #a2a2a2;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000000;
  }
  .table, .hce-dealer-locator .opening-hours table {
    border-collapse: collapse !important;
  }
  .table td, .hce-dealer-locator .opening-hours table td,
  .table th,
  .hce-dealer-locator .opening-hours table th {
    background-color: #ffffff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e0e0e0 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #e0e0e0;
  }
  .table .thead-dark th, .hce-dealer-locator .opening-hours table .thead-dark th {
    color: inherit;
    border-color: #e0e0e0;
  }
}
.bg-light {
  background-color: #ffffff !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #e6e6e6 !important;
}

.bg-medium {
  background-color: #f1f1f1 !important;
}

a.bg-medium:hover, a.bg-medium:focus,
button.bg-medium:hover,
button.bg-medium:focus {
  background-color: #d8d8d8 !important;
}

.bg-dark {
  background-color: #002554 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #000f21 !important;
}

.bg-secondary, .hce-dealer-locator .opening-hours table thead {
  background-color: #00ad1d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #007a14 !important;
}

.bg-gray-100 {
  background-color: #f1f1f1 !important;
}

a.bg-gray-100:hover, a.bg-gray-100:focus,
button.bg-gray-100:hover,
button.bg-gray-100:focus {
  background-color: #d8d8d8 !important;
}

.bg-gray-200 {
  background-color: #e9ecef !important;
}

a.bg-gray-200:hover, a.bg-gray-200:focus,
button.bg-gray-200:hover,
button.bg-gray-200:focus {
  background-color: #cbd3da !important;
}

.bg-gray-300 {
  background-color: #e0e0e0 !important;
}

a.bg-gray-300:hover, a.bg-gray-300:focus,
button.bg-gray-300:hover,
button.bg-gray-300:focus {
  background-color: #c7c7c7 !important;
}

.bg-gray-400 {
  background-color: #c7c7c7 !important;
}

a.bg-gray-400:hover, a.bg-gray-400:focus,
button.bg-gray-400:hover,
button.bg-gray-400:focus {
  background-color: #aeaeae !important;
}

.bg-gray-500 {
  background-color: #a2a2a2 !important;
}

a.bg-gray-500:hover, a.bg-gray-500:focus,
button.bg-gray-500:hover,
button.bg-gray-500:focus {
  background-color: #898989 !important;
}

.bg-gray-600 {
  background-color: #666666 !important;
}

a.bg-gray-600:hover, a.bg-gray-600:focus,
button.bg-gray-600:hover,
button.bg-gray-600:focus {
  background-color: #4d4d4d !important;
}

.bg-gray-700 {
  background-color: #495057 !important;
}

a.bg-gray-700:hover, a.bg-gray-700:focus,
button.bg-gray-700:hover,
button.bg-gray-700:focus {
  background-color: #32373b !important;
}

.bg-gray-800 {
  background-color: #343a40 !important;
}

a.bg-gray-800:hover, a.bg-gray-800:focus,
button.bg-gray-800:hover,
button.bg-gray-800:focus {
  background-color: #1d2124 !important;
}

.bg-gray-900 {
  background-color: #333333 !important;
}

a.bg-gray-900:hover, a.bg-gray-900:focus,
button.bg-gray-900:hover,
button.bg-gray-900:focus {
  background-color: #1a1a1a !important;
}

.bg-white .bg-white {
  background-color: #ffffff !important;
}
.bg-white a.bg-white:hover, .bg-white a.bg-white:focus,
.bg-white button.bg-white:hover,
.bg-white button.bg-white:focus {
  background-color: #e6e6e6 !important;
}

.bg-black .bg-black {
  background-color: #000000 !important;
}
.bg-black a.bg-black:hover, .bg-black a.bg-black:focus,
.bg-black button.bg-black:hover,
.bg-black button.bg-black:focus {
  background-color: black !important;
}

.bg-hard-gradient-50-50-white-primary-100 {
  background: linear-gradient(to bottom, #ffffff 50%, #f2f2f5 50%);
}

.bg-product-list-filters {
  background: linear-gradient(to bottom, #ffffff 23px, #f2f2f5 0);
}
.productfilters-open .bg-product-list-filters {
  background: linear-gradient(to bottom, #ffffff 23px, #bfc1ce 0);
}
@media (min-width: 768px) {
  .bg-product-list-filters {
    background: linear-gradient(to bottom, #ffffff 85px, #f2f2f5 0);
  }
}

.border-top {
  border-top: 1px solid #e0e0e0 !important;
}

.border-right {
  border-right: 1px solid #e0e0e0 !important;
}

.border-bottom {
  border-bottom: 1px solid #e0e0e0 !important;
}

.border-left {
  border-left: 1px solid #e0e0e0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-x {
  border-left: 1px solid #e0e0e0 !important;
  border-right: 1px solid #e0e0e0 !important;
}

.border-y {
  border-top: 1px solid #e0e0e0 !important;
  border-bottom: 1px solid #e0e0e0 !important;
}

@media (min-width: 576px) {
  .border-sm-top {
    border-top: 1px solid #e0e0e0 !important;
  }
  .border-sm-right {
    border-right: 1px solid #e0e0e0 !important;
  }
  .border-sm-bottom {
    border-bottom: 1px solid #e0e0e0 !important;
  }
  .border-sm-left {
    border-left: 1px solid #e0e0e0 !important;
  }
  .border-sm-top-0 {
    border-top: 0 !important;
  }
  .border-sm-right-0 {
    border-right: 0 !important;
  }
  .border-sm-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-sm-left-0 {
    border-left: 0 !important;
  }
  .border-sm-x {
    border-left: 1px solid #e0e0e0 !important;
    border-right: 1px solid #e0e0e0 !important;
  }
  .border-sm-y {
    border-top: 1px solid #e0e0e0 !important;
    border-bottom: 1px solid #e0e0e0 !important;
  }
}
@media (min-width: 768px) {
  .border-md-top {
    border-top: 1px solid #e0e0e0 !important;
  }
  .border-md-right {
    border-right: 1px solid #e0e0e0 !important;
  }
  .border-md-bottom {
    border-bottom: 1px solid #e0e0e0 !important;
  }
  .border-md-left {
    border-left: 1px solid #e0e0e0 !important;
  }
  .border-md-top-0 {
    border-top: 0 !important;
  }
  .border-md-right-0 {
    border-right: 0 !important;
  }
  .border-md-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-md-left-0 {
    border-left: 0 !important;
  }
  .border-md-x {
    border-left: 1px solid #e0e0e0 !important;
    border-right: 1px solid #e0e0e0 !important;
  }
  .border-md-y {
    border-top: 1px solid #e0e0e0 !important;
    border-bottom: 1px solid #e0e0e0 !important;
  }
}
@media (min-width: 992px) {
  .border-lg-top {
    border-top: 1px solid #e0e0e0 !important;
  }
  .border-lg-right {
    border-right: 1px solid #e0e0e0 !important;
  }
  .border-lg-bottom {
    border-bottom: 1px solid #e0e0e0 !important;
  }
  .border-lg-left {
    border-left: 1px solid #e0e0e0 !important;
  }
  .border-lg-top-0 {
    border-top: 0 !important;
  }
  .border-lg-right-0 {
    border-right: 0 !important;
  }
  .border-lg-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-lg-left-0 {
    border-left: 0 !important;
  }
  .border-lg-x {
    border-left: 1px solid #e0e0e0 !important;
    border-right: 1px solid #e0e0e0 !important;
  }
  .border-lg-y {
    border-top: 1px solid #e0e0e0 !important;
    border-bottom: 1px solid #e0e0e0 !important;
  }
}
@media (min-width: 1200px) {
  .border-xl-top {
    border-top: 1px solid #e0e0e0 !important;
  }
  .border-xl-right {
    border-right: 1px solid #e0e0e0 !important;
  }
  .border-xl-bottom {
    border-bottom: 1px solid #e0e0e0 !important;
  }
  .border-xl-left {
    border-left: 1px solid #e0e0e0 !important;
  }
  .border-xl-top-0 {
    border-top: 0 !important;
  }
  .border-xl-right-0 {
    border-right: 0 !important;
  }
  .border-xl-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-xl-left-0 {
    border-left: 0 !important;
  }
  .border-xl-x {
    border-left: 1px solid #e0e0e0 !important;
    border-right: 1px solid #e0e0e0 !important;
  }
  .border-xl-y {
    border-top: 1px solid #e0e0e0 !important;
    border-bottom: 1px solid #e0e0e0 !important;
  }
}
.gap-0 {
  gap: 0 !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.gap-1 {
  gap: 5px !important;
}

.row-gap-1 {
  row-gap: 5px !important;
}

.column-gap-1 {
  column-gap: 5px !important;
}

.gap-2 {
  gap: 10px !important;
}

.row-gap-2 {
  row-gap: 10px !important;
}

.column-gap-2 {
  column-gap: 10px !important;
}

.gap-3 {
  gap: 15px !important;
}

.row-gap-3 {
  row-gap: 15px !important;
}

.column-gap-3 {
  column-gap: 15px !important;
}

.gap-4 {
  gap: 20px !important;
}

.row-gap-4 {
  row-gap: 20px !important;
}

.column-gap-4 {
  column-gap: 20px !important;
}

.gap-5 {
  gap: 25px !important;
}

.row-gap-5 {
  row-gap: 25px !important;
}

.column-gap-5 {
  column-gap: 25px !important;
}

.gap-6 {
  gap: 30px !important;
}

.row-gap-6 {
  row-gap: 30px !important;
}

.column-gap-6 {
  column-gap: 30px !important;
}

.gap-7 {
  gap: 35px !important;
}

.row-gap-7 {
  row-gap: 35px !important;
}

.column-gap-7 {
  column-gap: 35px !important;
}

.gap-8 {
  gap: 40px !important;
}

.row-gap-8 {
  row-gap: 40px !important;
}

.column-gap-8 {
  column-gap: 40px !important;
}

.gap-9 {
  gap: 45px !important;
}

.row-gap-9 {
  row-gap: 45px !important;
}

.column-gap-9 {
  column-gap: 45px !important;
}

.gap-10 {
  gap: 50px !important;
}

.row-gap-10 {
  row-gap: 50px !important;
}

.column-gap-10 {
  column-gap: 50px !important;
}

.glide__slides {
  margin-bottom: 0;
}
.glide__arrows {
  width: 100%;
}
.glide__arrow {
  display: block;
  z-index: 2;
  background-color: transparent;
  border: 0;
  opacity: 1;
  cursor: pointer;
  transition: opacity 150ms ease, border 300ms ease-in-out;
  padding: 0;
  margin: 0;
}
.glide__arrow:focus {
  outline: none;
}
.glide__arrow--disabled {
  opacity: 0.33;
}
.glide__bullet {
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
}
.glide__bullet:focus {
  outline: none;
}
.glide__bullet--active {
  background-color: white;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-medium {
  font-weight: 400 !important;
}

.font-weight-semibold {
  font-weight: 700 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}