.article-tags {
  display: flex;
  margin-top: .5rem;

  @include media-breakpoint-up(md) {
    display: inline-flex;
    margin-top: 0;
    margin-left: 1rem;
  }
}

.article-tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: .125rem .625rem 0 .625rem;
  border: rem(1px) solid $gray-300;
  border-radius: 1rem;
  background-color: $white;
  color: $primary-800;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;

  &,
  &.is-small {
    height: 1.25rem;
    font-size: $font-size-xs;
    letter-spacing: rem(1px);
  }

  @include media-breakpoint-up(md) {
    height: 1.75rem;
    font-size: $font-size-sm;
    letter-spacing: rem(3px);
  }

  + .article-tag {
    margin-left: .5rem;
  }
}
