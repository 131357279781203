.hce-toggle {
  display: flex;
  align-items: center;

  &--large {
    @include media-breakpoint-up(md) {
      .hce-toggle__wrapper {
        width: 6rem;
        height: 3rem;
        padding: rem(5px);
      }

      .hce-toggle__handle {
        width: 2.5rem;
        height: 2.5rem;
      }

      .hce-toggle__checkbox:checked ~ .hce-toggle__wrapper .hce-toggle__handle {
        transform: translateX(2.875rem);
      }

      .hce-toggle__label {
        font-size: 1.125rem;
      }
    }
  }

  &__checkbox {
    @include screenreader;

    &:checked {
      ~ .hce-toggle__wrapper {
        background-image: linear-gradient(63deg, $secondary-600, $secondary-800);

        .hce-toggle__handle {
          transform: translateX(1.875rem);
          box-shadow: rem(-3px) 0 0 0 rgba($primary, .1);
        }
      }
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    width: 3.875rem;
    height: 2rem;
    padding: .25rem;
    border-radius: 2rem;
    background-image: linear-gradient(63deg, $yellow, lighten($yellow, 20%));
  }

  &__handle {
    display: inline-flex;
    height: 1.625rem;
    width: 1.625rem;
    border-radius: 50%;
    background: $white;
    box-shadow: rem(3px) 0 0 0 rgba($primary, .1);
    transition: transform 300ms cubic-bezier(.175, .885, .32, 1.275);
    cursor: pointer;
  }

  &__label {
    color: $primary-400;
    font-size: 1rem;

    &.is-highlighted {
      color: $white;
      font-weight: $font-weight-semibold;
    }

    &:first-child { margin-right: .75rem; }

    &:last-child { margin-left: .75rem; }
  }
}
