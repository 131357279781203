.rte {
  letter-spacing: .5px;
  position: relative;

  a {
    display: inline-block;
    position: relative;
    text-decoration: none;
    z-index: 1;
    color: inherit;

    &:before {
      content: "";
      position: absolute;
      bottom: rem(7px);
      left: 0;
      width: 100%;
      height: rem(4px);
      background-color: $secondary-600;
      z-index: -1;
      transition: 100ms all ease-in-out;
    }

    &:hover,
    &:focus {
      &:before {
        bottom: rem(3px);
      }
    }

    &:focus {
      outline: 1px dotted black;
    }
  }
}
