.icon {
  width: 24px;
  height: 24px;
  pointer-events: none;

  svg {
    width: 24px;
    height: 24px;
  }
}
