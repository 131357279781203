.hce-dealer-filters {
  overflow: hidden;

  .apply {
    margin-top: 2rem;

    .btn {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: auto;
      }
    }
  }
}
