@use 'sass:math';

.hce-product-list {
  &--introduction {
    padding-bottom: rem(70px);

    @include media-breakpoint-up(md) {
      padding-top: rem(14px);
      padding-bottom: rem(30px);
    }

    .switch {
      position: absolute;
      bottom: rem(-51px);
      right: 12px;
      width: rem(250px);
      height: rem(32px);

      @include media-breakpoint-up(md) {
        top: rem(9px);
        bottom: auto;
        right: 0;
      }
    }

    .rte {
      padding-top: rem(20px);

      @include media-breakpoint-up(md) {
        width: math.percentage(math.div(2, 3));
      }

      h1 {
        margin-bottom: rem(13px);
        font-size: rem(36px); // overwrite required by design

        @include media-breakpoint-up(md) {
          font-size: rem(56px);
        }
      }
    }
  }

  &--introduction-wrapper {
    padding-left: 20px;

    @include media-breakpoint-up(sm) {
      padding-left: 0;
    }
  }

  &--filters {
    @include animate(350ms);

    position: relative;
    background-color: $primary-100;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1.5rem;
      background-color: $white;
    }

    @include media-breakpoint-up(lg) {
      &:before {
        height: rem(88px);
      }
    }

    .filterscontainer {
      > form {
        > .row {
          margin-top: spacer(6); // 24px
          margin-bottom: spacer(5); // 20px

          @include media-breakpoint-up(lg) {
            margin-top: 0;
            margin-bottom: 0;
          }
        }
      }

      &.collapse:not(.show) {
        @include media-breakpoint-up(lg) {
          display: block;
        }
      }
    }

    .btn {
      &--list,
      &--grid {
        width: 48px;
        height: 48px;
        border: solid 1px $primary-400;
        border-radius: 50%;
        background-color: theme-color("primary-100");
        padding: 0;
        min-height: auto;
        justify-content: center;
        @include media-breakpoint-up(md) {
          width: 36px;
          height: 36px;
        }

        .icon {
          width: 24px;
          height: 24px;
          position: relative;
          top: 2px;

          @include media-breakpoint-up(md) {
            left: 0px;
          }

          svg {
            width: 24px;
            height: 24px;
            fill: theme-color("primary");
          }
        }

        &.is-active {
          background-color: theme-color("primary");
          border: solid 1px theme-color("primary");

          .icon {
            svg {
              fill: $white;
            }
          }
        }
      }

      &--filter-mobile {
        position: relative;

        .icon {
          position: absolute;
          right: 24px;
          fill: theme-color("primary");
          @include animate(100ms);
        }

        &.is-active {
          background-color: theme-color("primary") !important;
          border: solid 1px theme-color("primary") !important;
          color: theme-color("white") !important;

          .icon {
            fill: theme-color("white");
            transform: rotate(180deg);
            transform-origin: 50% 65%;
          }
        }
      }

      & + .btn {
        margin-left: rem(6px);
      }
    }

    .custom-select {
      margin-bottom: spacer(2); // 8px

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }
  }
}

.productfilters-open .hce-product-list--filters {
  background-color: $primary-400;
}
