.language-selector {
  position: relative;
  padding: 0;
  margin: 0;
  list-style: none;

  &-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100%);

    &.is-open {
      position: fixed;
      left: 20px;
      width: calc(100% - 20px);
    }
  }

  @include media-breakpoint-up(sm) {
    column-count: 2;
    column-gap: 4rem;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      height: 100%;
      width: 1px;
      background-color: $primary-200;
    }
    &::before { left: 50%; }
    &::after { left: 50%; }
  }

  @include media-breakpoint-up(md) {
    column-count: 3;

    &::before { left: 32%; }
    &::after { left: 68%; }
  }

  li {
    width: 100%;

    a {
      height: auto;
      padding: rem(5px) 0;
    }

    .flag-icon {
      width: rem(21px);
      height: rem(16px);
      border: rem(1px) solid $primary-200;
      margin-right: rem(20px);
    }
  }
}
