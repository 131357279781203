.hce-dealer-list {
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__dealer-count {
    margin-bottom: .5rem;

    .label {
      color: $primary-600;
    }
  }

  &__dealer {
    $dealer-class: &;
    position: relative;
    border: rem(1px) solid $primary-200;
    background-color: $white;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &:after {
      content: none;
    }

    // show additional border left when collapsed
    &.is-collapsed:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      border-left: .25rem solid $yellow;
    }

    .cut-corner {
      z-index: 1;
      position: absolute;
      right: rem(-1px);
      bottom: (-1px);
      width: rem(17px);
      height: rem(27px);
    }

    &-service-types {
      display: flex;
      gap: .5rem;
      margin: .25rem 0;
    }

    .badge {
      font-size: .85rem;
      padding: .25rem .5rem;

      &-primary {
        background-color: $green;
        color: $white;
      }
    }

    &-heading {
      font-size: 1.125rem;
    }

    p,
    p > a {
      color: $primary-800;
      line-height: 1.5;
    }

    &-wrapper {
      position: relative;
      display: flex;
      width: 100%;
      padding: 1rem;
      cursor: pointer;
    }

    .collapse-button {
      z-index: 1;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      padding: 0;
      border: 0;
      background: none;
      outline: none;

      &:focus {
        outline: rem(1px) dotted black;
      }
    }

    &-main {
      flex: 1;
      margin-right: 1.5rem;
    }

    &-toggle {
      position: relative;
      flex: 0 0 3.25rem;
      margin: -1rem;

      @include media-breakpoint-up(md) {
        flex-basis: 3.75rem;
      }

      // border left with margin top and bottom
      &:after {
        content: "";
        position: absolute;
        top: 1rem;
        bottom: 1rem;
        left: 0;
        border-left: rem(1px) solid $primary-200;
      }

      .collapse-icon {
        position: absolute;
        right: 1rem;
        bottom: 1rem;
        width: 1.125rem;
        height: 1.125rem;
        background: none;
        background-image: url(/assets/images/svg/checkmark-primary.svg);
        background-repeat: no-repeat;
        background-size: 1.125rem;
        transform: rotate(90deg);
        transition: transform 150ms ease-in-out;

        @at-root #{$dealer-class}.is-collapsed & {
          transform: rotate(0deg);
        }
      }
    }

    // Dealer divider (mobile), open collapsible btn, distance indicator
    &-divider {
      display: none;
      height: rem(1px);
      margin: 0 1rem;
      background-color: $primary-200;

      @at-root #{$dealer-class}.is-collapsed & {
        display: block;
      }
    }

    // Dealer contact collapsible, initially hidden
    &-contact {
      max-height: 0;
      overflow: hidden;

      @at-root #{$dealer-class}.is-collapsed & {
        max-height: rem(250px);
        padding: 1rem;
        transition: max-height 500ms ease;
      }

      p {
        margin-bottom: 1rem;
      }

      .btn-link {
        display: block;
        height: auto;
        padding: 0;
        text-align: left;
      }

      .btn-primary {
        border-color: $primary-800;
        background-color: $primary-800;
      }
    }
  }

  &__full-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
}

.dealers-page {
  max-width: 30rem;
  padding: 2rem 1.25rem 2rem 2.5rem;
  margin: 0 auto;
}
