//
// custom links underline
//
@mixin link-underline($start, $end, $color: theme-color("secondary"), $opacity: .75) {
  display: inline;
  background: linear-gradient(180deg,
                              transparent 0,
                              transparent $start,
                              rgba($color, $opacity) $start,
                              rgba($color, $opacity) $end,
                              transparent $end,
                              transparent 0
                            );
}
