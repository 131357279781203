$breadcrumb-divider: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBzdHlsZT0iZmlsbDojZmZmIiBkPSJNMTIgNGwtMS40MSAxLjQxTDE2LjE3IDExSDR2MmgxMi4xN2wtNS41OCA1LjU5TDEyIDIwbDgtOHoiLz48L3N2Zz4=");

.footer {
  margin-left: rem(20px);
  background-color: $primary;

  .brand-logo {
    .img-fluid {
      max-width: 200px;
    }
  }

  @include media-breakpoint-up(md) {
    margin-left: rem(100px);
  }

  &__breadcrumb-container {
    padding: rem(13px) 1rem;
    padding-left: rem(30px);
    border-bottom: rem(1px) solid theme-color("primary-800");
    margin-bottom: 1rem;
    overflow: hidden;
    overflow-x: auto;

    @include media-breakpoint-up(md) {
      padding-left: rem(20px);
      margin-bottom: 2rem;
    }

    .breadcrumbs {
      display: flex;
      padding: 0;
      margin: 0;
      list-style: none;

      .breadcrumb-item {
        font-weight: $font-weight-light;
        white-space: nowrap;

        a { color: $white; }

        + .breadcrumb-item {
          position: relative;
          padding-left: rem(34px);

          &:before {
            content: $breadcrumb-divider;
            position: absolute;
            top: rem(2px);
            left: rem(9px);
            width: rem(20px);
            height: rem(15px);
          }
        }

        &:last-child { padding-right: 1rem; }

        &.active { color: theme-color("primary-400"); }
      }
    }
  }

  &__links-container {
    margin-bottom: 2.5rem;

    .link-list {
      margin: 0 0 3rem 2rem;

      @include media-breakpoint-up(md) {
        margin: 0;
      }

      .label { color: theme-color("primary-400"); }

      .link { 
        color: $primary-200;

        &:hover,
        &:active,
        &:focus {
          color: $secondary;
        }
      }

      &--social {
        margin-bottom: 0;

        .list {
          padding: .25rem;
          margin-bottom: 0;

          &-inline-item {
            padding: 0;

            a {
              display: block;
            }
          }
        }
      }
    }
  }

  &__legal-container {
    background-color: $white;

    .legal-links {
      padding: 0 0 rem(10px) 0;

      @include media-breakpoint-up(lg) { padding: 0; }

      .copyright {
        padding: rem(16px) 0 rem(15px);
        color: theme-color("primary-600");

        // @include media-breakpoint-up(lg) { margin-left: rem(100px); }
      }

      .list {
        margin: 0;

        .list-inline-item {
          padding: rem(14px) 0 rem(5px) 0;

          a,
          button { color: theme-color("primary-600"); }

          button {
            padding: 0;
            border: none;
            margin: 0;
            background: none;
            outline: none;

            &:hover,
            &:active { text-decoration: underline; }
          }

          @include media-breakpoint-up(lg) { padding: rem(14px) 0; }
        }
      }
    }
  }
}
