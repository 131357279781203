@mixin button-hover {
  &:hover,
  &:active {
    color: theme-color("primary") !important;
    background-color: theme-color("secondary") !important;
    border-color: theme-color("secondary") !important;

    .icon {
      fill: theme-color("primary") !important;
    }
  }
}

.btn {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
  min-height: 48px;
  border-radius: $border-radius;
  border-width: 1px;
  font-size: rem(18px);
  font-weight: $font-weight-normal;
  @include line-height(25px, 18px);

  &.is-disabled {
    background-color: $gray-300;
    border-color: $gray-300;
    color: $gray-400;
    pointer-events: none;
  }

  &__label {
    display: inline-block;
  }

  .icon {
    width: 18px;
    height: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &--left {
      margin-right: rem(25px);
    }

    &--right {
      margin-left: rem(25px);
    }

    svg {
      margin-top: -2px;
      width: 18px;
      height: 18px;
    }
  }

  &-lg {
    padding: 18px 32px;
    min-height: 64px;
    font-size: rem(20px);
    @include line-height(26px, 20px);
    font-weight: $font-weight-semibold;

    .icon {
      width: 20px;
      height: 20px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  &-sm {
    padding: 6px 24px;
    font-size: rem(16px);
    min-height: 38px;

    .icon {
      width: 16px;
      height: 16px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  &-primary {
    .icon {
      fill: $white;
    }
  }

  &-primary-toggle {
    position: relative;
    border-color: $primary-400;
    background-color: $white;
    color: $primary-800;
    font-size: 1rem;
    text-align: left;

    .icon {
      position: absolute;
      top: rem(11px);
      right: rem(28px);

      svg {
        fill: $primary;
        transition: transform 300ms ease-in-out;
      }
    }

    &.is-open {
      border-color: $primary;
      background-color: $primary;
      color: $white;

      .icon svg {
        fill: $white;
        transform: rotate(180deg);
      }
    }

    &:hover {
      .icon svg {
        fill: $primary;
      }
    }
  }

  &-secondary {
    background-color: $secondary;
    color: $white;
    border-color: $secondary;

    .icon {
      fill: $white;
    }

    &:hover,
    &:active {
      background-color: $white;
      color: $secondary;
      border-color: $secondary;
      .icon{
        fill: $secondary;
      }
    }
  }

  &-outline-primary {
    color: theme-color("primary-800");
    border-color: theme-color("primary-400");

    .icon {
      fill: theme-color("primary-800");
    }
  }

  &-primary,
  &-outline-primary {
    @include button-hover;
  }

  &-link {
    text-decoration: none !important;

    .btn__label {
      //@include link-underline(65%, 75%, theme-color("secondary-600"), 1);
      text-decoration: underline;
      text-decoration-color: $secondary-600;
    }

    &:hover {
      .btn__label {
        //@include link-underline(68%, 84%, theme-color("secondary"), 1);
        text-decoration-color: $secondary;
        //text-decoration-thickness: 2px;
      }
    }

    &--alt {
      .icon {
        margin-right: 4px;
      }

      // stylelint-disable
      .btn__label {
        //@include link-underline(65%, 75%, theme-color("primary-200"), .75);
        text-decoration-color: $primary-600;
      }

      &:hover {
        .btn__label {
          //@include link-underline(68%, 84%, theme-color("primary-200"), 1);
          text-decoration-color: $primary;
        }
      }
      // stylelint-enable
    }
  }

  .bg-dark &-primary {
    background-color: $white;
    color: theme-color("primary");
    @include button-hover;

    .icon {
      fill: theme-color("primary");
    }
  }

  .bg-dark &-outline-primary {
    border-color: $white;
    color: $white;
    @include button-hover;

    .icon {
      fill: $white;
    }
  }

  .bg-dark &-link {
    color: $white;
  }

  &:focus,
  &.focus,
  &:active:focus,
  &.active:focus,
  &:active.focus,
  &.active.focus {
    box-shadow: none !important;
    outline: none !important;
  }
}

// stylelint-disable
.bg-dark {
  .btn-link--alt {
    .btn__label {
      text-decoration-color: $primary-600;
    }

    &:hover {
      .btn__label {
        text-decoration-color: $primary-200;
      }
    }
    .icon {
      fill: $white;
    }
  }
}
// stylelint-enable
