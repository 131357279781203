.usp {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  margin: 0 auto;

  &--row {
    @include media-breakpoint-up(md) {
      flex-direction: row;
      width: 100%;
      margin: 0;
    }
  }

  &--column {
    flex-direction: column;
  }

  &__divider {
    display: block;
    width: 3rem;
    height: rem(1px);
    margin: .75rem auto 0 auto;

    .usp--row & {
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .75rem;
    padding-bottom: 0;
    margin-bottom: .75rem; // use margin-bottom instead of padding to cut off divider with overflow: hidden
    text-align: center;
    overflow: hidden;

    .usp--row & {
      @include media-breakpoint-up(md) {
        flex: 1;
      }
    }

    &--left {
      align-items: flex-start;
      padding-left: 0;
      text-align: left;

      .usp__value {
        padding-left: 0;
      }

      .usp__divider {
        margin-left: 0;
      }
    }
  }

  &__value {
    position: relative;
    width: rem(240px);
    padding: 0 1.25rem;

    .usp--row & {
      @include media-breakpoint-up(md) {
        width: 100%;
      }
    }

    .usp--row .usp__item:not(:last-child) &:after {
      @include media-breakpoint-up(md) {
        content: "";
        position: absolute;
        border-right: rem(1px) solid $primary-200;
        top: 0;
        right: 0;
        height: 10rem;
      }
    }
  }

  .icon {
    width: 2rem;
    height: 2rem;
    margin-bottom: .5rem;

    svg {
      width: inherit;
      height: inherit;
      fill: $secondary;
    }
  }
}
