.hce-video {
  position: relative;
  width: 100%;
  height: rem(475px);

  &--small {
    height: auto;
    padding-top: 56.25%;
  }

  @include media-breakpoint-up(md) {
    // video aspect ratio (16:9) 9 / 16 = 56.25%;
    padding-top: 56.25%;
  }

  &__player,
  &__placeholder,
  &__gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__gradient {
    background-image: linear-gradient(to bottom, rgba($primary, .2), rgba($primary, .75));

    .hce-video--small & {
      background-image: linear-gradient(to bottom, rgba($primary, .2), rgba($primary, .6));
    }

    .hce-video--disabled & {
      opacity: .75;
      background-color: $primary;
    }
  }

  &__details {
    z-index: 2;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding: 1.25rem;

    @include media-breakpoint-up(md) {
      .hce-video:not(.hce-video--small) & {
        width: 65%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__placeholder-image {
    height: 100%;
  }

  .hce-loader {
    margin-bottom: 2rem;

    @at-root .hce-video--small .hce-loader {
      margin-bottom: .5rem;
    }

    @include media-breakpoint-up(md) {
      @at-root .hce-video--small .hce-loader {
        margin-bottom: 1rem;
      }
    }
  }

  &__play-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(72px);
    height: rem(72px);
    border: .125rem solid $white;
    border-radius: 50%;
    margin-bottom: 2rem;
    background-color: rgba($black, .2);
    cursor: pointer;

    &[disabled] {
      opacity: .5;
      border-width: .125rem;
      background-color: transparent;
      cursor: default;
    }

    .hce-video--small & {
      width: rem(72px);
      height: rem(72px);
      margin-bottom: .5rem;

      &[disabled] {
        width: rem(40px);
        height: rem(40px);
      }
    }

    @include media-breakpoint-up(md) {
      width: rem(80px);
      height: rem(80px);

      .hce-video--small & {
        margin-bottom: 1rem;
        border-width: .125rem;

        &[disabled] {
          width: rem(58px);
          height: rem(58px);
        }
      }
    }
  }

  &__play-button-icon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: 85%;
    fill: $secondary;
    transition: fill 200ms;

    .hce-video--small .hce-video__play-button[disabled] & {
      height: 100%;
    }

    .hce-video__play-button:not([disabled]):hover & {
      fill: $white;
    }
  }

  &__title,
  &__subtitle {
    width: 100%;
    color: $white;
  }

  &__title {
    @include media-breakpoint-down(md) {
      font-size: $h5-font-size;
      font-weight: $font-weight-semibold;
    }

    @include media-breakpoint-down(lg) {
      .hce-video--small & {
        margin-bottom: 0;
        font-size: 1.125rem;
        line-height: 1.166;
      }
    }

    @include media-breakpoint-up(lg) {
      .hce-video--small & {
        font-size: $h5-font-size;
        font-weight: $font-weight-semibold;
        line-height: 1.166;
      }
    }
  }

  &__subtitle {
    margin-top: .5rem;
    font-weight: $font-weight-light;

    .hce-video--small & {
      display: none;
    }

    @include media-breakpoint-down(md) {
      font-size: 1.125rem;
      font-weight: $font-weight-regular;
    }

    @include media-breakpoint-up(md) {
      padding-right: 5rem;
      padding-left: 5rem;
    }
  }

  .hce-toggle {
    margin-top: 1rem;

    @at-root .hce-video--small .hce-toggle {
      margin-top: .5rem;
    }
  }
}
