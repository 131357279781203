@each $color, $value in $bg-colors {
  @include bg-variant(".bg-#{$color}", $value);
}

@each $color, $value in $grays {
  @include bg-variant(".bg-gray-#{$color}", $value);
}

.bg-white {
  @include bg-variant(".bg-white", $white);
}

.bg-black {
  @include bg-variant(".bg-black", $black);
}

.bg-hard-gradient-50-50-white-primary-100 {
  background: linear-gradient(to bottom, $white 50%, $primary-100 50%);
}

.bg-product-list-filters {
  background: linear-gradient(to bottom, $white 23px, $primary-100 0);

  .productfilters-open & {
    background: linear-gradient(to bottom, $white 23px, $primary-400 0);
  }

  @include media-breakpoint-up(md) {
    background: linear-gradient(to bottom, $white 85px, $primary-100 0);
  }
}
