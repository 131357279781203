// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25), // 4px
    2: ($spacer * .5),  // 8px
    3: ($spacer * .75), // 12px
    4: ($spacer * 1),   // 16px
    5: ($spacer * 1.25),// 20px
    6: ($spacer * 1.5), // 24px
    7: ($spacer * 1.75),// 28px
    8: ($spacer * 2),   // 32px
    9: ($spacer * 2.25),// 36px
    10:($spacer * 2.5), // 40px
    11:($spacer * 2.75),// 44px
    12:($spacer * 3)    // 48px
  ),
  $spacers
);


