.image-text {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    //padding-left: 1.5rem;

    margin-left: -12px;
    margin-right: -12px;
    .row-big & {
      margin-left: -25px;
      margin-right: -25px;
    }

    @include media-breakpoint-up(md) {
      flex-wrap: nowrap;
      //padding: 0;
    }
  }

  // on small screens always show text above image
  &__text {
    order: 1;
    //padding: 0;
    padding-left: 12px;
    padding-right: 12px;
    .row-big & {
      padding-left: 25px;
      padding-right: 25px;
    }

    .image-text--left & {
      // switch the order of text and image so image is on the left
      @include media-breakpoint-up(md) {
        order: 2;
        //padding-left: rem(22px);
      }

      @include media-breakpoint-up(lg) {
        //padding-left: rem(53px);
      }
    }

    .image-text--right & {
      @include media-breakpoint-up(md) {
        //padding-right: rem(22px);
      }

      @include media-breakpoint-up(lg) {
        //padding-right: rem(53px);
      }
    }

    p:not(:last-child) {
      margin-bottom: 1rem;
    }

    .btn {
      margin-top: 1rem;
    }

    .btn-link {
      height: auto;
      padding: 0;
    }
  }

  &__image {
    order: 2;
    //padding: 0;
    margin-top: 1rem;
    padding-left: 12px;
    padding-right: 12px;
    .row-big & {
      padding-left: 25px;
      padding-right: 25px;
    }

    @include media-breakpoint-up(md) {
      margin-top: 0;

      .image-text--left & {
        order: 1;
      }
    }

    img {
      width: 100%;
    }
  }
}
