// font family
$font-family-sans-serif: HDHyundaiGothicEN, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

// font-sizes
$font-size-base: 1rem !default;
$font-size-xl: ($font-size-base * 1.325); // 20px
$font-size-lg: ($font-size-base * 1.125); // 18px
$font-size-sm: ($font-size-base * .875); // 14px
$font-size-xs: ($font-size-base * .75); // 12px

// font-weights
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-regular: $font-weight-normal;
$font-weight-medium: 400; // 500
$font-weight-semibold: 700; // 600
$font-weight-bold: 700;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;

// headings
$h1-font-size: $font-size-base * 3.5 !default; // 56px
$h2-font-size: $font-size-base * 3 !default; // 48px
$h3-font-size: $font-size-base * 2.25 !default; // 36px
$h4-font-size: $font-size-base * 1.75 !default; // 28px
$h5-font-size: $font-size-base * 1.5 !default; // 24px
$h6-font-size: $font-size-base * 1.25 !default; // 20px

// $headings-margin-bottom: $font-size-base / 2 !default;
// $headings-font-family: inherit !default;
// $headings-font-weight: 700 !default;
// $headings-line-height: .8 !default;
// $headings-color: $primary !default;

$display1-size: $font-size-base * 4.25 !default;
$display2-size: $h2-font-size !default;
$display3-size: $h3-font-size !default;
$display4-size: $h4-font-size !default;
$display5-size: $h5-font-size !default;
$display6-size: $h6-font-size !default;

// $display1-weight: 700 !default;
// $display2-weight: 200 !default;
// $display3-weight: 200 !default;
// $display4-weight: 200 !default;
// $display5-weight: 200 !default;
// $display6-weight: 200 !default;
// $display-line-height: $headings-line-height !default;

