.form {
  &-control {
    margin: 0;
    padding: rem(6px) rem(24px);
    height: 48px;
    border-radius: 33px;
    font-size: 1rem;
    @include line-height(28px, 18px);
    border: rem(1px) solid $primary-400;
    color: theme-color("primary");

    &:focus {
      border-color: $primary-600;
      box-shadow: none;
      color: $primary;
    }

    @include input-placeholder {
      color: theme-color("primary-600");
    }

    // any icon next to input
    & + .icon {
      position: absolute;
      top: .75rem;
      right: rem(19px);
    }

    &.with-warning {
      padding-right: 3.5rem;
      border-color: $error;
      color: $error;

      & + .icon--warning {
        visibility: visible;
        opacity: 1;
      }
    }

    & + .icon--warning {
      opacity: 0;
      visibility: hidden;
      color: $error;
      fill: $error;
      transition: visibility 0s, opacity .5s linear;
    }

    & ~ .error-message {
      position: relative;
      color: $error;
      left: rem(27px);
      margin-top: rem(10px);
      font-size: $font-size-sm;
    }
  }
}
