///
/// Style `<input/>` placeholder.
/// @author Rocco Janse, <rocco.janse@valtech.nl>
///
@mixin input-placeholder {
  &::-webkit-input-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &:-moz-placeholder { @content; }
}
