$card-spacer-y:                     .75rem;
$card-spacer-x:                     1.25rem;
$card-border-width:                 0;
$card-border-radius:                0;
$card-border-color:                 transparent;
$card-inner-border-radius:          0;
$card-cap-bg:                       transparent;
$card-cap-color:                    null;
$card-height:                       null;
$card-color:                        null;
$card-bg:                           #ffffff;

$card-img-overlay-padding:          1.25rem;

$card-group-margin:                 1rem * .5;
$card-deck-margin:                  $card-group-margin;

$card-columns-count:                3;
$card-columns-gap:                  1.25rem;
$card-columns-margin:               $card-spacer-y;