.hce-loader {
  width: rem(72px);
  height: rem(72px);
  position: relative;

  @include media-breakpoint-up(md) {
    width: rem(80px);
    height: rem(80px);
  }

  &--small {
    width: rem(58px);
    height: rem(58px);
  }

  &__circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    &:before {
      content: '';
      display: block;
      width: .5rem;
      height: .5rem;
      border-radius: 100%;
      margin: 0 auto;
      background-color: $secondary;
      animation:
        fadeDelay 800ms infinite ease-in-out both,
        sizeDelay 800ms infinite ease-in-out both;

      .hce-loader--small & {
        animation:
          fadeDelay 800ms infinite ease-in-out both,
          sizeDelaySmall 800ms infinite ease-in-out both;
      }
    }

    &:nth-child(2) { transform: rotate(45deg); }
    &:nth-child(3) { transform: rotate(90deg); }
    &:nth-child(4) { transform: rotate(135deg); }
    &:nth-child(5) { transform: rotate(180deg); }
    &:nth-child(6) { transform: rotate(225deg); }
    &:nth-child(7) { transform: rotate(270deg); }
    &:nth-child(8) { transform: rotate(315deg); }

    &:nth-child(2):before { animation-delay: -700ms; }
    &:nth-child(3):before { animation-delay: -600ms; }
    &:nth-child(4):before { animation-delay: -500ms; }
    &:nth-child(5):before { animation-delay: -400ms; }
    &:nth-child(6):before { animation-delay: -300ms; }
    &:nth-child(7):before { animation-delay: -200ms; }
    &:nth-child(8):before { animation-delay: -100ms; }
  }
}

@keyframes fadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
}

@keyframes sizeDelay {
  0%, 39%, 100% {
    width: .75rem;
    height: .75rem;
  }
  40% {
    width: 1.25rem;
    height: 1.25rem;
  }
}

@keyframes sizeDelaySmall {
  0%, 39%, 100% {
    width: .25rem;
    height: .25rem;
  }
  40% {
    width: .75rem;
    height: .75rem;
  }
}
