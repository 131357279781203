@use 'sass:math';

.hce-article-overview {
  padding-bottom: 3rem;

  .overview-header {
    padding-top: 2.25rem;
    padding-bottom: 5.5rem;
    padding-left: 1.25rem;
    background-color: $primary-100;

    @include media-breakpoint-up(md) {
      padding-top: rem(100px);
      padding-bottom: 3.5rem;
    }
  }

  .overview-title {
    @include media-breakpoint-up(lg) {
      width: math.percentage(math.div(7, 12));
      font-size: 3.5rem;
      letter-spacing: rem(-1.17px);
      line-height: .86;
    }
  }

  .highlighted-cards {
    display: flex;
    flex-wrap: wrap;
    padding-left: 1.25rem;
    margin: rem(-10px);
    margin-bottom: rem(10px);

    @include media-breakpoint-up(md) {
      padding-left: 0;
    }

    .col-md-6 {
      padding: rem(10px);
    }
  }

  .regular-cards-masonry {
    margin: rem(-10px);
    margin-left: rem(10px);

    @include media-breakpoint-up(md) {
      margin-left: rem(-10px);
    }

    .masonry-item {
      width: 100%;
      padding: rem(10px);

      @include media-breakpoint-up(md) {
        width: math.percentage(math.div(4, 12));
      }

      @include media-breakpoint-up(lg) {
        width: math.percentage(math.div(3, 12));
      }
    }
  }

  .cards-enter-active,
  .cards-leave-active {
    transition: all .8s;
  }

  .cards-enter,
  .cards-leave-to {
    opacity: 0;
    transform: translateY(rem(30px));
  }

  .load-more-cards {
    margin-top: 2rem;

    .btn {
      display: block;
      margin: 0 auto;
    }
  }
}
