.product-specs-highlights {
  &__row {
    display: flex;
    flex-wrap: wrap;

    &:not(:first-child) .product-specs-highlights__column {
      border-top: 0;
    }
  }

  &__column {
    padding: rem(14px) 1.25rem;
    border: rem(1px) solid $gray-300;

    &:not(:first-child) {
      &:not(.col-12) {
        border-left: 0;
      }

      @include media-breakpoint-up(md) {
        border-left: 0;
      }
    }
    /*
    &--button {
      display: flex;
      padding: .5rem 1.25rem;

      @include media-breakpoint-up(md) {
        align-items: center;
        justify-content: center;
      }

      .btn {
        height: auto;
        padding: 0;
      }

      .icon svg {
        fill: $primary-600;
      }
    }
    */

    &--image {
      order: -1;
      position: relative;

      @include media-breakpoint-up(md) {
        order: 3;
      }
    }
  }

  &__label {
    font-size: $font-size-xs;
    color: $primary-900;
    vertical-align: top;

    @include media-breakpoint-up(md) {
      height: auto;
      font-size: $font-size-sm;
    }
  }

  &__value {
    color: $primary-900;
    font-size: $font-size-base;
    font-weight: bold;
  }

  .glide {
    /*&:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        to bottom,
        transparent 75%,
        rgba($primary, .6)
      );
      pointer-events: none;
      transition: opacity .2s;
    }

    &:hover:after {
      background-image: linear-gradient(
        to bottom,
        transparent 75%,
        rgba($primary, .8)
      );
    }*/

    &__bullet {
      background-color: transparent;
    }

    &__arrow:hover {
      svg {
        fill: $primary;
      }
    }

    img {
      height: rem(248px);
      object-fit: cover;

      @include media-breakpoint-up(md) {
        height: rem(353px);
      }
    }
  }
  &.short {
    .product-specs-highlights__column {
      border-left: rem(1px) solid $gray-300;
    }
  }
}
