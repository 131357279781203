.cookie-consent {
  &--sticky {
    opacity: 0;
    position: fixed;
    z-index: 9999;
    bottom: 0;
    left: 0;
    width: 100%;
    max-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    max-height: calc(var(--vh, 1vh) * 100);
    transform: translate(0, 100%);
    transition: 300ms transform ease-in-out;
    overflow-y: auto;

    &.is-shown {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  &__introduction {
    padding-top: rem(18px);
    padding-bottom: rem(16px);

    @include media-breakpoint-up(md) {
      padding-top: rem(25px);
      padding-bottom: rem(24px);
    }

    h3,
    p {
      color: $white;
      text-align: center;
      font-size: 14px; // overwrite
      line-height: 20px; // overwrite

      @include media-breakpoint-up(md) {
        font-size: 18px; // overwrite
        line-height: 24px; // overwrite
      }
    }

    h3 {
      @extend h5;
      font-size: 20px; // overwrite
      line-height: 24px; // overwrite

      @include media-breakpoint-up(md) {
        font-size: 24px; // overwrite
        line-height: 28px; // overwrite
      }
    }

    a {
      text-decoration: none;
      color: $white;
      @include link-underline(80%, 85%, theme-color("white"), .75);

      &:hover { @include link-underline(80%, 85%, theme-color("white"), 0); }
    }
  }

  &__options {
    position: relative;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: 500ms all ease-in-out;

    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $primary-800;
    }
    &:before { top: 0; }
    &:after { bottom: 1rem; }

    @include media-breakpoint-up(md) {
      &:after { bottom: 2rem; }
    }

    &.is-open {
      max-height: 1000px;
      opacity: 1;
    }

    .container {
      padding-top: rem(21px);
      padding-bottom: 2rem;

      @include media-breakpoint-up(md) {
        padding-top: 2rem;
        padding-bottom: 4rem;
       }
    }
  }

  &__option {
    position: relative;
    margin-bottom: rem(20px);

    &:last-child { margin-bottom: 0; }

    .form-check-input {
      display: none;

      & + label.form-check-label {
        padding-left: rem(20px);
        cursor: pointer;
        color: $white;
        font-size: 14px; // overwrite
        letter-spacing: 0; // overwrite
        line-height: 20px; // overwrite
        text-transform: inherit; // overwrite

        @include media-breakpoint-up(md) {
          font-size: 18px; // overwrite
          line-height: 24px; // overwrite
        }

        strong {
          @extend h6;
          font-size: 18px; // overwrite
          line-height: 20px; // overwrite
          color: $white;
          display: block;

          @include media-breakpoint-up(md) {
            font-size: 20px; // overwrite
          }
        }

        &:before,
        &:after {
          content: "";
          position: absolute;
          top: rem(1px);
          left: 0;
          width: rem(20px);
          height: rem(20px);
        }

        &:before { background-image: url('/assets/images/svg/checkbox-unchecked-2.svg'); }
      }

      &:checked + .form-check-label:after {
        background-image: url('/assets/images/svg/checkbox-checked-2.svg');
      }
    }
  }

  &__actions {
    padding-bottom: 1rem;

    @include media-breakpoint-up(md) { padding-bottom: 2rem; }

    .btn.btn-secondary {
      color: $black;

      &:hover,
      &:focus { color: $white; }
    }

    .col-12 {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
