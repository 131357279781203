.card {
  position: relative;
  display: block;

  .img-fluid {
    height: 100px;

    @include media-breakpoint-up(md) {
      height: 180px;
    }
  }

  &__body {
    padding: 0 rem(40px) rem(16px) 0;

    @include media-breakpoint-up(md) {
      padding: rem(17px) rem(10px) 0 rem(25px);
    }
  }

  &__text {
    margin-bottom: rem(9px);
    color: theme-color("primary-800");
  }

  &__link {
    @include link-underline(56%, 72%, theme-color("secondary"), .5);
    color: theme-color("primary");
  }

  &:hover {
    text-decoration: none !important;

    .card__link {
      @include link-underline(68%, 84%, theme-color("secondary"), 1);
    }
  }
}
