.main-navigation {
  @import 'main-navigation/bar';
  @import 'main-navigation/brand-logo';
  @import 'main-navigation/search';
  @import 'main-navigation/desktop';
  @import 'main-navigation/mobile';
  position: relative;
  background-color: theme-color("primary");

  &__item:not(.cta-item) {
    @include line-height(28px, 20px);
    display: block !important;
    font-size: rem(16px);
    color: $white;
    text-transform: uppercase;
    transition: 300ms background ease-in-out;

    &:hover {
      display: block !important;
      color: $white;
      text-decoration: none;
    }
  }

  &__item.cta-item {
    label {
      font-size: rem(16px);
    }
  }
}

