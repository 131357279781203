$custom-select-indicator: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjYgKDY3NDkxKSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5JY29ucy9kcm9wZG93bi8vd2hpdGUvZG93bjwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJJY29ucy9kcm9wZG93bi8vd2hpdGUvZG93biIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMy4wMDAwMDAsIDExLjUwMDAwMCkgc2NhbGUoMSwgLTEpIHRyYW5zbGF0ZSgtMTMuMDAwMDAwLCAtMTEuNTAwMDAwKSB0cmFuc2xhdGUoNi4wMDAwMDAsIDcuMDAwMDAwKSIgZmlsbC1ydWxlPSJub256ZXJvIiBzdHJva2U9IiMwMDA5M0IiIHN0cm9rZS13aWR0aD0iMiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDcuMDIwODE1MjggTDcsMCIgaWQ9IkxpbmUtMi1Db3B5LTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMuNTAwMDAwLCA0LjAwMDAwMCkgcm90YXRlKC0xODAuMDAwMDAwKSB0cmFuc2xhdGUoLTMuNTAwMDAwLCAtNC4wMDAwMDApICI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNNywxLjk1ODM2OTQ0IEwxNCw4Ljk3OTE4NDcyIiBpZD0iTGluZS0yLUNvcHktMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAuNTAwMDAwLCA0Ljk3OTE4NSkgcm90YXRlKC0xODAuMDAwMDAwKSB0cmFuc2xhdGUoLTEwLjUwMDAwMCwgLTQuOTc5MTg1KSAiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==");
$custom-select-indicator-disabled: url("data:image/svg+xml,%3Csvg width='17' height='10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.707 9.142L7 8.437l.002-.002L0 1.412 1.416 0l6.998 7.019L15.412 0l1.416 1.412-7.002 7.023.002.002-.707.705-.705.707-.002-.002-.002.002-.705-.707z' fill='%23BFC1CE'/%3E%3C/svg%3E");
$custom-select-indicator-selected: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5JY29ucy9kcm9wZG93bi8vd2hpdGUvZG93bjwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZyBpZD0iSWNvbnMvZHJvcGRvd24vL3doaXRlL2Rvd24iIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiPiAgICAgICAgPGcgaWQ9Ikdyb3VwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMy4wMDAwMDAsIDExLjUwMDAwMCkgc2NhbGUoMSwgLTEpIHRyYW5zbGF0ZSgtMTMuMDAwMDAwLCAtMTEuNTAwMDAwKSB0cmFuc2xhdGUoNi4wMDAwMDAsIDcuMDAwMDAwKSIgZmlsbC1ydWxlPSJub256ZXJvIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMiI+ICAgICAgICAgICAgPHBhdGggZD0iTTAsNy4wMjA4MTUyOCBMNywwIiBpZD0iTGluZS0yLUNvcHktMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMy41MDAwMDAsIDQuMDAwMDAwKSByb3RhdGUoLTE4MC4wMDAwMDApIHRyYW5zbGF0ZSgtMy41MDAwMDAsIC00LjAwMDAwMCkgIj48L3BhdGg+ICAgICAgICAgICAgPHBhdGggZD0iTTcsMS45NTgzNjk0NCBMMTQsOC45NzkxODQ3MiIgaWQ9IkxpbmUtMi1Db3B5LTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEwLjUwMDAwMCwgNC45NzkxODUpIHJvdGF0ZSgtMTgwLjAwMDAwMCkgdHJhbnNsYXRlKC0xMC41MDAwMDAsIC00Ljk3OTE4NSkgIj48L3BhdGg+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=");

.custom-select {
  color: $primary;
  height: auto;
  padding-top: 12px !important;
  padding-right: (1.5rem + 1rem) !important;
  padding-bottom: 10px !important;
  padding-left: 1.5rem !important;
  border: 1px solid $primary;
  border-radius: 0px;
  appearance: none;
  background: $custom-select-indicator no-repeat right 20px center / 18px 16px;
  cursor: pointer;
  font-size: 1rem;

  &.big {
    //padding: 18px 0px !important;
    min-height: 64px;
    font-size: 1.25rem;
    line-height: 1.3;
    font-weight: 600;
    background: $custom-select-indicator no-repeat right 32px center / 18px 16px;
  }
  &.bold {
    font-weight: 600;
  }

  // Hide custom select arrow on IE
  &::-ms-expand {
    display: none;
  }

  option {
    background-color: $white;
  }

  &:focus {
    border-color: $primary-600 !important;
    box-shadow: none !important;
  }

  &:disabled {
    background: $custom-select-indicator-disabled no-repeat right 20px
      center/14px 8px;
    background-color: $gray-300 !important;
    border-color: $gray-300;
    color: $primary-600;
    cursor: no-drop;
  }

  &.is-selected {
    background: $custom-select-indicator-selected no-repeat right 20px center /
      18px 16px;
    background-color: $primary !important;
    border-color: $custom-select-indicator-selected no-repeat right 20px center /
      18px 16px;
    color: $white;
  }
}

.dropdown {
  background: none;
  border-radius: 0;
  border: none;
  position: relative;
  width: 100%;
  text-align: left;
  padding: 0;

  .dropdown-toggle {
    background: #fff $custom-select-indicator no-repeat right 20px center/18px 16px;
    border-radius: 0;
    border: 1px solid $primary;
    color: $primary;
    cursor: pointer;
    font-size: 1rem;
    height: 47px;
    line-height: 47px;
    padding-left: 1.5rem;
    user-select: none;
    width: 100%;

    &:after {
      content: none;
    }

    &[aria-expanded="true"] {
      border-radius: 0;
    }
  }

  .dropdown-menu {
    background-color: #fff;
    border: 1px solid $primary;
    border-radius: 0;
    color: $primary;
    max-height: 240px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 15px 0;
    width: 100%;
  }

  .dropdown-item {
    cursor: pointer;
  }

  .item-link {
    display: block;
    padding: .5rem 1.5rem;
  }

  &.big {
    .dropdown-toggle {
      background: $custom-select-indicator no-repeat right 32px center / 18px 16px;
      border-radius: 0px;
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 1.3;
      min-height: 64px;
      padding: 12px 2.5rem 10px 1.5rem;
    }

    // .dropdown-item {
    //   font-size: 1.25rem;
    //   font-weight: 600;
    //   line-height: 1.3;
    //   padding: 18px 32px;
    // }
  }

  &.bold {
    font-weight: 700;
  }
}
