.teaser-fullwidth {
  position: relative;
  padding-left: rem(20px);

  @include media-breakpoint-up(md) { padding-left: rem(82px); }

  @include media-breakpoint-up(lg) { padding-left: 0; }

  @include media-breakpoint-up(xl) { padding-left: rem(100px); }

  &__image-container {
    position: relative;
  }

  &__gradient {
    display: none;
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
    clip-path: inherit;

    @include media-breakpoint-up(lg) {
      position: absolute;
      display: block;
      background: linear-gradient(270deg, rgba($black, 0), rgba($black, .75));

      .teaser-fullwidth.is-centered & {
        background: none;
        background-color: rgba($black, .55);
      }

      .teaser-fullwidth.is-right & {
        background: linear-gradient(90deg, rgba($black, 0), rgba($black, .75));
      }
    }
  }

  &__image {
    height: rem(190px);
    clip-path: inherit;

    @include media-breakpoint-up(md) {
      height: rem(274px);
    }

    @include media-breakpoint-up(lg) {
      height: rem(600px);
    }
  }

  &__container {
    @include media-breakpoint-up(lg) {
      @include align-center(absolute);
    }
  }

  &__body {
    padding: 1.75rem 0;

    @include media-breakpoint-up(md) {
      width: rem(480px);
      padding-top: 2.5rem;
      padding-left: 2.75rem;
      margin-right: auto;
    }

    @include media-breakpoint-up(lg) {
      width: rem(306px);
      padding: 0;

      .teaser-fullwidth.is-centered & {
        width: rem(633px);
        margin: 0 auto;
        text-align: center;
      }

      .teaser-fullwidth.is-right & {
        @include media-breakpoint-up(md) {
          margin-right: 0;
          margin-left: auto;
        }
      }

      h3, .h3, p {
        color: $white !important;
      }
    }
  }

  &__cta {
    margin-top: 1.25rem;

    @include media-breakpoint-up(lg) {
      color: $white;
    }
  }
}
