.product-image-carousel {
  @include media-breakpoint-up(md) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 340px;
  }

  @include media-breakpoint-up(lg) {
    width: 460px;
  }

  @include media-breakpoint-up(xl) {
    width: 480px;
  }

  /*&::after {
    @include media-breakpoint-up(md) {
      @include triangle(bottomright, 42px, 28px, #fff);
    }

    position: absolute;
    bottom: -1px;
    right: -1px;
  }*/

  > img {
    width: 100%;

    @include media-breakpoint-up(md) {
      max-width: 470px;
      width: auto;
    }
  }

  .glide {
    &__slides {
      align-items: flex-end;
    }

    &__slide {
      img {
        width: 100%;
      }
    }

    &__arrows {
      position: absolute;
      left: 0;
      bottom: 0;
    }

    &__arrow {
      .icon {
        svg {
          fill: $secondary;
        }
      }

      &--left,
      &--right {
        position: absolute;
        bottom: 1rem;

        @include media-breakpoint-up(md) {
          bottom: 1.6rem;
        }
      }

      &--left {
        left: 1rem;

        @include media-breakpoint-up(md) {
          left: 2rem;
        }
      }

      &--right {
        right: 1rem;

        @include media-breakpoint-up(md) {
          right: 2rem;
        }
      }
    }

    &__bullets {
      &--wrapper {
        position: absolute;
        right: 0;
        top: -20px;

        @include media-breakpoint-up(md) {
          top: auto;
          right: 35px;
          bottom: -30px;
        }
      }

      .glide__bullet {
        display: none;

        &--active {
          display: block;
        }
      }
    }
  }
}
