.article-card {
  transition: box-shadow .2s;

  .card-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 0 1.75rem rgba($black, .5);

      @at-root .bg-dark & {
        box-shadow: 0 0 1.75rem rgba($white, .5);
      }

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-bottom: .25rem solid $secondary;
      }
    }
  }

  .card-image {
    height: rem(138px);
    background-color: $primary-200;
    object-fit: cover;
  }

  .card-body {
    padding: 1.25rem 1rem;
    border: rem(1px) solid $primary-200;
    border-top-width: 0;
    background-color: $primary-100;

    @at-root .bg-medium &,
      .bg-dark & {
      background-color: $white;
    }
  }

  .card-title {
    margin-bottom: .75rem;
    color: $primary;
    font-size: 1.25rem;
    font-weight: $font-weight-semibold;
    letter-spacing: rem(-.5px);
    line-height: 1;
  }
}

.article-card-highlighted {
  position: relative;
  height: rem(324px);
  transition: .2s filter;

  .card-wrapper {
    display: flex;
    flex-flow: column wrap;
    height: 100%;
    padding: 1.5rem 1rem;
    border: rem(1px) solid $primary-200;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      filter: drop-shadow(0 0 1.75rem rgba($black, .5));
    }
  }

  .card-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .card-body {
    position: relative;
    margin-top: auto;
    padding: 0;
  }

  .card-title {
    margin-bottom: .75rem;
    color: $white;
    font-size: 1.5rem;
    font-weight: $font-weight-semibold;
    line-height: 1.17;

    @include media-breakpoint-up(md) {
      margin-bottom: .5rem;
    }
  }
}
