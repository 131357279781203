.hce-product-specifications {
  padding-bottom: 2rem;
  background-color: $primary-100;

  &__container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    max-width: rem(map-get($container-max-widths, xl));
    padding-left: 1.25rem;
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      padding-right: .75rem;
      padding-left: .75rem;
    }
  }

  &__wrapper {
    flex: 1 1 auto;
    position: relative;
    top: -2.5rem;
    max-width: 100%;
    padding: 3.25rem 1.25rem rem(45px);
    border: rem(1px) solid $primary-200;
    margin-bottom: -2.5rem;
    background-color: $white;
    overflow: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    @include media-breakpoint-up(sm) {
      padding-top: 0;
    }

    @include media-breakpoint-up(md) {
      overflow: visible;
    }

    .cut-corner {
      display: none;
      position: absolute;
      right: rem(-1px);
      bottom: rem(-1px);
      width: rem(15px);
      height: rem(24px);

      polygon {
        fill: $primary-100;
      }
      line {
        stroke: $primary-200;
      }

      @include media-breakpoint-up(md) {
        display: block;
      }
    }
  }

  &__label {
    color: $primary-600;
    font-size: $font-size-sm;
    line-height: 1.25rem;

    .icon,
    .icon svg {
      width: 1rem;
      height: 1rem;
      fill: $primary-600;
    }
  }

  &__scroll-hint {
    opacity: 1;
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
    transition: opacity .5s ease-out;

    @include media-breakpoint-up(sm) {
      display: none;
    }

    &.is-hidden {
      opacity: 0;
    }
  }

  &__margin-wrapper {
    margin-top: 1.25rem;
    margin-bottom: 2rem;
  }

  &__footnote {
    margin-top: 1.25rem;
    white-space: nowrap;
  }

  .hce-product-table {
    min-width: rem(map-get($container-max-widths, sm)) - 2.5rem; // minus wrapper padding
  }
}

.product-specifications-anchor {
  &:before {
    content: "";
    display: block;
    position: relative;
    top: rem(-100px) - 3rem - 4rem; // fixed navigation + whitespace + breathing space
    height: rem(1px);
    margin-top: rem(-1px);
  }
}
