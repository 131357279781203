@mixin animate($duration: null) {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);

  @if ($duration) {
    transition-duration: $duration;
  }

  @else {
    transition-duration: 100ms;
  }
}
