.image-caption {
  position: relative;
  margin-right: rem(-20px);

  @include media-breakpoint-up(md) {
    margin-right: 0;
  }

  .image-wrapper {
    position: relative;

    .gradient {
      @include media-breakpoint-up(md) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(
          to bottom,
          transparent 75%,
          rgba($primary, .6)
        );
      }
    }
  }

  .caption {
    margin-top: .75rem;
    margin-right: 1.25rem;
    color: $primary-600;
    text-align: right;

    @include media-breakpoint-up(md) {
      position: absolute;
      right: 1.25rem;
      bottom: .75rem;
      max-width: 50%;
      margin: 0;
      color: $white;
    }
  }
}
