.hce-product-link-box {
  .card {
    background-color: $secondary;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.5rem;

    .img-fluid {
      height: auto;
      width: 100%;
    }

    &-header,
    &-body {
      color: $white;

      ul {
        font-size: 1.25rem;
        font-weight: 400;

        span {
          font-weight: 700;
        }
      }
    }

    &-body {
      ul {
        margin-bottom: 1.5rem;
        padding-left: 22px;
      }
    }

    &-header {
      position: relative;

      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        list-style: none;
        margin-bottom: 0;
        margin-top: 10px;
        padding: 0;

        span {
          color: $white;
        }
      }
    }

    &-title {
      h3 {
        color: $white;
        margin-bottom: 1rem;
      }

      h4 {
        color: $white;
        font-size: 1.5rem;
      }
    }
  }

  .btn {
    display: table;
    font-size: 1.125rem;
    font-weight: 700;
    margin: 0 auto;

    &-primary {
      &:focus,
      &:hover,
      &:active {
        border: 1px solid $primary !important;
      }
    }
  }

  &[data-mode="vertical"] {
    .card {
      &-title {
        margin-top: 1rem;
      }
    }
  }

  @include media-breakpoint-between(sm, sm) {
    .card {
      &-header {
        ul {
          flex-direction: column;
          position: absolute;
          right: 0;
        }
      }

      &-title {
        margin-top: 1rem;
      }
    }
  }

  @include media-breakpoint-up(md) {
    &[data-mode="horizontal"] {
      .card {
        flex-direction: row-reverse;

        &-header,
        &-body {
          //align-self: flex-start;
          flex: 1 0 0;
        }

        &-body {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }

      .btn {
        display: inline-block;
        margin: 0 auto 0 0;
      }
    }
  }

  @include media-breakpoint-between(md, md) {
    &[data-mode="horizontal"] {
      .card {
        &-header {
          padding-left: 1.5rem;
        }

        &-body {
          padding-right: 1.5rem;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .card {
      &-header,
      &-body {
        padding: 0 1.5rem;
      }
    }

    &[data-mode="horizontal"] {
      .card {
        // &-header {
        //   ul {
        //     margin-bottom: 0;
        //   }
        // }

        &-header,
        &-body {
          padding: 0 3rem;
        }
      }

      &[data-adaptive="sm"] {
        .card {
          &-header {
            padding: 0 0 0 1.5rem;
          }
    
          &-body {
            padding: 0 1.5rem 0 0;
          }
        }
      }
    }

    &[data-mode="vertical"] {
      .card {
        &-header {
          ul {
            flex-direction: column;
            margin-bottom: 1rem;
            position: absolute;
            right: 0;
          }
        }
      }

      &[data-adaptive="xs"] {
        .card {
          &-header,  
          &-body {
            padding: 0;
          }
  
          &-header {
            ul {
              position: relative;
            }
          }
  
          &-title {
            margin-top: 0;
          }
        }
      }
    }
  }
}
