// bootstrap grid overrides

// margins per breakpoint
.container,
.container-fluid {
  padding-left: 20px;
  padding-right: 20px;

  @include media-breakpoint-up(lg) {
    padding-left: $grid-gutter-width * .5;
    padding-right: $grid-gutter-width * .5;
  }
}

.no-container-gutters {
  margin: 0 -20px 0 -20px !important;
  @include media-breakpoint-up(lg) {
    margin: 0 (0 - ($grid-gutter-width * .5)) 0 (0 - ($grid-gutter-width * .5)) !important;
  }
}
