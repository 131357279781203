.hero-header {
  position: relative;

  &__image-container {
    padding-left: rem(20px);

    @include media-breakpoint-up(xl) {
      padding-left: rem(100px);
    }

    // gradient
    /*.hero-header--transparent &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: rem(20px);
      background: linear-gradient(180deg, rgba($primary, .1) 0, rgba($primary, .6) 100%);
      clip-path: inherit;

      @include media-breakpoint-up(xl) {
        left: rem(100px);
      }
    }*/
  }

  &__image {
    height: rem(256px);
    clip-path: inherit;

    @include media-breakpoint-up(md) {
      height: rem(320px);
    }

    @include media-breakpoint-up(lg) {
      height: rem(648px);
    }

    .hero-header--transparent & {
      height: rem(536px);

      @include media-breakpoint-up(lg) {
        height: rem(648px);
      }
    }

    .hero-header--transparent.hero-header--medium & {
      height: rem(432px);
    }

    .hero-header--none.hero-header--medium & {
      height: rem(432px);
      @include media-breakpoint-down(sm) {
        height: rem(250px);
      }
    }
  }

  .container {
    @include media-breakpoint-down(sm) {
      padding-right: 0;
    }
  }

  &__title {
    margin-bottom: 1rem;
  }

  &__subtitle {
    font-weight: $font-weight-medium;
  }

  &__title,
  &__subtitle {
    color: $white;

    .hero-header--transparent & {
      text-shadow: 0 0 2.5rem rgba(0, 0, 0, .3);
    }

    .hero-header__color--primary & {
      color: $primary;
    }
    .hero-header__color--secondary & {
      color: $secondary;
    }
    .hero-header--none & {
      @include media-breakpoint-down(sm) {
        color: $primary;
      }
    }
  }

  &__back-link {
    position: absolute;
    top: 10px;
    left: rem(25px);
    a {
      color: $white;
      svg {
        fill: $white;
      }
    }

    @include media-breakpoint-up(xl) {
      left: rem(110px);
    }
    .hero-header__color--primary & {
      a {
        color: $primary;
        svg {
          fill: $primary;
        }
      }
    }
    .hero-header__color--secondary & {
      a {
        color: $secondary;
        svg {
          fill: $secondary;
        }
      }
    }
  }

  &__panel {
    position: relative;
    padding: 1.5rem 2rem 1rem 2rem;
    margin-top: -3rem;
    margin-left: 1.25rem;

    @include media-breakpoint-up(sm) {
      margin-left: -2rem;
    }

    @include media-breakpoint-up(md) {
      margin-left: 0;
    }

    .hero-header--primary & {
      background-color: $primary;
    }

    .hero-header--secondary & {
      background-color: $secondary;
    }

    .hero-header--transparent & {
      position: absolute;
      bottom: 8rem;
      background-color: transparent;
    }

    .hero-header--none & {
      position: absolute;
      bottom: 0;
      background-color: transparent;
      padding-left: 0;
      @include media-breakpoint-down(sm) {
        position: relative;
        margin-top: 0px;
      }
    }

    .hero-header--transparent.hero-header--medium & {
      bottom: 4rem;
    }

    @include media-breakpoint-up(md) {
      width: rem(565px);
      padding: 2rem 2rem 1rem 2.5rem;
      margin: auto;
      margin-top: -4rem;
    }

    @include media-breakpoint-up(lg) {
      width: rem(714px);
      padding: 3rem 3rem 2rem 5rem;
      margin: 0;
      margin-top: -10rem;
    }
  }
}
