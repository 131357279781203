.link-list {
  &--social {
    position: relative;

    .list {
      > .list-inline-item {
        margin-right: rem(27px);

        @include media-breakpoint-up(md) {
          margin-right: rem(17px);
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .social-link {
      .icon {
        width: 32px;
        height: 32px;
        fill: $white;
        opacity: .9;
        transition: 100ms opacity ease-in-out;

        svg {
          width: 32px;
          height: 32px;
        }

        // youtube icon exception
        &--youtube {
          width: 38px;
          height: 38px;

          svg {
            width: 38px;
            height: 38px;
          }
        }

        @include media-breakpoint-up(md) {
          width: 20px;
          height: 20px;

          svg {
            width: 20px;
            height: 20px;
          }

          // youtube icon exception
          &--youtube {
            width: 26px;
            height: 26px;

            svg {
              width: 26px;
              height: 26px;
            }
          }
        }
      }

      &:hover {
        .icon {
          opacity: 1;
          fill: $secondary;
        }
      }
    }
  }
}
