.whitespace {
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
  background-color: transparent;

  &--small,
  &--sm {
    height: rem(24px);
  }

  &--medium,
  &--md {
    height: rem(48px);
  }

  &--large,
  &--lg {
    height: rem(72px);
  }

  &--extralarge,
  &--xl {
    height: rem(96px);
  }
}
