/*
// light
@font-face {
  font-family: 'Hind';
  src: url('/assets/fonts/Hind/Hind-Light.woff2') format('woff2'),
  url('/assets/fonts/Hind/Hind-Light.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: fallback;
}

// regular
@font-face {
  font-family: 'Hind';
  src: url('/assets/fonts/Hind/Hind-Regular.woff2') format('woff2'),
  url('/assets/fonts/Hind/Hind-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

// medium
@font-face {
  font-family: 'Hind';
  src: url('/assets/fonts/Hind/Hind-Medium.woff2') format('woff2'),
  url('/assets/fonts/Hind/Hind-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

// semibold
@font-face {
  font-family: 'Hind';
  src: url('/assets/fonts/Hind/Hind-SemiBold.woff2') format('woff2'),
  url('/assets/fonts/Hind/Hind-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}

// bold
@font-face {
  font-family: 'Hind';
  src: url('/assets/fonts/Hind/Hind-Bold.woff2') format('woff2'),
  url('/assets/fonts/Hind/Hind-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}
*/
// HDHyundaiGothicEN
@font-face {
  font-family: 'HDHyundaiGothicEN';
  src: url('/assets/fonts/HDHyundaiGothicEN/woff2/HDHyundaiGothicEN-Light.woff2') format('woff2'),
  url('/assets/fonts/HDHyundaiGothicEN/woff/HDHyundaiGothicEN-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'HDHyundaiGothicEN';
  src: url('/assets/fonts/HDHyundaiGothicEN/woff2/HDHyundaiGothicEN-Regular.woff2') format('woff2'),
  url('/assets/fonts/HDHyundaiGothicEN/woff/HDHyundaiGothicEN-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'HDHyundaiGothicEN';
  src: url('/assets/fonts/HDHyundaiGothicEN/woff2/HDHyundaiGothicEN-Bold.woff2') format('woff2'),
  url('/assets/fonts/HDHyundaiGothicEN/woff/HDHyundaiGothicEN-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}