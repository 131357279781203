$ql-quicklink-item-height: rem(130px);
$ql-transition-speed: 80ms;
$ql-box-shadow: 0 rem(2px) rem(40px) 0 rgba(0, 0, 0, .25);
$ql-box-shadow-hover: 0 rem(15px) rem(40px) 0 rgba(0, 0, 0, .25);

.quick-links {
  margin-top: rem(-55px);

  &__nav {
    gap: 1px;
  }

  &__item {
    background-color: $secondary;
    position: relative;
    height: $ql-quicklink-item-height;
    background-clip: padding-box;
    box-shadow: $ql-box-shadow;
    flex: 0 0 calc(50% - 1px);

    &:hover {
      @include media-breakpoint-up(lg) {
        box-shadow: $ql-box-shadow-hover;
      }
    }

    a {
      display: flex;
      height: 100%;
      margin-right: rem(-1px);
      text-decoration: none;
      transition: all $ql-transition-speed ease-in;

      &:hover,
      &.is-active {
        @include media-breakpoint-up(lg) {
          background-color: $primary;
          transform: scaleY(1.3) translateY(rem(4px));
        }
      }
    }

    @include media-breakpoint-up(md) {
      flex-basis: calc(33.33% - 1px);
    }

    @include media-breakpoint-up(lg) {
      flex-basis: calc(20% - 1px);
    }
  }

  &__content {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: rem(14px);
    left: 0;
    top: 0;
    pointer-events: none;

    .label {
      width: 100%;
      min-height: rem(42px);
      color: theme-color("white");
      text-align: center;
      text-transform: uppercase;
      font-size: rem(14px);
      letter-spacing: rem(3px);
    }

    .icon {
      width: 4.5rem;
      height: 4.5rem;

      svg {
        width: 100%;
        height: 100%;
        fill: theme-color("white");
      }
    }
  }
}
