.product-introduction {
  padding-left: 1.25rem;
  padding-bottom: 1.5rem;

  @include media-breakpoint-up(md) {
    padding-left: 0;
  }

  &__title {
    margin-bottom: 1rem;
    font-size: 2.25rem;
    line-height: 2.25rem;

    @include media-breakpoint-up(md) {
      font-size: $h1-font-size;
      line-height: 3rem;
    }
  }

  &__subtitle {
    margin-bottom: 1rem;
    color: $primary-600;

    @include media-breakpoint-up(md) {
      margin-bottom: 1.5rem;
    }
  }

  &__summary {
    margin-bottom: 1rem;
  }
}
