@use 'sass:math';

///
/// Unitless line height based font-size.
/// @param {string} $height Height in pixels
/// @param {string} $font-size Font size in pixels
/// @author Rocco Janse, <rocco.janse@valtech.nl>
///
@mixin line-height($height, $font-size) {
  line-height: math.div(strip-units($height), strip-units($font-size));
}

///
/// Anti alias fonts.
/// @param {string} $value [antialiased] None, antialiased (default), subpixel-antialiased
/// @author Rocco Janse, <rocco.janse@valtech.nl>
///
@mixin font-smoothing($value: antialiased) {
  @if $value == antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}
