.product-specs-details {
  &__label {
    font-size: $font-size-xs;
    color: $primary-900;
    vertical-align: top;

    @include media-breakpoint-up(md) {
      height: auto;
      font-size: $font-size-sm;
    }
  }

  &__value {
    color: $primary-900;
    font-size: $font-size-base;
    font-weight: bold;
  }
}
