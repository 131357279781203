@import "../../../../node_modules/swiper/css/swiper.min.css";

.hce-image-gallery {
  &__carousel {
    @include media-breakpoint-up(md) {
      transform: translateY(rem(50px));
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  &__controls {
    &-sm {
      display: flex;
      justify-content: flex-end;
      margin-top: 1.25rem;
    }

    &-md {
      display: none;
    }

    @include media-breakpoint-up(md) {
      &-sm {
        display: none;
      }
      &-md {
        display: flex;
      }
    }
  }

  &__slides {
    margin-right: -1.25rem;

    @include media-breakpoint-up(md) {
      margin-right: 0;
    }
  }

  &__slide {
    position: relative;
    width: auto;
    border-bottom: .25rem solid transparent;
    transition: border 150ms;

    &:not(:last-child) img {
      border-right: 0;
    }

    /*&:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to bottom, transparent, rgba($primary, .75));
      transition: opacity 200ms;

      @include media-breakpoint-up(md) {
        opacity: 0;
      }
    }*/

    &:hover {
      z-index: 1;
      border-bottom-color: $secondary;
      box-shadow: 0 0 rem(30px) 0 rgba($black, .5);

      &:after {
        opacity: 1;
      }

      .hce-image-gallery__zoom-in {
        display: block;
        opacity: 1;
      }

      img {
        outline: none;
      }
    }

    img {
      max-height: rem(292px);
      border: rem(1px) solid $primary-600;
    }

    button {
      background-color: transparent;
      border: 0;
      padding: 0;
    }
  }

  &__zoom-in {
    z-index: 1;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    opacity: .75;
    width: 3.5rem;
    height: 3.5rem;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__scroll-shadow {
    z-index: 1;
    opacity: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: .25rem;
    width: 5rem;
    background-image: linear-gradient(
      to right,
      transparent,
      rgba($primary, .6)
    );
    pointer-events: none;
    transition: opacity .5s ease-out;

    &.is-hidden {
      opacity: 0;
    }
  }

  .image-gallery-overlay {
    z-index: 1000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($primary, .9);

    .swiper-container {
      width: 100%;
      height: 100%;
    }

    &__slide {
      img {
        @include align-center(absolute);
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 60%;
      }
    }

    button {
      border: 0;
      background-color: transparent;
    }

    &__close {
      z-index: 10;
      position: absolute;
      top: 1.25rem;
      right: 1.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.5rem;
      height: 3.5rem;
      padding: 0;
      cursor: pointer;
    }
  }

  .icon {
    width: inherit;
    height: inherit;
    color: $white;
    fill: $white;
  }

  .swiper-button-next,
  .swiper-button-prev {
    height: auto;
    margin: 0;
    transform: translateY(-50%);
    color: $white;
  }

  .swiper-button-next {
    right: 2rem;

    &:after {
      @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) {
        content: "→";
        font-size: 2rem;
      }
    }
  }

  .swiper-button-prev {
    left: 2rem;

    &:after {
      @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) {
        content: "←";
        font-size: 2rem;
      }
    }
  }

  .swiper-pagination {
    bottom: 2.5rem;

    &-bullet {
      background-color: $primary-100;
    }
  }
}

.no-scroll {
  overflow: hidden;
}
