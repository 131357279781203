///
/// Hide from both screenreaders and browsers.
/// @author Rocco Janse, <rocco.janse@valtech.nl>
///
@mixin hidden {
  display: none;
  visibility: hidden;
}

///
/// Hide only visually, but have it available for screenreaders.
/// @param {boolean} $focusable [false] Should the element be focusable
/// @author Rocco Janse, <rocco.janse@valtech.nl>
///
@mixin screenreader($focusable: false) {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  @if $focusable {
      @include screenreader-focusable;
  }
}

///
/// Allow the element to be focusable when navigated to via the keyboard.
/// @author Rocco Janse, <rocco.janse@valtech.nl>
///
@mixin screenreader-focusable {
  &:active,
  &:focus {
      clip: auto;
      height: auto;
      margin: 0;
      overflow: visible;
      position: static;
      width: auto;
  }
}

///
/// Resets component after hidden for screenreaders.
/// @author Rocco Janse, <rocco.janse@valtech.nl>
///
@mixin screenreader-reset {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

///
/// Disable browser selection of elements. Usefull for menu's, navigations etc.
/// @author Rocco Janse, <rocco.janse@valtech.nl>
///
@mixin unselectable {
  -webkit-touch-callout: none;
  user-select: none;
}
