// Define spacing scale (matching Bootstrap 5's gap utility)
$gap-sizes: (
    0: 0,
    1: 5px,
    2: 10px,
    3: 15px,
    4: 20px,
    5: 25px,
    6: 30px,
    7: 35px,
    8: 40px,
    9: 45px,
    10: 50px
) !default;

// Generate `.gap-*`, `.row-gap-*` and `.column-gap-*` utilities
@each $key, $size in $gap-sizes {
    .gap-#{$key} {
        gap: $size !important;
    }

    .row-gap-#{$key} {
        row-gap: $size !important;
    }

    .column-gap-#{$key} {
        column-gap: $size !important;
    }
}
