.hce-article-detail {
  padding-bottom: 3rem;

  .article-header {
    padding-top: 1.5rem;
    background-color: $primary-100;

    .back-button {
      margin-bottom: 1rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 2rem;
      }

      a {
        padding-right: 0;
        padding-left: 0;
      }
    }

    h1 {
      margin-bottom: 1.25rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 1.5rem;
      }
    }
  }

  .article-wrapper {
    width: 100%;
    padding-left: 1.25rem;
  }

  time {
    color: $primary-600;
  }

  .article-intro {
    margin-top: 1.75rem;
    color: $primary-800;
    line-height: 1.56;
    font-weight: $font-weight-semibold;
    letter-spacing: 0;

    @include media-breakpoint-up(md) {
      margin-top: 2rem;
      color: $primary-dark;
      line-height: 1.8;
      letter-spacing: rem(-.5px);
    }
  }

  .article-hero {
    padding: 3rem 0;
    
    &:last-child {
      padding: 0;
    }
  }

  .image-caption {
    img {
      width: 100%;
      height: rem(212px);
      object-fit: cover;

      @include media-breakpoint-up(md) {
        height: rem(432px);
      }
    }
  }

  .main-content {
    > p:not(:last-child),
    ul {
      margin-bottom: 2rem;
    }

    ul {
      padding-left: 1.25rem;

      li {
        color: $primary-800;
      }
    }
  }

  h4 {
    font-size: rem(18px);
  }
}
